/* import MuiButton from './MuiButton';
import MuiChip from './MuiChip';
import MuiFab from './MuiFab';
import MuiFilledInput from './MuiFilledInput';
import MuiIconButton from './MuiIconButton';
import MuiInputBase from './MuiInputBase';
import MuiLinearProgress from './MuiLinearProgress';
import MuiListItemIcon from './MuiListItemIcon';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiSvgIcon from './MuiSvgIcon';
import MuiTab from './MuiTab';
import MuiTableCell from './MuiTableCell';
import MuiTableRow from './MuiTableRow';
import MuiTabs from './MuiTabs';
import MuiInputLabel from './MuiInputLabel';
import MuiPickerDTTabs from './MuiPickerDTTabs';
import MuiPickersToolbar from './MuiPickersToolbar';
import MuiPickersDay from './MuiPickersDay';
import MuiPickerDTToolbar from './MuiPickerDTToolbar'; */

const modules = {
  /*   MuiButton,
  MuiChip,
  MuiFab,
  MuiFilledInput,
  MuiIconButton,
  MuiInputBase,
  MuiLinearProgress,
  MuiListItemIcon,
  MuiOutlinedInput,
  MuiSvgIcon,
  MuiTab,
  MuiTableCell,
  MuiTableRow,
  MuiTabs,
  MuiInputLabel,
  MuiPickerDTTabs,
  MuiPickersToolbar,
  MuiPickersDay,
  MuiPickerDTToolbar, */
};

export default modules;
