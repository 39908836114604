// @ts-check
import { Box, makeStyles, Switch } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import React from 'react';
import styles from './style';

const useStyles = makeStyles(styles);

/**
 * @typedef Props
 * @property {(value: boolean) => void} onChange
 * @property {boolean | undefined} value
 * @param {Props} props
 */
const SafeGridToggle = ({ value, onChange }) => {
  const classes = useStyles();

  /** @type {React.ComponentProps<typeof Switch>['onChange']} */
  const onSwitchValueChange = (...[, checked]) => {
    onChange(checked);
  };

  return (
    <FormControl>
      <Box alignItems="center" display="flex">
        <Switch value={value} onChange={onSwitchValueChange} />
        <Box className={classes.switchDescription}>View safe area grid</Box>
      </Box>
    </FormControl>
  );
};

export default SafeGridToggle;
