import React, { useState, useEffect } from 'react';
import { AvailabilityDropDownProps } from './types';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Select from '../../design/Select';
import { AvailabilityDropDownContainer } from './style';

const AvailabilityDropDown: React.FC<AvailabilityDropDownProps> = ({
  value,
  onChange,
  labels,
  unAvailableForOptions = [],
}) => {
  const [availablity, setAvailablity] = useState(value);
  useEffect(() => {
    setAvailablity(value);
  }, [value]);

  const handleChange = (e) => {
    setAvailablity(e.target.value);
    onChange(e.target.value);
  };

  // Using labels for default options for backwards compatibility
  const options = [
    {
      value: 'true',
      label: labels.available,
      icon: <FiberManualRecordIcon style={{ color: '#65B202', fontSize: 12 }} />,
    },
    {
      value: 'false',
      label: labels.unavailable,
      icon: <FiberManualRecordIcon style={{ color: '#C20018', fontSize: 12 }} />,
    },
  ];

  unAvailableForOptions.forEach((option) => {
    options.push({
      value: option.value,
      label: option.label,
      icon: <FiberManualRecordIcon style={{ color: '#C20018', fontSize: 12 }} />,
    });
  });

  return (
    <AvailabilityDropDownContainer>
      <Select value={availablity} options={options} onChange={handleChange} />
    </AvailabilityDropDownContainer>
  );
};

export default AvailabilityDropDown;
