import React from 'react';
import { SectionWrapper, StyledInput, Column, BottomLabelInput } from '../styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { nutritionDetailsFields } from './constants';
import { hasFieldError } from '../utils';
import { NutritionDetailsSectionProps } from '../types';

const NutritionDetailsSection: React.FC<NutritionDetailsSectionProps> = (props) => {
  const { t, handleOnChange, isLiquid } = props;
  const translationKeyWithPrefix = (key) => `menu_management_pandora.${key}`;

  const title = React.useMemo(
    () =>
      isLiquid
        ? t(translationKeyWithPrefix('nutrition_details_title_liquid'))
        : t(translationKeyWithPrefix('nutrition_details_title')),
    [t, isLiquid]
  );

  const renderEndAdornment = (value) =>
    value && <InputAdornment position="end">{value}</InputAdornment>;

  const hasError = (field: string): boolean => hasFieldError(field, props.errors);

  return (
    <SectionWrapper title={title}>
      <div style={{ display: 'flex' }}>
        {nutritionDetailsFields.map((column, i) => (
          <Column key={`nutr_details_column_${i}`} style={{ marginRight: i === 0 ? 16 : 0 }}>
            {column.map(({ id, name, endAdornment }) => (
              <BottomLabelInput key={id} bottomLabel={`${t(translationKeyWithPrefix(id))}`}>
                <StyledInput
                  id={id}
                  margin="normal"
                  value={props[name]}
                  InputProps={{
                    endAdornment: renderEndAdornment(endAdornment),
                  }}
                  onChange={handleOnChange}
                  name={name}
                  type={'number'}
                  error={hasError(name)}
                />
              </BottomLabelInput>
            ))}
          </Column>
        ))}
      </div>
    </SectionWrapper>
  );
};

export default NutritionDetailsSection;
