import React, { useRef, useState } from 'react';
import {
  Box,
  Card,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { VariantChoiceGroupAssociationSection as VariantChoiceGroup } from '../../../../../components';
import t from '../../../../../utility/Translate';
import { getDefaultLanguage } from '../../../../../utility/information';
import ProductSection from '../../../../components/ProductDetail/ProductSection';
import LoadingState from '../../../../../components/LoadingState';

const ProductChoiceGroups = ({
  variants,
  choiceGroupAPIStatus,
  vendorChoiceGroups,
  onChoiceGroupChange,
  variantChoiceGroupAssociation,
  languageKeys,
  isAgentAssigned,
  fwfFlags,
  newChoiceGroupData,
}) => {
  const getChoiceGroupState = (variants) => {
    let hasIds = false;
    let hasObjects = false;

    for (let i = 0; i < variants.length; i++) {
      hasIds = variants?.[i]?.choiceGroupIds?.length > 0 || hasIds;
      hasObjects = variants?.[i]?.choiceGroups?.length > 0 || hasObjects;
    }
    return [hasIds, hasObjects];
  };

  if (newChoiceGroupData && newChoiceGroupData.length > 0 && variants.length > 0) {
    newChoiceGroupData.forEach((element, key) => {
      element.tempId = key;
    });

    variants.forEach((variant) => {
      newChoiceGroupData.forEach((cgData) => {
        if (variant.id === cgData.variantId && cgData.hasOwnProperty('title')) {
          if (Array.isArray(variant.choiceGroups)) {
            const found = variant.choiceGroups.find(
              (variantCg) => variantCg.tempId === cgData.tempId
            );
            if (!found) {
              variant.choiceGroups.push(cgData);
            }
          } else {
            variant.choiceGroups = [cgData];
          }
          variant.quantity = cgData.quantity;
        }
      });
    });
  }

  const [hasChoiceGroupIds, hasChoiceGroupObjects] = getChoiceGroupState(variants);

  if (choiceGroupAPIStatus !== undefined && !choiceGroupAPIStatus)
    return (
      <Box mt={2}>
        <LoadingState />
      </Box>
    );

  //Do not render anything if cannot determine applicable data structure
  if (!hasChoiceGroupIds && !hasChoiceGroupObjects) return null;

  //Embedded*** change types will return ChoiceGroup objects and will crash the VariantChoiceGroup component
  return (
    <>
      {(hasChoiceGroupIds && hasChoiceGroupObjects) ||
      (newChoiceGroupData && newChoiceGroupData.length > 0) ? (
        <EmbeddedChoiceGroups variants={variants} />
      ) : (
        <div data-enzyme={'VariantChoiceGroup'}>
          <VariantChoiceGroup
            t={t}
            variants={variants}
            choiceGroups={[...vendorChoiceGroups]}
            // May need to add handler to let agent ammend in the future
            handleChoiceGroupSelect={onChoiceGroupChange}
            variantChoiceGroupAssociation={variantChoiceGroupAssociation}
            currentLanguage={languageKeys[0]}
            selectingDisabled={
              !(isAgentAssigned && fwfFlags.fwfProductDetailChoiceGroupsField.variation)
            }
          />
        </div>
      )}
    </>
  );
};

export default ProductChoiceGroups;

const EmbeddedChoiceGroups = ({ variants }) => {
  return (
    <>
      {variants.map((embeddedVariant) => {
        const variantTitle = embeddedVariant?.title?.[getDefaultLanguage(embeddedVariant?.title)];
        if (
          !embeddedVariant.hasOwnProperty('choice_groups') &&
          !embeddedVariant.hasOwnProperty('choiceGroups')
        )
          return <></>;
        return (
          <ProductSection
            title={`Choice Groups ${variantTitle ? '-' + variantTitle : ''}`}
            key={embeddedVariant.id}
          >
            <ChoiceGroupContainer choiceGroups={embeddedVariant.choiceGroups} />
          </ProductSection>
        );
      })}
    </>
  );
};

const ChoiceGroupContainer = ({ choiceGroups }) => {
  return (
    <Table>
      <TableHead>
        <TableCell>Name</TableCell>
        <TableCell>Choices</TableCell>
      </TableHead>
      <TableBody>
        {choiceGroups?.length > 0 ? (
          choiceGroups?.map((choiceGroup) => {
            return <ChoiceGroup choiceGroup={choiceGroup} key={choiceGroup.id} />;
          })
        ) : (
          <TableRow>
            <TableCell colSpan={2}>No choice groups assigned</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

const ChoiceGroup = ({ choiceGroup }) => {
  const anchorElement = useRef(null);
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);

  const togglePopover = (isOpen) => () => {
    setIsPopOverOpen(isOpen);
  };

  const choices = choiceGroup?.choices;

  return (
    <TableRow>
      <TableCell>
        {choiceGroup?.title?.[getDefaultLanguage(choiceGroup?.title)] ?? choiceGroup?.id}
      </TableCell>
      <TableCell ref={anchorElement}>
        <div style={{ display: 'flex' }}>
          <div style={{ whiteSpace: '', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {Array.isArray(choices) &&
              choices
                ?.map((choice) => {
                  return choice?.title?.[getDefaultLanguage(choice?.title)];
                })
                .join(', ')}
          </div>
          <div style={{ marginLeft: 'auto' }} onClick={togglePopover(true)}>
            See Details
          </div>
        </div>
      </TableCell>
      <Popover
        open={isPopOverOpen}
        anchorEl={anchorElement?.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClick={togglePopover(false)}
      >
        <Card>
          <div style={{ padding: '1rem' }}>
            <Typography variant={'h5'}>
              {Object.values(choiceGroup?.title ?? {})
                .map((title) => title)
                .join(', ')}
            </Typography>
            <Typography variant={'subtitle2'}>
              Min: {choiceGroup?.quantity?.minimum ?? 0}, Max: {choiceGroup?.quantity?.maximum ?? 0}
            </Typography>
          </div>
          <Table>
            <TableHead>
              <TableCell>Choice Name</TableCell>
              <TableCell>Price</TableCell>
            </TableHead>
            {choices?.map((choice) => {
              return (
                <TableRow>
                  <TableCell>
                    {Object.values(choice?.title ?? {})
                      .map((title) => title)
                      .join(', ')}
                  </TableCell>
                  <TableCell>{choice?.price}</TableCell>
                </TableRow>
              );
            })}
          </Table>
        </Card>
      </Popover>
    </TableRow>
  );
};
