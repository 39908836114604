import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { getCountryPlugins } from '../App/utility/countryPlugin';

const APPLICATION_NAME = 'ops-portal-fuze';

/*
 * Collect the plugins available to users based on IAM data
 */
const usePlugins = (currentCountry, currentUser, fwfLoading, fwfFlags) => {
  const [plugins, setPlugins] = useState([]);
  const [parents, setParents] = useState([]);

  /*   console.log(
    'currentCountry',
    currentCountry,
    'currentUser',
    currentUser,
    'fwfLoading',
    fwfLoading,
    'fwfFlags',
    fwfFlags
  ); */
  useEffect(() => {
    const extractCountryPlugins = () => {
      if (isEmpty(currentCountry) || isEmpty(currentUser) || fwfLoading !== false) {
        return;
      }

      let IAMdata;
      if (currentUser.permissions) {
        IAMdata = currentUser.permissions[APPLICATION_NAME];
      }

      const { fwfPluginACL, fwfMasterACL, fwfCountryACL } = fwfFlags;
      const fwfCheck =
        fwfPluginACL.variation &&
        fwfPluginACL.loaded &&
        fwfCountryACL.variation &&
        fwfCountryACL.loaded &&
        fwfMasterACL.variation &&
        fwfMasterACL.loaded;

      const { parents, availablePlugins: plugins } = getCountryPlugins({
        fwfFlags,
        fwfCheck,
        currentCountry,
        IAMdata,
      });
      setPlugins(plugins);
      setParents(parents);
    };

    extractCountryPlugins();
  }, [currentCountry, currentUser, fwfLoading, fwfFlags]);
  return { parents, plugins };
};

export default usePlugins;
