export const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    width: 500,
    outline: "none",
    padding: 20,
  },
});

export default styles;
