import React, { useCallback, useEffect, useState } from 'react';
import { formatErrorForDisplay } from 'utility';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LoaderWrapper from '../../../../App/components/LoaderWrapper';

import { useThunkReducer } from '../../../../App/hooks';
import pendingNumberReducer from '../../../store/pendingNumbers/reducer';
import masterCategoriesReducer from '../../../store/masterCategories/reducer';
import { usePrevious } from '../../../../utility/hook';
import { putDishPhoto, search, uploadPhotos, batchImageDownload } from '../helpers';
import { useDishPhotoService } from '../../../../hooks/useDishPhotoService';
import { fetchPendingNumberAsync } from '../../../store/pendingNumbers/actions';
import { fetchMasterCategories } from '../../../store/masterCategories/actions';
import config from '../../../../App/config';
import TableContent from '../Table';
import AppRightBar from '../../../../App/layouts/AppRightBar';
import ActionBar from '../ActionBar';
import PhotoFilter from '../../../components/PhotoFilter';
import CategoryFilter from '../../../components/CategoryFilter';
import SafeGridToggle from '../../../components/SafeGridToggle';
import Notification from '../../../../App/components/Notification';
import UploadPhotoModal from '../../../components/UploadPhotoModal';
import RejectModal from '../../../../App/components/RejectModal';
import { getPhotoRejectReasons } from '../../../common/constants/rejectReason';
import styles from '../styles';
import EmptyState from '../../../../components/EmptyState2';
import { AppContext, UserContext } from '../../../../App';
import { useDispatch, useSelector } from 'react-redux';
const useStyles = makeStyles(styles);

const STATUS = 'pending';

const VSSPhotoQueue = ({ customContext, fwfFlags }) => {
  const { fetchDishPhoto } = useDishPhotoService();

  const classes = useStyles();
  const [groupedPhoto, setGroupedPhoto] = useState([]);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [downloading, setDownloadInProgress] = useState(false);
  const [checks, setChecks] = useState([]);
  const [checkedAllPhoto, setCheckedAllPhoto] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [successNotification, setSuccessNotification] = useState({
    open: false,
    message: '',
    variant: '',
  });
  const [errorNotification, setErrorNotification] = useState({
    open: false,
    message: '',
    variant: '',
  });
  const currentUser = React.useContext(UserContext);
  const agentEmail = currentUser.sub;
  const [state, dispatch] = useThunkReducer(pendingNumberReducer, {
    loading: false,
    data: 0,
  });
  const { currentCountry } = React.useContext(AppContext);

  const { fwfDishPhotoMasterCategoryFilter, fwfDishPhotoTypeFilter, fwfPhotoSafeGrid } = fwfFlags;
  const [masterCategories, dispatchMasterCategories] = useThunkReducer(masterCategoriesReducer, {
    loading: false,
    data: [],
    error: null,
  });
  const [masterCategory, setMasterCategory] = useState('');
  const [photoType, setPhotoType] = useState('');
  const [isSafeGridEnabled, setIsSafeGridEnabled] = useState(false);

  const [uploadPhotoLoading, setUploadPhotoLoading] = useState(false);
  const [isUploadPhotoModalOpen, setisUploadPhotoModalOpen] = useState(false);
  const prevGroupedPhoto = usePrevious(groupedPhoto);
  const prevCurrentCountry = usePrevious(currentCountry);
  const filterState = useSelector((state) => state.filterState);
  const globalDispatch = useDispatch();
  const fetchData = async (currentCountry) => {
    try {
      const value = await fetchDishPhoto(currentCountry);
      setGroupedPhoto(value);
    } catch (error) {
      console.error(error.response || error.message);
    }
  };

  const searchData = async (currentCountry, masterCategory, photoType) => {
    try {
      let fromDate = filterState.payload.input.timeFrom ? filterState.payload.input.timeFrom : null;
      let toDate = filterState.payload.input.timeTo ? filterState.payload.input.timeTo : null;
      let vendorCode = filterState.payload.input.search || null;
      const value = await search(
        currentCountry,
        vendorCode,
        fromDate,
        toDate,
        masterCategory,
        photoType
      );
      setGroupedPhoto(value);
    } catch (error) {
      console.error(error.response);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchData(currentCountry);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry]);

  // TODO update from calculated state instead of network call
  useEffect(
    function fetchPendingNumbers() {
      dispatch(fetchPendingNumberAsync({ country: currentCountry, status: 'pending' }));
    },
    [currentCountry, dispatch, groupedPhoto]
  );

  useEffect(() => {
    dispatchMasterCategories(fetchMasterCategories({ country: currentCountry, status: STATUS }));
  }, [currentCountry, dispatchMasterCategories]);

  useEffect(() => {
    return () => {
      globalDispatch({
        type: 'SET_SELECTED_NAVBAR_COUNT',
        data: {
          pluginComponentName: 'ManagePhoto',
          count: null,
        },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const requestCount = state.data || 0;
    globalDispatch({
      type: 'SET_SELECTED_NAVBAR_COUNT',
      data: { pluginComponentName: 'ManagePhoto', count: requestCount },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.data]);

  useEffect(() => {
    let fromDate = filterState.payload.input.timeFrom ? filterState.payload.input.timeFrom : null;
    let toDate = filterState.payload.input.timeTo ? filterState.payload.input.timeTo : null;
    let vendorCode = filterState.payload.input.search || null;
    if (vendorCode || fromDate || toDate || masterCategory || photoType) {
      return;
    }
    // Prevent refetching
    if (prevGroupedPhoto && prevGroupedPhoto.length === groupedPhoto.length) {
      return;
    }

    // Listening groupedPhoto length after approval
    // if record become lower than 50 , refetch again
    if (
      currentCountry === prevCurrentCountry &&
      groupedPhoto.length !== prevGroupedPhoto.length &&
      groupedPhoto.length < config.photoRefetchLowerThanNumber
    ) {
      fetchData(currentCountry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    groupedPhoto,
    currentCountry,
    prevCurrentCountry,
    prevGroupedPhoto,
    filterState.payload,
    masterCategory,
    photoType,
  ]);

  useEffect(() => {
    setGroupedPhoto([]);
    setChecks([]);
    setCheckedAllPhoto(false);
  }, [currentCountry]);

  useEffect(() => {
    // const { q, fromDate, toDate } = customContext;
    let fromDate = filterState.payload.input.timeFrom ? filterState.payload.input.timeFrom : null;
    let toDate = filterState.payload.input.timeTo ? filterState.payload.input.timeTo : null;
    let vendorCode = filterState.payload.input.search || null;
    if (vendorCode || fromDate || toDate || masterCategory || photoType) {
      searchData(currentCountry, masterCategory, photoType);
    } else {
      fetchData(currentCountry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.payload, currentCountry, masterCategory, photoType]);

  /**
   * Function to clear checks anytime
   * 1- called from batchApprove
   */
  const _clearSelectedChecks = () => {
    setChecks([]);
    setCheckedAllPhoto(false);
  };

  const handleSaves = useCallback(
    async (approvedRequests) => {
      let remainingRequests = [];
      let approvedIds = {};
      approvedRequests.forEach((request) => {
        const photoId = getPhotoId(request.vendorId, request.productId);
        approvedIds[photoId] = photoId;
      });

      groupedPhoto.forEach((gp) => {
        if (!approvedIds[getPhotoId(gp.vendorId, gp.productId)]) {
          remainingRequests.push(gp);
        }
      });

      setGroupedPhoto([...remainingRequests]);
    },
    [groupedPhoto]
  );

  const handleReject = useCallback(
    async (reasons) => {
      setRejectLoading(true);
      const rejectPromises = [];
      const rejectedRequests = [];

      checks.forEach((check) => {
        const data = groupedPhoto.find((gp) => getPhotoId(gp.vendorId, gp.productId) === check);
        data.change.rejectReasons = reasons;
        rejectedRequests.push(data);
        rejectPromises.push(saveData(data, 'rejected'));
      });

      try {
        await Promise.all(rejectPromises);
        handleSaves(rejectedRequests);
        setRejectLoading(false);
        setIsRejectModalOpen(false);
        _clearSelectedChecks();
        setSuccessNotification({
          open: true,
          variant: 'success',
          message: `Successfully rejected request${rejectPromises.length > 1 ? 's' : ''}`,
        });
      } catch (error) {
        console.error(error);
        setRejectLoading(false);
        setErrorNotification({
          open: true,
          variant: 'error',
          message: formatErrorForDisplay({ error }),
        });
      }
    },
    [checks, groupedPhoto, handleSaves]
  );

  const batchApprove = async () => {
    setApproveLoading(true);

    let savePromises = [];
    let approvedRequests = [];

    checks.forEach((check) => {
      const data = groupedPhoto.find((gp) => getPhotoId(gp.vendorId, gp.productId) === check);
      approvedRequests.push(data);

      savePromises.push(saveData(data, 'approved'));
    });

    try {
      await Promise.all(savePromises);
      handleSaves(approvedRequests);
      setApproveLoading(false);
      _clearSelectedChecks();
      setSuccessNotification({
        open: true,
        variant: 'success',
        message: `Successfully approved request${savePromises.length > 1 ? 's' : ''}`,
      });
    } catch (error) {
      console.error(error);
      setApproveLoading(false);
      setErrorNotification({
        open: true,
        variant: 'error',
        message: formatErrorForDisplay({ error }),
      });
    }
  };

  const saveData = async (data, type = 'approved') => {
    return putDishPhoto(data, type);
  };

  const getPhotoId = (vendorId, productId) => {
    return vendorId + '|' + productId;
  };

  const handlePhotoCheck = (e, vendorId, productId) => {
    e.stopPropagation();

    if (isPhotoChecked(vendorId, productId)) {
      setChecks(checks.filter((check) => check !== getPhotoId(vendorId, productId)));
      setCheckedAllPhoto(false);
    } else {
      setChecks([...checks, getPhotoId(vendorId, productId)]);
    }
  };

  const isPhotoChecked = (vendorId, productId) => {
    return (
      checks.findIndex((check) => {
        return check === getPhotoId(vendorId, productId);
      }) >= 0
    );
  };

  const handleAllPhotoCheck = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();

    if (isAllPhotoChecked()) {
      setChecks([]);
      setCheckedAllPhoto(false);
    } else {
      setChecks(Array.from(groupedPhoto, (gp) => getPhotoId(gp.vendorId, gp.productId)));
      setCheckedAllPhoto(true);
    }
  };

  const isAllPhotoChecked = () => {
    return checkedAllPhoto;
  };

  const batchDownload = async () => {
    const result = groupedPhoto.filter(
      (gp) => checks.indexOf(getPhotoId(gp.vendorId, gp.productId)) > -1
    );

    await batchImageDownload({
      getImageDownloads: (downloadImage) => {
        const imageDownloads = result.map((p) => [
          p.change.location,
          downloadImage(p.change.location),
        ]);
        return imageDownloads;
      },
      preDownloadCallbackFn: () => {
        setDownloadInProgress((_) => true);
      },
      postDownloadCallbackFn: () => {
        setDownloadInProgress(false);
        _clearSelectedChecks();
      },
    });
  };

  const batchUpload = async (files) => {
    try {
      setUploadPhotoLoading(true);
      await uploadPhotos(files);

      let updateChangePromise = [];
      let approvedIds = [];

      const Ids = files
        .map((file) => file.name)
        .map((name) => {
          const data = name.split('_');

          // Due to the name of New product id
          // v3lv_NEW_1234, we need to append 3 index
          // to get actual id
          const productId = data[2] === 'NEW' ? `${data[2]}_${data[3]}` : data[2];
          return `${data[1]}|${productId}`;
        });

      Ids.forEach((id) => {
        const data = groupedPhoto.find((gp) => {
          const found = getPhotoId(gp.vendorId, gp.productId) === id;
          return found;
        });

        if (data) {
          approvedIds.push(id);
          updateChangePromise.push(saveData(data, 'pending'));
        }
      });

      await Promise.all(updateChangePromise);
      const time = Date.now();

      const newGroupedPhoto = groupedPhoto.map((gp) => {
        if (approvedIds.includes(getPhotoId(gp.vendorId, gp.productId))) {
          return { ...gp, agentEmail, modifiedOn: time };
        }
        return gp;
      });

      setGroupedPhoto(newGroupedPhoto);
      setUploadPhotoLoading(false);
      setisUploadPhotoModalOpen(false);
      setSuccessNotification({
        open: true,
        variant: 'success',
        message: `Successfully uploaded photo${files.length > 1 ? 's' : ''}`,
      });
    } catch (error) {
      console.error(error, 'Dish photo upload error');
      setErrorNotification({
        open: true,
        variant: 'error',
        message: formatErrorForDisplay({ error }),
      });
    }
  };

  const showTableContent = (groupedPhoto, masterCategories) => {
    if (!_.isEmpty(groupedPhoto)) {
      return (
        <Box>
          <TableContent
            masterCategories={masterCategories}
            groupedPhoto={groupedPhoto}
            handlePhotoCheck={handlePhotoCheck}
            isPhotoChecked={isPhotoChecked}
            handleAllPhotoCheck={handleAllPhotoCheck}
            isAllPhotoChecked={isAllPhotoChecked}
            isSafeGridEnabled={isSafeGridEnabled}
          />
        </Box>
      );
    } else {
      return <EmptyState />;
    }
  };
  const modalRooter = document.getElementById('appbar');

  const onMasterCategoryChange = useCallback((evt) => {
    setMasterCategory(evt.target.value);
  }, []);

  const onPhotoTypeChange = useCallback((evt) => {
    setPhotoType(evt.target.value);
  }, []);

  return (
    <div style={{ display: 'flex', width: '100%' }} data-enzyme={'VSSPhotoQueue'}>
      {modalRooter && (
        <AppRightBar modalRoot={modalRooter}>
          <ActionBar
            checks={checks}
            approveLoading={approveLoading}
            setIsRejectModalOpen={setIsRejectModalOpen}
            batchApprove={batchApprove}
            setisUploadPhotoModalOpen={setisUploadPhotoModalOpen}
            batchDownload={batchDownload}
            downloading={downloading}
          />
        </AppRightBar>
      )}
      <Container maxWidth="xl" className={classes.innerscroll}>
        <Box alignItems="center" display="flex" my={1}>
          <Box mr={1}>
            {fwfDishPhotoTypeFilter.variation && (
              <PhotoFilter
                value={photoType}
                onChange={onPhotoTypeChange}
                country={currentCountry}
              />
            )}
          </Box>
          <Box>
            {fwfDishPhotoMasterCategoryFilter.variation && !masterCategories.error && (
              <CategoryFilter
                categories={masterCategories.data}
                value={masterCategory}
                country={currentCountry}
                onChange={onMasterCategoryChange}
              />
            )}
          </Box>
          {fwfPhotoSafeGrid != null && fwfPhotoSafeGrid.variation && (
            <SafeGridToggle onChange={setIsSafeGridEnabled} value={isSafeGridEnabled} />
          )}
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <LoaderWrapper isLoading={loading}>
              {showTableContent(groupedPhoto, masterCategories.data)}
            </LoaderWrapper>
          </Grid>
        </Grid>
      </Container>
      <div>
        <Notification
          variant={'success'}
          open={successNotification.open}
          handleClose={() => {
            setSuccessNotification({
              open: false,
              variant: 'success',
              message: '',
            });
          }}
          message={successNotification.message}
        />

        <Notification
          variant={'error'}
          open={errorNotification.open}
          handleClose={() => {
            setErrorNotification({
              open: false,
              variant: 'success',
              message: '',
            });
          }}
          message={errorNotification.message}
        />

        <UploadPhotoModal
          loading={uploadPhotoLoading}
          batchUpload={batchUpload}
          open={isUploadPhotoModalOpen}
          handleClose={() => setisUploadPhotoModalOpen(false)}
        />

        <RejectModal
          loading={rejectLoading}
          setIsRejectModalOpen={setIsRejectModalOpen}
          isRejectModalOpen={isRejectModalOpen}
          onSubmit={handleReject}
          header="Choose Reject Reason"
          subheader="Are you sure there is nothing you can do to approve this item? This
              action is permanent. The vendor will see the rejection reason you
              choose."
          rejectReasons={getPhotoRejectReasons(currentCountry.geid)}
        />
      </div>
    </div>
  );
};

export default VSSPhotoQueue;
