export const styles = (theme) => ({
  changeValuesNew: {
    background: theme.palette.changeValues.new,
  },
  changeValuesOld: { background: theme.palette.changeValues.old },

  priceQCLabel: {
    marginTop: '10px',
    color: '#D00025',
    backgroundColor: '#F8D9DE',
    fontWeight: 'normal',
    padding: '8px 10px',
    borderRadius: '5px',
    fontSize: '12px',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.1)',
    lineHeight: '22px',
    width: '220px',
    boxSizing: 'border-box',
  },
  priceQCInfoIcon: {
    verticalAlign: 'middle',
    marginTop: '-2px',
    marginLeft: '6px',
  },
});

export default styles;
