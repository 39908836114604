import * as React from 'react';
import { SectionContent, SectionHeader, SectionWrapper, SectionHeaderV2 } from './style';
import { ProductSectionProps } from './types';

const Section: React.FC<ProductSectionProps> = ({
  children,
  title,
  desc,
  addButton,
  handleAdd,
  isAddDisabled,
  className,
}) => {
  return (
    <SectionWrapper className={className}>
      <SectionHeader isAddDisabled={isAddDisabled}>
        {title} <span>{desc && `- ${desc}`}</span>
        {addButton && (
          <button
            onClick={(e) => handleAdd(e)}
            color="primary"
            className="button"
            disabled={isAddDisabled}
          >
            {addButton}
          </button>
        )}
      </SectionHeader>
      <SectionContent>{children}</SectionContent>
    </SectionWrapper>
  );
};

export default Section;

export const SectionV2: React.FC<ProductSectionProps> = ({
  children,
  title,
  desc,
  addButton,
  handleAdd,
  isAddDisabled,
  className,
}) => {
  return (
    <SectionWrapper className={className}>
      <SectionHeaderV2 isAddDisabled={isAddDisabled}>
        {title} <span>{desc && `- ${desc}`}</span>
        {addButton && (
          <button
            onClick={(e) => handleAdd(e)}
            color="primary"
            className="button"
            disabled={isAddDisabled}
          >
            {addButton}
          </button>
        )}
      </SectionHeaderV2>
      <div>{children}</div>
    </SectionWrapper>
  );
};
