export const styles = theme => ({
  root: {
    backgroundColor: "white",
    color: "rgb(14, 25, 26)",
    textTransform: "none",
    fontWeight: "bold",
  },
});

export default styles;
