import { TYPE_OF_CHANGE, TYPE_OF_CHANGE_CHOICE_GROUP } from '../../../App/common/constant';
import { getCurrentCountry } from '../../../App/utility';
import Cookies from 'js-cookie';
import { getMutationRequestDefaultData } from '../../../utility/information';

/**
 *
 * @typedef {Object} MenuPhotoData
 * @property {boolean} isFlagOn - Flag to show image
 * @property {QCRequest} photoRequest - Request with photo data
 *
 * function to return request containing menu photo
 * @param {*} requests array of requests from api response
 * @param {*} fwfFlags fwf variation object
 * @returns {MenuPhotoData}
 * QCRequest structure can be found in ITEM_CHANGES_QUERY query
 */
export const getMenuPhotoData = (requests, fwfFlags) => {
  const isFlagOn = !!fwfFlags?.fwfProductDetailMenuPhoto?.variation;

  const TYPES_OF_CHANGE_TO_SHOW_MENU_PHOTO = new Set([
    TYPE_OF_CHANGE_CHOICE_GROUP.CHOICE_GROUP_CREATE,
    TYPE_OF_CHANGE_CHOICE_GROUP.CHOICES_CREATE,
    TYPE_OF_CHANGE_CHOICE_GROUP.CHOICES_ADD,
    TYPE_OF_CHANGE.PRODUCT_CREATE,
    TYPE_OF_CHANGE.VARIANT_CREATE,
    TYPE_OF_CHANGE_CHOICE_GROUP.CHOICES_PRICE_CHANGE,
    TYPE_OF_CHANGE.VARIANT_UNITPRICE_CHANGE,
    TYPE_OF_CHANGE.DISH_PHOTO_CHANGE,
  ]);

  const menuPhotoRequest = requests.find((request) =>
    TYPES_OF_CHANGE_TO_SHOW_MENU_PHOTO.has(request.typeOfChange)
  );

  return { isFlagOn, photoRequest: menuPhotoRequest };
};
/**
 * @typedef GraphQLArgs
 * @property {'APPROVE' || 'REJECT'} approveOrReject
 * @property {{permissions: {"ops-portal-fuze": Object}, sub: String}} currentUser
 * @property {Object} additionalFields
 * @property {Object} patchData
 * @param {GraphQLArgs} arguments
 */
export const getChangeRequestGraphQLInput = ({
  approveOrReject,
  currentUser,
  additionalFields,
  patchData,
}) => {
  const input = [];
  additionalFields = additionalFields ?? {};
  const currentCountry = getCurrentCountry(Cookies.get('country'));

  Object.keys(patchData).forEach((requestId) => {
    const request = patchData[requestId];
    const { updated, version, ...requestPatchData } = request;
    const defaultInput = {
      type: approveOrReject,
      ...getMutationRequestDefaultData({ ...request, requestId }, currentUser, currentCountry),
      ...additionalFields,
    };
    if (updated) {
      input.push({
        ...defaultInput,
        patchData: requestPatchData,
      });
    } else {
      input.push(defaultInput);
    }
  });

  return input;
};
