import styled from 'styled-components';
import Info from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { CircularProgress, Button } from '@material-ui/core';
import { Banner, CloseButton } from '../Banner';

export const ConfirmBannerWrapper = styled.div`
  background: rgb(232, 244, 250);
  padding: 12px 16px 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 4px solid rgb(30, 145, 214);
  max-width: 1312px; /* this number follow what rps team set */
  min-height: 52px;
  margin: 0 auto;

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-flow: column;
    padding: 16px;
  }
`;

export const StateBannerWrapper = styled(Banner)`
  background: rgb(232, 244, 250);
  padding: ${(props) => props.theme.spacing(1.5, 0)};
  ${CloseButton} {
    top: ${(props) => props.theme.spacing(1.5)}px;
  }
`;

export const InfoIcon = styled(Info)`
  color: #1e91d6;
  margin-right: 8px;

  ${(props) => props.theme.breakpoints.down('xs')} {
    display: none;
  }
`;

export const SuccessIcon = styled(CheckCircleIcon)`
  color: #65b202;
  height: 24px;
  width: 24px;
`;

export const FailIcon = styled(CancelIcon)`
  color: #d61f26;
  height: 24px;
  width: 24px;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 25px;
  margin-right: 8px;

  ${(props) => props.theme.breakpoints.down('xs')} {
    font-size: 14px;
    line-height: 14px;
  }
`;

export const DisclaimerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1130px;
`;

export const ChildrenWrapper = styled.div`
  display: flex;
  line-height: 24px;
  margin: ${(props) => props.theme.spacing(1.5, 6.5, 1.5, 2)};
  ${(props) => props.theme.breakpoints.down('xs')} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const ConfirmButton = styled(Button)`
  white-space: nowrap;
  overflow: hidden;

  ${(props) => props.theme.breakpoints.down('xs')} {
    margin-top: 16px;
    width: 100%;
    height: 48px;
  }
`;

export const Loader = styled(CircularProgress)`
  flex: 0 0 auto;
`;
