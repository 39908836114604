import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import RejectModal from '../../../App/components/RejectModal';
import { getMenuRejectReasons } from '../../common/constants/rejectReason';
import { AppContext } from '../../../App';

const RooterApproveAndRejectButton = ({ handleApprove, handleReject, buttonsDisabled, rejectionReasonType }) => {
  const { currentCountry } = React.useContext(AppContext);

  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  return (
    <>
      <Box>
        <Button
          variant={'contained'}
          color={'primary'}
          style={{
            textTransform: 'none',
            fontWeight: 'bold',
            marginRight: 8,
          }}
          disabled={buttonsDisabled}
          onClick={handleApprove}
          data-testid={'rooter-approve'}
        >
          <CheckCircleIcon />
          <Box ml={1}>{'Approve'}</Box>
        </Button>
        <Button
          variant={'outlined'}
          disabled={buttonsDisabled}
          onClick={() => setRejectModalOpen(true)}
          style={{
            textTransform: 'none',
            fontWeight: 'bold',
            boxShadow: 'none',
            marginRight: 8,
          }}
          data-testid={'rooter-reject'}
        >
          <CancelIcon />
          <Box ml={1}>{'Reject'}</Box>
        </Button>
      </Box>
      <RejectModal
        loading={false}
        header="Choose Reject Reason"
        subheader={
          'Are you sure there is nothing you can do to approve this item? This action is permanent. The vendor will see the rejection reason you choose.'
        }
        isRejectModalOpen={rejectModalOpen}
        setIsRejectModalOpen={(value) => setRejectModalOpen(value)}
        onSubmit={handleReject}
        rejectReasons={getMenuRejectReasons(currentCountry.geid, rejectionReasonType)}
      />
    </>
  );
};

export default RooterApproveAndRejectButton;
