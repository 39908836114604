import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { Box, Container, makeStyles } from '@material-ui/core';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import axios from '../../../App/utility/axios';
import config from '../../../App/config';
import styles from '../Home/styles';
import { getCurrentCountry } from '../../../App/utility';
import { AppContext, UserContext } from '../../../App';
import {
  ITEM_CHANGES_QUERY,
  TALABAT_PROCESS_CHANGE_REQUESTS_QUERY,
  PROCESS_CHANGE_REQUESTS_QUERY,
} from '../Home/helpers';
import ProductDetailMenuPhoto from '../../components/ProductDetailMenuPhoto';
import HighLightBox from '../../components/HighlightBox';
import { ChoiceGroupDetailSection, ChoicesSection } from '../../../components';
import Translate from '../../../utility/Translate';
import { choiceGroupHighlightBoxStyle } from '../../components/HighlightBox/styles';
import { defaultErrorMessage } from '../../utility/information';
import AppRightBar from '../../../App/layouts/AppRightBar';
import {
  getMenuPhotoData,
  getApprovalQueryInputFromPatchData,
  getEmbeddedPatchDataIds,
} from '../Home/NewChoiceGroup/helpers';
import { getOldPatchData } from '../reviewHelpers';
import useChoiceGroupDetails from '../Home/NewChoiceGroup/useChoiceGroupDetails';
import ChoiceGroupDetailSummary from '../Home/NewChoiceGroup/ChoiceGroupDetailSummary';
import HighlightBoxHeader from '../../components/HighlightBoxHeader';
import RooterApproveAndRejectButton from '../../components/RooterApproveAndRejectButton';
import { TYPE_OF_CHANGE, TYPE_OF_CHANGE_CHOICE_GROUP } from '../../../App/common/constant';
import { TicketResolvedScreen } from '../../../components/TicketResolvedScreen';
import { REJECTION_REASON_TYPE } from '../../common/constants/rejectReason';
import LoadingState from '../../../components/LoadingState';
import ErrorScreen from '../../../components/ErrorScreen';
import StatusInfoBox from '../../../components/StatusInfoBox';
import { getMutationRequestDefaultData } from '../../../utility/information';
import ProductDetailDisplay from '../../../App/components/ProductDetailDisplay';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import RelatedItems from 'components/RelatedItems';

const useStyles = makeStyles(styles);

const useChoiceGroupHighlightBoxStyles = makeStyles(choiceGroupHighlightBoxStyle);

const ChoiceGroupReview = ({ match }) => {
  const location = useLocation();
  const { getHistoricalData } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const showHistoricalData = getHistoricalData === 'true';
  const urlParams = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const { countryCode, vendorCode, item_id } = urlParams;
  const classes = useStyles();
  const choiceGroupHighlightBoxClasses = useChoiceGroupHighlightBoxStyles();
  const [isComplete, setIsComplete] = useState(false);

  const { currentCountry, setCurrentCountry } = React.useContext(AppContext);
  const currency = currentCountry.currency;
  const currentUser = React.useContext(UserContext);
  const [countryData, setCountryData] = useState(getCurrentCountry(countryCode));
  const [isPermission, setIsPermission] = useState(
    currentUser?.permissions?.['ops-portal-fuze'][countryData.geid]?.length > 0
  );
  const fwfFlags = useSelector((state) => state.fwfState.flags);

  //Set current country based on URL
  useEffect(() => {
    setCountryData(getCurrentCountry(countryCode));
    setIsPermission(currentUser?.permissions?.['ops-portal-fuze'][countryData.geid]?.length > 0);

    if (!isPermission) {
      window.location.href = '/access-denied';
      return;
    }

    Cookies.set('country', countryData.code, { sameSite: 'None', secure: true });
    setCurrentCountry(countryData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCurrentCountry, countryCode]);

  let talabatChangeRequestsQuery = fwfFlags.fwfTBCheckIfThereIsMore.variation
    ? TALABAT_PROCESS_CHANGE_REQUESTS_QUERY
    : PROCESS_CHANGE_REQUESTS_QUERY;

  const [processChangeRequest] = useMutation(talabatChangeRequestsQuery, {
    onError: () => {},
  });

  const processChangeRequestsThen = (data, message) => {
    if (data?.data?.completeQCRequests) {
      enqueueSnackbar(message, {
        variant: 'success',
      });
      setIsComplete(true);
    } else if (data?.errors?.message) {
      enqueueSnackbar(data?.errors?.message, { variant: 'error' });
    } else {
      enqueueSnackbar(defaultErrorMessage, { variant: 'error' });
    }
  };

  const [itemChanges, setItemChanges] = useState([]);
  const [hasRequestResponse, setHasRequestResponse] = useState(false);
  const [baseItemId, choiceGroupId] = item_id.split(';');
  //Only for embedded choice groups
  const [parentProduct, setParentProduct] = useState(null);
  const [parentStatus, setParentStatus] = useState(null);

  const [isLoadingItem, setIsLoadingItem] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [targetItemId, setTargetItemId] = useState(null);
  const [variantKey, setVariantKey] = useState(null);

  //Fetch product data from previous QCRequests
  const isNewProduct = baseItemId.startsWith('NEW_');
  const { data: productData } = useQuery(ITEM_CHANGES_QUERY, {
    variables: {
      itemID: baseItemId,
      latestCaseIDOnly: true,
      statusList: ['APPROVE', 'REJECT', 'PENDING'],
    },
    displayName: 'ProductItemQuery',
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    skip: !isNewProduct || !isPermission,
  });

  useEffect(() => {
    const qcRequests = productData?.getItemByItemID?.qcRequests ?? [];
    const productCreateRequest = qcRequests.find(
      (r) => r.item.typeOfChange === TYPE_OF_CHANGE.PRODUCT_CREATE && r.item.patchData !== null
    );

    if (!!productCreateRequest) {
      const productPatchData = productCreateRequest.item.patchData;
      setParentProduct({
        ...productPatchData,
        id: baseItemId,
      });
      setParentStatus(productCreateRequest?.item?.status);
    }
  }, [productData, baseItemId]);

  // Fetch choice group data from brand proxy endpoint
  useEffect(() => {
    if (itemChanges.length > 0 && isPermission) {
      const isEmbedded = itemChanges[0].item.typeOfChange.startsWith('EmbeddedChoice');
      const isNewChoiceGroup = baseItemId.startsWith('NEWCG');

      //Only need to retrieve data if it's an embedded choice group related change.
      if (!isEmbedded && isNewChoiceGroup) {
        return;
      }

      setIsLoadingItem(true);
      const { variantId, choiceGroupId: realChoiceGroupId } = getEmbeddedPatchDataIds(
        itemChanges[0].item.patchData
      );

      let _targetItemId;
      if (isEmbedded && isNewChoiceGroup) {
        _targetItemId = variantId;
      } else {
        _targetItemId = baseItemId;
      }
      setTargetItemId(_targetItemId);

      axios(`${config.brandProxyApi}/${isEmbedded ? 'product' : 'choice-group'}/${_targetItemId}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-global-entity-id': currentCountry?.geid,
          'x-vendor-id': vendorCode,
        },
      })
        .then((response) => {
          const data = response.data.data;
          if (isEmbedded && !isNewProduct) {
            setParentProduct(data);
            setParentStatus('APPROVE');
            //For Talabat 1 product -> 1 variant so it is always in index[0]
            //1 Product -> 1 Variant -> MANY Choice groups
            const choiceGroups = data.variants[0].choice_groups;
            let choiceGroupData = choiceGroups.find((cg) => cg.id === realChoiceGroupId);
            setCurrentItem(choiceGroupData);
          } else {
            setCurrentItem(data);
          }
          // TODO handle response.data.data == null. data null is an api failure even though status is 200
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoadingItem(false);
        });
    }
  }, [currentCountry, vendorCode, baseItemId, itemChanges, isNewProduct, isPermission]);

  //Fetch Item Changes

  const { data: changeData, loading: loadingChangeData } = useQuery(ITEM_CHANGES_QUERY, {
    variables: {
      itemID: baseItemId,
      statusList: ['APPROVE', 'REJECT', 'PENDING', 'INVALID'],
      ...(showHistoricalData
        ? {
            latestCaseIDOnly: true,
            statusList: ['APPROVE', 'REJECT'],
          }
        : {}),
    },
    displayName: 'ItemChanges',
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    skip: !isPermission,
  });
  //Create item from changes if new choice group
  useEffect(() => {
    if (changeData?.length > 0) {
      const changeTypeList = changeData.map((c) => c.item.typeOfChange);

      const isCreate =
        changeTypeList.includes(TYPE_OF_CHANGE_CHOICE_GROUP.CHOICE_GROUP_CREATE) ||
        changeTypeList.includes(TYPE_OF_CHANGE_CHOICE_GROUP.EMBEDDED_CHOICE_GROUP_CREATE);
      if (isCreate) {
        let changeRequest = {};
        let newChoiceGroupData = {};
        if (changeTypeList.includes(TYPE_OF_CHANGE_CHOICE_GROUP.CHOICE_GROUP_CREATE)) {
          //if choice group create, should only have 1 change
          changeRequest = changeData[0];
          newChoiceGroupData = changeRequest.item.patchData;
        }
        if (changeTypeList.includes(TYPE_OF_CHANGE_CHOICE_GROUP.EMBEDDED_CHOICE_GROUP_CREATE)) {
          changeRequest = changeData[0];
          const newChoiceGroups = Object.values(changeRequest.item.patchData.variants)[0]
            ?.choice_groups;
          newChoiceGroupData = Object.values(newChoiceGroups ?? {})[0];
        }

        const createdItem = {
          choices: Object.values(newChoiceGroupData?.choices || {}) ?? [],
          defaultTitle: Object.values(newChoiceGroupData?.title || {})[0],
          deleted: false,
          id: changeRequest.item.itemId,
          quantity: newChoiceGroupData?.quantity ?? { min: 0, max: 0 },
          title: newChoiceGroupData?.title || {},
          type: 'choice-group',
        };
        setCurrentItem(createdItem);
      }
    }
  }, [changeData]);

  const [menuPhotoData, setMenuPhotoData] = useState({
    showPhoto: false,
    changeWithImageData: undefined,
  });
  useEffect(() => {
    const changes = changeData?.getItemByItemID?.qcRequests || [];

    let relevantChanges = changes.filter((QCRequest) => {
      const typeOfChange = QCRequest.item.typeOfChange;

      const isChoiceGroupRelated = Object.values(TYPE_OF_CHANGE_CHOICE_GROUP).includes(
        typeOfChange
      );

      let isRelatedToEmbeddedChoice;
      if (typeOfChange.startsWith('EmbeddedChoice')) {
        const { choiceGroupId: patchDataChoiceGroupId } = getEmbeddedPatchDataIds(
          QCRequest.item.patchData
        );

        isRelatedToEmbeddedChoice =
          (choiceGroupId.toString() || baseItemId.toString()) === patchDataChoiceGroupId;
      } else {
        isRelatedToEmbeddedChoice = true;
      }

      return isChoiceGroupRelated && isRelatedToEmbeddedChoice;
    });

    setHasRequestResponse(relevantChanges.length > 0);

    let pendingRequests = [];

    if (!showHistoricalData) {
      let newRequest = [
        {
          item: {
            timestamp: 0,
          },
        },
      ];

      if (choiceGroupId) {
        relevantChanges = relevantChanges.filter(
          (request) =>
            request.item.itemId.toString() === choiceGroupId ||
            request.item.itemId.toString() === baseItemId
        );
      }

      relevantChanges.forEach((req) => {
        if (req.item.timestamp > newRequest[0].item.timestamp) {
          newRequest = [req];
        }
      });

      const validRequest = newRequest[0].item.status?.toLowerCase() === 'pending';

      if (validRequest) {
        relevantChanges.forEach((request) => {
          if (request.item.status.toLowerCase() === 'pending') {
            pendingRequests.push(request);
          }
        });
      }
    } else {
      pendingRequests = relevantChanges;
    }

    const checkAllChoiceGroupRequest = (pendingRequests) =>
      pendingRequests.every(
        (request) => request.item.typeOfChange === TYPE_OF_CHANGE_CHOICE_GROUP.CHOICE_GROUP_CREATE
      );
    const allChoiceGroupCreateRequest = checkAllChoiceGroupRequest(pendingRequests);

    if (allChoiceGroupCreateRequest) {
      //allChoiceGroup Request but dont have choiceGroupId. Add extra conrol. Waiting Akın

      if (!!choiceGroupId) {
        pendingRequests = pendingRequests.filter(
          (request) => request?.item?.itemId === choiceGroupId
        );
      }
    }

    setItemChanges(pendingRequests);

    if (pendingRequests.length > 0) {
      const variants = pendingRequests[0].item.patchData.variants;
      let variantKey;
      for (var key in variants) {
        variantKey = key;
      }

      setVariantKey(variantKey);
    }

    const { data, shouldShowMenuPhoto: shouldShowPhoto } = getMenuPhotoData(
      relevantChanges,
      fwfFlags
    );
    setMenuPhotoData({ showPhoto: shouldShowPhoto, changeWithImageData: data });
  }, [changeData, fwfFlags, choiceGroupId, baseItemId, showHistoricalData]);

  //Form values
  const {
    title,
    compiledTitle,
    quantityMax,
    quantityMin,
    choices,
    setChoices,
    editableChoices,
    editableChoicesPrice,
    processedQuantity,
    languages,
    handleMinimumChange,
    handleMaximumChange,
    handleTitleChange,
    getPatchData,
  } = useChoiceGroupDetails({
    choiceGroup: currentItem,
    requests: itemChanges,
    currentCountry: currentCountry,
  });

  const handleChoiceTextChange = (e, languageCode, index, multi, value) => {
    setChoices((prevObj) => {
      let keys = Object.keys(prevObj);
      let i = keys[index];

      prevObj[i].choice.title[languageCode] = value;
      return { ...prevObj };
    });
  };

  const handleChoicePriceChange = (value, index) => {
    setChoices((prevObj) => {
      let keys = Object.keys(prevObj);
      let i = keys[index];

      prevObj[i].price = parseFloat(value);

      return { ...prevObj };
    });
  };

  const approveHandler = useCallback(() => {
    let patchData = getPatchData();
    Object.keys(patchData).forEach((request) => {
      let choicesFromPatchData;
      if (!patchData[request]?.variants) {
        choicesFromPatchData = patchData[request];
      } else {
        choicesFromPatchData =
          patchData[request]?.variants[baseItemId]?.choice_groups[choiceGroupId];

        if (!choicesFromPatchData) {
          choicesFromPatchData =
            patchData[request]?.variants[variantKey]?.choice_groups[choiceGroupId];
        }
      }
      const choiceIds = choicesFromPatchData?.choices && Object.keys(choicesFromPatchData.choices);
      if (choiceIds) {
        choiceIds.forEach((id) => {
          const choicesLangKeys =
            choices[id]?.choice?.title && Object.keys(choices[id].choice.title);

          if (choicesLangKeys) {
            choicesLangKeys.forEach((key) => {
              Object.keys(choices).forEach((choice) => {
                if (choice === id) {
                  const choiceTitle = choices[choice].choice.title;

                  // set title
                  if (choicesFromPatchData.choices[id]?.title) {
                    choicesFromPatchData.choices[id].title[key] = choiceTitle[key];
                    patchData[request].updated = true;
                  } // set price
                  if (choicesFromPatchData.choices[id].hasOwnProperty('price')) {
                    choicesFromPatchData.choices[id].price = choices[choice].price;
                    patchData[request].updated = true;
                  }
                }
              });
            });
          }
        });
      }
    });

    let queryInputData = getApprovalQueryInputFromPatchData({
      patchData,
      currentUser,
    });

    queryInputData = getOldPatchData(itemChanges, queryInputData, patchData);

    let changeRequestPayload;
    if (fwfFlags.fwfTBCheckIfThereIsMore.variation) {
      changeRequestPayload = {
        input: queryInputData,
        itemID: choiceGroupId ? choiceGroupId : baseItemId,
        itemType: 'choice-group',
      };
    } else {
      changeRequestPayload = {
        input: queryInputData,
      };
    }

    processChangeRequest({
      variables: changeRequestPayload,
    }).then((data) => {
      processChangeRequestsThen(data, 'Successfully saved request(s)');
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentUser,
    enqueueSnackbar,
    processChangeRequest,
    getPatchData,
    itemChanges,
    baseItemId,
    choiceGroupId,
    choices,
  ]);

  const modalRejectHandler = useCallback(
    (reasons) => {
      const inputData = itemChanges.map((request) => ({
        ...getMutationRequestDefaultData(request, currentUser, currentCountry),
        type: 'REJECT',
        rejectionReasons: reasons,
      }));
      let changeRequestPayload;
      if (fwfFlags.fwfTBCheckIfThereIsMore.variation) {
        changeRequestPayload = {
          input: inputData,
          itemID: choiceGroupId ? choiceGroupId : baseItemId,
          itemType: 'choice-group',
        };
      } else {
        changeRequestPayload = {
          input: inputData,
        };
      }

      processChangeRequest({
        variables: changeRequestPayload,
      }).then((data) => {
        processChangeRequestsThen(data, 'Successfully rejected request(s)');
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser, itemChanges, processChangeRequest, currentCountry, baseItemId, choiceGroupId]
  );

  const placeholderFunction = () => {};

  const isPageReady =
    !loadingChangeData && !!changeData && !isLoadingItem && itemChanges.length > 0;

  const isLoading = loadingChangeData || isLoadingItem;
  if (isLoading) {
    return <LoadingState />;
  }

  if (!isPageReady && !hasRequestResponse) {
    const errorMessages = [];
    if (!currentItem) {
      errorMessages.push('Choice Group could not be found');
    }
    if (itemChanges.length === 0) {
      errorMessages.push('No changes found');
    }

    return <ErrorScreen errors={errorMessages} testId={'ChoiceGroupReviewError'} customText={''} />;
  }

  const status = _get(itemChanges[0], 'item.status')?.toLowerCase();

  if (
    !isLoading &&
    !showHistoricalData &&
    (isComplete || (itemChanges.length === 0 && hasRequestResponse))
  ) {
    return <TicketResolvedScreen />;
  }

  return (
    <Container maxWidth="xl" className={classes.innerscroll} data-enzyme={'ChoiceReview'}>
      <Box display="flex">
        {menuPhotoData.showPhoto && (
          <ProductDetailMenuPhoto
            flex="0 0 50%"
            menuPhoto={menuPhotoData.changeWithImageData?.meta?.menuPhoto}
            width="50%"
            currentCountry={currentCountry}
            fwfFlags={fwfFlags}
            vendorCode={vendorCode}
          />
        )}

        <Box
          {...(menuPhotoData.showPhoto
            ? {
                flex: '0 0 50%',
                ml: 'auto',
                overflow: 'scroll',
                style: { backgroundColor: '#edf0f7' },
                width: '50%',
              }
            : { width: '100%', height: '100%' })}
        >
          {showHistoricalData && <StatusInfoBox changeRequests={itemChanges}></StatusInfoBox>}

          {!!parentProduct && (
            <ProductDetailDisplay
              productData={parentProduct}
              status={parentStatus}
              currentCountry={currentCountry}
              variantId={variantKey}
            />
          )}

          <HighLightBox type={status} className={choiceGroupHighlightBoxClasses.wrapper}>
            <HighlightBoxHeader status={status} />
            <ChoiceGroupDetailSummary changeRequests={itemChanges} item={currentItem} />
          </HighLightBox>

          <RelatedItems
            currentCountry={currentCountry}
            itemID={targetItemId ?? item_id}
            type={'Choice'}
            vendorCode={vendorCode}
          ></RelatedItems>

          {!_isEmpty(processedQuantity) && (
            <ChoiceGroupDetailSection
              t={Translate}
              title={compiledTitle}
              defaultLanguage={languages[0]}
              languages={languages || []} // TODO: Get languages from some API response
              handleTitleChange={handleTitleChange}
              quantity={processedQuantity}
              numberOfChoices={Object.keys(choices).length || 0}
              isMinmaxDisable={
                (!quantityMin.relatedRequest && !quantityMax.relatedRequest) || showHistoricalData
              }
              isTextFieldDisable={!Object.keys(title).length || showHistoricalData}
              handleMaximumChange={handleMaximumChange}
              handleMinimumChange={handleMinimumChange}
              errors={{}}
            />
          )}
          <ChoicesSection
            t={Translate}
            defaultLanguage={languages[0]}
            languages={languages || []}
            currency={currency?.code}
            pickedChoices={Object.values(choices)}
            editableChoices={editableChoices} //Re-enable this when choices are editable
            editableChoicesPrice={editableChoicesPrice}
            isAddDisabled={true}
            handleAddChoice={placeholderFunction}
            handleChoiceTextChange={handleChoiceTextChange}
            handleChoiceChange={placeholderFunction}
            handleChoicePriceChange={handleChoicePriceChange}
            handleDelete={placeholderFunction}
            availabilityOptions={[]}
            errors={{}}
          />
        </Box>
      </Box>

      {!showHistoricalData && (
        <AppRightBar modalRoot={document.getElementById('appbar')}>
          <RooterApproveAndRejectButton
            handleApprove={approveHandler}
            handleReject={modalRejectHandler}
            buttonsDisabled={false}
            rejectionReasonType={REJECTION_REASON_TYPE.CHOICE_GROUP}
          />
        </AppRightBar>
      )}
    </Container>
  );
};

export default ChoiceGroupReview;
