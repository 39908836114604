import { MenuItem } from '@material-ui/core';
import TextField from '../TextField';
import styled from 'styled-components';

export const NormalTextField = styled(TextField)`
  flex: 1 1 0px;

  .MuiFormHelperText-root.Mui-error {
    color: #d61f26;
  }

  .MuiFormLabel-root.Mui-error {
    color: #d61f26;
  }
`;

export const MinMaxMenuItem = styled(MenuItem)`
  text-transform: capitalize;
`;
