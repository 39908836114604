/**
 *
 * @param {Object} param0 input object
 * @param {string} param0.currentCountry current country of user
 * @param {Object} param0.IAMdata IAM data for current app
 * @returns {Array} Available PLUGINS for current country for the user
 */
import MenuManagement from './../../MenuManagement';
import PhotoManagement from '../../PhotoManagement';
import MenuCreation from '../../MenuCreation';
import RiderPhotos from '../../RiderPhotos';
import HistoryManagement from '../../HistoryManagement';

export const getCountryPlugins = ({ fwfFlags, fwfCheck, currentCountry, IAMdata }) => {
  const pluginMap = {
    'menu-agent': MenuManagement,
    'photo-agent': PhotoManagement,
    'menu-creation': MenuCreation,
    // 'rider-photos': RiderPhotos,
    'history-agent': HistoryManagement,
  };
  if (!fwfCheck) {
    const navbar = {
      parents: [MenuManagement, HistoryManagement],
      availablePlugins: [
        MenuManagement,
        PhotoManagement,
        MenuCreation,
        RiderPhotos,
        HistoryManagement,
      ],
    };

    return navbar;
  }

  /** @type {string[]} */
  const availablePlugins = [];

  /** @type {string[]} */
  let parentNavbar = [];

  if (IAMdata.hasOwnProperty(currentCountry.geid)) {
    const myPermissions = IAMdata[currentCountry.geid];
    if (myPermissions.includes('admin') || myPermissions.includes('menu-agent') || myPermissions.includes('photo-agent')) {
      parentNavbar.push(pluginMap['menu-agent']);
    }

    if (Array.isArray(myPermissions)) {

      if(myPermissions.includes("admin")){
        availablePlugins.push(pluginMap["menu-agent"]);
        availablePlugins.push(pluginMap["photo-agent"]);
      }
      else{
        myPermissions.forEach((permissionName) => {
          if (permissionName in pluginMap) {
            availablePlugins.push(pluginMap[permissionName]);
          }
        });
      }

    }
    /*     if (myPermissions.includes('photo-agent') || myPermissions.includes('menu-agent')) {
      availablePlugins.push(pluginMap['rider-photos']);
    } */


    if (fwfFlags?.fwfIsHistoryPanelVisible?.variation && myPermissions.includes('admin')) {
      availablePlugins.push(pluginMap['history-agent']);
      parentNavbar.push(pluginMap['history-agent']);
    }

  }
  return {
    parents: parentNavbar,
    availablePlugins: availablePlugins,
  };
};
