import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Grid } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { AGENT_QUEUE_HISTORY } from '../../MenuManagement/containers/Home/helpers';
import { AppContext } from '../../App/index';
import HistoryMenuQueue from 'HistoryManagement/components/HistoryMenuQueue/HistoryMenuQueue';
import Loading from 'MenuCreation/containers/MenuCreation/components/Loading';
import { IfilterState } from './../../App/store/filterReducer';
type country = {
  code?: string;
  name?: string;
  index?: number;
  geid?: string;
  currency?: any;
};

type IAppContext = {
  currentCountry: country;
  setCurrentCountry: Function;
};

export const HistoryContainer = ({ setCustomContext, customContext }) => {
  const [pageToken, setPageToken] = useState('');
  const rowsPerPage = 100;
  const { currentCountry } = useContext(AppContext) as IAppContext;

  const filterState: IfilterState = useSelector((state: any) => state.filterState);
  const {
    loading,
    data: historyMenuQueueData,
    error,
    refetch: refetchAgentQueueHistoryData,
  } = useQuery(AGENT_QUEUE_HISTORY, {
    variables: {
      input: {
        globalEntityID: currentCountry.geid,
        timeFrom: filterState.payload.input.timeFrom
          ? `${filterState.payload.input.timeFrom}`
          : null,
        timeTo: filterState.payload.input.timeTo ? `${filterState.payload.input.timeTo}` : null,
        search: filterState.payload.input.search || null,
      },
      pageSize: rowsPerPage,
      pageToken: pageToken,
    },
    displayName: 'AgentQueueHistoryQuery',
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  } as any);
  useEffect(() => {
    refetchAgentQueueHistoryData();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container maxWidth="xl">
      <Grid item>
        <HistoryMenuQueue
          historyMenuData={historyMenuQueueData?.agentQueueHistory}
          refetchHistoryMenuData={refetchAgentQueueHistoryData}
          error={error}
          customContext={customContext}
          setCustomContext={setCustomContext}
          pageToken={pageToken}
          setPageToken={setPageToken}
        />
      </Grid>
    </Container>
  );
};
