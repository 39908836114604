import React from 'react';
import { SectionWrapper, StyledInput } from '../styles';
import { ProducerInformationProps } from '../types';
import { hasFieldError } from '../utils';

const ProducerInformationSection: React.FC<ProducerInformationProps> = ({
  t,
  producerName,
  producerAddress,
  producerCountry,
  handleOnChange,
  errors,
}) => {
  const hasError = (field: string): boolean => hasFieldError(field, errors);

  return (
    <SectionWrapper noBorderBottom title={t('menu_management_pandora.producer_information')}>
      <StyledInput
        id={'producer_information_name'}
        label={t('menu_management_pandora.producer_information_name')}
        margin="normal"
        value={producerName}
        name={'producerName'}
        onChange={handleOnChange}
        error={hasError('producerName')}
      />
      <StyledInput
        id={'producer_information_address'}
        label={t('menu_management_pandora.producer_information_address')}
        margin="normal"
        value={producerAddress}
        name={'producerAddress'}
        onChange={handleOnChange}
        error={hasError('producerAddress')}
      />
      <StyledInput
        id={'producer_information_country'}
        label={t('menu_management_pandora.producer_information_country')}
        margin="normal"
        value={producerCountry}
        name={'producerCountry'}
        onChange={handleOnChange}
        error={hasError('producerCountry')}
      />
    </SectionWrapper>
  );
};

export default ProducerInformationSection;
