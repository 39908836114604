import { useEffect, useMemo, useState } from 'react';
import _mapKeys from 'lodash/mapKeys';
import _camelCase from 'lodash/camelCase';
import _merge from 'lodash/merge';
import _isNil from 'lodash/isNil';
import _snakeCase from 'lodash/snakeCase';
import _cloneDeep from 'lodash/cloneDeep';

import { TYPE_OF_CHANGE } from '../../App/common/constant';
import {
  initializeFormValue,
  getUpdatedTitleOrDescriptionState,
  getPatchDataAccumulatorFromFormStates,
} from '../utility/formStateHelpers';

const useProductDetails = (product, changes, cgData) => {
  const [titles, setTitles] = useState([]); // interface { value, hasChanged, relatedRequest }[] assumed 1 request per language update
  const [descriptions, setDescriptions] = useState([]); // interface { value, hasChanged, relatedRequest }[] assumed 1 request per language update
  const [isPrepacked, setIsPrepacked] = useState(initializeFormValue(null, false));
  const [category, setCategory] = useState(initializeFormValue(null, ''));
  const [vatId, setVatId] = useState(initializeFormValue(null, ''));
  const [variantRequests, setVariantRequests] = useState({
    create: {},
    title: {},
    unit_price: {},
    container_price: {},
    delete: {},
  });

  //Compile title when list of title requests change
  const compiledTitle = useMemo(() => {
    const newTitles = titles.reduce((acc, titleRequest) => {
      return { ...acc, ...titleRequest.value };
    }, {});

    return { ...product?.title, ...newTitles };
  }, [product, titles]);

  //Compile description when list of description requests change
  const compiledDescription = useMemo(() => {
    const newDescriptions = descriptions.reduce((acc, descriptionRequest) => {
      return { ...acc, ...descriptionRequest.value };
    }, {});

    return { ...product?.description, ...newDescriptions };
  }, [product, descriptions]);

  //Compile variants when there are changes in variants from product or QCRequests
  const variants = useMemo(() => {
    let updatedVariants = {};

    let productVariants = product?.variants ?? [];

    const embeddedVariantCreateData = changes.filter(
      (change) => change.item.typeOfChange === TYPE_OF_CHANGE.EMBEDDED_VARIANT_CREATE
    );

    let variants, filterCg, newAddedVariant;
    embeddedVariantCreateData.forEach((newVariant) => {
      variants = newVariant.item.patchData.variants;
      Object.keys(variants).forEach((variant) => {
        newAddedVariant = { ...variants[variant] };
        if (newAddedVariant?.choice_group_ids && cgData) {
          newAddedVariant.choice_group_ids.forEach((cgId) => {
            filterCg = cgData.filter((cg) => cg.id === cgId);
            newAddedVariant.choice_groups = filterCg;
          });

          productVariants.push(newAddedVariant);
        }
      });
    });

    if (cgData && cgData.length > 0) {
      changes.forEach((change) => {
        if (
          !change?.item?.patchData?.variants ||
          change?.item?.typeOfChange !== TYPE_OF_CHANGE.EMBEDDED_VARIANT_CHOICE_GROUP_ADD
        ) {
          return false;
        }
        const itemId = change?.item?.itemId;
        const newChoiceGroupId = Object.keys(
          change?.item?.patchData?.variants[itemId]?.choice_groups
        );
        if (newChoiceGroupId.length > 0) {
          newChoiceGroupId.forEach((newCgId) => {
            cgData.forEach((cg) => {
              if (cg.id === newCgId) {
                let variant = productVariants.find((variant) => variant.id === cg.baseItemId);

                //For multiple variant
                if (!variant) {
                  variant = productVariants.find((variant) => variant.id === itemId);
                }

                if (!Array.isArray(cg.choices)) {
                  cg.choices = Object.values(cg.choices);
                }
                if (variant && !variant?.choice_group_ids?.includes(cg.id)) {
                  if (!Array.isArray(variant.choice_groups)) {
                    variant = { ...variant, choice_groups: [] };
                  }
                  if (!Array.isArray(variant.choice_group_ids)) {
                    variant = { ...variant, choice_group_ids: [] };
                  }

                  variant.choice_group_ids = [...variant.choice_group_ids, cg.id];
                  variant.choice_groups = [...variant.choice_groups, cg];
                }
              }
            });
          });
        }
      });
    }

    productVariants.forEach((initialVariant) => {
      const currentVariantId = initialVariant.id;

      const camelCased = _mapKeys(initialVariant, (value, key) => {
        return _camelCase(key);
      });

      updatedVariants = {
        ...updatedVariants,
        [currentVariantId]: _merge({ title: {} }, camelCased, updatedVariants[currentVariantId]),
      };
    });

    Object.keys(variantRequests).forEach((modifiedField) => {
      const changes = variantRequests[modifiedField];

      Object.keys(changes).forEach((variantId) => {
        let updatedVariant = updatedVariants[variantId];
        //Deal with ProductCreate variant separately

        if (
          changes[variantId].relatedRequest.item.typeOfChange ===
          TYPE_OF_CHANGE.EMBEDDED_VARIANT_CREATE
        ) {
          return false;
        }

        if (modifiedField === 'create') {
          const camelCased = _mapKeys(changes[variantId].value, (value, key) => {
            return _camelCase(key);
          });

          updatedVariants = {
            ...updatedVariants,
            [variantId]: _merge(
              {},
              camelCased,
              { id: variantId },
              { title: { ...camelCased.title } }, // Required because title is not being created and will cause VariantChoiceGroupAssociationSection to crash
              { modifiedFields: [modifiedField] }
            ),
          };
        } else if (modifiedField === 'delete') {
          if (
            changes[variantId]?.relatedRequest?.item?.typeOfChange !==
            TYPE_OF_CHANGE.EMBEDDED_VARIANT_CHOICE_GROUP_DELETE
          ) {
            delete updatedVariants[variantId];
          }
        } else {
          updatedVariants = {
            ...updatedVariants,
            [variantId]: _merge(
              { title: {} },
              { id: variantId }, //VariantCreate doesn't have an id in the object
              updatedVariant,
              { [_camelCase(modifiedField)]: changes[variantId].value },
              {
                modifiedFields: Array.from(
                  new Set([...(updatedVariant?.modifiedFields || []), modifiedField])
                ),
              }
            ),
          };
        }
      });
    });

    Object.keys(updatedVariants).forEach((variant) => {
      if (variant.includes('_') && updatedVariants[variant].modifiedFields.includes('title')) {
        const correctVariantId = variant.split('_')[0];
        if (variantRequests['title'][correctVariantId]?.hasChanged) {
          updatedVariants[correctVariantId].title = {
            ...updatedVariants[correctVariantId].title,
            ...updatedVariants[variant].title,
          };
          delete updatedVariants[variant];
          return;
        }

        updatedVariants[correctVariantId].title = {
          ...updatedVariants[correctVariantId].title,
          ...updatedVariants[variant].title,
        };
        delete updatedVariants[variant];
      }
    });
    return updatedVariants;
  }, [variantRequests, product, cgData, changes]);

  //Set all the initial values when there is a change in product but preserving any changes that are made on the form
  useEffect(() => {
    setIsPrepacked(({ hasChanged, relatedRequest, value }) => ({
      hasChanged,
      relatedRequest,
      value: product?.is_prepacked || value, //Boolean, might be set wrongly
    }));
    setCategory(({ hasChanged, relatedRequest, value }) => ({
      hasChanged,
      relatedRequest,
      value: product?.category?.id || value,
    }));
    setVatId(({ hasChanged, relatedRequest, value }) => ({
      hasChanged,
      relatedRequest,
      value: product?.vat?.id || value,
    }));
  }, [product]);

  // populate form field based on change requests
  useEffect(() => {
    changes.forEach((changeRequest, changeRequestIndex) => {
      const requestItem = changeRequest.item;
      const requestChangeType = requestItem.typeOfChange;
      const patchData = requestItem.patchData;

      const setEmbeddedVariantChange = (QCRequest, index, fieldToChange) => {
        //const requestId = QCRequest.item.requestId;
        const requestPatchData = QCRequest.item.patchData;
        const variantKey = Object.keys(requestPatchData?.variants ?? {})?.[0];
        const variantPatchData = requestPatchData?.variants?.[variantKey] ?? requestPatchData;

        const isCreate = fieldToChange === 'create';

        setVariantRequests((prevState) => {
          const updatedRequests = { ...prevState };
          //const key = requestChangeType === "EmbeddedVariantTitleChange" ? variantKey : variantKey;

          updatedRequests[fieldToChange] = {
            ...updatedRequests[fieldToChange],
            [variantKey ?? index]: initializeFormValue(
              isCreate ? variantPatchData : variantPatchData[fieldToChange],
              {},
              QCRequest
            ),
          };

          return updatedRequests;
        });
      };

      switch (requestChangeType) {
        case TYPE_OF_CHANGE.PRODUCT_CREATE:
        case TYPE_OF_CHANGE.CATEGORY_CREATE:
          setTitles([initializeFormValue(patchData?.title, {}, changeRequest)]);
          setDescriptions([initializeFormValue(patchData?.description, {}, changeRequest)]);
          setIsPrepacked(initializeFormValue(patchData?.is_prepacked, false, changeRequest));
          setCategory(initializeFormValue(patchData?.category_id, '', changeRequest));
          setVatId(initializeFormValue(patchData?.vat_id, '', changeRequest));

          //Process Variants
          setVariantRequests((prevState) => {
            const updatedRequests = { ...prevState };

            Object.keys(patchData?.variants || {}).forEach((variantId) => {
              updatedRequests.create = {
                ...updatedRequests.create,
                [variantId]: initializeFormValue(patchData.variants[variantId], {}, changeRequest),
              };
            });

            return updatedRequests;
          });
          break;
        case TYPE_OF_CHANGE.TITLE_CHANGE:
        case TYPE_OF_CHANGE.CATEGORY_TITLE_CHANGE:
          const newTitleData = initializeFormValue(patchData?.title, {}, changeRequest);
          setTitles((prevValue) => {
            return [...prevValue, newTitleData];
          });
          break;
        case TYPE_OF_CHANGE.DESCRIPTION_CHANGE:
        case TYPE_OF_CHANGE.CATEGORY_DESCRIPTION_CHANGE:
          const newDescData = initializeFormValue(patchData?.description, {}, changeRequest);
          setDescriptions((prevValue) => {
            return [...prevValue, newDescData];
          });
          break;
        case TYPE_OF_CHANGE.PREPACKED_CHANGE:
          setIsPrepacked(initializeFormValue(patchData?.is_prepacked, false, changeRequest));
          break;
        case TYPE_OF_CHANGE.CATEGORY_CHANGE:
          setCategory(initializeFormValue(patchData?.category_id, '', changeRequest));
          break;
        case TYPE_OF_CHANGE.VAT_CHANGE:
          setVatId(initializeFormValue(patchData?.vat_id, '', changeRequest));
          break;
        case TYPE_OF_CHANGE.EMBEDDED_VARIANT_UNIT_PRICE_CHANGE: {
          setEmbeddedVariantChange(changeRequest, changeRequestIndex, 'unit_price');
          break;
        }
        case TYPE_OF_CHANGE.EMBEDDED_VARIANT_TITLE_CHANGE: {
          setEmbeddedVariantChange(changeRequest, changeRequestIndex, 'title');
          break;
        }
        case TYPE_OF_CHANGE.EMBEDDED_VARIANT_CREATE:
          setEmbeddedVariantChange(changeRequest, changeRequestIndex, 'create');
          break;
        case TYPE_OF_CHANGE.EMBEDDED_VARIANT_DELETE:
        case TYPE_OF_CHANGE.CATEGORY_DELETE:
          setEmbeddedVariantChange(changeRequest, changeRequestIndex, 'delete');
          break;
        case TYPE_OF_CHANGE.EMBEDDED_VARIANT_CHOICE_GROUP_DELETE:
          setEmbeddedVariantChange(changeRequest, changeRequestIndex, 'delete');
          break;
        case TYPE_OF_CHANGE.VARIANT_CREATE:
          //For variant create, split into the respective components, similar to ProductCreate
          setVariantRequests(({ create, container_price, title, unit_price }) => {
            return {
              create,
              container_price,
              unit_price,
              title: {
                ...title,
                [requestItem.itemId]: initializeFormValue(patchData?.title, {}, changeRequest),
              },
            };
          });
          setVariantRequests(({ create, container_price, unit_price, title }) => {
            return {
              create,
              container_price,
              title,
              unit_price: {
                ...unit_price,
                [requestItem.itemId]: initializeFormValue(patchData?.unit_price, 0, changeRequest),
              },
            };
          });
          setVariantRequests(({ create, container_price, unit_price, title }) => {
            return {
              create,
              unit_price,
              title,
              container_price: {
                ...container_price,
                [requestItem.itemId]: initializeFormValue(
                  patchData?.container_price,
                  0,
                  changeRequest
                ),
              },
            };
          });
          break;
        case TYPE_OF_CHANGE.EMBEDDED_VARIANT_CHOICE_GROUP_ADD:
          //embeddedvariantchoicegroupadd
          setVariantRequests(({ create, container_price, title, unit_price }) => {
            return {
              create,
              container_price,
              unit_price,
              title: {
                ...title,
                [changeRequest.requestId]: initializeFormValue(patchData?.title, {}, changeRequest),
              },
            };
          });
          setVariantRequests(({ create, container_price, unit_price, title }) => {
            return {
              create,
              container_price,
              title,
              unit_price: {
                ...unit_price,
                [changeRequest.requestId]: initializeFormValue(
                  patchData?.unit_price,
                  0,
                  changeRequest
                ),
              },
            };
          });
          setVariantRequests(({ create, container_price, unit_price, title }) => {
            return {
              create,
              unit_price,
              title,
              container_price: {
                ...container_price,
                [changeRequest.requestId]: initializeFormValue(
                  patchData?.container_price,
                  0,
                  changeRequest
                ),
              },
            };
          });
          break;
        case TYPE_OF_CHANGE.VARIANT_TITLE_CHANGE:
          setVariantRequests(({ create, container_price, title, unit_price }) => {
            return {
              create,
              container_price,
              unit_price,
              title: {
                ...title,
                [requestItem.itemId]: initializeFormValue(patchData?.title, {}, changeRequest),
              },
            };
          });
          break;
        case TYPE_OF_CHANGE.VARIANT_CONTAINERPRICE_CHANGE:
          setVariantRequests(({ create, container_price, unit_price, title }) => {
            return {
              create,
              unit_price,
              title,
              container_price: {
                ...container_price,
                [requestItem.itemId]: initializeFormValue(
                  patchData?.container_price,
                  0,
                  changeRequest
                ),
              },
            };
          });
          break;
        case TYPE_OF_CHANGE.VARIANT_UNITPRICE_CHANGE:
          setVariantRequests(({ create, container_price, unit_price, title }) => {
            return {
              create,
              container_price,
              title,
              unit_price: {
                ...unit_price,
                [requestItem.itemId]: initializeFormValue(patchData?.unit_price, 0, changeRequest),
              },
            };
          });
          break;
        case TYPE_OF_CHANGE.VARIANT_CHOICE_GROUP_ADD:
          break;
        default:
          break;
      }
    });
  }, [changes]);

  //Form Handlers
  const handleTitleDescUpdate = (e, language) => {
    let { name, value } = e.target;
    if (name === 'title') {
      setTitles((prevState) => {
        return getUpdatedTitleOrDescriptionState(prevState, value, language);
      });
    }
    if (name === 'description') {
      setDescriptions((prevState) => {
        return getUpdatedTitleOrDescriptionState(prevState, value, language);
      });
    }
  };

  const handleCategoryUpdate = (e) => {
    let { value } = e.target;
    setCategory((prevState) => ({
      ...prevState,
      value: value,
      hasChanged: true,
    }));
  };
  const handlePrepackedUpdate = (e) => {
    let { checked } = e.target;
    setIsPrepacked((prevState) => ({
      ...prevState,
      value: checked,
      hasChanged: true,
    }));
  };
  const handlePriceUpdate = (e, variant, defaultValue) => {
    const { value, name } = e.target;
    const targetVariantId = variant.id;
    const isCreate = variant.modifiedFields?.includes('create');

    const setValue = (toSet, fallbackValue) => {
      return !!toSet ? parseFloat(toSet) : _isNil(fallbackValue) ? toSet : fallbackValue;
    };

    //implement the change in the QCRequests to be saved later
    setVariantRequests((prevVariantRequests) => {
      const updatedVariantRequests = { ...prevVariantRequests };
      const targetField = _snakeCase(name); // field in QCRequest is in snakecase, in variant object is camelcase

      const targetVariantRequest = isCreate
        ? updatedVariantRequests['create'][targetVariantId]
        : updatedVariantRequests[targetField][targetVariantId];
      const updatedRequest = _cloneDeep(targetVariantRequest);

      if (isCreate) {
        updatedRequest.value[targetField] = setValue(value, defaultValue);
        updatedVariantRequests['create'][targetVariantId] = updatedRequest;
      } else {
        updatedRequest.value = setValue(value, defaultValue);
        updatedVariantRequests[targetField][targetVariantId] = updatedRequest;
      }
      updatedRequest.hasChanged = true;

      return updatedVariantRequests;
    });
  };
  const handleVariationNameUpdate = (e, variant, language) => {
    const { value, name } = e.target;
    const targetVariantId = variant.id;
    const targetField = name;
    const isCreate = variant.modifiedFields?.includes('create');

    setVariantRequests((prevVariantRequests) => {
      const updatedVariantRequests = { ...prevVariantRequests };

      let targetVariantRequest = isCreate
        ? updatedVariantRequests['create'][targetVariantId]
        : updatedVariantRequests[targetField][targetVariantId];
      let updatedRequest = { ...targetVariantRequest };

      if (isCreate) {
        const variantToUpdate = {
          ...updatedRequest.value,
        };
        variantToUpdate[targetField] = { ...variantToUpdate[targetField], [language]: value };
        updatedRequest.value = variantToUpdate;

        updatedVariantRequests['create'][targetVariantId] = updatedRequest;
      } else {
        if (updatedRequest.value.hasOwnProperty(language)) {
          updatedRequest.value = { ...updatedRequest.value, [language]: value };

          updatedVariantRequests[targetField][targetVariantId] = updatedRequest;
        } else {
          const variantIds = Object.keys(updatedVariantRequests[targetField]);

          const duplicateVariantIds = variantIds.filter((item) => item.includes(targetVariantId));

          duplicateVariantIds.forEach((variantId) => {
            if (variantId.includes('_')) {
              targetVariantRequest = isCreate
                ? updatedVariantRequests['create'][variantId]
                : updatedVariantRequests[targetField][variantId];
              updatedRequest = { ...targetVariantRequest };

              updatedRequest.value = { ...updatedRequest.value, [language]: value };

              updatedVariantRequests[targetField][variantId] = updatedRequest;
            }
          });
        }
      }
      updatedRequest.hasChanged = true;

      return updatedVariantRequests;
    });
  };
  const handleVatUpdate = (e) => {
    let { value } = e.target;

    setVatId((prevState) => {
      return { ...prevState, value: value, hasChanged: true };
    });
  };
  //TODO: Handle updating of variant choices
  const updateChoiceGroups = (variantsChoiceGroup) => {};

  //Change handlers
  const getPatchData = () => {
    const formData = {
      title: titles,
      description: descriptions,
      category_id: category,
      vat_id: vatId,
      is_prepacked: isPrepacked,
    };
    const patchDataAccumulator = { ...getPatchDataAccumulatorFromFormStates(formData) };
    //Handle variant QCRequests due to different base structure
    const changeKeys = Object.keys(variantRequests);
    changeKeys.forEach((changeKey) => {
      if (variantRequests.hasOwnProperty(changeKey)) {
        const changeList = variantRequests[changeKey];

        Object.keys(changeList).forEach((variantId) => {
          const changeRequestItem = changeList[variantId];
          if (!changeRequestItem.relatedRequest) {
            //Don't process item if it's not part of a QCRequest
            return;
          }

          const requestId = changeRequestItem.relatedRequest.requestId;

          //handle ProductCreate/EmbeddedVariantCreate changes separately
          if (changeKey === 'create') {
            patchDataAccumulator[requestId] = {
              ...patchDataAccumulator?.[requestId],
              variants: {
                ...patchDataAccumulator?.[requestId]?.variants,
                [variantId]: {
                  ...changeRequestItem.value,
                },
              },
              ...(changeRequestItem.hasChanged ? { updated: true } : {}),
              version: changeRequestItem.relatedRequest.version,
            };
          } else if (
            changeRequestItem?.relatedRequest?.item?.typeOfChange?.startsWith('EmbeddedVariant')
          ) {
            //All other EmbeddedVariant changes
            const variantsData = patchDataAccumulator?.[requestId]?.variants;
            const splitVariantId = variantId.includes('_') ? variantId.split('_')[0] : variantId;
            patchDataAccumulator[requestId] = {
              ...patchDataAccumulator?.[requestId],
              variants: {
                ...variantsData,
                [splitVariantId]: {
                  ...variantsData?.[variantId],
                  [changeKey]: changeRequestItem.value,
                },
              },
              ...(changeRequestItem.hasChanged ? { updated: true } : {}),
              version: changeRequestItem.relatedRequest.version,
            };
          } else {
            patchDataAccumulator[requestId] = {
              ...patchDataAccumulator[requestId],
              [changeKey]: changeRequestItem.value,
              ...(changeRequestItem.hasChanged ? { updated: true } : {}),
              version: changeRequestItem.relatedRequest.version,
            };
          }
        });
      }
    });

    return patchDataAccumulator;
  };

  return {
    titles,
    descriptions,
    isPrepacked,
    category,
    vatId,
    variantRequests,
    variants,
    handleTitleDescUpdate,
    handleCategoryUpdate,
    handlePrepackedUpdate,
    handlePriceUpdate,
    handleVariationNameUpdate,
    handleVatUpdate,
    updateChoiceGroups,
    getPatchData,
    compiledTitle,
    compiledDescription,
  };
};

export default useProductDetails;
