import React from 'react';
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';

import StatusBox from '../../../MenuManagement/components/StatusBox';
import GridDisplay from '../GridDisplay';
import DetailDisplay from './DetailDisplay';
import _isEmpty from 'lodash/isEmpty';

interface Variants {
  unit_price: string;
  title?: any;
  id?: number;
}
interface CurrentCountry {
  currency: Currency;
}
interface Currency {
  code: string;
}
interface iProps {
  productData: {
    id: string;
    title: { [key: string]: string };
    description: { [key: string]: string };
    defaultTitle?: string;
    variants:Variants[];
  }
  status: string;
  currentCountry: CurrentCountry,
  variantId?: any
}

const ProductDetailDisplay = ({ productData, status, currentCountry, variantId }: iProps) => {
  const invalidProductData =
    !productData || _isEmpty(productData) || !productData.hasOwnProperty('title');
    let priceWithCurrency = "";

    type Variants = {
      unit_price?: string;
      title?: any;
      id?: number;
    };

    let relatedVariant: Variants = {};
    if(productData?.variants)
    {
      if(Array.isArray(productData.variants)){
        const findVariant = variantId && productData.variants.find(variant => variant.id === variantId);
        relatedVariant = Object.assign({}, findVariant)
      }
      else{
        relatedVariant = productData.variants[variantId];
      }
    }

    const hasVariants = productData?.variants;
    
    if(hasVariants && variantId && relatedVariant)
    {
      priceWithCurrency = relatedVariant.unit_price ? "+" + currentCountry.currency.code + " " + relatedVariant?.unit_price : "";
    }

  return (
    <Card data-enzyme={'ProductDetailDisplay'}>
      <CardHeader
        avatar={<StatusBox type={status?.toLowerCase() || 'info'} />}
        title={'Parent Product Details'}
        subheader={`ID: ${invalidProductData ? 'INVALID' : productData.id}`}
      />
      
      <CardContent>
        {invalidProductData ? (
          <Typography>Invalid Product Data</Typography>
        ) : (
          Object.keys(productData.title).map((titleKey) => (
            <GridDisplay key={titleKey} itemsPerRow={4}>
              <DetailDisplay label={`Title [${titleKey}]`} value={productData?.title?.[titleKey]} />
              <DetailDisplay
                label={`Description [${titleKey}]`}
                value={productData?.description?.[titleKey]}
              />

            {(variantId) && (
              <DetailDisplay label={`Variant Title [${titleKey}]`} value={relatedVariant?.title?.[titleKey]} />
            )}

            {(titleKey !== "En" && priceWithCurrency) && (
              <DetailDisplay label={`Price`} value={priceWithCurrency} />
            )}
              
             
            </GridDisplay>
          ))
        )}
      </CardContent>
    </Card>
  );
};

export default ProductDetailDisplay;
