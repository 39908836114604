import React from 'react';
import { Typography } from '@material-ui/core';

const AccessDenied = () => {
  return (
    <div
      data-enzyme={'CompleteScreen'}
      style={{ minHeight: '100%', display: 'flex', justifyContent: 'center', padding: '30px 0' }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ textAlign: 'center' }}>
          <img alt="empty state" src="/assets/access-denied.png" width="auto" height="157" />
          <Typography variant={'h4'} style={{ padding: '15px 0' }}>
            You are not authorized to access this page
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
