import { useEffect, useReducer } from 'react';

import opsPortal from '../client/authClient';
import config from '../App/config';

const APAC_COUNTRIES = 'BD,HK,KH,LA,MM,MY,PK,PH,SG,TW,TH,JP';

const initialState = {
  isInsideOpsPortal: false,
  countries: [],
  currentCountry: {},
  client: null,
};

function reducer(state, action) {
  if (action.type === 'OPS_PORTAL_LOADED') {
    return {
      isInsideOpsPortal: action.isInsideOpsPortal,
      countries: action.countries,
      currentCountry: action.currentCountry,
      client: action.client,
    };
  }
  return state;
}

const useOpsPortal = () => {
  const [{ isInsideOpsPortal, countries, currentCountry, client }, dispatch] = useReducer(
    reducer,
    initialState
  );

  useEffect(() => {
    const loadOpsPortal = async () => {
      try {
        await opsPortal.setPluginLoaded();
        const isInsideOpsPortal = await opsPortal.isRunningInOpsPortal();
        let countries = await opsPortal.getCountries();
        countries = countries.map((country) => ({
          ...country,
          geid: `${country.entity.code}_${country.code}`,
        }));

        const currentCountry = await opsPortal.getCurrentCountry();
        const client = await opsPortal.getClient();
        dispatch({
          type: 'OPS_PORTAL_LOADED',
          isInsideOpsPortal,
          countries,
          currentCountry,
          client,
        });
      } catch (error) {
        console.error('error loading ops portal');
      }
    };

    loadOpsPortal();
  }, []);

  return {
    isInsideOpsPortal,
    countries,
    currentCountry,
    opsPortalClient: client,
    isAPAC: config.supportedCountries === APAC_COUNTRIES,
  };
};

export default useOpsPortal;
