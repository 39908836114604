import axios from '../../../App/utility/axios';
import config from '../../../App/config';
import { AxiosPromise } from 'axios';
import { Storage, storageKeys } from './../../../storage/storage';
export function fetchPendingNumber({ country, status }): AxiosPromise<any> {
  return axios(config.api + '/stats?country=' + country.code + '&status=' + status, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + Storage.get(storageKeys.ACCESS_TOKEN),
    },
  });
}
