import { nutritionDetailsFieldsTypes, otherInformationFieldsTypes } from './types';

export const nutritionDetailsFields: nutritionDetailsFieldsTypes[][] = [
  [
    {
      id: 'nutrition_details_energyOne',
      name: 'energyOne',
      endAdornment: 'KJ',
    },
    {
      id: 'nutrition_details_fat',
      name: 'fat',
      endAdornment: 'g',
    },
    {
      id: 'nutrition_details_carbohydrates',
      name: 'carbohydrates',
      endAdornment: 'g',
    },
    {
      id: 'nutrition_details_protein',
      name: 'protein',
      endAdornment: 'g',
    },
  ],
  [
    {
      id: 'nutrition_details_energyTwo',
      name: 'energyTwo',
      endAdornment: 'kcal',
    },
    {
      id: 'nutrition_details_saturates',
      name: 'saturates',
      endAdornment: 'g',
    },
    {
      id: 'nutrition_details_sugars',
      name: 'sugars',
      endAdornment: 'g',
    },
    {
      id: 'nutrition_details_salt',
      name: 'salt',
      endAdornment: 'g',
    },
  ],
];

export const otherInformationFields: otherInformationFieldsTypes[] = [
  {
    id: 'other_information_legal_description',
    name: 'legalDescription',
  },
  {
    id: 'other_information_storage_instructions',
    name: 'storageInstructions',
  },
  {
    id: 'other_information_instructions_for_use',
    name: 'instructionsForUse',
  },
];
