import React, { useCallback, useEffect, useState } from 'react';
import { Modal, StylesProvider } from '@material-ui/core';
import ModalAppBar from '../ModalAppBar';
import { ModalBodyContainer, ContentContainer } from './style';
import { ModalProps } from './types';
import AlertDialog from '../AlertDialog';
import { useSdk } from '@deliveryhero/vendor-portal-sdk';

const FpModal: React.FC<ModalProps> = ({
  open,
  handleClose,
  handleDraftButtonClick,
  draftButtonShouldRender,
  submitButtonShouldRender = true,
  draftButtonDisabled,
  draftButtonText,
  handleButtonClick,
  children,
  buttonText,
  title,
  isFormDirty, // this will be deprecated in favour of hasUnsavedChanges
  buttonDisabled = !isFormDirty, // TODO: this is to keep backward compatibility. Should be removed after all the Modals start using the new API
  hasUnsavedChanges = false,
  appBarPosition = 'static',
  infoLink,
  t,
  gtmEventNames = {},
  tSource = 'menu_management_pandora',
}) => {
  const [clientY, setClientY] = useState(null);
  const [closeEvent, setCloseEvent] = useState(null);
  const [startTime, setStartTime] = useState(null);

  const { pushGtmEvent } = useSdk();

  const _iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);

  // https://stackoverflow.com/questions/41594997/ios-10-safari-prevent-scrolling-behind-a-fixed-overlay-and-maintain-scroll-position
  function isOverlayTotallyScrolled(that) {
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
    return that.scrollHeight - that.scrollTop <= that.clientHeight;
  }

  function disableRubberBand(event: any) {
    const _clientY = event.targetTouches[0].clientY - clientY;

    if (event.target.scrollTop === 0 && _clientY > 0) {
      // element is at the top of its scroll
      event.preventDefault();
    }

    if (isOverlayTotallyScrolled(event.target) && _clientY < 0) {
      //element is at the top of its scroll
      event.preventDefault();
    }
  }

  const cancelExit = useCallback(() => {
    setCloseEvent(null);
  }, []);

  const confirmExit = useCallback(() => {
    if (handleClose) {
      handleClose(closeEvent);
    }
    setCloseEvent(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeEvent]);

  const handleCloseWithConfirmation = useCallback(
    (e) => {
      if (hasUnsavedChanges) {
        e.persist();
        setCloseEvent(e);
      } else {
        handleClose(e);
      }
    },
    [handleClose, hasUnsavedChanges]
  );

  useEffect(() => {
    if (!open) return;

    const currentTime = Date.now();
    setStartTime(currentTime);

    if (gtmEventNames.onOpen) {
      pushGtmEvent(gtmEventNames.onOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleButtonClickWithTime = useCallback(() => {
    const endTime = Date.now();
    if (handleButtonClick) {
      handleButtonClick();
    }

    const totalTime = (endTime - startTime) / 1000;
    if (gtmEventNames.onSubmit) {
      pushGtmEvent(gtmEventNames.onSubmit, { eventValue: totalTime });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, handleButtonClick]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseWithConfirmation}
        disableAutoFocus={true}
        onTouchStart={(event) => {
          if (_iOSDevice && event.targetTouches.length === 1) {
            setClientY(event.targetTouches[0].clientY);
          }
        }}
        onTouchMove={(event) => {
          if (_iOSDevice && event.targetTouches.length === 1) {
            // detect single touch
            disableRubberBand(event);
          }
        }}
        style={_iOSDevice ? { WebkitOverflowScrolling: 'touch', touchAction: 'none' } : {}}
      >
        <StylesProvider injectFirst>
          {/* @material-ui/lab still exposing theme and overriding the styles from
         RPS @material-ui/core which is exposed as external dependency. To
        prevent that we have to apply StylesProvider HOC to put the styles from @material-ui/lab at the very top
        */}
          <ModalBodyContainer>
            <ModalAppBar
              onClose={handleCloseWithConfirmation}
              handleDraftButtonClick={handleDraftButtonClick}
              draftButtonShouldRender={draftButtonShouldRender}
              submitButtonShouldRender={submitButtonShouldRender}
              draftButtonDisabled={draftButtonDisabled}
              handleButtonClick={handleButtonClickWithTime}
              draftButtonText={draftButtonText}
              buttonDisabled={buttonDisabled}
              buttonText={buttonText}
              position={appBarPosition}
              title={title}
              infoLink={infoLink}
              t={t}
            />
            <ContentContainer>{children}</ContentContainer>
          </ModalBodyContainer>
        </StylesProvider>
      </Modal>
      <AlertDialog
        open={Boolean(closeEvent)}
        title={t(`${tSource}.discard_changes_prompt`)}
        description={t(`${tSource}.discard_changes_prompt_description`)}
        rejectLabel={t(`${tSource}.discard_changes_cancel_btn_txt`)}
        confirmLabel={t(`${tSource}.discard_changes_confirm_btn_txt`)}
        onClose={cancelExit}
        onAccept={confirmExit}
        onReject={cancelExit}
        disabled={false}
      />
    </>
  );
};

export default FpModal;
