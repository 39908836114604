import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormControlLabel, Checkbox, Box, CircularProgress } from '@material-ui/core';
import { unstable_createMuiStrictModeTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  FormPrePackageDiv,
  PrePackageCheckbox,
  PrePackageFieldWrapper,
  SectionHeader,
} from './style';
import NutritionInformation from '../NutritionInformation';
import { NutritionSectionProps, PrepackedCheckboxProps } from './types';

// Since the portal framework is using React 17 where findDOMNode is deprecated
// and MUI 4~ version's Collapse component (what is used by Accordion) uses it, with the normal MUI theme
// the code would break.
// This custom Theme wrapper is added so that it uses nodeRef instead, and makes it work.
// TODO: once portal updates MUI to 5.~, remove this custom Theme wrapper
const theme = unstable_createMuiStrictModeTheme();

const PrepackedCheckbox: React.FC<PrepackedCheckboxProps> = ({
  t,
  isPrepacked,
  onChangePrepacked,
}) => (
  <FormPrePackageDiv>
    <FormControlLabel
      control={<Checkbox checked={isPrepacked} onChange={onChangePrepacked} name="isPrepacked" />}
      label={<PrePackageCheckbox>{t('menu_management_pandora.prepackaged')}</PrePackageCheckbox>}
    />
    <PrePackageFieldWrapper>
      {t('menu_management_pandora.prepackaged_message')}
    </PrePackageFieldWrapper>
  </FormPrePackageDiv>
);

const NutritionSection: React.FC<NutritionSectionProps> = ({
  t,
  nutritionProps,
  onChangePrepacked,
}) => {
  const { isLoading } = nutritionProps;

  return (
    <MuiThemeProvider theme={theme}>
      <StyledAccordion>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <SectionHeader>
            {t('menu_management_pandora.item_navigation_nutrition_information')}
          </SectionHeader>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {isLoading ? (
            <Box
              data-testid="nutrition_section_loader"
              margin={3}
              display={'flex'}
              justifyContent={'center'}
            >
              <CircularProgress size={64} />
            </Box>
          ) : (
            <>
              <PrepackedCheckbox
                t={t}
                isPrepacked={nutritionProps.isPrepacked}
                onChangePrepacked={onChangePrepacked}
              />
              <NutritionInformation
                data-testid="section_accordion_nutrition_information"
                {...nutritionProps}
                t={t}
                isInChoiceItem
              />
            </>
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
    </MuiThemeProvider>
  );
};

export default NutritionSection;
