import { COUNTRY_LIST } from '../../App/common/constant';
export { findCountryRegion } from './countryRegion';
export { findRegionApp } from './regionApp';
export { redirectTo } from './redirect';
export { getCountriesFromNamespaces } from './countryNamespace';
export { filterRegionIAMCountries } from './regionIAMCountries';
export { fetchCurrentUser } from './helpers';
export * from './fwf';

export function getCurrentCountry(countryCode) {
  return COUNTRY_LIST[countryCode] || {};
}

export function getAgentEmail(authorizationToken) {
  const authorizationTokenPayload = JSON.parse(atob(authorizationToken.split('.')[1]));
  return authorizationTokenPayload.sub || authorizationTokenPayload.data.email;
}

export const isLocalHost = () => {
  return Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );
};

export const app = {
  version: '1',
};
