import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const useStyles = makeStyles(styles);

/**
 * @typedef Props
 * @property {React.CSSProperties} [style]
 * @param Props props
 */
const ModalAppBar = ({
  onClose,
  handleSave,
  isFormDirty,
  isSaving,
  isAgentAssignedToVendor = true,
  style,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={style}>
      <AppBar position="static" style={{ backgroundColor: 'rgb(45, 54, 69)' }}>
        <Toolbar>
          <IconButton
            onClick={onClose}
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
          >
            <KeyboardBackspace />
          </IconButton>

          <Typography variant="h4" color="inherit" className={classes.flex}>
            Amend Changes
          </Typography>

          <Button
            color="primary"
            onClick={handleSave}
            disabled={!isAgentAssignedToVendor || !isFormDirty || isSaving}
            variant="contained"
            data-cp="product-save-btn"
          >
            {isSaving ? 'Approving' : 'Approve'}
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};
ModalAppBar.propTypes = {
  onClose: PropTypes.func,
  handleSave: PropTypes.func,
  isFormDirty: PropTypes.bool,
  isSaving: PropTypes.bool,
};
export default ModalAppBar;
