/**
 * Function for the following logic: if Not Applicable is being selected, the rest of the items should not be selected.
 * Otherwise if Not Applicable is already selected, and a new item is being selected, remove
 * Not Applicable from the values.
 *
 * @param options string[]
 * @param selectedValues string[]
 * @returns string[]
 */
const handleNotApplicableLogic = (options: string[], selectedValues: string[]) => {
  const notApplicableOption = options[0];

  /**
   * Checks if it is only Not Applicable selected, and if so
   * returns the new array without it (deselects it).
   * Otherwise return the original.
   *
   * @param values string[]
   * @returns string[]
   */
  const handleIfOtherValuesSelected = (values: string[]): string[] => {
    const notApplicableItemIdx = values.indexOf(notApplicableOption);
    const hasNotApplicableItem = notApplicableItemIdx > -1;
    const areTwoItemsSelected = values.length === 2;

    if (hasNotApplicableItem && areTwoItemsSelected) {
      return [...values.slice(0, notApplicableItemIdx), ...values.slice(notApplicableItemIdx + 1)];
    }

    return values;
  };

  /**
   * Checks if the newly selected vaue (last item) is Not Applicable.
   * If so, only return that item, otherwise check if it was previously Not Applicable.
   *
   * @param values string[]
   * @returns string[]
   */
  const handleSelectedItemType = (values: string[]): string[] => {
    const newlySelectedValue = values[values.length - 1];
    const isNotApplicableSelected = newlySelectedValue === notApplicableOption;

    return isNotApplicableSelected ? [notApplicableOption] : handleIfOtherValuesSelected(values);
  };

  return handleSelectedItemType(selectedValues);
};

export default handleNotApplicableLogic;
