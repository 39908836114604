const REJECT_REASON_REJECTION_COMBINED_PRICES = {
  key: 'menu_rejection_combined_prices',
  text: 'Combined Prices',
};

const REJECT_REASON_SUSPICIOUS_PRICES = {
  key: 'menu_rejection_suspicious_prices',
  text: 'Suspicious prices',
};

const REJECT_REASON_REPEATED_PRICE_FOR_CHOICES = {
  key: 'menu_rejection_repeated_price_for_choices',
  text: 'Repeated Price for Choices',
};

export const TALABAT_PRICE_REJECT_REASONS = [
  REJECT_REASON_REJECTION_COMBINED_PRICES,
  REJECT_REASON_SUSPICIOUS_PRICES,
  REJECT_REASON_REPEATED_PRICE_FOR_CHOICES,
];
