export const navigationInitialState = {
  topNavbarPages: [],
  menuRequestCount: null,
  menuPhotosCount: null,
};

export const navigationReducer = (state = navigationInitialState, action) => {
  if (action.type === 'SET_PASSIVE') {
    return { ...state, ...action.data };
  } else if (action.type === 'SET_ACTIVE') {
    return { ...state, ...action.data };
  } else if (action.type === 'SET_NAVBAR_PAGES_TOP') {
    // maintain selected topnavbar when country changes
    if (state.topNavbarPages) {
      let index = state.topNavbarPages.findIndex((item) => item.selected);
      if (index !== -1 && action.topNavbarPages[index]) {
        action.topNavbarPages[index].selected = true;
        action.topNavbarPages[index].children = state.topNavbarPages[index].children;
      }
    }
    return { ...state, topNavbarPages: [...action.topNavbarPages] };
  } else if (action.type === 'SET_SELECTED_NAVBAR_TOP_ITEM') {
    state.topNavbarPages.forEach((item, index) => {
      state.topNavbarPages[index].selected = false;
    });
    state.topNavbarPages[action.data.index].selected = true;
    return {
      ...state,
      topNavbarPages: [...state.topNavbarPages],
    };
  } else if (action.type === 'SET_SELECTED_NAVBAR_COUNT') {
    /*     action.data.pluginComponentName;
    action.data.count; */

    let propsToUpdate;
    if (action.data.pluginComponentName === 'Home') {
      propsToUpdate = {
        ...state,
        menuRequestCount: action.data.count,
      };
    } else if (action.data.pluginComponentName === 'ManagePhoto') {
      propsToUpdate = {
        ...state,
        menuPhotosCount: action.data.count,
      };
    }
    return propsToUpdate;
  }

  /*   if (action.type === 'SET_SELECTED_NAVBAR_COUNT') {
    let selectedIndex = state.topNavbarPages.findIndex((item) => item.selected);
    if (selectedIndex !== -1) {
      let selectedChildIndex = state.topNavbarPages[selectedIndex].children.findIndex(
        (item) => item.component.name === action.data.pluginComponentName // ['Home', 'ManagePhoto']
      );
      if (
        selectedChildIndex !== -1 &&
        state.topNavbarPages[selectedIndex].children[selectedChildIndex]
      ) {
        state.topNavbarPages[selectedIndex].children[selectedChildIndex].count = action.data.count;
      }
    }

    return {
      ...state,
      topNavbarPages: [...state.topNavbarPages],
    };
  } */

  return state;
};
