import React from 'react';
import styled from 'styled-components';
import TextField from '../TextField';
import { Autocomplete } from '@material-ui/lab';
import { Box, FormControl, FormHelperText } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { SectionWrapperProps } from './types';
import { IsInChoiceItemContext } from './NutritionInformation';
import { ThemeProps } from '../../utilities/interface';

export const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
  max-width: 368px;

  > div {
    width: 100%;
    max-width: 368px;

    > div > .MuiAutocomplete-endAdornment {
      top: unset;
      bottom: 0;
      margin-bottom: 3px;
    }

    > div > .MuiAutocomplete-endAdornment > * {
      padding: 4px;
    }
  }
`;

export const StyledInput = styled(TextField)`
  width: 100%;
  max-width: 368px;
`;

const SectionTitle = styled.h2`
  padding: ${({ isInChoiceItem }) => (isInChoiceItem ? '24px 0px 0px' : '24px 24px 0px')};
  font-size: 22px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  margin: 0px;

  ${(props: ThemeProps) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) =>
      props.isInChoiceItem
        ? `${props.theme.spacing(2)}px 0px 0px`
        : `${props.theme.spacing(2)}px ${props.theme.spacing(2)}px 0px`};
    font-size: 22px;
  }
`;

const SectionBox = styled(Box)`
  & {
    padding: ${({ isInChoiceItem }) => (isInChoiceItem ? '24px 0px' : '24px')};

    ${(props: ThemeProps) => props.theme.breakpoints.down('sm')} {
      padding: ${({ isInChoiceItem }) => (isInChoiceItem ? '16px 0px' : '16px')};
    }
  }
`;

export const SectionWrapper: React.FC<SectionWrapperProps> = ({
  children,
  title,
  noBorderBottom,
}) => {
  const isInChoiceItem = React.useContext(IsInChoiceItemContext);
  const boxShadow = isInChoiceItem ? 0 : 1;
  const borderBottom = isInChoiceItem && !noBorderBottom ? '1px solid #DDDDDD' : 0;

  return (
    <Box bgcolor="white" mb={2} boxShadow={boxShadow} borderBottom={borderBottom}>
      {title && <SectionTitle isInChoiceItem={isInChoiceItem}>{title}</SectionTitle>}
      <SectionBox isInChoiceItem={isInChoiceItem}>{children}</SectionBox>
    </Box>
  );
};

export type BottomLabelInputProps = {
  children: React.ReactNode;
  bottomLabel?: string;
  style?: any;
};

export const BottomLabelInput: React.FC<BottomLabelInputProps> = ({
  children,
  bottomLabel,
  style,
}) => (
  <FormControl style={style}>
    {children}
    <FormHelperText>{bottomLabel}</FormHelperText>
  </FormControl>
);

export const Column = styled('div')`
  display: flex;
  flex-direction: column;
  width: 240px;
`;

export const InlineInput = styled(StyledInput)`
  max-width: 240px;

  @media (max-width: 500px) {
    max-width: 368px;
  }
`;

export const FirstRow = styled('div')`
  display: flex;

  ${(props: ThemeProps) => props.theme.breakpoints.down('sm')} {
    display: block;
  }
`;

export const HelperTextWrapper = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
  line-height: 22px;
`;

export const InfoBox = styled(Alert)`
  background-color: #edf0f7;
`;
