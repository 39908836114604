import axios from 'axios';

export const searchUtil = ({
  country,
  endpoint,
  q,
  fromDate,
  toDate,
  masterCategory,
  photoType,
}) => {
  let queryString = `country=${country.code}&status=pending`;
  queryString += q ? `&q=${q}` : '';
  queryString += fromDate ? `&fromDate=${fromDate}` : '';
  queryString += toDate ? `&toDate=${toDate}` : '';
  queryString += masterCategory ? `&masterCategoryId=${masterCategory}` : '';
  queryString += photoType ? `&photoType=${photoType}` : '';
  const headers = { 'Content-Type': 'application/json' };
  return axios(`${endpoint}?${queryString}`, { headers });
};
