import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import Box from '@material-ui/core/Box';
import styles from './styles';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(styles);

export default function Search({ onChange, type, value }) {
  const [q, setQ] = useState('');
  const classes = useStyles();
  const dispatch = useDispatch();
  let title, maxLength;

  if (type === 'requestId') {
    title = 'Request ID';
    maxLength = 8;
  } else if (type === 'vendorCode') {
    title = 'Vendor Code';
    maxLength = 99;
  }

  useEffect(() => {
    setQ(value ? value : '');
  }, [value]);

  const resetField = () => {
    if (type === 'requestId') {
      dispatch({ type: 'SET_FILTER_REQUEST_ID', requestId: null });
    } else if (type === 'vendorCode') {
      dispatch({ type: 'SET_FILTER_VENDOR_CODE', vendorCode: null });
    }

    setQ('');
  };

  return (
    <Box
      component="form"
      className={`${classes.commonRoot}`}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <TextField
        placeholder={title}
        className={classes.input}
        InputProps={{
          'aria-label': title,
          endAdornment: (
            <InputAdornment position="end">
              {q ? (
                <Box style={{ cursor: 'pointer' }} display="flex">
                  <CancelIcon onClick={() => resetField()} htmlColor="gray" />
                </Box>
              ) : (
                <SearchIcon htmlColor="gray" />
              )}
            </InputAdornment>
          ),
        }}
        inputProps={{
          maxLength: maxLength,
        }}
        onChange={(e) => onChange(e.target.value)}
        value={q}
        label={title}
        variant="filled"
      />
    </Box>
  );
}
