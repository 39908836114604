export const TYPE_OF_CHANGE = {
  CATEGORY_CHANGE: 'CategoryChange',
  DESCRIPTION_CHANGE: 'DescriptionChange',
  PRODUCT_CREATE: 'ProductCreate',
  TITLE_CHANGE: 'TitleChange',
  VARIANT_CHOICE_GROUP_ADD: 'VariantChoiceGroupAdd',
  VARIANT_CHOICE_GROUP_DELETE: 'VariantChoiceGroupDelete',
  VARIANT_CREATE: 'VariantCreate',
  VARIANT_TITLE_CHANGE: 'VariantTitleChange',
  VARIANT_UNITPRICE_CHANGE: 'VariantUnitPriceChange',
  VARIANT_CONTAINERPRICE_CHANGE: 'VariantContainerPriceChange',
  PREPACKED_CHANGE: 'PrepackedChange',
  PRODUCT_AVAILABILITY_CHANGE: 'ProductAvailabilityChange',
  VAT_CHANGE: 'VatChange',
  VARIANT_DELETE: 'VariantDelete',
  EMBEDDED_VARIANT_CREATE: 'EmbeddedVariantCreate',
  EMBEDDED_VARIANT_DELETE: 'EmbeddedVariantDelete',
  EMBEDDED_VARIANT_TITLE_CHANGE: 'EmbeddedVariantTitleChange',
  EMBEDDED_VARIANT_UNIT_PRICE_CHANGE: 'EmbeddedVariantUnitPriceChange',
  CHOICE_REMOTE_CODE_CHANGE: 'ChoiceRemoteCodeChange',
  EMBEDDED_CHOICES_ADD: 'EmbeddedChoicesAdd',
  EMBEDDED_VARIANT_CHOICE_GROUP_ADD: 'EmbeddedVariantChoiceGroupAdd',
  MENU_AVAILABLE_WEKKDAYS_CHANGE: 'MenuAvailableWeekdaysChange',
  MENU_CHANGE: 'MenuChange',
  MENU_CREATE: 'MenuCreate',
  MENU_DELETE: 'MenuDelete',
  MENU_DESCRIPTION_CHANGE: 'MenuDescriptionChange',
  MENU_TITLE_CHANGE: 'MenuTitleChange',
  PRODUCT_DM_CREATE: 'ProductDMCreate',
  VARIANT_REMOTE_CODE_CHANGE: 'VariantRemoteCodeChange',
  VENDOR_MENU_SYNC: 'VendorMenuSync',
  EMBEDDED_CHOICE_GROUP_DELETE: 'EmbeddedChoiceGroupDelete',
  EMBEDDED_VARIANT_CHOICE_GROUP_DELETE: 'EmbeddedVariantChoiceGroupDelete',
  PRODUCT_DELETE: 'ProductDelete',
  PRODUCT_SORT: 'ProductSort',
  CHOICES_SORT: 'ChoicesSort',
  CATEGORY_CREATE: 'CategoryCreate',
  CATEGORY_DELETE: 'CategoryDelete',
  CATEGORY_DESCRIPTION_CHANGE: 'CategoryDescriptionChange',
  CATEGORY_TITLE_CHANGE: 'CategoryTitleChange',
  MASTER_CATEGORY_CHANGE: 'MasterCategoryChange',
};

export const TYPE_OF_CHANGE_PHOTOS = {
  DISH_PHOTO_CHANGE: 'DishPhotoChange',
  HERO_PHOTO_CHANGE: 'HeroPhotoChange',
  LISTING_PHOTO_CHANGE: 'ListingPhotoChange',
  EMBEDDED_PHOTO_UPDATE: 'EmbeddedPhotoUpdate',
};

export const TYPE_OF_CHANGE_UNUSED = {
  CATEGORY_CREATE: 'CategoryCreate',
};

export const TYPE_OF_CHANGE_CHOICE_GROUP = {
  CHOICE_GROUP_CREATE: 'ChoiceGroupCreate',
  CHOICE_GROUP_QUANTITY_CHANGE: 'ChoiceGroupQuantityChange',
  CHOICE_GROUP_TITLE_CHANGE: 'ChoiceGroupTitleChange',
  CHOICES_ADD: 'ChoicesAdd',
  CHOICES_DELETE: 'ChoicesDelete',
  CHOICES_CREATE: 'ChoicesCreate',
  CHOICES_PRICE_CHANGE: 'ChoicesPriceChange',
  CHOICE_TITLE_CHANGE: 'ChoiceTitleChange',
  CHOICE_AVAILABILITY_CHANGE: 'ChoiceAvailabilityChange',
  EMBEDDED_CHOICE_GROUP_CREATE: 'EmbeddedChoiceGroupCreate',
  EMBEDDED_CHOICE_GROUP_TITLE_CHANGE: 'EmbeddedChoiceGroupTitleChange',
  EMBEDDED_CHOICE_GROUP_QUANTITY_CHANGE: 'EmbeddedChoiceGroupQuantityChange',
  EMBEDDED_CHOICES_CREATE: 'EmbeddedChoicesCreate',
  EMBEDDED_CHOICE_TITLE_CHANGE: 'EmbeddedChoiceTitleChange',
  EMBEDDED_CHOICES_PRICE_CHANGE: 'EmbeddedChoicesPriceChange',
  EMBEDDED_CHOICES_DELETE: 'EmbeddedChoicesDelete',
  EMBEDDED_CHOICE_GROUP_DELETE: 'EmbeddedVariantChoiceGroupDelete',
  CHOICE_GROUP_DELETE: 'ChoiceGroupDelete',
};

export const TYPE_OF_CHANGE_CATEGORY = {
  CATEGORY_CREATE: 'CategoryCreate', // TODO: clean up TYPE_OF_CHANGE_UNUSED.CATEGORY_CREATE
  CATEGORY_DELETE: 'CategoryDelete',
  CATEGORY_DESCRIPTION_CHANGE: 'CategoryDescriptionChange',
  CATEGORY_TITLE_CHANGE: 'CategoryTitleChange',
  CATEGORY_AVAILABILITY_CHANGE: 'CategoryAvailabilityChange',
  MASTER_CATEGORY_CHANGE: 'MasterCategoryChange',
};
