import React from 'react';
import { AssignmentTurnedIn } from '@material-ui/icons';
import { Typography } from '@material-ui/core';

const TicketResolvedScreen = () => {
  return (
    <div
      data-enzyme={'CompleteScreen'}
      style={{ minHeight: '100%', display: 'flex', justifyContent: 'center' }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ textAlign: 'center' }}>
          <AssignmentTurnedIn />
          <Typography variant={'h4'}>Ticket resolved</Typography>
          <Typography variant={'subtitle2'}>Please close this window.</Typography>
        </div>
      </div>
    </div>
  );
};

export default TicketResolvedScreen;
