import styled from 'styled-components';
import { DateTimePicker as DateTimePickerBase } from '@material-ui/pickers';
import { Box } from '@material-ui/core';

export const DateTimePicker = styled(DateTimePickerBase)`
  && {
    width: 100%;
  }
`;

export const EndDateTimePicker = styled(DateTimePicker)`
  && {
  }
`;

export const DateRangeWrapperBox = styled(Box)`
  display: flex;
  flex-flow: row nowrap;

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-flow: column;
  }
`;

export const ToWrapperBox = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;
