import { getCurrentCountry } from '../../../App/utility';
import Cookies from 'js-cookie';
import { getMutationRequestDefaultData } from '../../../utility/information';

/**
 * @typedef GraphQLArgs
 * @property {'APPROVE' || 'REJECT'} approveOrReject
 * @property {{permissions: {"ops-portal-fuze": Object}, sub: String}} currentUser
 * @property {Object} additionalFields
 * @property {Object} patchData
 * @param {GraphQLArgs} arguments
 */
export const getChangeRequestGraphQLInput = ({
  approveOrReject,
  currentUser,
  additionalFields,
  patchData,
}) => {
  const input = [];
  additionalFields = additionalFields ?? {};
  const currentCountry = getCurrentCountry(Cookies.get('country'));

  Object.keys(patchData).forEach((requestId) => {
    const request = patchData[requestId];
    const { updated, version, ...requestPatchData } = request;

    const defaultInput = {
      type: approveOrReject,
      ...getMutationRequestDefaultData(request, currentUser, currentCountry),
      ...(request?.item?.typeOfChange?.includes('Photo')
        ? { typeOfChange: request.item.typeOfChange }
        : {}),
      ...additionalFields,
    };
    if (updated) {
      input.push({
        ...defaultInput,
        patchData: requestPatchData,
      });
    } else {
      input.push(defaultInput);
    }
  });

  return input;
};
