import React from 'react';
import { Box } from '@material-ui/core';
import { HelperTextWrapper, SectionWrapper, StyledInput, InfoBox } from '../styles';
import BarcodeSearch from './BarcodeSearch';
import { hasFieldError } from '../utils';
import { ProductDetailsSectionProps } from '../types';

const ProductDetailsSection: React.FC<ProductDetailsSectionProps> = ({
  t,
  handleSearchBarcode,
  isLoadingBarcode,
  gtin,
  errors,
  isGtinFound,
  isGtinFetched,
  isEANRequired = false,
}) => {
  const hasErrorGtin = React.useMemo(() => {
    return hasFieldError('gtin', errors);
  }, [errors]);
  const hasGtinNotFoundError = isEANRequired ? isGtinFetched && !isGtinFound : false;
  const placeholder = isEANRequired
    ? `${t('menu_management_pandora.product_details_barcode')}*`
    : `${t('menu_management_pandora.product_details_barcode')}`;
  const showInformationBanner = () => {
    // if the field is left empty
    if (hasErrorGtin && isEANRequired)
      return (
        <InfoBox severity="error">
          {t('menu_management_pandora.nutrition_information_gtin_required')}
        </InfoBox>
      );
    if (!isGtinFetched) return null;
    if (!isGtinFound)
      return isEANRequired ? (
        <InfoBox severity="error">
          {t('menu_management_pandora.nutrition_information_gtin_required')}
        </InfoBox>
      ) : (
        <InfoBox severity="error">
          {t('menu_management_pandora.nutrition_information_not_found_gtin_message')}
        </InfoBox>
      );

    return (
      <InfoBox severity="info">
        {t('menu_management_pandora.nutrition_information_found_gtin_message')}
      </InfoBox>
    );
  };
  return (
    <SectionWrapper title={t('menu_management_pandora.product_details')}>
      <Box mb={6}>
        <HelperTextWrapper>
          {t('menu_management_pandora.product_details_card_subtitle')}
        </HelperTextWrapper>
      </Box>
      <BarcodeSearch
        t={t}
        handleSearchBarcode={handleSearchBarcode}
        isLoadingBarcode={isLoadingBarcode}
      />
      <StyledInput
        id={'product_details_barcode'}
        label={gtin || placeholder}
        margin="normal"
        disabled
        error={hasErrorGtin || hasGtinNotFoundError}
      />
      {showInformationBanner()}
    </SectionWrapper>
  );
};

export default ProductDetailsSection;
