import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Warnings from '../NutritionInformationSection/Warnings';
import { BottomLabelInput, SectionWrapper, StyledInput } from '../styles';
import { hasFieldError } from '../utils';
import { PrepackedNutritionInformationSectionProps } from '../types';

const NutritionInformationSection: React.FC<PrepackedNutritionInformationSectionProps> = ({
  t,
  warnings,
  initialWarnings,
  handleWarningsChange,
  errors,
  handleOnChangeIsLiquid,
  isLiquid,
  handleOnChange,
  ingredients,
  isGermanVendor,
}) => {
  const hasErrorIngredients = React.useMemo(() => hasFieldError('ingredients', errors), [errors]);
  return (
    <SectionWrapper title={t('menu_management_pandora.item_navigation_nutrition_information')}>
      <BottomLabelInput
        style={{ width: '100%', marginBottom: 16 }}
        bottomLabel={t('menu_management_pandora.nutrition_information_allergens_subtext')}
      >
        <StyledInput
          name="ingredients"
          id="nutrition_information_ingredients"
          margin="normal"
          placeholder="Ingredients"
          value={ingredients}
          onChange={handleOnChange}
          error={hasErrorIngredients}
        />
      </BottomLabelInput>
      <Warnings
        t={t}
        data-testid="nutrition_information_prepacked_warnings"
        handleOnChange={handleWarningsChange}
        options={initialWarnings}
        value={warnings}
        errors={errors}
        isGermanVendor={isGermanVendor}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isLiquid}
            onChange={handleOnChangeIsLiquid}
            name="isLiquid"
            data-testid="nutrition_information_prepacked_liquid"
          />
        }
        label={t('menu_management_pandora.nutrition_information_card_isLiquid')}
      />
    </SectionWrapper>
  );
};

export default NutritionInformationSection;
