import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  centeredContainer: {
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
