export const styles = (theme) => ({
  container: {
    display: 'flex',
    background: 'white',
    padding: '24px',
    flexDirection: 'column',
  },
  categoryDetailText: {
    fontSize: '22px',
    fontWeight: '500',
    color: 'black',
    display: 'flex',
    width: '100%',
  },
  textField: {
    width: '-webkit-fill-available',
  }
});