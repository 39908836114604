export const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column' as const,
    // marginTop: -66,
    background: 'transparent',
    fontFamily: 'Roboto',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    flexShrink: 0,
  },
  logoCircleSmallWrapper: {
    width: '80px',
    height: '80px',
    borderRadius: '40px',
    overflow: 'hidden',
  },
  logoCircleBigWrapper: {
    width: '50px',
    height: '50px',
    borderRadius: '25px',
    overflow: 'hidden',
  },
  logoLink: {
    fontSize: 0,
  },
  logoImage: {
    cursor: 'pointer',
  },
  logoDivider: {
    marginBottom: theme.spacing(2),
  },
  profile: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: '100px',
    height: '100px',
  },
  nameText: {
    marginTop: theme.spacing(2),
  },
  bioText: {},
  profileDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  listSubheader: {
    color: theme.palette.text.secondary,
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      borderRadius: '0px',
      '& $listItemIcon': {
        color: theme.palette.primary.main,
        marginLeft: '-4px',
      },
    },
    '& + &': {
      marginTop: theme.spacing(),
    },
    width: 'auto',
    paddingLeft: 5,
    paddingRight: 5,
  },
  ListItem: {
    width: 'auto',
    borderBottom: '4px solid transparent',
    '&.active': {
      borderBottom: '4px solid rgb(78, 174, 217)',
      color: theme.palette.common.white,
      fontWeight: 'bold',
    },
  },
  listDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  innerscroll: {
    flexGrow: 1,
    minHeight: '100vh',
    overflow: 'auto',
  },

  bottomTabButton: {
    background: '#FFF',
    border: '1px solid #EDEDED',
    borderRadius: '6px',
    width: 'unset',
    minWidth: '180px',
    margin: '0 16px 0 0',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.05)',
  },
  bottomTabButtonActive: {
    border: '1px solid #4EAED9',
    '& div span': {
      color: '#4EAED9',
    },
  },

  bottomTabButtonText: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#69696A',
    fontWeight: 'bold' as 'bold',
    textAlign: 'center' as 'center',
  },
});

export const homestyles = (theme) => ({
  root: {
    display: 'flex' as const,
    background: '#f4f4f4',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column' as const,
    minHeight: '100vh',
    overflow: 'auto',
    paddingBottom: 80,
  },
  list: {
    width: 250,
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative' as const,
    whiteSpace: 'nowrap' as const,
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden' as const,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(9),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  image: {
    textAlign: 'center' as const,
    overflowX: 'auto' as const,
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  appBar: {
    position: 'relative' as const,
    background: 'rgb(58, 58, 69)',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    // position: "sticky",
    // top: 0,
    // boxShadow: "none",
  },
  appBarRoot: {
    position: 'relative' as const,
    minHeight: 65,
    color: 'white',
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row' as 'row',
    backgroundColor: 'inherit',
  },
  appBarLeft: {
    flexDirection: 'row' as const,
    alignItems: 'center',
    display: 'flex',
  },
  appBarRight: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 40,
  },
  logoCircleBigWrapper: {
    width: '32px',
    height: '32px',
    borderRadius: '25px',
    overflow: 'hidden',
  },
  actionBarContainer: {
    position: 'fixed' as const,
    width: '100%',
    bottom: '0px',
    zIndex: 999,
  },
  actionBar: {
    background: 'white',
    borderRadius: '5px',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.08),0px 6px 6px 0px rgba(0, 0, 0, 0.16)',
  },
  userIcon: {
    height: 24,
    width: 24,
    fontSize: 24,
  },
  dateTimeRangeWrapper: {
    width: 450,
    bottom: 10,
    display: 'flex' as const,
    float: 'right' as const,
    borderRadius: 4,
  },
  dateTimeRangePicker: {
    color: 'rgb(255, 255, 255)',
  },
  assignedToMe: {
    display: 'flex' as const,
    bottom: 10,
    float: 'right' as const,
    color: '#fff',
  },
  filters: {
    position: 'absolute' as const,
    right: 0,
    /* left: '200px', */
    bottom: '7px',
    padding: '0 10px 0 0',
  },
});

export const drawerFilters = (theme) => ({
  root: {
    display: 'flex' as const,
    background: '#f4f4f4',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column' as const,
    minHeight: '100vh',
    overflow: 'auto',
    paddingBottom: 80,
  },
  list: {
    width: 356,
  },

  root2: {
    flexGrow: 1,
  },
  container: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },

  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative' as const,
    whiteSpace: 'nowrap' as const,
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden' as const,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(9),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  image: {
    textAlign: 'center' as const,
    overflowX: 'auto' as const,
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  appBar: {
    position: 'relative' as const,
    background: 'rgb(58, 58, 69)',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    // position: "sticky",
    // top: 0,
    // boxShadow: "none",
  },
  appBarRoot: {
    position: 'relative' as const,
    minHeight: 65,
    color: 'white',
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row' as 'row',
    backgroundColor: 'inherit',
  },
  appBarLeft: {
    flexDirection: 'row' as const,
    alignItems: 'center',
    display: 'flex',
  },
  appBarRight: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 40,
  },
  logoCircleBigWrapper: {
    width: '32px',
    height: '32px',
    borderRadius: '25px',
    overflow: 'hidden',
  },
  actionBarContainer: {
    position: 'fixed' as const,
    width: '100%',
    bottom: '0px',
    zIndex: 999,
  },
  actionBar: {
    background: 'white',
    borderRadius: '5px',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.08),0px 6px 6px 0px rgba(0, 0, 0, 0.16)',
  },
  userIcon: {
    height: 24,
    width: 24,
    fontSize: 24,
  },
  dateTimeRangeWrapper: {
    width: 450,
    bottom: 10,
    display: 'flex' as const,
    float: 'right' as const,
    borderRadius: 4,
  },

  assignedToMe: {
    display: 'flex' as const,
    bottom: 10,
    float: 'right' as const,
    color: '#fff',
  },
  filters: {
    position: 'absolute' as const,
    right: 0,
    /* left: '200px', */
    bottom: '7px',
    padding: '0 10px 0 0',
  },

  chip: {
    backgroundColor: '#fff',
    marginBottom: '16px',
    marginRight: '12px',
    fontWeight: 'bold' as const,

    '& .MuiChip-deleteIcon': {
      color: '#777',
    },
  },

  filtersTitle: {
    color: '#12161B',
    fontSize: '28px',
    margin: '52px 0 5px 0',
  },

  filterDescription: {
    color: '#A4A5A7',
    fontSize: '14px',
    margin: '0',
    fontWeight: 'normal' as 'normal',
  },

  resetFilter: {
    position: 'absolute' as const,
    top: '25px',
    left: '22px',
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold' as 'bold',
    textDecoration: 'underline',
    textTransform: 'unset' as const,
  },

  applyButton: {
    position: 'absolute' as const,
    top: '22px',
    right: '22px',
    width: '160px',
  },

  filterButton: {
    backgroundColor: '#fff',
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    boxShadow: 'none',
    color: '#12161B',
    padding: '6px 21px',
    position: 'relative' as const,

    '& svg': {
      width: '18px',
      height: '18px',
      margin: '0 12px 0 0',
    },
    '& .filterCount': {
      display: 'none',
      position: 'absolute' as const,
      right: '-6px',
      top: '-6px',
      background: '#000',
      border: '2px solid #FFF',
      width: '17px',
      height: '17px',
      borderRadius: '50%',
      color: '#fff',
      fontWeight: 'bold' as 'bold',
      fontSize: '12px',
      textAlign: 'center' as 'center',
      lineHeight: '15px',
    },

    '&.active': {
      borderColor: '#000',
    },

    '&.active .filterCount': {
      display: 'block',
    },
  },
  closeDrawer: {
    float: 'right' as const,
    padding: '10px',
    margin: '10px 0 0 0',
    cursor: 'pointer',
  },
});
export default styles;
