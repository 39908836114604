import React, { useEffect, useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@apollo/client';
import { getFromBrandProxy } from '../utils';
import { getCurrentCountry } from '../../../App/utility';
import _isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import _get from 'lodash/get';

// components
import { Box, Container, makeStyles } from '@material-ui/core';
import LoaderWrapper from '../../../App/components/LoaderWrapper/index';
import AppRightBar from '../../../App/layouts/AppRightBar';
import HighLightBox from '../../components/HighlightBox';
import HighlightBoxHeader from '../../components/HighlightBoxHeader';
import styles from '../Home/styles';
import CategoryDetailForm from './CategoryDetailForm';
import RooterApproveAndRejectButton from '../../components/RooterApproveAndRejectButton';
import { TicketResolvedScreen } from '../../../components/TicketResolvedScreen';

// helpers
import { renderChangeDetails } from './CategoryHelper';
import {
  ITEM_CHANGES_QUERY,
  TALABAT_PROCESS_CHANGE_REQUESTS_QUERY,
  PROCESS_CHANGE_REQUESTS_QUERY,
} from '../Home/helpers';
import { getOldPatchData } from '../reviewHelpers';
import { defaultErrorMessage } from '../../utility/information';
import { TYPE_OF_CHANGE_CATEGORY } from '../../../App/common/constant';
import { REJECTION_REASON_TYPE } from '../../common/constants/rejectReason';
import { AppContext, UserContext } from '../../../App';
import {
  initializeFormValue,
  getUpdatedTitleOrDescriptionState,
  getPatchDataAccumulatorFromFormStates,
} from '../../utility/formStateHelpers';
import { getChangeRequestGraphQLInput } from '../ProductReview/helpers';
import LoadingState from '../../../components/LoadingState';
import ErrorScreen from 'components/ErrorScreen';
import StatusInfoBox from '../../../components/StatusInfoBox';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

const useStyles = makeStyles(styles);

const CategoryReview = ({ match }) => {
  const location = useLocation();
  const { getHistoricalData } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const showHistoricalData = getHistoricalData === true;
  const { countryCode, vendorCode, item_id: categoryId } = match.params;
  const isCategoryCreationRequest = categoryId.startsWith('NEW');
  const { enqueueSnackbar } = useSnackbar();
  const { currentCountry, setCurrentCountry } = React.useContext(AppContext);
  const currentUser = React.useContext(UserContext);
  const [countryData, setCountryData] = useState(getCurrentCountry(countryCode));
  const [isPermission, setIsPermission] = useState(
    currentUser?.permissions?.['ops-portal-fuze'][countryData.geid]?.length > 0
  );
  const classes = useStyles();

  const [category, setCategory] = useState({});
  const [requests, setRequests] = useState([]);
  const [hasRequestResponse, setHasRequestResponse] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isLoadingCategory, setIsLoadingCategory] = useState(false);

  // form states
  const [titles, setTitles] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [masterCategoryId, setMasterCategoryId] = useState({});
  const [availablity, setAvailability] = useState({});
  const fwfFlags = useSelector((state) => state.fwfState.flags);

  //Set current country based on URL
  useEffect(() => {
    setCountryData(getCurrentCountry(countryCode));
    setIsPermission(currentUser?.permissions?.['ops-portal-fuze'][countryData.geid]?.length > 0);

    if (!isPermission) {
      window.location.href = '/access-denied';
      return;
    }

    Cookies.set('country', countryData.code, { sameSite: 'None', secure: true });
    setCurrentCountry(countryData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  useEffect(() => {
    // init all categories
    if (!isCategoryCreationRequest && isPermission) {
      setIsLoadingCategory(true);
      getFromBrandProxy('categories', vendorCode, currentCountry.geid)
        .then((data) => {
          if (!!data?.data) {
            const categories = data.data;
            const category = categories.find((category) => category.id === categoryId);
            setCategory(category ?? {});
          }
        })
        .finally(() => {
          setIsLoadingCategory(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: categoryRequests,
    loading,
    error,
  } = useQuery(ITEM_CHANGES_QUERY, {
    variables: {
      itemID: categoryId,
      statusList: ['APPROVE', 'REJECT', 'PENDING', 'INVALID'],
      ...(showHistoricalData
        ? {
            latestCaseIDOnly: true,
            statusList: ['APPROVE', 'REJECT'],
          }
        : {}),
    },
    displayName: 'CategoryItemQuery',
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    skip: !isPermission,
  });

  useEffect(() => {
    if (!error && !loading) {
      let request = categoryRequests?.getItemByItemID?.qcRequests || [];

      request = request.filter((r) => r.item.patchData !== null);

      setHasRequestResponse(request.length > 0);

      let pendingRequests = [];
      if (!showHistoricalData) {
        let newRequest = [
          {
            item: {
              timestamp: 0,
            },
          },
        ];

        request.forEach((req) => {
          if (req.item.timestamp > newRequest[0].item.timestamp) {
            newRequest = [req];
          }
        });

        const validRequest = newRequest[0].item.status?.toLowerCase() === 'pending';

        if (validRequest) {
          request.forEach((request) => {
            if (request.item.status.toLowerCase() === 'pending') {
              pendingRequests.push(request);
            }
          });
        }
      } else {
        pendingRequests = request;
      }

      setRequests(pendingRequests);
    }
  }, [categoryRequests, error, loading, showHistoricalData]);

  let talabatChangeRequestsQuery = fwfFlags.fwfTBCheckIfThereIsMore.variation
    ? TALABAT_PROCESS_CHANGE_REQUESTS_QUERY
    : PROCESS_CHANGE_REQUESTS_QUERY;

  const [processChangeRequests] = useMutation(talabatChangeRequestsQuery, {
    onError: () => {},
  });

  const processChangeRequestsThen = (data, message) => {
    if (data?.data?.completeQCRequests) {
      enqueueSnackbar(message, {
        variant: 'success',
      });
      setIsComplete(true);
    } else if (data?.errors?.message) {
      enqueueSnackbar(data?.errors?.message, { variant: 'error' });
    } else {
      enqueueSnackbar(defaultErrorMessage, { variant: 'error' });
    }
  };

  // populate form field based on change requests
  const initFormFieldsFromRequests = useCallback(() => {
    requests.forEach((changeRequest) => {
      const requestItem = changeRequest.item;
      const requestChangeType = requestItem.typeOfChange;
      const patchData = requestItem.patchData;

      switch (requestChangeType) {
        case TYPE_OF_CHANGE_CATEGORY.CATEGORY_CREATE:
          setTitles([initializeFormValue(patchData?.title, {}, changeRequest)]);
          setDescriptions([initializeFormValue(patchData?.description, {}, changeRequest)]);
          setMasterCategoryId(
            initializeFormValue(patchData?.master_category_id, {}, changeRequest)
          );
          break;
        case TYPE_OF_CHANGE_CATEGORY.CATEGORY_DELETE:
          // TODO
          break;
        case TYPE_OF_CHANGE_CATEGORY.CATEGORY_DESCRIPTION_CHANGE:
          setDescriptions((prevValue) => {
            return [...prevValue, initializeFormValue(patchData?.description, {}, changeRequest)];
          });
          break;
        case TYPE_OF_CHANGE_CATEGORY.CATEGORY_TITLE_CHANGE:
          setTitles((prevValue) => {
            return [...prevValue, initializeFormValue(patchData?.title, {}, changeRequest)];
          });
          break;
        case TYPE_OF_CHANGE_CATEGORY.CATEGORY_AVAILABILITY_CHANGE:
          setAvailability(initializeFormValue(patchData?.available, null, changeRequest));
          break;
        case TYPE_OF_CHANGE_CATEGORY.MASTER_CATEGORY_CHANGE:
          setMasterCategoryId(
            initializeFormValue(patchData?.master_category_id, {}, changeRequest)
          );
          break;
        default:
          break;
      }
    });
  }, [requests]);
  useEffect(() => {
    initFormFieldsFromRequests();
  }, [initFormFieldsFromRequests]);

  // populate form field based on category data (existing data)
  const initFormFieldsFromCategoryData = useCallback(() => {
    setTitles((prevValue) => {
      if (!_isEmpty(category?.title)) {
        return [...prevValue, initializeFormValue(category.title, {})];
      }
      return prevValue;
    });
  }, [category]);
  useEffect(() => {
    initFormFieldsFromCategoryData();
  }, [initFormFieldsFromCategoryData]);

  // form handlers
  const handleTitleChange = (e, language) => {
    let value = e.target.value;
    setTitles((prevState) => {
      return getUpdatedTitleOrDescriptionState(prevState, value, language);
    });
  };
  const hasRelatedRequest = (formState) => {
    if (Array.isArray(formState)) {
      return formState.some((state) => !!state?.relatedRequest);
    } else {
      return !!formState?.relatedRequest;
    }
  };
  const getPatchData = () => {
    const formData = {
      title: titles,
      description: descriptions,
      available: availablity,
      master_category_id: masterCategoryId,
    };
    return getPatchDataAccumulatorFromFormStates(formData);
  };
  const handleApprove = () => {
    setIsProcessing(true);
    const patchData = getPatchData();

    let queryInputData = getChangeRequestGraphQLInput({
      approveOrReject: 'APPROVE',
      currentUser,
      patchData: patchData,
    });

    queryInputData = getOldPatchData(requests, queryInputData, patchData);

    let changeRequestPayload;
    if (fwfFlags.fwfTBCheckIfThereIsMore.variation) {
      changeRequestPayload = {
        input: queryInputData,
        itemID: categoryId,
        itemType: 'category',
      };
    } else {
      changeRequestPayload = {
        input: queryInputData,
      };
    }
    processChangeRequests({
      variables: {
        changeRequestPayload,
      },
    })
      .then((data) => {
        processChangeRequestsThen(data, 'Successfully saved request');
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };
  const handleReject = (rejectReasons) => {
    setIsProcessing(true);
    const input = getChangeRequestGraphQLInput({
      approveOrReject: 'REJECT',
      currentUser,
      additionalFields: { rejectionReasons: rejectReasons },
      patchData: getPatchData(),
    });

    let changeRequestPayload;
    if (fwfFlags.fwfTBCheckIfThereIsMore.variation) {
      changeRequestPayload = {
        input: input,
        itemID: categoryId,
        itemType: 'category',
      };
    } else {
      changeRequestPayload = {
        input: input,
      };
    }

    processChangeRequests({
      variables: {
        changeRequestPayload,
      },
    })
      .then((data) => {
        processChangeRequestsThen(data, 'Requests successfully rejected');
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const isLoading = loading || isLoadingCategory;

  if (isLoading) {
    return <LoadingState />;
  }

  if (!isLoading && (!hasRequestResponse || error)) {
    return (
      <ErrorScreen errors={[]} testId={'CategoryReviewError'} customText={'Not found changes'} />
    );
  }

  const status = _get(requests[0], 'item.status')?.toLowerCase();

  if (!showHistoricalData && (isComplete || (requests.length === 0 && hasRequestResponse))) {
    return <TicketResolvedScreen />;
  }

  return (
    <Container maxWidth="xl" className={classes.innerscroll}>
      <LoaderWrapper isLoading={loading}>
        <Box marginY={3} width="100%">
          {showHistoricalData && <StatusInfoBox changeRequests={requests}></StatusInfoBox>}
          <HighLightBox type={status}>
            <HighlightBoxHeader status={status} />
            {requests.map((request) => renderChangeDetails(request))}
          </HighLightBox>
        </Box>
        <CategoryDetailForm
          countryCode={countryCode}
          titles={titles.map((title) => {
            return title.value;
          })}
          handleTitleChange={handleTitleChange}
          isTitleEditable={hasRelatedRequest(titles)}
          readonly={showHistoricalData}
        />
      </LoaderWrapper>
      {!showHistoricalData && (
        <AppRightBar modalRoot={document.getElementById('appbar')}>
          <RooterApproveAndRejectButton
            handleApprove={handleApprove}
            handleReject={handleReject}
            buttonsDisabled={isProcessing}
            rejectionReasonType={REJECTION_REASON_TYPE.CATEGORY}
          />
        </AppRightBar>
      )}
    </Container>
  );
};

export default CategoryReview;
