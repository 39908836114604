import React from 'react';
import { FirstRow, InlineInput, SectionWrapper, StyledInput } from '../styles';
import { otherInformationFields } from './constants';
import { hasFieldError } from '../utils';
import { OtherInformationProps } from '../types';

const OtherInformationSection: React.FC<OtherInformationProps> = (props) => {
  const { t, handleOnChange, isLiquid, netWeight, sizeInLitres, isFinnishVendor } = props;
  const translationKeyWithPrefix = (key) => `menu_management_pandora.${key}`;
  let fields = otherInformationFields;

  if (isFinnishVendor) {
    fields = [
      ...fields,
      {
        id: 'other_information_nutritional_claims',
        name: 'nutritionalClaims',
      },
    ];
  }

  const physicalMetricProps = isLiquid
    ? {
        id: 'other_information_size_in_litres',
        label: t(translationKeyWithPrefix('other_information_size_in_litres')),
        value: sizeInLitres,
        name: 'sizeInLitres',
      }
    : {
        id: 'other_information_net_weight',
        label: t(translationKeyWithPrefix('other_information_net_weight')),
        value: netWeight,
        name: 'netWeight',
      };

  if (isLiquid) {
    fields = [
      ...fields,
      {
        id: 'other_information_bottle_deposit',
        name: 'bottleDeposit',
      },
    ];
  }

  const hasError = (field: string): boolean => hasFieldError(field, props.errors);

  return (
    <SectionWrapper title={t('menu_management_pandora.other_information')}>
      <FirstRow>
        <InlineInput
          style={{ marginRight: 16 }}
          id={'other_information_alcohol'}
          label={t(translationKeyWithPrefix('other_information_alcohol'))}
          margin="normal"
          value={props.alcohol}
          name="alcohol"
          onChange={handleOnChange}
          error={hasError('alcohol')}
        />
        <InlineInput
          error={hasError(physicalMetricProps.name)}
          margin="normal"
          onChange={handleOnChange}
          {...physicalMetricProps}
        />
      </FirstRow>
      {fields.map(({ id, name }) => (
        <StyledInput
          key={id}
          id={id}
          label={t(translationKeyWithPrefix(id))}
          margin="normal"
          variant="standard"
          value={props[name]}
          name={name}
          onChange={handleOnChange}
          error={hasError(name)}
        />
      ))}
    </SectionWrapper>
  );
};

export default OtherInformationSection;
