import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export const BasicTextfield = styled(TextField)`
  /* Fix label overlap issue */
  .MuiFormLabel-root {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    right: 22px;
    bottom: 0px;

    &.MuiInputLabel-shrink {
      right: initial;
    }
  }

  /* left to right as default */
  ${({ direction }) => (direction ? `direction: ${direction}` : 'ltr')}
`;

export const AutocompleteWrapper = styled(Autocomplete)`
  .MuiAutocomplete-endAdornment {
    top: auto;
  }
`;
