// @ts-check
import { Client } from '@deliveryhero/opsportal';

/**
 * @typedef Args
 * @property {typeof window} [global]
 * @property {(...args: ConstructorParameters<typeof Client>) => Client} OpsPortalClientFactory
 * @property {string} [opsPortalURL]
 * @param {Args} arg0
 */
export function createOpsPortalClient({
  global = window,
  OpsPortalClientFactory,
  opsPortalURL = process.env.REACT_APP_OPS_PORTAL_URL || '',
}) {
  /** @type {Promise<import('@deliveryhero/opsportal').IOpsSdkAsync> | undefined} */
  let sdkPromise;

  function getSDK() {
    if (sdkPromise == null) {
      const opsClient = OpsPortalClientFactory(opsPortalURL);
      sdkPromise = opsClient.init();
    }

    return sdkPromise;
  }

  const client = {
    getAccessToken: async () => {
      const sdk = await getSDK();
      const accessToken = await sdk.getAccessToken();
      /* istanbul ignore if */
      if (accessToken === undefined) throw new Error('Unexpected error');
      return accessToken;
    },
    getRefreshedAccessToken: async () => {
      const sdk = await getSDK();
      const refreshedToken = await sdk.getRefreshedAccessToken();
      if (refreshedToken === undefined) throw new Error('Unexpected Error');
      return refreshedToken;
    },
    isRunningInOpsPortal: async () => {
      const { REACT_APP_ENV = '' } = process.env;
      if (REACT_APP_ENV.startsWith('test')) return false;
      return global.self !== global.top;
    },
    setPluginLoaded: async () => {
      await getSDK().then((sdk) => sdk.setPluginLoaded());
    },
    getCurrentCountry: async () => {
      const sdk = await getSDK();
      const currentCountry = await sdk.getCountry();
      // @ts-ignore
      return { ...currentCountry, geid: `${currentCountry.entity.code}_${currentCountry.code}` };
    },
    getCountries: async () => {
      const sdk = await getSDK();
      const countries = (await sdk.getCountries()) || [];
      return countries.map((country) => ({
        ...country,
        geid: `${country.entity.code}_${country.code}`,
      }));
    },
    getClient: async () => await getSDK(),
  };

  return client;
}

/* istanbul ignore next */
const defaultOpsPortalClient = createOpsPortalClient({
  OpsPortalClientFactory: (...args) => new Client(...args),
});

export default defaultOpsPortalClient;
