import styled from 'styled-components';

export const SectionWrapper = styled.div`
  background: white;
  @media (max-width: 500px) {
    border: 0;
  }
`;

export const SectionHeader = styled.div`
  padding: 24px 24px 0px;
  font-size: 22px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  justify-content: space-between;
  > span {
    flex: 1;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.54);
  }
  button {
    font-size: 16px;
    float: right;
    color: ${(props) => (props.isAddDisabled ? `rgba(0, 0, 0, 0.26)` : `rgb(30, 145, 214)`)};
    border-style: none;
    background: white;
    font-weight: 600;
    pointer-events: ${(props) => (props.isAddDisabled ? 'none' : 'auto')};
    text-transform: uppercase;
  }
  @media (max-width: 500px) {
    padding: 16px 16px 24px;
  }
`;

export const SectionContent = styled.div`
  padding: 21px 24px 32px;
  @media (max-width: 500px) {
    padding: 16px 16px 24px;
  }
`;

export const SectionHeaderV2 = styled(SectionHeader)`
  padding: 24px;

  button {
    font-weight: 500;
  }
`;
