import React from 'react';
import NonPrepackaged from './NonPrepackaged';
import Prepackaged from './Prepackaged';
import { NutritionInformationProps } from './types';

export const IsInChoiceItemContext = React.createContext(false);

const NutritionInformation: React.FC<NutritionInformationProps> = ({
  isPrepacked,
  t,
  allergens,
  warnings,
  additives,
  initialAllergens,
  initialWarnings,
  initialAdditives,
  handleAllergensChange,
  handleWarningsChange,
  handleAdditivesChange,
  producerName,
  producerAddress,
  producerCountry,
  handleOnChange,
  errors,
  energyOne,
  fat,
  carbohydrates,
  salt,
  energyTwo,
  saturates,
  sugars,
  protein,
  alcohol,
  netWeight,
  legalDescription,
  storageInstructions,
  instructionsForUse,
  nutritionalClaims,
  isLiquid,
  handleOnChangeIsLiquid,
  sizeInLitres,
  bottleDeposit,
  gtin,
  handleSearchBarcode,
  isLoadingBarcode,
  ingredients,
  isGtinFound,
  isGtinFetched,
  platformCompanyName,
  isGermanVendor,
  shouldShowAdditives,
  isFinnishVendor,
  isInChoiceItem,
  isEANRequired = false,
}) => (
  <IsInChoiceItemContext.Provider value={isInChoiceItem}>
    {isPrepacked ? (
      <Prepackaged
        t={t}
        producerName={producerName}
        producerAddress={producerAddress}
        producerCountry={producerCountry}
        handleOnChange={handleOnChange}
        energyOne={energyOne}
        fat={fat}
        carbohydrates={carbohydrates}
        salt={salt}
        energyTwo={energyTwo}
        saturates={saturates}
        sugars={sugars}
        protein={protein}
        alcohol={alcohol}
        netWeight={netWeight}
        legalDescription={legalDescription}
        storageInstructions={storageInstructions}
        instructionsForUse={instructionsForUse}
        nutritionalClaims={nutritionalClaims}
        warnings={warnings}
        initialWarnings={initialWarnings}
        handleWarningsChange={handleWarningsChange}
        errors={errors}
        isLiquid={isLiquid}
        handleOnChangeIsLiquid={handleOnChangeIsLiquid}
        sizeInLitres={sizeInLitres}
        bottleDeposit={bottleDeposit}
        gtin={gtin}
        handleSearchBarcode={handleSearchBarcode}
        isLoadingBarcode={isLoadingBarcode}
        ingredients={ingredients}
        isGtinFound={isGtinFound}
        isGtinFetched={isGtinFetched}
        isGermanVendor={isGermanVendor}
        isFinnishVendor={isFinnishVendor}
        isEANRequired={isEANRequired}
      />
    ) : (
      <NonPrepackaged
        t={t}
        allergens={allergens}
        warnings={warnings}
        additives={additives}
        initialAllergens={initialAllergens}
        initialWarnings={initialWarnings}
        initialAdditives={initialAdditives}
        handleAllergensChange={handleAllergensChange}
        handleWarningsChange={handleWarningsChange}
        handleAdditivesChange={handleAdditivesChange}
        errors={errors}
        platformCompanyName={platformCompanyName}
        isGermanVendor={isGermanVendor}
        shouldShowAdditives={shouldShowAdditives}
      />
    )}
  </IsInChoiceItemContext.Provider>
);

export default NutritionInformation;
