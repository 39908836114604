import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { filterStyles } from './style';

const useStyles = makeStyles(filterStyles);

export const Count = ({ count = 0 }) => <>&#40;{count}&#41;</>;

const CategoryFilter = ({ value, categories, country, onChange, open }) => {
  const classes = useStyles();
  const total = categories.find((category) => category.id === 'total') || null;
  return (
    <FormControl>
      <Select
        open={open}
        autoWidth
        className={classes.select}
        value={value}
        onChange={onChange}
        disableUnderline
        displayEmpty
        classes={{
          root: classes.root,
        }}
      >
        <MenuItem value={''}>All Categories {total && <Count count={total.count} />}</MenuItem>
        {categories
          .filter((category) => category.id !== 'total')
          .map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.title}&nbsp;
              <Count count={category.count} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default CategoryFilter;
