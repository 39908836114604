import React, { useEffect } from 'react';
import theme from '../theme';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { homestyles } from './styles';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import { Typography } from '@material-ui/core';
import { ReactComponent as FOODPANDALOGO } from '../assets/foodpanda-small.svg';
import { supportedCountryList } from '../common/regional';
import { getCurrentCountry } from '../utility';
/* import { useHistory } from 'react-router-dom'; */
import Cookies from 'js-cookie';
import CountryFilter from '../../App/components/CountryFilter';
import Box from '@material-ui/core/Box';

import AppBarMenu from '../components/AppBarMenu';
import useOpsPortal from '../../hooks/useOpsPortal';
import { AppContext, UserContext } from '../index';
import { TopNavbar } from './TopNavbar';
import usePlugins from '../../hooks/usePlugins';
import { useInitialMount } from './../hooks/useInitialMount';

const useStyles = makeStyles(homestyles);

/**
 * @param {object} props
 * @param {React.ReactNode[]} props.children
 */
function App({ children, appBar = [], setCustomContext, supportedCountries, fwfFlags }) {
  const IsinitialMount = useInitialMount();
  const { isInsideOpsPortal, isAPAC } = useOpsPortal();
  const currentUser = React.useContext(UserContext);
  const { currentCountry, setCurrentCountry } = React.useContext(AppContext);

  /*  const history = useHistory(); */

  /*   const { fwfPluginACL, fwfMasterACL, fwfCountryACL } = fwfFlags; */
  /*   const fwfLoaded =
    fwfPluginACL.variation &&
    fwfPluginACL.loaded &&
    fwfCountryACL.variation &&
    fwfCountryACL.loaded &&
    fwfMasterACL.variation &&
    fwfMasterACL.loaded; */
  const { parents } = usePlugins(currentCountry, currentUser, false, fwfFlags);
  const classes = useStyles();

  /*
   * !isInsideOpsPortal -> show
   *
   * isInsideOpsPortal && !isAPAC -> show
   *
   * isInsideOpsPortal && isAPAC && fwfOpsPortalCountries.variation -> show
   */

  const isKuwait = Object.keys(currentUser?.permissions?.['ops-portal-fuze'] ?? {}).includes(
    'TB_KW'
  );

  const hideAllActions = currentCountry.geid === 'TB_KW' && isKuwait;

  const shouldRender =
    !isInsideOpsPortal ||
    (isInsideOpsPortal && !isAPAC) ||
    (isInsideOpsPortal && isAPAC && !fwfFlags.fwfOpsPortalCountries.variation);

  useEffect(() => {
    if (!IsinitialMount) {
      /*       let rootPathSet = false;
      let canIstayInThisPath = false;
      let permitedPathsInNextCountry = [];
      parents.forEach((plugin) => {
        const optionalPath = rootPathSet ? '' : '/';
        const ok = plugin({ app, setPluginTitle, currentCountry, optionalPath, fwfFlags });
        rootPathSet = true;
        permitedPathsInNextCountry = [
          ...permitedPathsInNextCountry,
          ...ok.pages.map((it) => it.path),
        ];
      });
      /// http://localhost:3008/changes/KW/13614/choice-group/202177683 check it for this link
      //below is to be revised
      //If perms have kuweit then I will let any vendor to reach those routes available for kuweit
      console.log('permitedPathsInNextCountry', permitedPathsInNextCountry);
      if (permitedPathsInNextCountry.includes(window.location.pathname)) {
        canIstayInThisPath = true;
      }
      Cookies.set('country', nextCountryTobeSet.code, { sameSite: 'None', secure: true });
      setCurrentCountry(getCurrentCountry(nextCountryTobeSet.code));
      if (!canIstayInThisPath) {
        console.log('aa');
        if (permitedPathsInNextCountry.includes('/') && !isKuwait) {
          history.push('/');
        }
      } */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parents]);

  const handleFilterClick = (selectedCountryObject) => {
    selectedCountryObject = JSON.parse(selectedCountryObject);
    // setNextCountry(selectedCountryObject);
    Cookies.set('country', selectedCountryObject.code, { sameSite: 'None', secure: true });
    setCurrentCountry(getCurrentCountry(selectedCountryObject.code));
  };

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div
          className={classes.root}
          style={{ fontFamily: 'Roboto' }}
          data-enzyme={'DefaultLayout'}
        >
          <div className={classes.content}>
            <AppBar position="static" color="default" className={classes.appBar} elevation={0}>
              <div className={classes.appBarRoot}>
                <div className={classes.appBarLeft}>
                  <div style={{ width: 40 }}>
                    <div className={classes.logoCircleBigWrapper}>
                      <FOODPANDALOGO />
                    </div>
                  </div>
                  <Typography
                    variant="h5"
                    style={{ marginLeft: 10, color: 'white', fontWeight: 'bold' }}
                  >
                    Vendor Content QC
                  </Typography>
                  <Box ml={8}>
                    {shouldRender && !hideAllActions && (
                      <CountryFilter
                        countryList={supportedCountryList(supportedCountries)}
                        handleFilterClick={handleFilterClick}
                        currentCountry={currentCountry}
                      />
                    )}
                  </Box>
                </div>

                <AppBarMenu pages={appBar} fwfFlags={fwfFlags} hideAllActions={hideAllActions} />
              </div>

              {!hideAllActions && (
                <>
                  <TopNavbar />
                </>
              )}
            </AppBar>

            {children}

            <div className={classes.actionBarContainer} style={{ zIndex: 999 }}>
              <div id="appbar" className={classes.actionBar} />
            </div>
          </div>
        </div>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
