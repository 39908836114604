/**
 * This function takes namespaces array
 * @param {Array} namespaces Array containing country namespaces
 * @returns {Array} retuns country part from the namespaces
 */
export function getCountriesFromNamespaces(namespaces) {
  const countries = [];
  namespaces.forEach((namespace) => {
    const splitted = namespace.split('_');
    if (splitted.length === 2) {
      countries.push(splitted[1]);
    }
  });
  return countries;
}
