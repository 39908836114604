export const homestyles = (theme) => ({
  button: {
    margin: 0,
    marginRight: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  image: {
    textAlign: 'center',
    overflowX: 'auto',
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  loader: {
    textAlign: 'center',
    width: 'auto',
    height: '600px',
    marginTop: '150px',
  },
  countryFilterContainer: {
    textAlign: 'right',
    paddingTop: theme.spacing(5),
  },
  innerscroll: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  loadingDiv: {
    position: 'absolute',
    width: 'calc(100% - 45px)',
    top: '32px',
    height: 'calc(100vh - 240px)',
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#000',
    fontSize: '18px',
    borderRadius: '4px',
  },
});

export const tablestyles = (theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: 'collapse',
    '& tbody': {
      paddingRight: '20px solid transparent',
    },
    border: '1px solid rgb(224, 224, 224)',
    '& + &': {
      marginTop: '20px',
    },
  },
  tableRoot: {
    width: '100%',
    maxHeight: 'calc(100vh - 240px)',
    marginTop: theme.spacing(1),
    overflowX: 'auto',
    overflowY: 'auto',
  },
  vendorName: {
    minWidth: '100px',
  },
  vendorCode: {
    minWidth: '100px',
  },
  submitDate: {
    width: '20%',
  },
  files: {
    width: '170px',
  },
  agentEmail: {
    width: '320px',
    minWidth: '320px',
    maxWidth: '320px',
    wordBreak: 'break-all',
    paddingRight: '25px',
  },
  button: {
    margin: 0,
    marginRight: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  label: {
    fontWeight: 'bold',
    width: 'auto',
  },
  labelContainer: {
    flexBasis: '20%',
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
  textBold: {
    fontWeight: 'bold',
  },
  choiceAlignment: {
    paddingLeft: '65px',
  },
  choiceBlock: {
    display: 'inline-table',
    margin: '8px',
  },
  choicePriceChange: {
    display: 'inline-table',
    margin: '8px',
  },
  noBottomBorder: {
    borderTopColor: '#f9f9f9',
  },
});

export const changesStyles = (theme) => ({
  changeValuesNew: {
    background: theme.palette.changeValues.new,
  },
  changeValuesOld: { background: theme.palette.changeValues.old },
});

export default homestyles;
