import React from 'react';
import VSSPhotoQueue from './VSSPhotoQueue/VSSPhotoQueue';
import AgentPhotoQueue from './AgentPhotoQueue/AgentPhotoQueue';

export const ManagePhoto = ({
  setCustomContext,
  customContext,
  setTitle,
  currentCountry,
  fwfFlags,
  setRequestCount,
}) => {
  const SHOULD_USE_AGENT_QUEUES_PHOTOS = fwfFlags?.fwfAgentQueuePhoto?.variation || false;

  if (SHOULD_USE_AGENT_QUEUES_PHOTOS) {
    return (
      <AgentPhotoQueue
        fwfFlags={fwfFlags}
        currentCountry
        setRequestCount={setRequestCount}
        setCustomContext={setCustomContext}
        customContext={customContext}
      />
    );
  } else {
    return (
      <VSSPhotoQueue
        setCustomContext={setCustomContext}
        customContext={customContext}
        setTitle={setTitle}
        currentCountry={currentCountry}
        fwfFlags={fwfFlags}
      />
    );
  }
};

export default ManagePhoto;
