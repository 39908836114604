import { REGION_COUNTRY } from '../../../App/common/constant/countryList';
import { TALABAT_CATEGORY_REJECT_REASONS } from './rejectReason/talabat/categoryRejectReason';
import { TALABAT_CG_REJECT_REASONS } from './rejectReason/talabat/cgRejectReason';
import { TALABAT_PRODUCT_REJECT_REASONS } from './rejectReason/talabat/productRejectReason';
import _ from 'lodash';


const REJECT_REASON_IMAGE_NOT_VISIBLE = {
  key: 'rejection_reason_price_not_visible',
  text: 'The title, price, or description is not visible in the menu photo.',
};
const REJECT_REASON_IMAGE_WRONG = {
  key: 'menu_rejection_reason_wrong_photo',
  text: 'Wrong menu photo provided.',
};
const REJECT_REASON_IMAGE_INVALIDPRICE = {
  key: 'menu_rejection_reason_price_markup',
  text: 'Invalid price markup',
};
const REJECT_REASON_PROHIBITED_ITEM = {
  key: 'menu_rejection_prohibited_item',
  text: 'Prohibited item',
};


//Conditional Types
export const REJECT_REASON_NO_MENU_PHOTO_ATTACHED = {
  key:'menu_rejection_no_menu_photo_attached',
  text:'No Menu Photo Attached'
}

export const REJECT_REASON_NO_ATTESTED_MENU_ATTACHED = {
  key:'menu_rejection_no_attested_menu_attached',
  text:'No Attested Menu Attached'
}

export const REJECT_REASON_NO_MATCHING_PRICES = {
  key:'menu_rejection_no_matching_prices',
  text:'Not Matching Prices'
}

export const REJECT_REASON_EGYPT_NO_MATCHING_PRICES = {
  key:'menu_rejection_no_matching_prices_egypt',
  text:'Not Matching Prices'
}



export const DEFAULT_REJECT_REASONS = [
  REJECT_REASON_IMAGE_NOT_VISIBLE,
  REJECT_REASON_IMAGE_WRONG,
  REJECT_REASON_IMAGE_INVALIDPRICE,
  REJECT_REASON_PROHIBITED_ITEM,
];

export const REJECTION_REASON_TYPE = {
  PRODUCT: 'PRODUCT',
  CHOICE_GROUP: 'CHOICE_GROUP',
  CATEGORY: 'CATEGORY',
}

export const getMenuRejectReasons = (GEID = '', rejectReasonType = '') => {
  const [region, countryCode] = GEID.split('_');
  
  let TB_CG_REJECT_REASONS = TALABAT_CG_REJECT_REASONS;
  let TB_PRODUCT_REJECT_REASONS = TALABAT_PRODUCT_REJECT_REASONS;

  if(GEID === "HF_EG" || region === "TB"){
    TB_PRODUCT_REJECT_REASONS.push(REJECT_REASON_NO_MENU_PHOTO_ATTACHED);
    TB_PRODUCT_REJECT_REASONS.push(REJECT_REASON_NO_ATTESTED_MENU_ATTACHED);

    TB_CG_REJECT_REASONS.push(REJECT_REASON_NO_MENU_PHOTO_ATTACHED);
    TB_CG_REJECT_REASONS.push(REJECT_REASON_NO_ATTESTED_MENU_ATTACHED);

    if(GEID === "HF_EG"){
      TB_CG_REJECT_REASONS.push(REJECT_REASON_EGYPT_NO_MATCHING_PRICES)
      TB_PRODUCT_REJECT_REASONS.push(REJECT_REASON_EGYPT_NO_MATCHING_PRICES)
    }

    if(region === "TB"){
      TB_CG_REJECT_REASONS.push(REJECT_REASON_NO_MATCHING_PRICES)
      TB_PRODUCT_REJECT_REASONS.push(REJECT_REASON_NO_MATCHING_PRICES)
    }
  }

  TB_PRODUCT_REJECT_REASONS = _.uniqBy(TB_PRODUCT_REJECT_REASONS);
  TB_CG_REJECT_REASONS = _.uniqBy(TB_CG_REJECT_REASONS);


  switch (`${GEID}_${rejectReasonType}`) {
    case `${REGION_COUNTRY.ME[countryCode]?.geid}_${REJECTION_REASON_TYPE.PRODUCT}`:
      return TB_PRODUCT_REJECT_REASONS;
    case `${REGION_COUNTRY.ME[countryCode]?.geid}_${REJECTION_REASON_TYPE.CHOICE_GROUP}`:
      return TB_CG_REJECT_REASONS;
    case `${REGION_COUNTRY.ME[countryCode]?.geid}_${REJECTION_REASON_TYPE.CATEGORY}`:
      return TALABAT_CATEGORY_REJECT_REASONS
    default:
      return DEFAULT_REJECT_REASONS;
  }
};
