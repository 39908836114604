export const style = theme => ({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    padding: "20px",
  },
  image: {
    objectFit: "contain",
    "max-height": "500px",
    "& + &": {
      marginTop: "20px",
    },
  },
  pdfWrapper: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
  },
});

export default style;
