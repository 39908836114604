import newConfig from './newConfig';
import newConfigAp from './newConfigAp';
import newConfigMe from './newConfigMe';

let config = {};

config = {
  fuzeGQLApi: process.env.REACT_APP_FUZE_GQL_ENDPOINT,
  fuzeRiderPhotosApi: process.env.REACT_APP_FUZE_RIDER_PHOTOS_ENDPOINT,
  api: process.env.REACT_APP_NIMBUS_SERVICE_CHANGE_REQUEST_ENDPOINT,
  openingTimeApi: process.env.REACT_APP_NIMBUS_SERVICE_OPENINGTIME_REQUEST_ENDPOINT,
  menuAttachedFilesApi: process.env.REACT_APP_NIMBUS_SERVICE_GET_MENU_IMAGE_ENDPOINT,
  dishPhotosApi: process.env.REACT_APP_PHOTO_SERVICE_DISH_PHOTO_ENDPOINT,
  choiceGroupApi: process.env.REACT_APP_NIMBUS_SERVICE_CHOICE_GROUP_REQUEST_ENDPOINT,
  variantApi: process.env.REACT_APP_VARIANT_SERVICE_ENDPOINT,
  authorizerApi: process.env.REACT_APP_AUTHENTICATION_ENDPOINT,
  agentApi: process.env.REACT_APP_AGENT_SERVICE_ENDPOINT,
  catalogServiceApi: process.env.REACT_APP_CATALOG_SERVICE_ENDPOINT,
  catalogServiceAuthToken: process.env.REACT_APP_CATALOG_SERVICE_AUTH_TOKEN || '',
  services: {
    google: {
      clientId: process.env.REACT_APP_GOOGLE_SSO_CLIENT,
    },
  },
  photoRefetchLowerThanNumber: process.env.REACT_APP_PHOTO_REFETCH_LOWER_THAN_NUMBER,
  regionApps: {
    EU: process.env.REACT_APP_EU,
    AP: process.env.REACT_APP_AP,
    MENA: process.env.REACT_APP_MENA,
  },
  supportedCountries: process.env.REACT_APP_SUPPORTED_COUNTRIES || '*',
  fwfConfig: {
    region: process.env.REACT_APP_FWF_REGION, //*required
    debugMode: false, //*optional by default is false
    environmentToken: process.env.REACT_APP_FWF_KEY, //*required
    user: {},
  },
  ddg: {
    applicationId: process.env.REACT_APP_DDG_APP_ID,
    clientToken: process.env.REACT_APP_DDG_CLIENT_TOKEN,
  },
  fwcApi: process.env.REACT_APP_FWC,
  fwcVariantApi: process.env.REACT_APP_FWC_VARIANTS,
  fuzeRiderPhotosApiKey: process.env.REACT_APP_FUZE_RIDER_PHOTOS_API_KEY,
  brandProxyApi: process.env.REACT_APP_BRAND_PROXY_HOST,
};



if(window.location.host === "dev-mqc.portal.restaurant"){
  const newDevConfig = newConfig();
  
  config.fuzeGQLApi= newDevConfig.REACT_APP_FUZE_GQL_ENDPOINT;
  config.fuzeRiderPhotosApi= newDevConfig.REACT_APP_FUZE_RIDER_PHOTOS_ENDPOINT;
  config.api= newDevConfig.REACT_APP_NIMBUS_SERVICE_CHANGE_REQUEST_ENDPOINT;
  config.openingTimeApi= newDevConfig.REACT_APP_NIMBUS_SERVICE_OPENINGTIME_REQUEST_ENDPOINT;
  config.menuAttachedFilesApi= newDevConfig.REACT_APP_NIMBUS_SERVICE_GET_MENU_IMAGE_ENDPOINT;
  config.dishPhotosApi= newDevConfig.REACT_APP_PHOTO_SERVICE_DISH_PHOTO_ENDPOINT;
  config.choiceGroupApi= newDevConfig.REACT_APP_NIMBUS_SERVICE_CHOICE_GROUP_REQUEST_ENDPOINT;
  config.variantApi= newDevConfig.REACT_APP_VARIANT_SERVICE_ENDPOINT;
  config.authorizerApi= newDevConfig.REACT_APP_AUTHENTICATION_ENDPOINT;
  config.agentApi= newDevConfig.REACT_APP_AGENT_SERVICE_ENDPOINT;
  config.catalogServiceApi= newDevConfig.REACT_APP_CATALOG_SERVICE_ENDPOINT;
  config.catalogServiceAuthToken= newDevConfig.REACT_APP_CATALOG_SERVICE_AUTH_TOKEN || '';
  config.services.google.clientId = newDevConfig.REACT_APP_GOOGLE_SSO_CLIENT;
  config.photoRefetchLowerThanNumber = newDevConfig.REACT_APP_PHOTO_REFETCH_LOWER_THAN_NUMBER;
  config.supportedCountries = newDevConfig.REACT_APP_SUPPORTED_COUNTRIES;
  config.fwfConfig.region = newDevConfig.REACT_APP_FWF_REGION;
  config.fwcApi = newDevConfig.REACT_APP_FWC;
  config.fwcVariantApi = newDevConfig.REACT_APP_FWC_VARIANTS;
  config.fuzeRiderPhotosApiKey = newDevConfig.REACT_APP_FUZE_RIDER_PHOTOS_API_KEY;
  config.brandProxyApi = newDevConfig.REACT_APP_BRAND_PROXY_HOST;


}

if(window.location.host === "dev-mqc-ap.portal.restaurant"){
  const newDevConfigAp = newConfigAp();
  
  config.fuzeGQLApi= newDevConfigAp.REACT_APP_FUZE_GQL_ENDPOINT;
  config.fuzeRiderPhotosApi= newDevConfigAp.REACT_APP_FUZE_RIDER_PHOTOS_ENDPOINT;
  config.api= newDevConfigAp.REACT_APP_NIMBUS_SERVICE_CHANGE_REQUEST_ENDPOINT;
  config.openingTimeApi= newDevConfigAp.REACT_APP_NIMBUS_SERVICE_OPENINGTIME_REQUEST_ENDPOINT;
  config.menuAttachedFilesApi= newDevConfigAp.REACT_APP_NIMBUS_SERVICE_GET_MENU_IMAGE_ENDPOINT;
  config.dishPhotosApi= newDevConfigAp.REACT_APP_PHOTO_SERVICE_DISH_PHOTO_ENDPOINT;
  config.choiceGroupApi= newDevConfigAp.REACT_APP_NIMBUS_SERVICE_CHOICE_GROUP_REQUEST_ENDPOINT;
  config.variantApi= newDevConfigAp.REACT_APP_VARIANT_SERVICE_ENDPOINT;
  config.authorizerApi= newDevConfigAp.REACT_APP_AUTHENTICATION_ENDPOINT;
  config.agentApi= newDevConfigAp.REACT_APP_AGENT_SERVICE_ENDPOINT;
  config.catalogServiceApi= newDevConfigAp.REACT_APP_CATALOG_SERVICE_ENDPOINT;
  config.catalogServiceAuthToken= newDevConfigAp.REACT_APP_CATALOG_SERVICE_AUTH_TOKEN || '';
  config.services.google.clientId = newDevConfigAp.REACT_APP_GOOGLE_SSO_CLIENT;
  config.photoRefetchLowerThanNumber = newDevConfigAp.REACT_APP_PHOTO_REFETCH_LOWER_THAN_NUMBER;
  config.supportedCountries = newDevConfigAp.REACT_APP_SUPPORTED_COUNTRIES;
  config.fwfConfig.region = newDevConfigAp.REACT_APP_FWF_REGION;
  config.fwcApi = newDevConfigAp.REACT_APP_FWC;
  config.fwcVariantApi = newDevConfigAp.REACT_APP_FWC_VARIANTS;
  config.fuzeRiderPhotosApiKey = newDevConfigAp.REACT_APP_FUZE_RIDER_PHOTOS_API_KEY;
  config.brandProxyApi = newDevConfigAp.REACT_APP_BRAND_PROXY_HOST;


}

if(window.location.host === "dev-mqc-mena.portal.restaurant"){
  const newDevConfigMe = newConfigMe();
  
  config.fuzeGQLApi= newDevConfigMe.REACT_APP_FUZE_GQL_ENDPOINT;
  config.fuzeRiderPhotosApi= newDevConfigMe.REACT_APP_FUZE_RIDER_PHOTOS_ENDPOINT;
  config.api= newDevConfigMe.REACT_APP_NIMBUS_SERVICE_CHANGE_REQUEST_ENDPOINT;
  config.openingTimeApi= newDevConfigMe.REACT_APP_NIMBUS_SERVICE_OPENINGTIME_REQUEST_ENDPOINT;
  config.menuAttachedFilesApi= newDevConfigMe.REACT_APP_NIMBUS_SERVICE_GET_MENU_IMAGE_ENDPOINT;
  config.dishPhotosApi= newDevConfigMe.REACT_APP_PHOTO_SERVICE_DISH_PHOTO_ENDPOINT;
  config.choiceGroupApi= newDevConfigMe.REACT_APP_NIMBUS_SERVICE_CHOICE_GROUP_REQUEST_ENDPOINT;
  config.variantApi= newDevConfigMe.REACT_APP_VARIANT_SERVICE_ENDPOINT;
  config.authorizerApi= newDevConfigMe.REACT_APP_AUTHENTICATION_ENDPOINT;
  config.agentApi= newDevConfigMe.REACT_APP_AGENT_SERVICE_ENDPOINT;
  config.catalogServiceApi= newDevConfigMe.REACT_APP_CATALOG_SERVICE_ENDPOINT;
  config.catalogServiceAuthToken= newDevConfigMe.REACT_APP_CATALOG_SERVICE_AUTH_TOKEN || '';
  config.services.google.clientId = newDevConfigMe.REACT_APP_GOOGLE_SSO_CLIENT;
  config.photoRefetchLowerThanNumber = newDevConfigMe.REACT_APP_PHOTO_REFETCH_LOWER_THAN_NUMBER;
  config.supportedCountries = newDevConfigMe.REACT_APP_SUPPORTED_COUNTRIES;
  config.fwfConfig.region = newDevConfigMe.REACT_APP_FWF_REGION;
  config.fwcApi = newDevConfigMe.REACT_APP_FWC;
  config.fwcVariantApi = newDevConfigMe.REACT_APP_FWC_VARIANTS;
  config.fuzeRiderPhotosApiKey = newDevConfigMe.REACT_APP_FUZE_RIDER_PHOTOS_API_KEY;
  config.brandProxyApi = newDevConfigMe.REACT_APP_BRAND_PROXY_HOST;


}


export default config;
