import useAxios from 'axios-hooks';

import config from '../App/config';

// Temporary hook for now. Might have to change the flow based on further functionality in Rider Photos feature
const usePhotoCount = (geID) => {
  const [{ data, loading, error }, ] = useAxios(
    {
      url: `${config.fuzeRiderPhotosApi}/${geID}/count`,
      headers: {
        'x-api-key': config.fuzeRiderPhotosApiKey,
      }
    }
  );

  if (error || loading) return 0;

  return data?.count || 0;
};

export default usePhotoCount;
