// @ts-check
import React from 'react';
import _find from 'lodash/find';
import config from '../App/config';
import { REGION_COUNTRY } from '../App/common/constant';
export * from './search';

export const CONSTANTS = {
  COLOR_LINK: '#1E91D6',
  COLOR_HEADER: '#050505',
  COLOR_NORMAL_TEXT: '#717171',
  ERROR_CHOICE_GROUP_MISSING: 'CHOICE_GROUP_MISSING',
  HEADER_IGNORE_AUTHORIZATION: 'X-Ignore-Authorization',
  KEY_MISSING_FIELD_ERROR_PREFIX: '__MISSING_FIELD__',
  URL_INCIDENT_FORM: 'https://jira.deliveryhero.com/plugins/servlet/desk/portal/118',
};

export const COMMON_VSS_ERRORS = {
  'AP-NS-CA-104': 'The category in this request has been deleted. Please reject the request.',
  'AP-NS-CA-204': 'The category in this request has been deleted. Please reject the request.',
  'AP-CG-CA-104': 'The choice group in this request has been deleted. Please reject the request.',
  'AP-CG-CA-204': 'The choice group in this request has been deleted. Please reject the request.',
  'AP-VR-CA-104': 'The variant in this request has been deleted. Please reject the request.',
  'AP-VR-CA-204': 'The variant in this request has been deleted. Please reject the request.',
};

/** @param {number} ms timeout duration in milliseconds */
export function asyncTimeout(ms) {
  return new Promise((resolve) => setTimeout(() => resolve(undefined), ms));
}

/**
 * @param {object} args
 * @param {Error & { code: string, message: string, response: { data: { code: string }}}} args.error
 * @param {'change_request'} [args.type]
 */

export function formatErrorForDisplay({ error }) {
  // /** Original */
  // return `APN-${error.status}: ${error.message}`;
  const presentInCommonErrors = _find(
    COMMON_VSS_ERRORS,
    (_, k) => error?.code?.startsWith(k) || error?.response?.data?.code.startsWith(k)
  );
  if (presentInCommonErrors) {
    return <span>{presentInCommonErrors}</span>;
  }

  const errorCode = error?.code || error?.response?.data?.code;
  return (
    <span>
      Oops, Something Went Wrong. Please unassign the request and fill up the incident{' '}
      <a
        href={CONSTANTS.URL_INCIDENT_FORM}
        target="__blank"
        rel="noopener noreferrer"
        style={{ color: 'white' }}
      >
        form
      </a>
      , and
      {errorCode && <> include error code "{errorCode}" </>}, we will get back to you very soon.
    </span>
  );
}

/**
 * Use this utility function when we need to construct a new change request manually and send it
 * to backend.
 * @param {Partial<Product>} changeDetails
 */
export function constructChangeManually(changeDetails) {
  const now = Date.now();

  return {
    id: `${Math.floor(Math.random() * Math.floor(1000000))}`,
    updatedOn: now,
    effectiveOn: now,
    createdAt: now,
    ...changeDetails,
  };
}

/** @param {string} fieldName */
export function constructMissingFieldErrorKey(fieldName) {
  return `${CONSTANTS.KEY_MISSING_FIELD_ERROR_PREFIX}_${fieldName}`;
}

export const isApac = () => {
  const supportedCountriesArr = config.supportedCountries.split(',');

  return supportedCountriesArr.every((countryCode) => {
    return REGION_COUNTRY.AP.hasOwnProperty(countryCode);
  });
};
