import React from "react";
import ApproveButton from "../../../App/components/ApproveButton";
import RejectButton from "../../../App/components/RejectButton";
import DownloadButton from "../../../App/components/DownloadButton";
import UploadButton from "../../../App/components/UploadButton";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function ActionBar({
  checks,
  approveLoading,
  setIsRejectModalOpen,
  batchApprove,
  setisUploadPhotoModalOpen,
  batchDownload,
  downloading,
}) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box className={classes.defaultOptions}>
        <ApproveButton
          className={classes.button}
          onClick={batchApprove}
          data-testid={'actionbar-approve'}
          disabled={checks.length === 0 || approveLoading}
        >
          {approveLoading ? "Approving" : "Approve"}
        </ApproveButton>
        <RejectButton
          className={classes.button}
          disabled={checks.length === 0}
          onClick={() => setIsRejectModalOpen(true)}
          data-testid={'actionbar-reject'}
        >
          {"Reject"}
        </RejectButton>
      </Box>
      <Box className={classes.moreOptions}>
        <DownloadButton
          className={classes.button}
          onClick={batchDownload}
          disabled={checks.length === 0 || downloading}
          data-testid={'actionbar-download'}
        >
          {downloading ? "Downloading" : "Download"}
        </DownloadButton>
        <UploadButton
          className={classes.button}
          onClick={() => setisUploadPhotoModalOpen(true)}
          variant="outlined"
          data-testid={'actionbar-upload'}
        >
          Upload
        </UploadButton>
      </Box>
      <Box display="inline-block">{checks.length} selected</Box>
    </Box>
  );
}
