import _cloneDeep from 'lodash/cloneDeep';

import {
  APPEND_AGENT_QUEUE_DATA,
  RESOLVE_CHECKED_REQUESTS,
  SET_AGENT_QUEUE_DATA,
  SET_ASSIGNED_AGENT,
  SET_CATEGORIES_BY_VENDOR,
  SET_CHECKED_REQUESTS,
  SET_CHOICE_GROUPS_BY_VENDOR,
  SET_COLLAPSE,
  SET_GROUPED_CHOICE_GROUP_REQUESTS,
  SET_GROUPED_PRODUCT_REQUESTS,
  SET_IS_PROCESSING_DATA,
  SET_IS_PROCESSING_LAZY_LOAD,
  SET_LAZY_VENDOR_ITEMS,
  SET_REJECT_MODAL_OPEN,
  SET_VENDOR_ITEMS,
  SET_VENDOR_NAMES,
} from './actions';

export const initialAgentQueueState = {
  vats: [],
  rejectModalOpen: false,
  categoriesByVendor: {},
  choiceGroupsByVendor: {},
  groupedProductRequests: {},
  groupedChoiceGroupRequests: {},
  collapse: new Set(),
  checkedRequests: [],
  agentQueueData: [],
  vendorItems: [],
  lazyVendorItems: [],
  isProcessingLazyLoad: false,
  pageToken: '',
  isProcessing: false,
};

const agentQueueReducer = (state, action) => {
  switch (action.type) {
    case SET_IS_PROCESSING_DATA: {
      return {
        ...state,
        isProcessing: action.payload,
      };
    }
    case SET_REJECT_MODAL_OPEN:
      return {
        ...state,
        rejectModalOpen: action.payload,
      };
    case SET_CATEGORIES_BY_VENDOR:
      return {
        ...state,
        categoriesByVendor: { ...action.payload },
      };
    case SET_CHOICE_GROUPS_BY_VENDOR:
      return {
        ...state,
        choiceGroupsByVendor: { ...action.payload },
      };
    case SET_GROUPED_PRODUCT_REQUESTS:
      return {
        ...state,
        groupedProductRequests: { ...action.payload },
      };
    case SET_GROUPED_CHOICE_GROUP_REQUESTS:
      return {
        ...state,
        groupedChoiceGroupRequests: { ...action.payload },
      };
    case SET_COLLAPSE:
      return {
        ...state,
        collapse: action.payload,
      };
    case SET_CHECKED_REQUESTS:
      return {
        ...state,
        checkedRequests: [...action.payload],
      };
    case RESOLVE_CHECKED_REQUESTS: {
      const checkedRequests = state.checkedRequests;
      const modifiedKeyValue = { ...state.dataKeyValue };

      for (let i = 0; i < checkedRequests.length; i++) {
        const requestId = checkedRequests[i].requestId;
        delete modifiedKeyValue[requestId];
      }

      return {
        ...state,
        checkedRequests: [],
        dataKeyValue: modifiedKeyValue,
      };
    }
    case SET_AGENT_QUEUE_DATA: {
      const dataArray = action.payload;
      const keyValue = dataArray.reduce((accumulator, element) => {
        accumulator[element.requestId] = element;
        return accumulator;
      }, {});

      return {
        ...state,
        dataKeyValue: keyValue || {},
        agentQueueData: Object.values(keyValue),
        pageToken: action.pageToken,
      };
    }
    case APPEND_AGENT_QUEUE_DATA: {
      const dataArray = action.payload;
      const keyValue = dataArray.reduce(
        (accumulator, element) => {
          accumulator[element.requestId] = element;
          return accumulator;
        },
        { ...state.dataKeyValue }
      );

      return {
        ...state,
        dataKeyValue: keyValue || {},
        agentQueueData: Object.values(keyValue),
        pageToken: action.pageToken,
      };
    }
    case SET_VENDOR_ITEMS:
      return {
        ...state,
        vendorItems: [...action.payload],
      };
    case SET_LAZY_VENDOR_ITEMS:
      return {
        ...state,
        lazyVendorItems: [...action.payload],
      };
    case SET_IS_PROCESSING_LAZY_LOAD:
      return {
        ...state,
        isProcessingLazyLoad: action.payload,
      };
    case SET_VENDOR_NAMES:
      return {
        ...state,
        vendorNames: { ...state.vendorNames, ...action.payload },
      };
    case SET_ASSIGNED_AGENT: {
      const { vendorCode, assignedAgent } = action.payload;

      const dataKeyValue = { ...state.dataKeyValue };
      for (let key in dataKeyValue) {
        if (dataKeyValue.hasOwnProperty(key)) {
          const request = _cloneDeep(dataKeyValue[key]);

          if (request.item.vendorCode === vendorCode) {
            request.item.assignedAgent = assignedAgent;
            dataKeyValue[key] = request;
          }
        }
      }

      return {
        ...state,
        dataKeyValue: dataKeyValue,
        agentQueueData: Object.values(dataKeyValue),
      };
    }
    default:
      return state;
  }
};

export default agentQueueReducer;
