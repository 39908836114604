import { createContext } from 'react';
import { IPortalSdk } from '@deliveryhero/vendor-portal-sdk';

export interface ApolloComponentsUtilityProviderValue {
  pushGtmEvent: IPortalSdk['pushGtmEvent'];
}

const apolloComponentsUtilityContextDefault: ApolloComponentsUtilityProviderValue = {
  pushGtmEvent: () => {
    // detailed implementation
  },
};

const ApolloComponentsUtilityContext = createContext(apolloComponentsUtilityContextDefault);
const ApolloComponentsUtilityProvider = ApolloComponentsUtilityContext.Provider;

export { ApolloComponentsUtilityProvider, ApolloComponentsUtilityContext };
