import { TALABAT_PRICE_REJECT_REASONS } from './priceRejectReason';

const REJECT_REASON_MISMATCHING_TRANSLATION_ITEM_NAME = {
  key: 'menu_rejection_mismatching_translation_item_name',
  text: 'Mismatching translation - Item Name',
};

const REJECT_REASON_GENERIC_ITEM_FOOD = {
  key: 'menu_rejection_generic_item_food',
  text: 'Generic / vague item - Food',
};

const REJECT_REASON_GENERIC_ITEM_NFV = {
  key: 'menu_rejection_generic_item_nfv',
  text: 'Generic / vague item - NFV',
};

const REJECT_REASON_NO_REQUIRED_CHOICES = {
  key: 'menu_rejection_no_required_choices',
  text: 'Item with no (Required Choices)',
};

const REJECT_REASON_FORBIDDEN_ITEM = {
  key: 'menu_rejection_forbidden_item',
  text: 'Forbidden item',
};

const REJECT_REASON_BACON_ITEM = {
  key: 'menu_rejection_bacon_item',
  text: 'Bacon item',
};

const REJECT_REASON_ITEM_WITH_COMMUNICATION_REQUEST = {
  key: 'menu_rejection_item_with_communication_request',
  text: 'Item with Communication Request',
};

const REJECT_REASON_NO_0_ALCOHOL_MENTIONED = {
  key: 'menu_rejection_no_0_alcohol_mentioned',
  text: 'Halal Drinks without 0% Alcohol mentioned',
};

const REJECT_REASON_NEW_ITEM_WITHOUT_IMAGE_NFV = {
  key: 'menu_rejection_new_item_without_image_nfv',
  text: 'New item without image - NFV',
};

const REJECT_REASON_NEW_ITEM_WITHOUT_IMAGE_QATAR_NFV_FOOD = {
  key: 'menu_rejection_new_item_without_image_qatar_nfv_food',
  text: 'New item without image - Qatar (NFV & Food)',
};

const REJECT_REASON_ITEM_IN_WRONG_SECTION_UAE = {
  key: 'menu_rejection_item_in_wrong_section_uae',
  text: 'Item in wrong section - UAE',
};

const REJECT_REASON_DUPLICATED_ITEM = {
  key: 'menu_rejection_duplicated_item',
  text: 'Duplicated Item',
};

const REJECT_REASON_REJECTION_FOR_POS_CHAINS = {
  key: 'menu_rejection_rejection_for_pos_chains',
  text: 'Rejection for POS chains',
};

const REJECT_REASON_REJECTION_MISMATCHING_TRANSLATION_DESCRIPTION = {
  key: 'menu_rejection_mismatching_translation_description',
  text: 'Mismatching translation - Description',
};

const REJECT_REASON_REJECTION_MISSING_WEIGHT_OR_SIZE = {
  key: 'menu_rejection_missing_weight_or_size',
  text: 'Missing weight or size',
};

const REJECT_REASON_REJECTION_VAGUE_INGREDIENT_DESCRIPTION_SALAD = {
  key: 'menu_rejection_vague_ingredient_description_salad',
  text: 'Vague ingredient - Description (Salad)',
};

const REJECT_REASON_REJECTION_VAGUE_INGREDIENT_DESCRIPTION_SAUCE = {
  key: 'menu_rejection_vague_ingredient_description_sauce',
  text: 'Vague ingredient - Description (Sauce)',
};

const REJECT_REASON_REJECTION_VAGUE_INGREDIENT_DESCRIPTION_SOUP = {
  key: 'menu_rejection_vague_ingredient_description_soup',
  text: 'Vague ingredient - Description (Soup)',
};

const REJECT_REASON_REJECTION_CHOICES_MENTIONED_IN_DESCRIPTION = {
  key: 'menu_rejection_choices_mentioned_in_description',
  text: 'Choices mentioned in Description',
};

const REJECT_REASON_REJECTION_MISSING_COOKING_METHOD = {
  key: 'menu_rejection_missing_cooking_method',
  text: 'Missing cooking method',
};

export const TALABAT_PRODUCT_REJECT_REASONS = [
  REJECT_REASON_MISMATCHING_TRANSLATION_ITEM_NAME,
  REJECT_REASON_GENERIC_ITEM_FOOD,
  REJECT_REASON_GENERIC_ITEM_NFV,
  REJECT_REASON_NO_REQUIRED_CHOICES,
  REJECT_REASON_FORBIDDEN_ITEM,
  REJECT_REASON_BACON_ITEM,
  REJECT_REASON_ITEM_WITH_COMMUNICATION_REQUEST,
  REJECT_REASON_NO_0_ALCOHOL_MENTIONED,
  REJECT_REASON_NEW_ITEM_WITHOUT_IMAGE_NFV,
  REJECT_REASON_NEW_ITEM_WITHOUT_IMAGE_QATAR_NFV_FOOD,
  REJECT_REASON_ITEM_IN_WRONG_SECTION_UAE,
  REJECT_REASON_DUPLICATED_ITEM,
  REJECT_REASON_REJECTION_FOR_POS_CHAINS,
  REJECT_REASON_REJECTION_MISMATCHING_TRANSLATION_DESCRIPTION,
  REJECT_REASON_REJECTION_MISSING_WEIGHT_OR_SIZE,
  REJECT_REASON_REJECTION_VAGUE_INGREDIENT_DESCRIPTION_SALAD,
  REJECT_REASON_REJECTION_VAGUE_INGREDIENT_DESCRIPTION_SAUCE,
  REJECT_REASON_REJECTION_VAGUE_INGREDIENT_DESCRIPTION_SOUP,
  REJECT_REASON_REJECTION_CHOICES_MENTIONED_IN_DESCRIPTION,
  REJECT_REASON_REJECTION_MISSING_COOKING_METHOD,
  ...TALABAT_PRICE_REJECT_REASONS
];
