import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  paper: {
    width: 560,
    padding: theme.spacing(3),
    outline: "none",
    maxHeight: '70vh',
    overflowY: 'auto',
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const useStylesControlLabel = makeStyles(theme => ({
  label: {
    width: "100%",
  },
}));

export default useStyles;
