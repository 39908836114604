import React, { useEffect } from 'react';
import AgentQueue from './AgentQueue';
import { Box, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { AGENT_QUEUE_REQUESTS } from './helpers';

const Home = ({ fwfFlags, currentCountry, currentUser, ...props }) => {
  const isFlagLoaded = fwfFlags?.fwfAgentQueueRequests?.loaded;
  const filterState = useSelector((state) => state.filterState);
  const SHOULD_USE_AGENT_QUEUES = fwfFlags?.fwfAgentQueueRequests?.variation;

  const {
    data: agentQueueResponseData,
    loading,
    refetch: refetchAgentQueueData,
    error,
  } = useQuery(AGENT_QUEUE_REQUESTS, {
    variables: {
      input: {
        globalEntityID: currentCountry.geid,
        contentType: null,
        timeFrom: filterState.payload.input.timeFrom
          ? `${filterState.payload.input.timeFrom}`
          : null,
        timeTo: filterState.payload.input.timeTo ? `${filterState.payload.input.timeTo}` : null,
        search: filterState.payload.input.search || null,
      },
      assignedAgent: filterState.payload.assignedToMe ? currentUser.sub : null,
      requestIdSuffix:
        filterState.payload?.requestIdSuffix?.length >= 6
          ? filterState.payload?.requestIdSuffix
          : null,
    },
    skip: !SHOULD_USE_AGENT_QUEUES,
    displayName: 'AgentQueueQuery',
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  useEffect(() => {
    refetchAgentQueueData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.payload.assignedToMe]);

  if (!isFlagLoaded) {
    return (
      <Box
        p={5}
        style={{
          textAlign: 'center',
          width: 'auto',
          height: '600px',
          marginTop: '150px',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <h1>Something went wrong.</h1>
      </div>
    );
  }

  return (
    <AgentQueue
      agentQueueResponseData={agentQueueResponseData}
      loading={loading}
      refetchAgentQueueData={refetchAgentQueueData}
      fwfFlags={fwfFlags}
      {...props}
    />
  );
};

export default Home;
