const styles = theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  root: {
    background: "rgb(58, 58, 69)",
    color: "white",
  },
});

export default styles;
