import { Theme } from '@material-ui/core';

export const gridStyle = (theme: Theme) => ({
  gridContainer: {
    display: 'flex',
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
  },
  gridItem: (props: { perRow: number }) => ({
    flexBasis: props.perRow > 0 ? `${100 / props.perRow}%` : 'auto',
    margin: theme.spacing(1),
  }),
});
