import red from "@material-ui/core/colors/red";

export const styles = theme => ({
  root: {
    textTransform: "none",
    fontWeight: "bold",
    boxShadow: "none",
  },
  contained: {
    color: "white",
    backgroundColor: red[400],
    "&:hover": {
      backgroundColor: red[500],
      boxShadow: "none",
      "@media (hover: none)": {
        backgroundColor: "#ff9190",
      },
    },
  },
});

export default styles;
