import React from 'react';
import { Box } from '@material-ui/core';
import Allergens from './Allergens';
import Warnings from './Warnings';
import Additives from './Additives';
import { SectionWrapper } from '../styles';
import { NutritionInformationSectionProps } from '../types';
import { HelperTextWrapper } from '../styles';
import { IsInChoiceItemContext } from '../NutritionInformation';
import NutritionDisclaimer from '../NutritionDisclaimer';

const NutritionInformationSection: React.FC<NutritionInformationSectionProps> = ({
  t,
  allergens,
  warnings,
  additives,
  initialAllergens,
  initialWarnings,
  initialAdditives,
  handleAllergensChange,
  handleWarningsChange,
  handleAdditivesChange,
  errors,
  platformCompanyName,
  shouldShowAdditives,
  isGermanVendor,
}) => {
  const isInChoiceItem = React.useContext(IsInChoiceItemContext);
  const sectionTitle = isInChoiceItem
    ? null
    : t('menu_management_pandora.item_navigation_nutrition_information');
  return (
    <SectionWrapper
      data-testid={'nutrition_information_section_wrapper'}
      noBorderBottom
      title={sectionTitle}
    >
      <Box mb={6}>
        <HelperTextWrapper>
          {t('menu_management_pandora.nutrition_information_card_subtitle')}
        </HelperTextWrapper>
      </Box>
      <Allergens
        t={t}
        handleOnChange={handleAllergensChange}
        options={initialAllergens}
        value={allergens}
        errors={errors}
      />
      <Warnings
        t={t}
        handleOnChange={handleWarningsChange}
        options={initialWarnings}
        value={warnings}
        errors={errors}
        isGermanVendor={isGermanVendor}
      />
      <Additives
        t={t}
        handleOnChange={handleAdditivesChange}
        options={initialAdditives}
        value={additives}
        errors={errors}
        shouldShowAdditives={shouldShowAdditives}
      />
      <NutritionDisclaimer
        t={t}
        platformCompanyName={platformCompanyName}
        shouldRender={!isInChoiceItem}
      />
    </SectionWrapper>
  );
};

export default NutritionInformationSection;
