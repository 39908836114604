import { StatusType } from '../../types';
import styled from 'styled-components';
import { StatusBoxProps } from './types';

export const StatubsBoxWrapper = styled.div<StatusBoxProps>`
  padding: 5px 10px 5px 10px;
  border-radius: 4px;
  background: ${(props) => {
    switch (props.type) {
      case StatusType.Pending:
        return '#d9a732';
      case StatusType.Rejected:
        return '#d61f26';
      default:
        return 'green';
    }
  }};
  color: white;
  text-align: center;
`;
