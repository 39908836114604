// @ts-check
import axios from 'axios';
import { configure } from 'axios-hooks';
import { Storage, storageKeys } from './../../storage/storage';
import { ERROR_LIST } from '../common/constant';
import config from '../config';
import _cookies from 'js-cookie';
import opsPortalClient from '../../client/opsPortal';

//This logOut function is used only for API error logout redirect
export function logOut() {
  Storage.clearItem(storageKeys.ACCESS_TOKEN);
  if (typeof window !== 'undefined') {
    window.location.href = `/login?redirect=${window.location.pathname}`;
  }
}

/** @param {any} err */
const getErrorRes = (err) => {
  //Setting error obj based on status and by default setting to 500
  const error = (err.response && ERROR_LIST[err.response.status]) || ERROR_LIST[500];

  if (err.response?.data?.detail) {
    error.message = err.response.data.detail;
  }

  // Setting the error trace to error obj
  if (err.response && err.response.data.errors) {
    error.detail = JSON.stringify(err.response.data.errors);
  } else {
    error.detail = (err.response && err.response.data.message) || '';
  }

  if (err.response && err.response?.data?.code) {
    error.code = err.response.data.code;
  }

  return error;
};
const instance = axios.create();

// for multiple requests
let isRefreshing = false;
/** @type {any[]} */
let failedQueue = [];

/**
 * @param {Error | null} error
 * @param {string | null} token
 */
const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    if (typeof window !== 'undefined' && window.location.pathname === '/login') {
      return Promise.reject(error);
    }

    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = false;
      isRefreshing = true;

      return new Promise((resolve) => {
        async function retrieveTokens() {
          const isInsideOpsPortal = await opsPortalClient.isRunningInOpsPortal();
          if (!isInsideOpsPortal) {
            const {
              data: { access_token, refresh_token },
            } = await axios.post(`${config.authorizerApi}/auth/refresh-token`, null, {
              headers: { Authorization: `Bearer ${Storage.get(storageKeys.ACCESS_TOKEN)}` },
            });
            return { access_token, refresh_token };
          } else {
            const access_token = await opsPortalClient.getRefreshedAccessToken();
            return { access_token, refresh_token: '' };
          }
        }

        retrieveTokens()
          .then(({ access_token, refresh_token }) => {
            Storage.set(storageKeys.ACCESS_TOKEN, access_token, {
              expires: 7,
              sameSite: 'None',
              secure: true,
            });

            _cookies.set('refresh_token', refresh_token, {
              expires: 7,
              sameSite: 'None',
              secure: true,
            });

            originalRequest.headers.Authorization = `Bearer ${access_token}`;
            processQueue(null, access_token);
            resolve(axios(originalRequest));
          })
          .catch(function (error) {
            // handle error
            console.error(error);
            processQueue(error, null);
            logOut();
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    if (error.response) {
      return Promise.reject(getErrorRes(error));
    }

    return Promise.reject(error);
  }
);

configure({ axios });

export default instance;
