const getNewConfigAp = (fwfFlags, currentUser) => {
    
    const config = {
        REACT_APP_FUZE_GQL_ENDPOINT:'https://stg-cp-gql-gateway-api.eu.stg.portal.restaurant/graphql/query',
        REACT_APP_FUZE_RIDER_PHOTOS_ENDPOINT:'https://stg-rider-rewards-service.eu.stg.portal.restaurant',        
        REACT_APP_NIMBUS_SERVICE_CHANGE_REQUEST_ENDPOINT:'https://eu-api.dev.pandaqc.io/products/api/v1',
        REACT_APP_NIMBUS_SERVICE_CHOICE_GROUP_REQUEST_ENDPOINT:'https://eu-api.dev.pandaqc.io/choicegroups/api/v1',
        REACT_APP_NIMBUS_SERVICE_OPENINGTIME_REQUEST_ENDPOINT:'https://eu-api.dev.pandaqc.io/openingtimes',
        REACT_APP_NIMBUS_SERVICE_GET_MENU_IMAGE_ENDPOINT:'https://eu-api.dev.pandaqc.io/menuimages/api/v1/menuImages',
        REACT_APP_PHOTO_SERVICE_DISH_PHOTO_ENDPOINT:'https://eu-api.dev.pandaqc.io/photos',
        REACT_APP_GOOGLE_SSO_CLIENT:'646393352685-sk1u1fri6glkbt8d9c3l75t0dff45rhl.apps.googleusercontent.com',
        REACT_APP_AUTHENTICATION_ENDPOINT:'https://m18xw3glll.execute-api.ap-southeast-1.amazonaws.com/stg',
        REACT_APP_VARIANT_SERVICE_ENDPOINT:'https://eu-api.dev.pandaqc.io/variants',
        REACT_APP_AGENT_SERVICE_ENDPOINT:'https://0o3d26rcc0.execute-api.eu-west-1.amazonaws.com/dev',
        REACT_APP_CATALOG_SERVICE_ENDPOINT:'https://my-st.fd-api.com',
        REACT_APP_ENV:'development',
        REACT_APP_PHOTO_REFETCH_LOWER_THAN_NUMBER:'50',
        REACT_APP_FWF_REGION:'ap',
        REACT_APP_OPS_PORTAL_URL:'https://portal-fo-euw-stg-vt.deliveryhero.io',
        REACT_APP_FWC:'https://eu-api.dev.pandaqc.io/fwc/api/v1',
        REACT_APP_FWC_VARIANTS:'https://eu-api.dev.pandaqc.io/fwc',
        REACT_APP_FUZE_RIDER_PHOTOS_API_KEY:'UNrP9jy4bstDy9Sm',
        REACT_APP_BRAND_PROXY_HOST:'https://apac-api.dev.pandaqc.io/client-api/v1/internal-api/entities',
        REACT_APP_SUPPORTED_COUNTRIES:'BD,HK,KH,LA,MM,MY,PK,PH,SG,TW,TH,JP',
    }

    return config;
};

export default getNewConfigAp;