import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import _filter from 'lodash/filter';
import _groupBy from 'lodash/groupBy';
import TablePagination from '@material-ui/core/TablePagination';

import { makeStyles } from '@material-ui/core/styles';
import { Refresh, Close, FileCopy } from '@material-ui/icons';
import {
  Container,
  Checkbox,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Grid,
  CircularProgress,
  Box,
  Button,
} from '@material-ui/core';
import LoaderWrapper from '../../../../App/components/LoaderWrapper';
import styles, { tablestyles } from '../styles';
import { useMutation, useQuery } from '@apollo/client';
import {
  PHOTO_QUEUE_REQUESTS,
  PROCESS_CHANGE_REQUESTS_QUERY,
  PENDING_PHOTOQC_REQUESTS,
} from '../../../../MenuManagement/containers/Home/helpers';
import { defaultErrorMessage } from '../../../../MenuManagement/utility/information';
import { TYPE_OF_CHANGE_PHOTOS } from '../../../../App/common/constant';
import { Count } from '../../../components/CategoryFilter';
import SafeGridToggle from '../../../components/SafeGridToggle';
import SafeGridImage from '../../../components/SafeGridImage';
import {
  capitalizeFirstLetter,
  copyToClipboard,
  getDefaultLanguage,
  getMutationRequestDefaultData,
} from '../../../../utility/information';
import AppRightBar from '../../../../App/layouts/AppRightBar';
import ActionBar from '../ActionBar';
import { formatErrorForDisplay } from '../../../../utility';
import { useSnackbar } from 'notistack';
import UploadPhotoModal from '../../../components/UploadPhotoModal';
import { uploadPhotos, batchImageDownload } from '../helpers';
import { getPhotoRejectReasons } from '../../../common/constants/rejectReason';
import RejectModal from '../../../../App/components/RejectModal';
import EmptyState from '../../../../components/EmptyState2/EmptyState';
import { AppContext, UserContext } from '../../../../App';
import { useDispatch, useSelector } from 'react-redux';
const useStyles = makeStyles(styles);
const useTableStyles = makeStyles(tablestyles);

const photoTypes = {
  [TYPE_OF_CHANGE_PHOTOS.DISH_PHOTO_CHANGE]: 'Dish Photos',
  [TYPE_OF_CHANGE_PHOTOS.HERO_PHOTO_CHANGE]: 'Hero Photos',
  [TYPE_OF_CHANGE_PHOTOS.LISTING_PHOTO_CHANGE]: 'Listing Photos',
};

const AgentPhotoQueue = ({ customContext, fwfFlags }) => {
  const { fwfDishPhotoMasterCategoryFilter, fwfDishPhotoTypeFilter, fwfPhotoSafeGrid } = fwfFlags;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const { currentCountry } = React.useContext(AppContext);
  const currentUser = React.useContext(UserContext);

  const [photoRequests, setPhotoRequests] = useState([]);
  const [photoTypeToShow, setPhotoTypeToShow] = useState('');
  const [photoRequestsByType, setPhotoRequestsByType] = useState({});

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  const [totalItemCount, setTotalItemCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageToken, setPageToken] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageTokenHistory, setPageTokenHistory] = React.useState([]);

  const globalDispatch = useDispatch();
  const filterState = useSelector((state) => state.filterState);

  //GraphQL Operations
  const {
    data: agentQueueData,
    loading,
    refetch: refetchAgentQueueData,
  } = useQuery(PHOTO_QUEUE_REQUESTS, {
    variables: {
      input: {
        globalEntityID: currentCountry.geid,
        contentType: null,
        timeFrom: filterState.payload.input.timeFrom
          ? `${filterState.payload.input.timeFrom}`
          : null,
        timeTo: filterState.payload.input.timeTo ? `${filterState.payload.input.timeTo}` : null,
        search: filterState.payload.input.search || null,
      },
      pageSize: rowsPerPage,
      pageToken: pageToken,
    },
    displayName: 'AgentQueueQuery',
    nextFetchPolicy: 'cache-first',
  });
  /*   variables: {

    assignedAgent: filterState.payload.assignedToMe ? currentUser.sub : null,
    requestIdSuffix:
      filterState.payload?.requestIdSuffix?.length >= 6
        ? filterState.payload?.requestIdSuffix
        : null,
  }, */

  /*   const getInputParams = useCallback(
    (type) => {
      const { q, fromDate, toDate } = customContext;

      if (type === 'reset') {
        setCurrentPage(0);
        setPageToken('');
        setPageTokenHistory([]);
      }

      return returnFilteredData(currentCountry, fromDate, toDate, q);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCountry.geid, customContext]
  ); */

  /*   useEffect(() => {
    let inputParams = getInputParams('reset');

    refetchAgentQueueData({
      input: inputParams,
    });
  }, [
    customContext.q,
    customContext.fromDate,
    customContext.toDate,
    currentCountry,
    refetchAgentQueueData,
    getInputParams,
  ]); */
  const { data: pendingPhotoQCRequestsData } = useQuery(PENDING_PHOTOQC_REQUESTS, {
    variables: {
      globalEntityID: currentCountry.geid,
    },
  });

  /*   useEffect(() => {
    const { q, fromDate, toDate } = customContext;
    if (q || fromDate || toDate) {
      setPageToken('');
      setCurrentPage(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customContext.q, customContext.fromDate, customContext.toDate, currentCountry]); */

  const handleChangePage = (event, newPage) => {
    let commingPageToken = agentQueueData?.getPhotoQCRequests?.pageToken;

    if (!(currentPage > newPage) && !agentQueueData?.getPhotoQCRequests?.pageToken) return; //block for undefined page

    if (currentPage > newPage) {
      //previous page
      const historyPageData = pageTokenHistory.find((history) => history.page === newPage - 1);
      commingPageToken = newPage === 0 ? '' : historyPageData.pageToken;
    }

    if (agentQueueData?.getPhotoQCRequests?.pageToken) {
      //save the pageToken
      const index = pageTokenHistory.findIndex((history) => history.page === currentPage);

      if (index > -1) {
        const newPageDataHistory = pageTokenHistory.filter(
          (history) => history.page !== currentPage
        );
        setPageTokenHistory(newPageDataHistory);
      }

      const data = {
        page: currentPage,
        pageToken: commingPageToken,
      };

      setPageTokenHistory((prevState) => [...prevState, data]);
    }

    setSelectedCategory('');
    setPageToken(commingPageToken);
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageToken('');
  };

  const [displayedRequestListByPhotoType, setDisplayedRequestListByPhotoType] = useState([]);
  const [filteredDisplayRequestList, setFilteredDisplayRequestList] = useState([]);
  useEffect(() => {
    //Change displayed requests based on type of photo filter and category filter
    let filteredDisplayRequestList = photoRequests;

    if (!!photoTypeToShow) {
      filteredDisplayRequestList = photoRequestsByType[photoTypeToShow];
      setDisplayedRequestListByPhotoType(filteredDisplayRequestList || []);
    } else {
      setDisplayedRequestListByPhotoType(photoRequests);
    }

    if (!!selectedCategory) {
      filteredDisplayRequestList = filteredDisplayRequestList.filter((request) => {
        const category = request?.metadata?.product?.Category;
        return category?.ID === selectedCategory;
      });
    }

    setFilteredDisplayRequestList(filteredDisplayRequestList || []);
  }, [photoTypeToShow, photoRequestsByType, photoRequests, selectedCategory]);

  const [isSafeGridEnabled, setIsSafeGridEnabled] = useState(false);

  const [photoToView, setPhotoToView] = useState(null);
  const [selectedRequests, setSelectedRequests] = useState([]);

  const [processChangeRequests, { loading: isProcessing }] = useMutation(
    PROCESS_CHANGE_REQUESTS_QUERY,
    {
      refetchQueries: [PHOTO_QUEUE_REQUESTS],
      onCompleted: () => {},
      onError: () => {
        enqueueSnackbar(defaultErrorMessage, { variant: 'error' });
      },
    }
  );

  /**
   * @param {Object[]} requestArray
   * @param {'APPROVE'||'REJECT'} approveOrReject
   * @param {any[]} [rejectionReasons]
   */
  const getGraphQLInput = useCallback(
    (requestArray, approveOrReject, rejectionReasons) => {
      return requestArray.map((request) => ({
        type: approveOrReject,
        ...(approveOrReject === 'REJECT' ? { rejectionReasons } : {}),
        ...getMutationRequestDefaultData(request, currentUser, currentCountry, {
          typeOfChange: request?.item?.typeOfChange,
        }),
      }));
    },
    [currentCountry, currentUser]
  );

  /**
   * @param {Object[]} requestArray
   */
  const onBatchApprove = useCallback(
    (requestArray) => {
      const input = getGraphQLInput(requestArray, 'APPROVE');

      processChangeRequests({
        variables: {
          input: input,
        },
      }).then(() => {
        enqueueSnackbar(`Successfully approved request${requestArray.length > 1 ? 's' : ''}`, {
          variant: 'success',
        });
        //Regardless of what requests were approved, reset selected requests to prevent stale data set in state
        setSelectedRequests([]);
      });
    },
    [enqueueSnackbar, getGraphQLInput, processChangeRequests]
  );
  //GraphQL operations END

  const getPhotoRequests = (requestList) => {
    //Only show photo change related requests
    return _filter(requestList, (r) =>
      Object.values(TYPE_OF_CHANGE_PHOTOS).includes(r.item.typeOfChange)
    );
  };

  useEffect(() => {
    if (!!agentQueueData) {
      const photoRequests = getPhotoRequests(agentQueueData.getPhotoQCRequests.qcRequests);
      setPhotoRequests(photoRequests);
    } else {
      setPhotoRequests([]);
    }
  }, [agentQueueData, currentCountry]);

  useEffect(() => {
    //Group all photo requests by typeOfChange whenever it changes
    const groupedPhotoRequests = _groupBy(photoRequests, (pr) => pr.item.typeOfChange);
    setPhotoRequestsByType(groupedPhotoRequests);
  }, [photoRequests, pendingPhotoQCRequestsData]);

  useEffect(() => {
    return () => {
      globalDispatch({
        type: 'SET_SELECTED_NAVBAR_COUNT',
        data: {
          pluginComponentName: 'ManagePhoto',
          count: null,
        },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    //Set count in Tab each time photoRequests changes
    let requestCount = pendingPhotoQCRequestsData?.getPendingPhotoQCRequestsCount || 0;
    globalDispatch({
      type: 'SET_SELECTED_NAVBAR_COUNT',
      data: {
        pluginComponentName: 'ManagePhoto',
        count: requestCount,
      },
    });
    setTotalItemCount(requestCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingPhotoQCRequestsData]);

  //Init category filter and update everytime photo type is changed
  useEffect(() => {
    let categories = [];
    displayedRequestListByPhotoType.forEach((request) => {
      const relevantProduct = request?.metadata?.product;
      const category = relevantProduct?.Category;

      if (!!category) {
        if (categories.length > 0) {
          const index = categories.findIndex((categoryAcc) => categoryAcc.id === category.ID);

          if (index === -1) {
            categories.push({
              id: category.ID,
              productCount: 1,
              title: category.Title,
            });
          } else {
            categories[index].productCount++;
          }
        } else {
          categories.push({
            id: category.ID,
            productCount: 1,
            title: category.Title,
          });
        }
      }
    });

    setCategories(categories);
  }, [displayedRequestListByPhotoType]);

  //Filter Menu Actions
  const handleFilterChange = (e) => {
    const selectedPhotoType = e.target.value;

    setPhotoTypeToShow(selectedPhotoType);
    setSelectedCategory(''); // reset category to all whenever type is changed
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;

    setSelectedCategory(selectedCategory);
  };
  //Filter Menu Actions END

  //Table Actions
  const onCopyVendorCode = (vendorCode) => (e) => {
    e.stopPropagation();
    copyToClipboard(vendorCode);
    enqueueSnackbar('Successfully copied the vendor code to clipboard', { variant: 'success' });
  };

  const checkAll =
    (requestList = []) =>
    (e) => {
      if (e.target.checked) {
        setSelectedRequests(requestList);
      } else {
        setSelectedRequests([]);
      }
    };

  const isAllPhotosChecked = (requestList = [], checkedRequests = []) => {
    return (
      requestList.every((r) => checkedRequests.map((cr) => cr.requestId).includes(r.requestId)) &&
      requestList.length > 0
    );
  };

  const isPhotoChecked = (selectedRequests, photoRequest) => {
    return selectedRequests.map((r) => r.requestId).includes(photoRequest.requestId);
  };

  const handlePhotoCheck = (photoRequest) => (e) => {
    const isChecked = e.target.checked;
    const requestId = photoRequest.requestId;

    setSelectedRequests((prevState) => {
      const modifiedRequests = [...prevState];
      const requestIndex = modifiedRequests.findIndex((r) => r.requestId === requestId);

      if (!isChecked && requestIndex >= 0) {
        modifiedRequests.splice(requestIndex, 1);
      } else {
        modifiedRequests.push(photoRequest);
      }
      return modifiedRequests;
    });
  };
  //Table Actions END

  //Bottom Action Bar related
  const modalRooter = document.getElementById('appbar');

  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isUploadPhotoModalOpen, setIsUploadPhotoModalOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUploadingImages, setIsUploadingImages] = useState(false);

  const onBatchDownload = (requestArray) => async () => {
    await batchImageDownload({
      getImageDownloads: (downloadImage) => {
        return requestArray.map((request) => {
          const imageLocation =
            request?.item?.patchData?.location || request?.item?.patchData?.photo_url;

          return [imageLocation, downloadImage(imageLocation)];
        });
      },
      preDownloadCallbackFn: () => setIsDownloading(true),
      postDownloadCallbackFn: () => {
        setIsDownloading(false);
        setSelectedRequests([]);
      },
    });
  };

  const onUploadImages = async (files) => {
    try {
      setIsUploadingImages(true);
      await uploadPhotos(files).then(() => {
        //After uploading, approve the related changes which are found by deriving the information from the uploaded image filename. This is why the filenames are important.
        // Sample filenames
        // uploads_v2_s2ie_216321_1652251536720_BD##c3F1YWQtdmVuZG9yLXNlbGYtc2VydmUrYmRsdEBmb29kcGFuZGEuY29t##c3F1YWQtdmVuZG9yLXNlbGYtc2VydmUrYmRsdEBmb29kcGFuZGEuY29t
        // uploads_v2_s2ie_NEW_216321_1652251536720_BD##c3F1YWQtdmVuZG9yLXNlbGYtc2VydmUrYmRsdEBmb29kcGFuZGEuY29t##c3F1YWQtdmVuZG9yLXNlbGYtc2VydmUrYmRsdEBmb29kcGFuZGEuY29t

        const fileRequestRelation = files.map((file) => {
          const fileName = file.name;
          const dataComponents = fileName.split('_');

          // Due to the name of New product id
          // v3lv_NEW_1234, we need to append 3 index
          const productId =
            dataComponents[3] === 'NEW'
              ? `${dataComponents[3]}_${dataComponents[4]}`
              : dataComponents[3];
          return { vendorCode: dataComponents[2], itemId: productId };
        });

        const requestArray = [];

        fileRequestRelation.forEach(({ vendorCode, itemId }) => {
          const relatedRequest = photoRequests.find((pr) => {
            const requestItem = pr?.item;

            return requestItem?.vendorCode === vendorCode && requestItem?.itemId === itemId;
          });

          if (!!relatedRequest) {
            requestArray.push(relatedRequest);
          }
        });

        onBatchApprove(requestArray);

        setIsUploadingImages(false);
        setIsUploadPhotoModalOpen(false);
        enqueueSnackbar(`Successfully uploaded photo${files.length > 1 ? 's' : ''}`, {
          variant: 'success',
        });
      });
    } catch (error) {
      console.error(error, 'Dish photo upload error');
      enqueueSnackbar(<span>{formatErrorForDisplay({ error })}</span>, { variant: 'error' });
    }
  };

  const handleReject = (rejectReasons = []) => {
    const input = getGraphQLInput(selectedRequests, 'REJECT', rejectReasons);

    processChangeRequests({
      variables: {
        input: input,
      },
    }).then(() => {
      enqueueSnackbar(`Successfully rejected request${selectedRequests.length > 1 ? 's' : ''}`, {
        variant: 'error',
      });
      setSelectedRequests([]);
      setIsRejectModalOpen(false);
    });
  };
  //Bottom Action Bar END

  const approveSelected = useCallback(() => {
    onBatchApprove(selectedRequests);
  }, [onBatchApprove, selectedRequests]);

  //Render
  if (photoRequests.length === 0 && !loading) {
    return <EmptyState />;
  } else {
    return (
      <div style={{ display: 'flex', width: '100%' }} data-enzyme={'AgentPhotoQueue'}>
        <AppRightBar modalRoot={modalRooter}>
          <ActionBar
            data-enzyme={'AgentPhotoQueueActionBar'}
            checks={selectedRequests.map((r) => r.requestId)}
            setIsRejectModalOpen={setIsRejectModalOpen}
            setisUploadPhotoModalOpen={setIsUploadPhotoModalOpen}
            batchApprove={approveSelected}
            approveLoading={isProcessing}
            batchDownload={onBatchDownload(selectedRequests)}
            downloading={isDownloading}
          />
        </AppRightBar>
        <Container maxWidth="xl" className={classes.innerscroll}>
          <Box alignItems="center" display="flex" my={1}>
            <Box mr={1}>
              {fwfDishPhotoTypeFilter.variation && (
                <FormControl data-enzyme={'AgentPhotoQueueAllPhotosFilter'}>
                  <Select
                    value={photoTypeToShow}
                    autoWidth
                    disableUnderline
                    displayEmpty
                    onChange={handleFilterChange}
                  >
                    <MenuItem value={''}>
                      All Photos <Count count={photoRequests.length} />
                    </MenuItem>
                    {Object.keys(photoTypes).map((photoTypeOfChange, index) => (
                      <MenuItem key={index} value={photoTypeOfChange}>
                        {photoTypes[photoTypeOfChange]}{' '}
                        <Count count={photoRequestsByType[photoTypeOfChange]?.length || 0} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
            <Box>
              {fwfDishPhotoMasterCategoryFilter.variation && (
                <FormControl data-enzyme={'AgentPhotoQueueSelectCategoryFilter'}>
                  <Select
                    autoWidth
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    disableUnderline
                    displayEmpty
                  >
                    <MenuItem value={''}>
                      All Categories <Count count={filteredDisplayRequestList?.length} />
                    </MenuItem>
                    {categories.map((category) => (
                      <MenuItem key={category?.id} value={category?.id}>
                        {category?.title?.[getDefaultLanguage(category?.title)] ||
                          category?.defaultTitle ||
                          ''}
                        &nbsp;
                        <Count count={category?.productCount} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
            {fwfPhotoSafeGrid != null && fwfPhotoSafeGrid.variation && (
              <SafeGridToggle onChange={setIsSafeGridEnabled} value={isSafeGridEnabled} />
            )}
            <Box marginLeft={'auto'}>
              <Button
                onClick={() => {
                  refetchAgentQueueData().then(() => {
                    console.log('Data has been reloaded');
                  });
                }}
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'row', verticalAlign: 'center' }}>
                    <Refresh /> Refresh list
                  </div>
                )}
              </Button>
            </Box>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <LoaderWrapper isLoading={loading}>
                <Box>
                  <Paper className={tableClasses.tableRoot}>
                    <Table className={tableClasses.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={tableClasses.checkBoxColumn}>
                            <Checkbox
                              checked={isAllPhotosChecked(
                                filteredDisplayRequestList,
                                selectedRequests
                              )}
                              onClick={checkAll(filteredDisplayRequestList)}
                              data-cp="SelectAll"
                            />
                          </TableCell>
                          <TableCell align="left" className={tableClasses.photoCell}>
                            Select All
                          </TableCell>
                          <TableCell align="left" />
                          <TableCell align="left">Category</TableCell>
                          <TableCell align="left">Uploaded by</TableCell>
                          <TableCell align="left">Vendor code</TableCell>
                          <TableCell align="left">Date &amp; time submitted</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredDisplayRequestList.map((currentRequest) => {
                          const currentRequestItem = currentRequest?.item;
                          const currentMetaData = currentRequest?.metadata;
                          const currentProductData = currentMetaData?.product;
                          const currentCategoryData = currentProductData?.Category;
                          const currentVendorCode = currentRequestItem?.vendorCode;
                          const currentPatchData = currentRequestItem?.patchData || {};
                          const currentPhotoUrl =
                            currentPatchData?.location || currentPatchData?.photo_url;

                          return (
                            <React.Fragment key={currentRequest.requestId}>
                              <TableRow>
                                <TableCell className={tableClasses.checkBoxColumn}>
                                  <Checkbox
                                    onClick={handlePhotoCheck(currentRequest)}
                                    checked={isPhotoChecked(selectedRequests, currentRequest)}
                                  />
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className={tableClasses.photoCell}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setPhotoToView({
                                      src:
                                        currentPhotoUrl + `?time=${currentRequestItem?.timestamp}`,
                                      photoType: currentPatchData?.type,
                                    });
                                  }}
                                >
                                  <div className={tableClasses.safeGridImageContent}>
                                    <SafeGridImage
                                      alt="Dish"
                                      src={currentPhotoUrl}
                                      className={tableClasses.dishPhotoImage}
                                      isSafeGridEnabled={isSafeGridEnabled}
                                      photoType={currentPatchData?.type}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className={tableClasses.productInformation}
                                  data-enzyme="product-title"
                                >
                                  {['hero', 'listing'].includes(currentPatchData?.type) ? (
                                    <Typography className={tableClasses.productTitle}>
                                      {capitalizeFirstLetter(currentPatchData?.type)} Photo
                                    </Typography>
                                  ) : (
                                    <>
                                      {!!currentProductData ? (
                                        <>
                                          <Typography className={tableClasses.productTitle}>
                                            {
                                              currentProductData?.Title[
                                                getDefaultLanguage(currentProductData?.Title)
                                              ]
                                            }
                                          </Typography>

                                          <Typography className={tableClasses.productDescription}>
                                            {
                                              currentProductData?.Description?.[
                                                getDefaultLanguage(currentProductData?.Description)
                                              ]
                                            }
                                          </Typography>
                                        </>
                                      ) : (
                                        <Typography className={tableClasses.productTitle}>
                                          Product data is empty...
                                        </Typography>
                                      )}
                                    </>
                                  )}
                                </TableCell>
                                <TableCell className={tableClasses.productInformation}>
                                  {
                                    currentCategoryData?.Title?.[
                                      getDefaultLanguage(currentCategoryData?.Title)
                                    ]
                                  }
                                </TableCell>
                                <TableCell align="left" className={tableClasses.updatedBy}>
                                  {!!currentRequestItem?.assignedAgent ? (
                                    <Typography className={classes.agentEmail}>
                                      {currentRequestItem.assignedAgent}
                                    </Typography>
                                  ) : (
                                    <Typography className={classes.vendorName}>
                                      {currentVendorCode}
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  onClick={onCopyVendorCode(currentVendorCode)}
                                  className={`${tableClasses.vendorCode} vendorCode`}
                                >
                                  <Box display="flex" alignItems="flex-start">
                                    <Box component="span" display="inline" mr={1} color="gray">
                                      <FileCopy />
                                    </Box>
                                    {currentRequestItem?.vendorCode}
                                  </Box>
                                </TableCell>
                                <TableCell className={tableClasses.dateSubmitted}>
                                  {moment(currentRequestItem?.timestamp, 'X').format(
                                    'DD MMM YYYY, H:mm'
                                  )}
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        })}
                      </TableBody>
                    </Table>
                    {!!photoToView && (
                      <Modal open={!!photoToView}>
                        <>
                          <div className={tableClasses.photoEnlargeContainerHeader}>
                            <IconButton
                              style={{ float: 'right' }}
                              key="close"
                              aria-label="Close"
                              color="inherit"
                              onClick={() => setPhotoToView(null)}
                            >
                              <Close className={classes.icon} />
                            </IconButton>
                          </div>
                          <SafeGridImage
                            alt={photoToView.photoType}
                            src={photoToView.src}
                            className={tableClasses.photoEnlargeContainer}
                            isSafeGridEnabled={isSafeGridEnabled}
                            photoType={photoToView.photoType}
                            imageWidth={770}
                            imageHeight={563}
                          />
                        </>
                      </Modal>
                    )}
                  </Paper>
                </Box>

                <TablePagination
                  data-enzyme={'AgentPhotoQueuePagination'}
                  component="div"
                  count={totalItemCount}
                  page={currentPage}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </LoaderWrapper>
            </Grid>
          </Grid>
        </Container>

        <UploadPhotoModal
          batchUpload={onUploadImages}
          loading={isUploadingImages}
          open={isUploadPhotoModalOpen}
          handleClose={() => setIsUploadPhotoModalOpen(false)}
        />

        <RejectModal
          setIsRejectModalOpen={setIsRejectModalOpen}
          loading={isProcessing}
          onSubmit={handleReject}
          isRejectModalOpen={isRejectModalOpen}
          rejectReasons={getPhotoRejectReasons(currentCountry.geid)}
          header="Choose Reject Reason"
          subheader="Are you sure there is nothing you can do to approve this item? This
              action is permanent. The vendor will see the rejection reason you
              choose."
        />
      </div>
    );
  }
};

export default AgentPhotoQueue;
