const styles = (theme) => ({
  appBarRight: {
    display: 'flex',
    alignItems: 'center',
  },
  appBarMenuItem: {
    cursor: 'pointer',
    borderBottom: '4px solid transparent',
    '&.active': {
      borderBottom: '4px solid rgb(78, 174, 217)',
    },
  },
});

export default styles;
