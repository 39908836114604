// @ts-check
const safeGridBorder = '1px dashed white';

/** @param {number} spacing */
const getHorizontalGridSpacing = (spacing) => ({
  bottom: spacing,
  top: spacing,
});

/** @param {number} spacing */
const getVerticalGridSpacing = (spacing) => ({
  left: spacing,
  right: spacing,
});

/**
 * @param {number} baseMargin
 * @param {number} imageSize
 * @param {number} baseImageSize
 */
const calculateDynamicGridSpacing = (baseMargin, imageSize, baseImageSize) => Math.floor((baseMargin * imageSize) / baseImageSize);

/** @type {Styles<'horizontalGrid' | 'image' | 'verticalGrid' | 'placeholderImg' | 'imgContent', | 'boxContent', { photoType: PhotoType, imageWidth: number, imageHeight: number }>} */
const styles = {
  horizontalGrid: ({ photoType, imageHeight }) => {
    let baseStyle = {};
    const base = 234;

    switch (photoType) {
      case 'dish':
        baseStyle = getHorizontalGridSpacing(calculateDynamicGridSpacing(15, imageHeight, base));
        break;

      case 'hero':
        baseStyle = getHorizontalGridSpacing(calculateDynamicGridSpacing(68, imageHeight, base));
        break;

      case 'listing':
        baseStyle = getHorizontalGridSpacing(calculateDynamicGridSpacing(52, imageHeight, base));
        break;

      default:
        break;
    }

    return {
      ...baseStyle,
      borderBottom: safeGridBorder,
      borderTop: safeGridBorder,
      left: 0,
      position: 'absolute',
      width: '100%',
      zIndex:10
    };
  },
  image: {
    width: '100%',
    position:'relative',
    zIndex:9
  },
  imgContent:{
  },
  placeholderImg:{
    position:'absolute',
    left:'50%',
    top:'50%',
    zIndex:8,
    transform:'translate(-50%,-50%)'
  },  

  verticalGrid: ({ photoType, imageWidth }) => {
    let baseStyle = {};
    const base = 320;


    switch (photoType) {
      case 'dish':
        baseStyle = getVerticalGridSpacing(calculateDynamicGridSpacing(52, imageWidth, base));
        break;

      case 'hero':
        return { display: 'none' };

      case 'listing':
        baseStyle = getVerticalGridSpacing(calculateDynamicGridSpacing(36, imageWidth, base));
        break;

      default:
        break;
    }

    return {
      ...baseStyle,
      borderLeft: safeGridBorder,
      borderRight: safeGridBorder,
      height: '100%',
      position: 'absolute',
      top: 0,
      zIndex:10
    };
  },
};

export default styles;
