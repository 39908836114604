import * as React from 'react';
import { useTheme, Grid } from '@material-ui/core';
import { EmptyStateMessage } from '../EmptyState/SubSectionEmptyState';

import {
  ChoiceGroupSectionWrapper,
  Wrapper,
  ChoiceWrapperGrid,
  ChoiceGridHeader,
  ActiveChoiceCheckBox,
  ChoiceGridText,
  ChoiceContainer,
  RowItemContainer,
  HeaderItemContainer,
  ChoiceGroupNameText,
} from './style';

const ChoiceGroupSection = ({
  t,
  variants,
  choiceGroups,
  variantChoiceGroupAssociation,
  handleChoiceGroupSelect,
  selectingDisabled,
  currentLanguage,
}) => {
  const theme = useTheme();

  const _getCommaSepratedChoice = (choices) => {
    return choices.map((choice, index) => {
      return (
        <span key={choice.id}>
          {choice.title[currentLanguage]}
          {index < choices.length - 1 ? ', ' : ''}
        </span>
      );
    });
  };
  const _getCommaSepratedChoiceString = (choices) => {
    let choiceString = '';
    choices.forEach((choice, index) => {
      choiceString =
        choiceString + choice.title[currentLanguage] + (index < choices.length - 1 ? ', ' : '');
    });
    return choiceString;
  };

  const _isChoiceGroupSelected = (choiceGroupId, variantId, variantIndex) => {
    // show selected if newly added or already present
    if (variantChoiceGroupAssociation[variantId]?.deletedChoiceGroups[choiceGroupId]) {
      return false;
    }
    return (
      variantChoiceGroupAssociation[variantId]?.addedChoiceGroups[choiceGroupId] ||
      variants[variantIndex].choiceGroupIds?.includes(choiceGroupId)
    );
  };

  return (
    <Wrapper>
      {variants.map(({ id: variantId, title }, variantIndex) => {
        return (
          <ChoiceGroupSectionWrapper
            key={variantId}
            title={
              t('menu_management_pandora.choice_groups') +
              (title && title.hasOwnProperty(currentLanguage) && title[currentLanguage]
                ? ` - ${title[currentLanguage]}`
                : '')
            }
          >
            {choiceGroups.length > 0 ? (
              <ChoiceWrapperGrid theme={theme}>
                <ChoiceContainer>
                  <HeaderItemContainer>
                    <Grid container item xs={12}>
                      <ChoiceGridHeader item xs={4}>
                        {t('menu_management_pandora.prompt_to_customer')}
                      </ChoiceGridHeader>
                      <ChoiceGridHeader item xs={8}>
                        {t('menu_management_pandora.choices')}
                      </ChoiceGridHeader>
                    </Grid>
                  </HeaderItemContainer>
                </ChoiceContainer>
                <Grid container>
                  {choiceGroups.map((choiceGroup, choiceGroupIndex) => (
                    <ChoiceContainer key={choiceGroup.id}>
                      <ActiveChoiceCheckBox
                        checked={_isChoiceGroupSelected(choiceGroup.id, variantId, variantIndex)}
                        name="choiceGroupCheck"
                        onChange={(e) => {
                          handleChoiceGroupSelect(e, choiceGroupIndex, variantIndex);
                        }}
                        disabled={selectingDisabled}
                      />
                      <RowItemContainer>
                        <Grid container item xs={12}>
                          <ChoiceGroupNameText
                            item
                            xs={4}
                            title={choiceGroup.title?.[currentLanguage] || ''}
                          >
                            {choiceGroup.title[currentLanguage]}
                          </ChoiceGroupNameText>
                          <ChoiceGridText
                            item
                            xs={8}
                            title={_getCommaSepratedChoiceString(choiceGroup.choices)}
                          >
                            {_getCommaSepratedChoice(choiceGroup.choices)}
                          </ChoiceGridText>
                        </Grid>
                      </RowItemContainer>
                    </ChoiceContainer>
                  ))}
                </Grid>
              </ChoiceWrapperGrid>
            ) : (
              <EmptyStateMessage>
                {t('menu_management_pandora.item_choice_group_empty_state')}
              </EmptyStateMessage>
            )}
          </ChoiceGroupSectionWrapper>
        );
      })}
    </Wrapper>
  );
};

export default ChoiceGroupSection;
