import React from 'react';
import { Box, TextField } from '@material-ui/core';
import { StyledAutocomplete } from '../styles';
import CheckBoxOption from './CheckBoxOption';
import handleNotApplicableLogic from './handleNotApplicableLogic';
import { hasFieldError } from '../utils';
import { WarningsProps } from '../types';

const Warnings: React.FC<WarningsProps> = ({
  t,
  options = [],
  handleOnChange,
  value,
  errors,
  //isGermanVendor,
}) => {
  const warningsText = t('menu_management_pandora.nutrition_information_warnings');

  const hasError = React.useMemo(() => hasFieldError('warnings', errors), [errors]);

  const onChange = (_e, value) => {
    const transformedValue = handleNotApplicableLogic(options, value);
    handleOnChange(transformedValue);
  };

  return (
    <Box mb={3}>
      <StyledAutocomplete
        data-testid="nutrition_information_warnings_input"
        multiple
        options={options}
        getOptionLabel={(option) => option}
        disableCloseOnSelect
        onChange={onChange}
        renderOption={(option, { selected }) => (
          <CheckBoxOption option={option} selected={selected} />
        )}
        renderInput={(params) => (
          <TextField {...params} error={hasError} variant="standard" label={warningsText} />
        )}
        value={value}
      />
    </Box>
  );
};

export default Warnings;
