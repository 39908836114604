import escaperegexp from 'lodash.escaperegexp';
import { GenerateOutput, Rule, RuleInfo, RuleConfig, RuleType, ValidateResult } from './types';

function createRule(ruleInfo: RuleInfo): Rule | undefined {
  if (ruleInfo.disabled) {
    return;
  }

  if (ruleInfo.type === RuleType.Regex) {
    return new RegexRule(ruleInfo.name, ruleInfo.value);
  }

  if (ruleInfo.type === RuleType.Wordlist) {
    const words = ruleInfo.value.split(',').map((word) => word.trim());
    return new RestrictedWordsRule(ruleInfo.name, words);
  }

  console.warn(`Received a rule with an unknown type: ${ruleInfo.type}`);
}

export function generateRules(config: RuleConfig): GenerateOutput {
  const generatedRules: { [name: string]: Rule } = {};

  if (config.rules) {
    Object.entries(config.rules).forEach(([name, ruleInfo]) => {
      const rule = createRule(ruleInfo);
      if (!rule) {
        return;
      }

      generatedRules[name] = rule;
    });
  }
  const rules: GenerateOutput = {};

  Object.entries(config.fields).forEach(([name, ruleInfo]) => {
    const rulesForField = [];
    ruleInfo.forEach((ruleInfo) => {
      if (typeof ruleInfo === 'string') {
        if (!generatedRules[ruleInfo]) {
          console.warn(`Received a rule with an unknown name: ${ruleInfo}`);
          return;
        }

        rulesForField.push(generatedRules[ruleInfo]);
        return;
      }

      const rule = createRule(ruleInfo);
      if (!rule) {
        return;
      }
      rulesForField.push(rule);
    });

    rules[name] = rulesForField;
  });

  return rules;
}

class RegexRule implements Rule {
  private regex: RegExp;
  name: string;

  constructor(name, regex: string) {
    this.name = name;
    this.regex = new RegExp(regex, 'i');
  }

  validate(input: string) {
    const result: ValidateResult = {
      valid: true,
    };
    const match = input.match(this.regex);

    if (match) {
      result.valid = false;

      // Since we are not using the g (global) flag on regex this array will only contain one entry.
      // containing the first match
      result.value = match[0];
    }

    return result;
  }
}

class RestrictedWordsRule implements Rule {
  private regex: RegExp;
  private wordBoundary = '(?:^|$|\\s|#|\\*|-|_|\\+|=)'; // \b is ascii only in JS
  name: string;

  constructor(name, words: string[]) {
    this.name = name;
    this.regex = new RegExp(
      `${this.wordBoundary}(${words.map((w) => escaperegexp(w)).join('|')})${this.wordBoundary}`,
      'i'
    );
  }

  validate(input: string) {
    const result: ValidateResult = {
      valid: true,
    };
    const match = input.match(this.regex);

    if (match) {
      result.valid = false;
      result.value = match[1]; // the restricted word is in the first caputure group
    }

    return result;
  }
}
