import styled from 'styled-components';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IconButton, Grid, Box } from '@material-ui/core';
import PriceInput from '../PriceInput';
import { ThemeProps } from '../../utilities/interface';
import Button from '../Button/index';
import TextField from '../TextField';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { SectionHeaderV2 } from '../Section/style';

export const ChoicesSectionWrapper = styled.div`
  margin-top: ${(props: ThemeProps) => `${props.theme.spacing(2)}px`};
`;

export const HelperTextWrapper = styled.div`
  color: rgba(0, 0, 0, 0.54);
  padding-left: ${(props: ThemeProps) => `${props.theme.spacing(3)}px`};
  padding-right: ${(props: ThemeProps) => `${props.theme.spacing(3)}px`};
  padding-bottom: ${(props: ThemeProps) => `${props.theme.spacing(3)}px`};
  font-size: 16px;
  line-height: 22px;
  border-bottom: ${({ isNutritionsInformationEnabled }) =>
    isNutritionsInformationEnabled ? 0 : '1px solid rgb(215, 219, 231)'};

  ${(props: ThemeProps) => props.theme.breakpoints.down('sm')} {
    padding-left: ${(props: ThemeProps) => `${props.theme.spacing(2)}px`};
  }
`;

export const ChoiceWrapper = styled(Grid)`
  :hover {
    background-color: #f9fbff;
  }
  align-items: center;
  padding-left: ${(props: ThemeProps) => `${props.theme.spacing(3)}px`};
  padding-right: ${(props: ThemeProps) => `${props.theme.spacing(3)}px`};
  ${(props: ThemeProps) => props.theme.breakpoints.down('sm')} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const ChoiceRowInnerWrapper = styled(Box)`
  padding-bottom: ${(props: ThemeProps) => `${props.theme.spacing(3)}px`};
  padding-top: ${(props: ThemeProps) => `${props.theme.spacing(3)}px`};
  padding-left: ${(props) => (props.isDraggable ? '24px' : '64px')};
  padding-right: 11px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  user-select: none;
  margin-bottom: 0px;
  ${(props: ThemeProps) => props.theme.breakpoints.down('sm')} {
    padding-left: ${(props) => (props.isDraggable ? '16px' : '44px')};
    padding-right: ${(props: ThemeProps) => `${props.theme.spacing(0.5)}px`};
  }
`;

export const ChoiceRowOuterWrapper = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  flex-direction: column;
  border-bottom: 1px solid rgb(215, 219, 231);
  :hover {
    background-color: #f9fbff;
  }
`;

export const ChoiceContainer = styled(Grid)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
`;

export const ChoiceAutoComplete = styled(Autocomplete)`
  flex: 0 1 35%;
  .MuiAutocomplete-endAdornment {
    top: auto;
  }

  ${(props: ThemeProps) => props.theme.breakpoints.down('sm')} {
    flex: 1 1 100%;
    margin-right: 0;
  }
`;

export const ChoiceAutoCompleteWithSuggestion = styled(TextField)`
  flex: 0 1 35%;
  .MuiAutocomplete-endAdornment {
    top: auto;
  }

  ${(props: ThemeProps) => props.theme.breakpoints.down('sm')} {
    flex: 1 1 100%;
    margin-right: 0;
  }
`;

export const DeleteIcon = styled(IconButton)``;

export const ChoicePriceInput = styled(PriceInput)`
  && {
    flex: 0 1 104px;

    ${(props: ThemeProps) => props.theme.breakpoints.down('sm')} {
      flex: 1 1 100%;
    }
  }
`;

export const ChoiceRemoteCode = styled(TextField)`
  && {
    ${(props: ThemeProps) => props.theme.breakpoints.down('sm')} {
      flex: 1 1 100%;
    }
  }

  &.MuiSkeleton-root {
    height: 45.6px;
  }
`;

// Same style with Remote Code field
export const ChoiceTax = styled(ChoiceRemoteCode)``;

export const DraggableIconContainer = styled(Box)`
  width: 24px;
  margin-right: ${(props: ThemeProps) => `${props.theme.spacing(2)}px`};

  ${(props: ThemeProps) => props.theme.breakpoints.down('sm')} {
    margin-right: ${(props: ThemeProps) => `${props.theme.spacing(1)}px`};
  }
`;

export const AvailabilityBox = styled(Box)`
  flex: 1 0 100%;
  margin-top: 32px;
  margin-left: -2px;
`;

export const AddChoiceButton = styled(Button)`
  && {
    font-size: 16px;
    line-height: 25px;
    color: rgb(30, 145, 214);
    margin-left: ${(props: ThemeProps) => `${props.theme.spacing(2.5)}px`};
    margin-top: ${(props: ThemeProps) => `${props.theme.spacing(3)}px`};
    margin-bottom: ${(props: ThemeProps) => `${props.theme.spacing(3)}px`};
  }
  .MuiSvgIcon-root {
    margin-right: ${(props: ThemeProps) => `${props.theme.spacing(1)}px`};
  }
`;

export const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    box-shadow: none;
  }

  &.MuiAccordion-root:before {
    display: none;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    padding: 0px 64px;

    ${(props: ThemeProps) => props.theme.breakpoints.down('sm')} {
      padding: ${(props: ThemeProps) => `0px ${props.theme.spacing(3)}px`};
    }
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    padding: 0px 64px;
    display: flex;
    flex-direction: column;

    ${(props: ThemeProps) => props.theme.breakpoints.down('sm')} {
      padding: ${(props: ThemeProps) => `0px ${props.theme.spacing(3)}px`};
    }
  }
`;

export const FormPrePackageDiv = styled(Box)`
  margin-bottom: ${(props: ThemeProps) => `${props.theme.spacing(2)}px`};
`;

export const PrePackageCheckbox = styled(Box)`
  color: rgba(0, 0, 0, 0.87),
  height: 5px,
  width: 187px,
  line-height: 25px,
`;

export const PrePackageFieldWrapper = styled.div`
  width: 100%;
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
  font-family: Roboto;
  font-weight: normal;
  letter-spacing: 0.2px;
  line-height: 16px;
`;

export const SectionHeader = styled(SectionHeaderV2)`
  padding: ${(props: ThemeProps) => `${props.theme.spacing(2.5)}px 0px `};
`;

export const FormAgeRestriction = styled(FormPrePackageDiv)``;

export const AgeRestrictionCheckbox = styled(PrePackageCheckbox)``;
