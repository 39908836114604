/* istanbul ignore file */
// @ts-check
import authInterceptor from './authInterceptor';
import {
  choiceGroupMissingFieldRequestInterceptor,
  choiceGroupMissingFieldResponseInterceptor,
} from './missingFieldInterceptor';

/** @param {import('axios').AxiosInstance} axios */
export function applyInterceptors(axios) {
  axios.interceptors.request.use(authInterceptor);
  axios.interceptors.request.use(choiceGroupMissingFieldRequestInterceptor);
  axios.interceptors.response.use(choiceGroupMissingFieldResponseInterceptor);
}
