import { StatusType } from '../../types';
import styled from 'styled-components';
import { StatusTagProps } from './types';

export const StatubsBoxWrapper = styled.div<StatusTagProps>`
  display: inline-block;
  line-height: 16px;
  border-radius: 12px;
  padding: 4px 12px 4px 12px;
  font-size: 13px;
  box-sizing: border-box;
  height: 24px;
  background: ${(props) => {
    switch (props.type) {
      case StatusType.Pending:
        return '#faeed2';
      case StatusType.RequestPending:
        return '#faeed2';
      case StatusType.Error:
      case StatusType.Rejected:
        return '#F2E5E1';
      case StatusType.Draft:
        return '#EDF1F2';
      default:
        return '#DBEDC5';
    }
  }};
  color: ${() => {
    return '#1e1e1e';
  }};
  text-align: center;
`;
