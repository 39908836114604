import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { ChoiceContainer } from '../ChoiceGroupList/style';

export const ProductChoiceAvailabilityContainer = styled(Box)`
  display: flex;
  width: 100%;
  border: 1px solid #dddddd;
  border-bottom: ${(props) => (props.choiceLength > 0 ? `0px` : `1px`)};
  border-top: 0px;
  justify-content: center;
  padding: 16px 0px;
  background-color: #d0d3df73;
  color: grey;
`;

export const ChoiceTitleContainer = styled(ChoiceContainer)`
  justify-content: space-between;
`;

export const ChoiceAvailabilityContainer = styled(Box)`
  display: flex;
  padding: 12px 24px;
  border: 1px solid #dddddd;
  justify-content: space-between;
  align-items: baseline

  width: 100%;
  & + & {
    border-top: 0px;
  }
`;

export const AvailabilityLabel = styled(FormControlLabel)`
  width: 100%;
  & .MuiTypography-body1 {
    font-size: 16px;
  }

  & .MuiFormControlLabel-label {
    padding-right: 12px;
  }
`;

export const ItemBox = styled(Box)`
  display: flex;
  column-gap: 20px;
  align-items: center;
`;
