import { fetchMasterCategories as fetchMasterCategoriesAPI } from './api';

const masterCategoriesRequest = () => ({ type: 'FETCH_MASTER_CATEGORIES' });
const masterCategoriesSuccess = (data) => ({ type: 'FETCH_MASTER_CATEGORIES_SUCCESS', data });
const masterCategoriesFailure = (error) => ({
  type: 'FETCH_MASTER_CATEGORIES_FAILURE',
  data: error,
});

export const fetchMasterCategories = ({ country, status }) => async (dispatch) => {
  dispatch(masterCategoriesRequest());

  try {
    const categories = await fetchMasterCategoriesAPI({ country, status });
    dispatch(masterCategoriesSuccess(categories));
  } catch (error) {
    dispatch(masterCategoriesFailure(error));
  }
};
