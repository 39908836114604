import { Grid, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { ChangeEvent, useState } from 'react';
import { ChoiceBarcodeSearchProps } from './types';
import { BarcodeTextField, ChoiceBarcodeSearchContainer, SearchButton } from './style';

const ChoiceBarcodeSearch: React.FC<ChoiceBarcodeSearchProps> = ({
  errors,
  index,
  onChoiceSearch,
  t,
}) => {
  const theme = useTheme();
  const [searchButtonDisabled, setSearchButtonDisabled] = useState(false);
  const [barcode, setBarcode] = useState('');

  const handleBarcodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBarcode(e.target.value);
  };

  const handleBarcodeSearch = () => {
    setSearchButtonDisabled(true);
    onChoiceSearch(barcode, index, () => {
      setSearchButtonDisabled(false);
    });
  };

  return (
    <ChoiceBarcodeSearchContainer container>
      <Grid item xs={12} sm={3}>
        <BarcodeTextField
          data-testid={'choice-barcode-text'}
          label={t('menu_management_pandora.barcode')}
          margin="normal"
          theme={theme}
          onChange={handleBarcodeChange}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SearchButton
          color="primary"
          variant="contained"
          data-testid="choice_barcode_search_button"
          onClick={handleBarcodeSearch}
          disabled={searchButtonDisabled}
        >
          {t('menu_management_pandora.search_barcode')}
        </SearchButton>
      </Grid>
      {errors[`rows.${index}_choice_barcode`] && (
        <Grid item xs={12}>
          <Alert severity="error">{errors[`rows.${index}_choice_barcode`]}</Alert>
        </Grid>
      )}
    </ChoiceBarcodeSearchContainer>
  );
};

export default ChoiceBarcodeSearch;
