import styled from 'styled-components';

export const ModalBodyContainer = styled.div`
  background: #edf0f7;
  min-width: 700px;
  max-width: 700px;
  height: 2000px;
  max-height: 100%;
  float: right;
  overflow: scroll;
  @media (max-width: 700px) {
    width: 100%;
    min-width: 100%;
  }
`;

export const ContentContainer = styled.div`
  padding: 0px;
`;
