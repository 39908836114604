export const filterToggleInitialState = {
  toggle: false,
};

export const filterModalReducer = (state = filterToggleInitialState, action) => {
  if (action.type === 'TOGGLE_FILTER_MODAL') {
    return { ...state, toggle: !state.toggle };
  }
  return state;
};
