import { Box, Button, Drawer, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { TabNavbar } from './TabNavbar';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import FilterList from '@material-ui/icons/FilterList';
import Close from '@material-ui/icons/Close';
import { DateTimeRangePicker } from 'components';
import { drawerFilters } from './styles';
import Search from 'App/components/Search';
import { useMemo } from 'react';
import moment from 'moment';
import Chip from '@material-ui/core/Chip';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
const useStyles = makeStyles(drawerFilters);

const LayoutOne = ({ children }) => {
  const [filterCount, setFilterCount] = useState(0);
  const [isCheckedAssignToMe, setIsCheckedAssignToMe] = useState(false);
  const filterModalState = useSelector((state: any) => state.filterModalState);
  const filterState = useSelector((state: any) => state.filterState);
  const navigationState = useSelector((state: any) => state.navigationState);
  const fwfState = useSelector((state: any) => state.fwfState);
  const fwfFlags = fwfState.flags;

  const dispatch = useDispatch();
  const classes = useStyles();
  const startDate = new Date().setHours(0, 0, 0, 0);
  const sixMonths = moment().subtract(6, 'months').add(1, 'day');
  useEffect(() => {
    let count = 0;
    if (filterState.payload.input.search) {
      count++;
    }
    if (filterState.payload.input.timeTo) {
      count++;
    }
    if (filterState.payload.input.timeFrom) {
      count++;
    }
    if (filterState.payload.requestIdSuffix) {
      count++;
    }
    setFilterCount(count);
  }, [filterState]);

  const onChangeVendorCode = (vendorCode) => {
    dispatch({ type: 'SET_FILTER_VENDOR_CODE', vendorCode: vendorCode });
  };
  const onChangeRequestId = (requestId) => {
    dispatch({ type: 'SET_FILTER_REQUEST_ID', requestId: requestId });
  };
  const isMinDateSixMonthsDisabled = useMemo(() => {
    let index = navigationState.topNavbarPages.findIndex((it) => it.selected);
    if (index !== -1) {
      return navigationState.topNavbarPages[index].id === 'history-management' ? true : false;
    }
    return false;
  }, [navigationState.topNavbarPages]);

  const onChangeDateTime = ({ type, date }) => {
    if (type === 'SET_START_DATE' && !filterState.draft.endDateTime.moment) {
      dispatch({
        type: 'SET_FILTER_END_DATE_TIME',
        endDateTime: moment(),
        unix: moment().valueOf(),
      });
    }

    switch (type) {
      case 'SET_START_DATE':
        dispatch({
          type: 'SET_FILTER_START_DATE_TIME',
          startDateTime: date,
          unix: moment(date).valueOf(),
        });
        break;
      case 'SET_END_DATE':
        dispatch({
          type: 'SET_FILTER_END_DATE_TIME',
          endDateTime: date,
          unix: moment(date).valueOf(),
        });
        break;
    }
  };
  const onClickHandleApply = () => {
    dispatch({ type: 'SET_FILTER_APPLY_TO_PAYLOAD' });
  };
  const onClickHandleClearAllFilters = () => {
    dispatch({ type: 'SET_FILTER_ALL_RESET' });
  };

  const pendingRequestPages = navigationState?.topNavbarPages.find(
    (menu) => menu.id === 'menu-management'
  );
  const pendingMenuRequestPage = pendingRequestPages?.children.find(
    (page) => page.id === 'menu-management'
  );
  const isMenuPage = useMemo(
    () => window.location.pathname === pendingMenuRequestPage?.path,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.pathname]
  );

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <div style={{ position: 'absolute', top: 0, bottom: '88px', overflowY: 'auto' }}>
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={12}>
            <div className={classes.closeDrawer} onClick={toggleDrawer()}>
              <Close />
            </div>
            <h3 className={classes.filtersTitle}>Filters</h3>
            <h6 className={classes.filterDescription}>Filter your views</h6>
          </Grid>

          <Grid item xs={12}>
            <Box>
              {isMenuPage && (
                <Search
                  value={filterState.draft.requestId}
                  onChange={onChangeRequestId}
                  type="requestId"
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Search
                value={filterState.draft.vendorCode}
                onChange={onChangeVendorCode}
                type="vendorCode"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {fwfFlags.fwfDateTimeFilter.variation && (
              <Box>
                <DateTimeRangePicker
                  startLabel={`Start date / time`}
                  endLabel={`End date / time`}
                  dateRange={[
                    filterState.draft.startDateTime.moment,
                    filterState.draft.endDateTime.moment,
                  ]}
                  focusedDateRange={[startDate, new Date()]}
                  onChange={onChangeDateTime}
                  variant={'inline'}
                  separatorLabel={'to'}
                  {...(isMinDateSixMonthsDisabled ? { minDate: sixMonths } : {})}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
      <Box
        boxShadow={4}
        style={{
          height: '88px',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Button onClick={onClickHandleClearAllFilters} className={classes.resetFilter} size="large">
          Clear All
        </Button>
        <Button
          size="large"
          className={classes.applyButton}
          variant="contained"
          color="primary"
          onClick={onClickHandleApply}
        >
          Apply
        </Button>
      </Box>
    </div>
  );

  const toggleDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch({ type: 'TOGGLE_FILTER_MODAL' });
  };

  const deleteChip = (type) => {
    switch (type) {
      case 'timeFrom':
        filterState.draft.startDateTime = {
          ...filterState.draft.startDateTime,
          moment: null,
          unix: null,
        };
        break;
      case 'timeTo':
        filterState.draft.endDateTime = {
          ...filterState.draft.endDateTime,
          moment: null,
          unix: null,
        };
        break;
      case 'search':
        filterState.draft.vendorCode = null;
        break;
      case 'requestIdSuffix':
        filterState.draft.requestId = null;
        break;
    }

    dispatch({ type: 'SET_FILTER_APPLY_TO_PAYLOAD' });
  };

  const handleAssignToMeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedAssignToMe(event.target.checked);
  };

  useEffect(() => {
    dispatch({ type: 'SET_FILTER_ASSIGN_TO_ME', assignedToMe: isCheckedAssignToMe });
    dispatch({ type: 'SET_FILTER_APPLY_TO_PAYLOAD' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckedAssignToMe]);

  const filterTitleMap = (type) => {
    switch (type) {
      case 'timeFrom':
        return 'Start Date';
      case 'timeTo':
        return 'End Date';
      case 'search':
        return 'Vendor Code';
    }
  };

  return (
    <div>
      <Grid
        container
        style={{
          textAlign: 'center',
          width: 'auto',
          padding: '23px 23px 20px 23px',
        }}
      >
        <Grid item sm={12}>
          <Box display="flex" justifyContent="flex-start">
            {Object.keys(filterState.payload.input).map((filter, i) => {
              let filterValue = filterState.payload.input[filter];
              if (filterValue && (filter === 'timeFrom' || filter === 'timeTo')) {
                filterValue = moment(filterValue).format('DD MMMM YYYY HH:mm:ss');
              }
              const filterTitle = filterTitleMap(filter);
              if (!!filterValue) {
                return (
                  <Chip
                    label={filterTitle + ': ' + filterValue}
                    onDelete={() => deleteChip(filter)}
                    className={classes.chip}
                  />
                );
              }
              return '';
            })}
            {filterState.payload.requestIdSuffix && (
              <Chip
                label={'Request ID: ' + filterState.payload.requestIdSuffix}
                onDelete={() => deleteChip('requestIdSuffix')}
                className={classes.chip}
              />
            )}
          </Box>
        </Grid>
        <Grid item sm={6}>
          <Box display="flex" justifyContent="flex-start">
            <TabNavbar />
          </Box>
        </Grid>
        <Grid item sm={6}>
          <Box display="flex" justifyContent="flex-end">
            {isMenuPage && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={isCheckedAssignToMe} onChange={handleAssignToMeChange} />
                  }
                  label="Assign to me"
                />
              </FormGroup>
            )}
            <Button
              onClick={toggleDrawer()}
              variant="contained"
              className={`${classes.filterButton}  ${filterCount > 0 && 'active'}`}
            >
              <FilterList /> Filter
              <span className="filterCount">{filterCount}</span>
            </Button>
          </Box>
        </Grid>
      </Grid>

      <React.Fragment>
        <Drawer
          style={{ position: 'relative' }}
          anchor={'right'}
          open={filterModalState.toggle}
          onClose={toggleDrawer()}
        >
          {list()}
        </Drawer>
      </React.Fragment>

      {children}
    </div>
  );
};
const LayoutTwo = ({ children }) => <>{children}</>;

export { LayoutOne, LayoutTwo };
