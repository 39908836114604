import React, { ChangeEvent, useMemo, useState } from 'react';
import Section from '../Section';
import { FormHelperText, Select, InputLabel, Grid, FormControl, Checkbox } from '@material-ui/core';
import { ChoiceGroupDetailSectionProps } from './types';
import { NormalTextField, MinMaxMenuItem } from './style';
import { Language } from '../../types';
import { IPortalSdk } from '@deliveryhero/vendor-portal-sdk';
const getLabel = (translationKey: string, language: Language, t: IPortalSdk['t']) => {
  return {
    // eslint-disable-next-line no-useless-computed-key
    ['menu_management_pandora.topping_template_name']: () => {
      if (language.required) {
        return `${t(translationKey)}`;
      }
      return `${t(translationKey)} - ${language.symbol} (${t('menu_management_pandora.optional')})`;
    },
  }[translationKey]();
};
const ChoiceGroupDetail: React.FC<ChoiceGroupDetailSectionProps> = ({
  t,
  title,
  defaultLanguage,
  languages,
  handleTitleChange,
  quantity,
  setQuantity,
  numberOfChoices,
  showOncePerOption = false,
  handleMaximumChange,
  handleMinimumChange,
  isMinMaxNewBehaviour,
  isTextFieldDisable,
  isMinmaxDisable,
  errors,
  disabled = false,
  rules,
  onValidateWith,
  ...props
}) => {
  const [isOncePerOption, setIsOncePerOption] = useState(false);
  const minimumChoicesOptions = useMemo(() => {
    let minimumChoicesOptions: JSX.Element[] = [];
    for (let i = 0; i < numberOfChoices + 1; i++) {
      minimumChoicesOptions.push(
        <MinMaxMenuItem value={i} key={i} data-testid="choiceGroup_minimum_options" data-value={i}>
          {i === 0 ? `0 - (${t('menu_management_pandora.optional')})` : i}
        </MinMaxMenuItem>
      );
    }
    return minimumChoicesOptions;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfChoices]);
  const onValidateTitle = onValidateWith?.(`title.${defaultLanguage.code}`);
  const maximumChoicesOptions = useMemo(() => {
    let maximumChoicesOptions: JSX.Element[] = [];
    for (let i = quantity.minimum || 1; i < (numberOfChoices || 1) + 1; i++) {
      maximumChoicesOptions.push(
        <MinMaxMenuItem value={i} key={i} data-testid="choiceGroup_maximum_options" data-value={i}>
          {i === quantity.minimum ? `${i} - (${t('menu_management_pandora.default')})` : i}
        </MinMaxMenuItem>
      );
    }
    return maximumChoicesOptions;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity.minimum, numberOfChoices]);
  const handleOncePerOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsOncePerOption(e.target.checked);
    setQuantity && setQuantity(1, 1);
  };
  return (
    <Section {...props} title={t('menu_management_pandora.choice_group_details')}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <NormalTextField
            getSuggestionList={props.getSuggestionList}
            onSuggestionSelect={props.onSuggestionSelect}
            helperText={
              errors[`title.${defaultLanguage.code}`] ||
              t('menu_management_pandora.prompt_to_customer_help')
            }
            language={defaultLanguage.code}
            label={getLabel('menu_management_pandora.topping_template_name', defaultLanguage, t)}
            value={title[defaultLanguage.code]}
            onChange={(e) => handleTitleChange(e, defaultLanguage.code)}
            disabled={disabled || isTextFieldDisable}
            required={defaultLanguage.required}
            error={errors[`title.${defaultLanguage.code}`]?.length > 0}
            rules={rules}
            onValidate={onValidateTitle}
            inputProps={{
              'data-testid': `choiceGroup_title_${defaultLanguage.code}`,
              'data-value': title[defaultLanguage.code],
            }}
            multiline
            fullWidth
            direction={defaultLanguage.rtl ? 'rtl' : 'ltr'}
          />
        </Grid>
        {languages
          .filter((language) => defaultLanguage.code !== language.code)
          .map((language) => {
            const onValidateOtherTitle = onValidateWith?.(`title.${language.code}`);
            return (
              <Grid item xs={12} sm={6} key={language.code}>
                <NormalTextField
                  language={language.code}
                  getSuggestionList={props.getSuggestionList}
                  onSuggestionSelect={props.onSuggestionSelect}
                  label={getLabel('menu_management_pandora.topping_template_name', language, t)}
                  value={title[language.code]}
                  onChange={(e) => handleTitleChange(e, language.code)}
                  disabled={disabled || isTextFieldDisable}
                  required={language.required}
                  rules={rules}
                  error={errors[`title.${language.code}`]}
                  helperText={errors[`title.${language.code}`]}
                  onValidate={onValidateOtherTitle}
                  inputProps={{
                    'data-testid': `choiceGroup_title_${language.code}`,
                    'data-value': title[language.code],
                  }}
                  multiline
                  fullWidth
                  direction={language.rtl ? 'rtl' : 'ltr'}
                />
              </Grid>
            );
          })}
        {showOncePerOption && (
          <Grid item xs={12} style={{ padding: '0' }}>
            <Checkbox onChange={handleOncePerOptionChange} data-testid="oncePerOptionCheckbox" />
            <span>{t('menu_management_pandora.once_per_option')}</span>
          </Grid>
        )}
        {isOncePerOption === false && !isMinMaxNewBehaviour && (
          <>
            <Grid item xs={12} sm={6}>
              <FormControl disabled={isMinmaxDisable} fullWidth>
                <InputLabel>{t('menu_management_pandora.minimum_number_of_choices')}</InputLabel>
                <Select
                  value={quantity.minimum}
                  onChange={handleMinimumChange}
                  renderValue={(value): any => value}
                  disabled={disabled}
                  data-testid={'choiceGroup_minimum'}
                  data-value={quantity.minimum}
                  fullWidth
                >
                  {minimumChoicesOptions}
                </Select>
                <FormHelperText>
                  {t('menu_management_pandora.minimum_number_of_choices_help')}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl disabled={isMinmaxDisable} fullWidth>
                <InputLabel>{t('menu_management_pandora.maximum_number_of_choices')}</InputLabel>
                <Select
                  value={quantity.maximum}
                  onChange={handleMaximumChange}
                  renderValue={(value): any => value}
                  disabled={disabled}
                  data-testid={'choiceGroup_maximum'}
                  data-value={quantity.minimum}
                  fullWidth
                >
                  {maximumChoicesOptions}
                </Select>
                <FormHelperText>
                  {t('menu_management_pandora.maximum_number_of_choices_help')}
                </FormHelperText>
              </FormControl>
            </Grid>
          </>
        )}
        {/* isMinMaxNewBehaviour==true */}
        {isOncePerOption === false && isMinMaxNewBehaviour && (
          <>
            <Grid item xs={12} sm={6}>
              <FormControl disabled={isMinmaxDisable} fullWidth>
                <NormalTextField
                  value={quantity.minimum}
                  label={t('menu_management_pandora.minimum_number_of_choices')}
                  onChange={handleMinimumChange}
                  disabled={disabled || isTextFieldDisable}
                  required={true}
                  fullWidth
                  direction={defaultLanguage.rtl ? 'rtl' : 'ltr'}
                  type="text"
                  inputProps={{
                    pattern: '^[0-9]*$',
                  }}
                  data-testid={'choice_group_input_min'}
                />
                <FormHelperText>
                  {t('menu_management_pandora.minimum_number_of_choices_help')}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl disabled={isMinmaxDisable} fullWidth>
                <NormalTextField
                  value={quantity.maximum}
                  label={t('menu_management_pandora.maximum_number_of_choices')}
                  onChange={handleMaximumChange}
                  disabled={disabled || isTextFieldDisable}
                  required={true}
                  fullWidth
                  direction={defaultLanguage.rtl ? 'rtl' : 'ltr'}
                  type="text"
                  inputProps={{
                    pattern: '^[0-9]*$',
                  }}
                  data-testid={'choice_group_input_max'}
                />
                <FormHelperText>
                  {t('menu_management_pandora.maximum_number_of_choices_help')}
                </FormHelperText>
              </FormControl>
            </Grid>
          </>
        )}
        {/* isMinMaxNewBehaviour ENDS */}
      </Grid>
    </Section>
  );
};
export default ChoiceGroupDetail;