import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import style from './style';

const useStyles = makeStyles(style);

const ErrorScreen = ({ errors, testId, customText }: { errors: string[]; testId: string; customText: string }) => {
  const classes = useStyles();

  return (
    <div className={classes.centeredContainer} data-enzyme={testId} data-testid={testId}>
      <div style={{ textAlign: 'center' }}>
      <img alt="empty state" src="/assets/opps.png" width="auto" height="157" />
        <Typography variant={'h3'}>Something went wrong</Typography>
        <Typography variant={'h4'}>{customText && customText}</Typography>

        {errors.map((errorMessage, index) => (
          <Typography key={index} variant={'h4'}>
            {errorMessage}
          </Typography>
        ))}
      </div>
    </div>
  );
};

export default ErrorScreen;
