import styled from 'styled-components';
import Button from '../../Button';
import { NormalTextField } from '../../ChoiceGroupDetailSection/style';
import { Grid } from '@material-ui/core';
import { ThemeProps } from '../../../utilities/interface';

export const ChoiceBarcodeSearchContainer = styled(Grid)`
  padding-top: ${(props: ThemeProps) => `${props.theme.spacing(3)}px`};
  padding-left: ${(props) => (props.isDraggable ? '24px' : '64px')};
  padding-bottom: 0;
  padding-right: 11px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  user-select: none;
  margin-bottom: 0;
  ${(props: ThemeProps) => props.theme.breakpoints.down('sm')} {
    flex-flow: column;
    padding-left: ${(props) => (props.isDraggable ? '16px' : '44px')};
    padding-right: ${(props: ThemeProps) => `${props.theme.spacing(0.5)}px`};
  }
  ${(props: ThemeProps) => props.theme.breakpoints.down('xs')} {
    & > * {
      width: 100%;
    }
  }
`;

export const BarcodeTextField = styled(NormalTextField)`
  flex: 0 0 49%;
  label {
    text-transform: capitalize;
  }
`;

export const SearchButton = styled(Button)`
  margin: 24px 24px 12px 12px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin: 0px 0px 2px 0px;
  }
`;
