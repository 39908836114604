import * as React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles";

const useStyles = makeStyles(styles);

const getLabel = type => {
  switch (type) {
    case "rejected":
    case "reject":
      return "Rejected";
    case "pending":
      return "Pending";
    default:
      return "Approved";
  }
};

const StatusBox = ({ type }) => {
  const classes = useStyles({ type });
  return (
    <Box
      type={type}
      color="white"
      textAlign="center"
      borderRadius={3}
      px={1}
      py={0.8}
      className={classes.root}
      data-enzyme = "statusBox"
    >
      {getLabel(type)}
    </Box>
  );
};

StatusBox.propTypes = {
  type: PropTypes.string.isRequired,
};
export default StatusBox;
