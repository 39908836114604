const translatedString = {
  'menu_management_pandora.choice_group_details': 'Choice Group Details',
  'menu_management_pandora.topping_template_name': 'Name',
  'menu_management_pandora.prompt_to_customer_help': 'Example: Choice of Sides, Choice of Extras',
  'menu_management_pandora.minimum_number_of_choices': 'Minimum Number of Choices',
  'menu_management_pandora.maximum_number_of_choices': 'Maximum Number of Choices',
  'menu_management_pandora.minimum_number_of_choices_help':
    "Enter '0' if this group is optional for your customers",
  'menu_management_pandora.maximum_number_of_choices_help':
    "Enter '1' if your customers can only choose 1",
  'menu_management_pandora.edit_topping_template': 'Edit choice group',
  'menu_management_pandora.save': 'Save',
  'menu_management_pandora.toppings': 'Choices',
  'menu_management_pandora.add_a_topping': 'Add a Choice',
  'menu_management_pandora.choices_section_help_text': 'Add choices for your customer to pick from',
  'menu_management_pandora.choices_section_help_text_v2':
    'Add choices for your customer to pick from',
  'menu_management_pandora.choice_name': 'Choice Name',
  'menu_management_pandora.price': 'Price',
  'menu_management_pandora.optional': 'optional',
  'menu_management_pandora.choice_groups': 'Choice Groups',
  'menu_management_pandora.choice_groups_eg': 'Example: Topping, Suger Level, Choice of Side',
  'menu_management_pandora.prompt_to_customer': 'Name',
  'menu_management_pandora.choices': 'Choices',
  'menu_management_pandora.number_of_choice': 'Number of Choices',
  'menu_management_pandora.prepackaged': 'This item is pre-packaged.',
  'menu_management_pandora.prepackaged_message':
    'A pre-packaged item is sealed in a package that you will sell directly to your customer. For example: a canned drink, jar of chilli sauce or bag of potato chips.',
  'menu_management_pandora.missing_master_category': "This category doesn't have master category",
  'menu_management_pandora.master_category': 'Item Master Category',
};

const Translate = (key) => {
  return translatedString[key] || key;
};

export default Translate;
