import React, { useState } from 'react';
import GoogleLogin from 'react-google-login';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import config from '../../config';
import styles from './styles';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import { ReactComponent as FOODPANDALOGO } from '../../assets/foodpanda.svg';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import Notification from '../../../App/components/Notification';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { Storage, storageKeys } from './../../../storage/storage';

const useStyles = makeStyles(styles);
const _classE2ELoginBox = 'loginBox';

const Login = () => {
  const location = useLocation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { redirect } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const validateToken = async (IdToken) => {
    const response = await axios.post(`${config.authorizerApi}/auth/login`, null, {
      headers: { Authorization: `Bearer ${IdToken}` },
    });
    return response;
  };

  const responseGoogle = async (response) => {
    const errorMessage = `Please try to login again. If you still can't login properly, please reach out to your team leads for permissions.`;

    if (response && response.profileObj && response.profileObj.email) {
      const id_token = response.tokenId;
      try {
        setLoading(true);
        const res = await validateToken(id_token);
        setLoading(false);

        if (res.data.access_token) {
          localStorage.setItem('email', response.profileObj.email);
          localStorage.setItem('googleId', response.profileObj.googleId);
          const cookieOptions = {
            expires: 7,
          };
          if (process.env.NODE_ENV !== 'test') {
            cookieOptions.sameSite = 'None';
            cookieOptions.secure = true;
          }
          Storage.set(storageKeys.ACCESS_TOKEN, res.data.access_token, cookieOptions);
          Cookies.set('refresh_token', res.data.refresh_token, cookieOptions);
          window.location.href = redirect ?? '/';
        }
      } catch (error) {
        setLoading(false);
        // We are using axios from default library instead of
        // utility/axios interceptor
        // because we don't want to redirect user to login
        // page again
        if (error.response.status === 403 || error.response.status === 401) {
          setError(errorMessage);
        }
      }
    } else {
      setError(errorMessage);
    }
  };

  const responseGoogleError = (error, details) => {
    setError('Something Went Wrong');
  };

  return (
    <div className={`${classes.container} ${_classE2ELoginBox}`}>
      <div className={classes.left}>
        <>
          <Box mt={3} display="flex" alignItems="center">
            <div className={classes.logoCircleBigWrapper}>
              <FOODPANDALOGO />
            </div>
            <Box ml={2}>
              <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: 20 }}>
                {' '}
                PandaPaw
              </Typography>
            </Box>
          </Box>
          <br />
          <Box mt={18} mb={6}>
            <Typography variant="h1" style={{ color: 'black', fontWeight: '300' }}>
              {' '}
              Vendor Content Quality Control
            </Typography>
          </Box>
          <div>
            <GoogleLogin
              clientId={config.services.google.clientId}
              buttonText="Login with SSO"
              onSuccess={responseGoogle}
              onFailure={responseGoogleError}
              cookiePolicy={'single_host_origin'}
              disabled={loading}
              render={(renderProps) => (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className={classes.loginButton}
                >
                  {loading ? 'Logging into PandaPaw' : 'Login with Google'}
                </Button>
              )}
            />
          </div>
        </>
      </div>
      <Notification
        open={error.length > 0}
        handleClose={() => setError('')}
        variant="error"
        message={error}
      />
      <div className={classes.right}>
        <img alt="logo" src="/assets/Agent.png" style={{ height: 550 }} />
      </div>
    </div>
  );
};

export default Login;
