import { ChoiceRemoteCodeProps } from './types';

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getShouldShowRemoteCode(remoteCode: ChoiceRemoteCodeProps) {
  if (!remoteCode) {
    return false;
  }

  const hasEmptyValueAfterFetch = !remoteCode.loading && remoteCode.value === undefined;
  if (hasEmptyValueAfterFetch) {
    return false;
  }

  return true;
}
