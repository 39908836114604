import { useState, useEffect, useCallback, useMemo } from 'react';
import { getEditableList, getEmbeddedPatchDataIds, getLanguageArray } from './helpers';
import _merge from 'lodash/merge';
import _concat from 'lodash/concat';
import { formatChoice } from '../../../utility/information';
import { TYPE_OF_CHANGE_CHOICE_GROUP } from '../../../../App/common/constant';
import { initializeFormValue } from '../../../utility/formStateHelpers';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _uniqBy from 'lodash/uniqBy';

function useChoiceGroupDetails({ choiceGroup, requests, currentCountry }) {
  const [title, setTitle] = useState({});
  const [quantityMin, setQuantityMin] = useState({});
  const [quantityMax, setQuantityMax] = useState({});
  const [choices, setChoices] = useState({});
  const [editableChoices, setEditableChoices] = useState([]);
  const [editableChoicesPrice, setEditableChoicesPrice] = useState([]);

  const [toDelete, setToDelete] = useState({});

  const [choiceRequests, setChoiceRequests] = useState({
    add: {},
    delete: {},
    title: { isCollection: true },
    price: {},
  });

  const [processedQuantity, setProcessedQuantity] = useState(choiceGroup?.quantity ?? {});

  //Compile title when list of title requests change
  const compiledTitle = useMemo(() => {
    const newTitles = Object.values(title).reduce((acc, titleRequest) => {
      return { ...acc, ...titleRequest.value };
    }, {});

    return { ...choiceGroup?.title, ...newTitles };
  }, [choiceGroup, title]);

  const languages = useMemo(() => {
    return getLanguageArray(compiledTitle, currentCountry);
  }, [compiledTitle, currentCountry]);

  useEffect(() => {
    //Set fields for display in modal

    setProcessedQuantity((prevState) => {
      return { ...choiceGroup?.quantity, ...prevState };
    });

    if (!!choiceGroup?.choices) {
      // Choices need to be reformatted because choices section expects a different structure
      setChoices((prevState) => {
        const modified = { ...prevState };

        choiceGroup.choices.forEach((c) => {
          const currentChoice = modified[c.id];
          modified[c.id] = _merge(
            {},
            formatChoice(c.id, currentChoice, choiceGroup),
            currentChoice
          );
        });

        return modified;
      });
    }
  }, [choiceGroup]);

  useEffect(() => {
    //Formulate choices into key pair with request reference
    setChoices((prevState) => {
      let modified = { ...prevState };

      const processChange = (QCRequest, modType) => {
        const choiceChange = QCRequest.value;
        const choiceId = choiceChange.tempId;
        const existingChoice = modified[choiceId] || {};

        modified[choiceId] = _merge({}, existingChoice, choiceChange, {});

        if (!Array.isArray(modified[choiceId].modType)) {
          modified[choiceId].modType = [];
        }
        modified[choiceId].modType.push(modType);
        modified[choiceId].modType = _uniqBy(modified[choiceId].modType);
      };

      for (let modType in choiceRequests) {
        if (choiceRequests.hasOwnProperty(modType)) {
          const { isCollection, ...choiceChanges } = choiceRequests[modType];

          Object.values(choiceChanges).forEach((choiceChangeRequest) => {
            if (isCollection) {
              const nestedChanges = Object.values(choiceChangeRequest);
              nestedChanges.forEach((nestedChange) => {
                processChange(nestedChange, modType);
              });
            } else {
              processChange(choiceChangeRequest, modType);
            }
          });
        }
      }

      return modified;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choiceRequests]);

  useEffect(() => {
    const editableChoiceList = getEditableList(choiceRequests);

    let mockEditableChoice = [];
    editableChoiceList.forEach((choiceId) => {
      if (
        choices[choiceId]?.modType?.includes('title') ||
        choices[choiceId]?.modType?.includes('add')
      ) {
        mockEditableChoice.push(choiceId);
      }
    });

    setEditableChoices(mockEditableChoice);

    let editablePrice = [];
    Object.keys(choices).forEach((choiceId) => {
      if (
        choices[choiceId]?.modType?.includes('price') ||
        choices[choiceId]?.modType?.includes('add')
      ) {
        editablePrice.push(choiceId);
      }
    });
    setEditableChoicesPrice(editablePrice);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choices]);

  // Update processed quantity each time there is a change in quantities
  useEffect(() => {
    setProcessedQuantity((prevState) => ({
      ...prevState,
      ...quantityMin.value,
      ...quantityMax.value,
    }));
  }, [quantityMin, quantityMax]);

  const getChoiceGroupPatchData = (request) => {
    const typeOfChange = request.item.typeOfChange;
    const isEmbedded = typeOfChange.startsWith('Embedded');
    const requestPatchData = request.item.patchData;

    if (isEmbedded) {
      const variantId = Object.keys(requestPatchData?.variants)[0];

      const choiceGroups = requestPatchData.variants[variantId].choice_groups;
      const choiceGroupId = Object.keys(choiceGroups)[0];

      const choiceGroupPatchData = choiceGroups[choiceGroupId];

      return { variantId, choiceGroupId, choiceGroupPatchData };
    } else {
      return {
        variantId: null,
        choiceGroupId: request.item.itemId,
        choiceGroupPatchData: requestPatchData,
      };
    }
  };

  const setTitleChanges = useCallback((request) => {
    const { choiceGroupPatchData } = getChoiceGroupPatchData(request);

    const newTitleData = { ...choiceGroupPatchData?.title };

    // handle title null in form because null will give a console error
    Object.keys(newTitleData).forEach((languageCode) => {
      if (newTitleData[languageCode] === null) {
        newTitleData[languageCode] = undefined;
      }
    });

    setTitle((prevState) => {
      return {
        ...prevState,
        [request.requestId]: initializeFormValue(newTitleData, {}, request),
      };
    });
  }, []);

  const setQuantityChanges = useCallback((request) => {
    const { choiceGroupPatchData } = getChoiceGroupPatchData(request);

    const quantityChange = choiceGroupPatchData?.quantity || {};
    if (quantityChange.hasOwnProperty('minimum')) {
      //quantityMin
      setQuantityMin(initializeFormValue({ minimum: quantityChange.minimum }, {}, request));
    }
    if (quantityChange.hasOwnProperty('maximum')) {
      //quantityMax
      setQuantityMax(initializeFormValue({ maximum: quantityChange.maximum }, {}, request));
    }

    setProcessedQuantity((prevQuantity) => ({
      ...prevQuantity,
      ...quantityChange,
    }));
  }, []);

  const setAddedChoices = useCallback(
    (request) => {
      const { choiceGroupPatchData } = getChoiceGroupPatchData(request);

      const reformattedChoices = Object.keys(choiceGroupPatchData?.choices || {}).map(
        (choiceId) => {
          const currentChoice = choiceGroupPatchData.choices[choiceId];

          return formatChoice(choiceId, currentChoice, choiceGroup);
        }
      );

      reformattedChoices.forEach((reformattedChoiceToAdd) => {
        setChoiceRequests((prevState) => ({
          ...prevState,
          add: {
            ...prevState.add,
            [reformattedChoiceToAdd.tempId]: initializeFormValue(
              reformattedChoiceToAdd,
              {},
              request
            ),
          },
        }));
      });
    },
    [choiceGroup]
  );

  const getEmbeddedChoice = (data) => {
    const variantId = Object.keys(data?.variants)[0];
    const choiceGroups = data?.variants?.[variantId]?.choice_groups;
    const choiceGroupId = Object.keys(choiceGroups)[0];
    const choiceGroupPatchData = choiceGroups?.[choiceGroupId];
    const choiceId = Object.keys(choiceGroupPatchData?.choices)[0];

    return { choiceId, choiceGroupPatchData };
  };

  const getChoiceChangeData = (patchData, typeOfChange) => {
    let choiceChangeData;
    if (typeOfChange.startsWith('Embedded')) {
      const { choiceId, choiceGroupPatchData } = getEmbeddedChoice(patchData);
      choiceChangeData = formatChoice(
        choiceId,
        choiceGroupPatchData?.choices?.[choiceId],
        choiceGroup
      );
    } else {
      choiceChangeData = Object.keys(patchData?.choices ?? {}).map((choiceId) => {
        return formatChoice(choiceId, patchData.choices[choiceId], choiceGroup);
      })[0];
    }

    return choiceChangeData;
  };

  //Set form values with changes
  useEffect(() => {
    requests.forEach((request) => {
      const typeOfChange = request.item.typeOfChange;
      const patchData = request.item.patchData;

      switch (typeOfChange) {
        case TYPE_OF_CHANGE_CHOICE_GROUP.EMBEDDED_CHOICE_GROUP_TITLE_CHANGE:
        case TYPE_OF_CHANGE_CHOICE_GROUP.CHOICE_GROUP_TITLE_CHANGE:
          setTitleChanges(request);
          break;
        case TYPE_OF_CHANGE_CHOICE_GROUP.EMBEDDED_CHOICE_GROUP_QUANTITY_CHANGE:
        case TYPE_OF_CHANGE_CHOICE_GROUP.CHOICE_GROUP_QUANTITY_CHANGE:
          setQuantityChanges(request);
          break;
        case TYPE_OF_CHANGE_CHOICE_GROUP.EMBEDDED_CHOICES_CREATE:
        case TYPE_OF_CHANGE_CHOICE_GROUP.CHOICES_CREATE:
        case TYPE_OF_CHANGE_CHOICE_GROUP.CHOICES_ADD:
          setAddedChoices(request);
          break;
        case TYPE_OF_CHANGE_CHOICE_GROUP.EMBEDDED_CHOICES_DELETE: {
          const { choiceId, choiceData } = getEmbeddedPatchDataIds(patchData);

          const reformattedChoice = formatChoice(choiceId, choiceData, choiceGroup);
          setChoiceRequests((prevState) => ({
            ...prevState,
            delete: {
              ...prevState.delete,
              [choiceId]: initializeFormValue(reformattedChoice, {}, request),
            },
          }));
          break;
        }
        case TYPE_OF_CHANGE_CHOICE_GROUP.CHOICES_DELETE:
          const choiceToDelete = Object.values(patchData.choices)[0];
          const reformattedChoiceToDelete = formatChoice(
            choiceToDelete.id,
            choiceToDelete,
            choiceGroup
          );
          setChoiceRequests((prevState) => ({
            ...prevState,
            delete: {
              ...prevState.delete,
              [reformattedChoiceToDelete.tempId]: initializeFormValue(
                reformattedChoiceToDelete,
                {},
                request
              ),
            },
          }));
          break;
        case TYPE_OF_CHANGE_CHOICE_GROUP.CHOICE_TITLE_CHANGE:
        case TYPE_OF_CHANGE_CHOICE_GROUP.EMBEDDED_CHOICE_TITLE_CHANGE: {
          const choiceWithTitleChange = getChoiceChangeData(patchData, typeOfChange);

          setChoiceRequests((prevState) => ({
            ...prevState,
            title: {
              ...prevState.title,
              [choiceWithTitleChange.tempId]: {
                ...prevState.title[choiceWithTitleChange.tempId],
                [request.requestId]: initializeFormValue(choiceWithTitleChange, {}, request),
              },
            },
          }));
          break;
        }
        case TYPE_OF_CHANGE_CHOICE_GROUP.EMBEDDED_CHOICES_PRICE_CHANGE:
        case TYPE_OF_CHANGE_CHOICE_GROUP.CHOICES_PRICE_CHANGE:
          const choiceWithPriceChange = getChoiceChangeData(patchData, typeOfChange);

          setChoiceRequests((prevState) => ({
            ...prevState,
            price: {
              ...prevState.price,
              [request.requestId]: initializeFormValue(choiceWithPriceChange, {}, request),
            },
          }));
          break;
        case TYPE_OF_CHANGE_CHOICE_GROUP.EMBEDDED_CHOICE_GROUP_CREATE:
        case TYPE_OF_CHANGE_CHOICE_GROUP.CHOICE_GROUP_CREATE:
          setTitleChanges(request);
          setQuantityChanges(request);
          setAddedChoices(request);
          break;
        case TYPE_OF_CHANGE_CHOICE_GROUP.EMBEDDED_CHOICE_GROUP_DELETE:
        case TYPE_OF_CHANGE_CHOICE_GROUP.CHOICE_GROUP_DELETE:
          setToDelete(initializeFormValue(true, true, request));
          break;
        default:
          break;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choiceGroup, requests, setAddedChoices, setQuantityChanges, setTitleChanges]);

  const handleMinimumChange = useCallback((e) => {
    const value = e.target.value;
    setQuantityMin((prevState) => {
      return {
        ...prevState,
        value: { ...prevState.value, minimum: value },
        hasChanged: true,
      };
    });
  }, []);

  const handleMaximumChange = useCallback((e) => {
    const value = e.target.value;
    setQuantityMax((prevState) => {
      return {
        ...prevState,
        value: { ...prevState.value, maximum: value },
        hasChanged: true,
      };
    });
  }, []);

  const handleTitleChange = useCallback((e, language) => {
    const { value } = e.target;

    setTitle((prevState) => {
      const newState = _cloneDeep(prevState);

      const targetTitleChange =
        Object.values(newState).find((element) => element.value.hasOwnProperty(language)) ??
        Object.values(newState)[0];

      targetTitleChange.value = {
        ...targetTitleChange.value,
        [language]: value,
      };
      targetTitleChange.hasChanged = true;

      return newState;
    });
  }, []);

  const addToPatchData = (patchData, formDataPoint, targetField) => {
    const { value, relatedRequest, hasChanged } = formDataPoint;
    const requestId = relatedRequest.requestId;

    const isEmbedded = relatedRequest.item.typeOfChange.startsWith('Embedded');
    const existing = patchData[requestId];

    let update;
    if (targetField === 'choices') {
      update = {
        choices: {
          [value.tempId]: { ...value.originalData },
        },
      };
    } else if (targetField.startsWith('quantity')) {
      update = { quantity: { ...value } };
    } else {
      update = { [targetField]: value };
    }

    if (isEmbedded) {
      const { variantId, choiceGroupId, choiceGroupPatchData } =
        getChoiceGroupPatchData(relatedRequest);

      const original = {
        variants: {
          [variantId]: {
            choice_groups: {
              [choiceGroupId]: choiceGroupPatchData,
            },
          },
        },
      };

      const rebuild = {
        variants: {
          [variantId]: {
            choice_groups: {
              [choiceGroupId]: {
                ...update,
              },
            },
          },
        },
      };

      patchData[requestId] = _merge(
        {},
        original,
        existing,
        rebuild,
        { version: relatedRequest.version },
        { ...(hasChanged ? { updated: true } : {}) }
      );
    } else {
      patchData[requestId] = _merge(
        {},
        existing,
        update,
        { version: relatedRequest.version },
        { ...(hasChanged ? { updated: true } : {}) }
      );
    }

    return patchData;
  };

  const getPatchData = () => {
    const changeFields = {
      title,
      quantityMin,
      quantityMax,
    };

    let patchData = {};

    if (!_isEmpty(toDelete)) {
      patchData = addToPatchData({}, toDelete, 'deleted');
    }

    Object.keys(changeFields).forEach((changeField) => {
      //multiple title requests
      if (changeField === 'title') {
        Object.values(changeFields['title']).forEach((titleChange) => {
          patchData = addToPatchData(patchData, titleChange, 'title');
        });
      }
      if (changeFields.hasOwnProperty(changeField)) {
        const formDataPoint = changeFields[changeField];

        //No related QCRequest, ignore field and move to next
        if (!formDataPoint.relatedRequest) {
          return;
        }

        patchData = addToPatchData(patchData, formDataPoint, changeField);
      }
    });

    //Choice Change handling, currently not supporting any changes but already structured to allow in future.
    const addRequests = Object.values(choiceRequests.add);
    const deleteRequests = Object.values(choiceRequests.delete);
    const priceChangeRequests = Object.values(choiceRequests.price);

    const { isCollection, ...titleRequests } = choiceRequests.title;
    const titleChangeRequestArray = Object.values(titleRequests);
    const titleChangeRequests = titleChangeRequestArray.map((cr) => Object.values(cr ?? {})).flat();

    const allChoiceChangeRequests = _concat(
      [],
      addRequests,
      deleteRequests,
      priceChangeRequests,
      titleChangeRequests
    );
    allChoiceChangeRequests.forEach((change) => {
      const { relatedRequest } = change;
      //No related QCRequest so can ignore even changes were made
      if (!relatedRequest) {
        return;
      }

      patchData = addToPatchData(patchData, change, 'choices');
    });

    return patchData;
  };

  return {
    title,
    compiledTitle,
    quantityMax,
    quantityMin,
    choices,
    setChoices,
    editableChoices,
    editableChoicesPrice,
    choiceRequests,
    processedQuantity,
    languages,
    handleMinimumChange,
    handleMaximumChange,
    handleTitleChange,
    getPatchData,
  };
}

export default useChoiceGroupDetails;
