import RiderPhotos from './RiderPhotos';
import { LayoutTwo } from '../App/layouts/TabsLayout';

function RiderPhotoPlugin({ setPluginTitle, currentCountry, optionalPath = '', fwfFlags }) {
  const { fwfFeatureRiderPhotosMenu } = fwfFlags;
  if (!fwfFeatureRiderPhotosMenu?.variation) {
    return {
      pages: [],
    };
  }

  const pluginId = 'rider-photos';
  const label = 'Rider Photos';

  return {
    id: pluginId,
    init: () => {},
    pages: [
      {
        path: optionalPath || '/rider-photos',
        visibleOnTabNavbar: true,
        component: RiderPhotos,
        label,
        isProtected: true,
        layout: LayoutTwo,
      },
    ],
  };
}

export default RiderPhotoPlugin;
