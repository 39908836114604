import * as React from 'react';
import styles from './styles';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

const useStyles = makeStyles(styles);

const DownloadButton = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <Button variant="outlined" classes={{ root: classes.root }} data-cp="DownloadButton" {...rest}>
      <SaveAltIcon />
      <Box ml={1}>{children}</Box>
    </Button>
  );
};

export default DownloadButton;
