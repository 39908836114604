// @ts-check
/**
 *
 * @param {Object} param0 Input
 * @param {boolean} param0.fwfCheck
 * @param {string} param0.REGION_COUNTRIES Countries in region
 * @param {readonly string[]} param0.iamCountries iamCountries in user IAM data
 */
export const filterRegionIAMCountries = ({ fwfCheck, REGION_COUNTRIES, iamCountries }) => {
  if (!fwfCheck) return REGION_COUNTRIES;

  /** @type {string[]} */
  const filteredCountries = [];

  for (const country of iamCountries) {
    if (!REGION_COUNTRIES.includes(country)) continue;
    filteredCountries.push(country);
  }

  return filteredCountries.join(',');
};
