import { Storage, storageKeys } from './../../../storage/storage';
import axios from '../../../App/utility/axios';
import config from '../../../App/config';

export function fetchPendingNumber({ country, status }) {
  return axios(`${config.dishPhotosApi}/api/v1/stats?country=${country.code}&status=${status}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + Storage.get(storageKeys.ACCESS_TOKEN),
    },
  });
}
