import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import styles from './styles';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(styles);

export default function AppDialog({
  open,
  handleCancel,
  handleOkay,
  title,
  message,
  okText = 'Okay',
  loadingText = "...",
  cancelText = 'Cancel',
}) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  
  const okayHandler = async () => {
    setLoading(true);
    await handleOkay();
    setLoading(false);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant = "contained" className={classes.button} color="default" onClick={handleCancel}>
            {cancelText}
          </Button>
          <Button variant="contained" className={classes.button} color="primary" disabled={loading} onClick={okayHandler}>
            {loading ? loadingText : okText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
