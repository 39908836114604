import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StyledInput } from '../styles';
import { BarcodeSearchProps } from '../types';

const BarcodeSearch: React.FC<BarcodeSearchProps> = ({
  t,
  handleSearchBarcode,
  isLoadingBarcode,
}) => {
  const [value, setValue] = React.useState('');
  return (
    <StyledInput
      placeholder={t('menu_management_pandora.product_details_search')}
      id="nutrition_info_search_barcode"
      variant="standard"
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSearchBarcode(value);
        }
      }}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      InputProps={{
        endAdornment: isLoadingBarcode ? <CircularProgress color="inherit" size={20} /> : null,
        startAdornment: <SearchIcon color="disabled" />,
      }}
    />
  );
};

export default BarcodeSearch;
