import { useEffect, useRef } from 'react';
import _ from 'lodash';
import {
  setIsProcessingLazyLoad,
  setLazyVendorItems,
} from '../MenuManagement/store/agentQueue/actions';

const useLazyVendorItems = (store, isLazyLoadActive) => {
  const LIST_SIZE_TO_INITIALIZE = 300;
  const [state, dispatch] = store;
  const tableRef = useRef();
  const lazyLoadRef = useRef({
    lazyLoadSize: 50,
    lazyLoadNonce: 1,
  });

  const loadNextPart = ({ lazyLoadNonce, lazyLoadSize }) => {
    const nextPart = state.vendorItems.slice(
      lazyLoadSize * lazyLoadNonce,
      lazyLoadSize * (lazyLoadNonce + 1)
    );
    const currentPart = state.vendorItems.slice(
      lazyLoadSize * (lazyLoadNonce - 1),
      lazyLoadSize * lazyLoadNonce
    );
    dispatch(setLazyVendorItems(currentPart.concat(nextPart)));
  };

  const loadPreviousPart = ({ lazyLoadNonce, lazyLoadSize }) => {
    const previousPart = state.vendorItems.slice(
      lazyLoadSize * (lazyLoadNonce - 1),
      lazyLoadSize * lazyLoadNonce
    );
    const currentPart = state.vendorItems.slice(
      lazyLoadSize * lazyLoadNonce,
      lazyLoadSize * (lazyLoadNonce + 1)
    );
    dispatch(setLazyVendorItems(previousPart.concat(currentPart)));
    setTimeout(() => dispatch(setIsProcessingLazyLoad(false)), 500);
  };

  const handleScroll = _.debounce((e) => {
    if (state.isProcessingLazyLoad) {
      return;
    }

    const { lazyLoadNonce } = lazyLoadRef.current;
    const { scrollTop, scrollHeight, clientHeight } = tableRef.current;

    if (scrollTop === 0 && lazyLoadNonce > 1) {
      dispatch(setIsProcessingLazyLoad(true));
      lazyLoadRef.current.lazyLoadNonce--;
      loadPreviousPart(lazyLoadRef.current);
      setTimeout(() => tableRef.current.scrollTo({ top: 3000 }));
    } else if (scrollTop + clientHeight + 200 > scrollHeight) {
      lazyLoadRef.current.lazyLoadNonce++;
      loadNextPart(lazyLoadRef.current);
    }
  }, 100);

  useEffect(() => {
    if (isLazyLoadActive) {
      if (
        state.vendorItems.length > lazyLoadRef.current.lazyLoadSize &&
        state.vendorItems.length > LIST_SIZE_TO_INITIALIZE
      ) {
        dispatch(
          setLazyVendorItems(state.vendorItems.slice(0, lazyLoadRef.current.lazyLoadSize * 2))
        );
      } else {
        dispatch(setLazyVendorItems(state.vendorItems));
      }
    }
  }, [state.vendorItems, dispatch, isLazyLoadActive]);

  return {
    tableRef,
    handleScroll,
    LIST_SIZE_TO_INITIALIZE,
  };
};

export default useLazyVendorItems;
