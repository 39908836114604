import { DashboardOutlined as DashboardIcon } from '@material-ui/icons';
import _isNil from 'lodash/isNil';

import Home from './containers/Home';
import PhotoList from './containers/PhotoList';
import ProductReviewPage from './containers/ProductReview';
import ChoiceGroupReview from './containers/ChoiceGroupReview/ChoiceGroupReview';
import CategoryReviewPage from './containers/CategoryReview';
import AccessDeniedPage from '../AccessDenied';
import { getPendingRequestCountOnInit, initPlugin } from '../initPlugin';
import { LayoutOne, LayoutTwo } from '../App/layouts/TabsLayout';

const MenuManagement = ({ setPluginTitle, currentCountry, optionalPath = '' }) => {
  const pluginId = 'menu-management';
  const label = 'Menu';
  const isTalabat = currentCountry.geid === 'TB_KW';

  const setRequestCount = (pluginId, labelText) => (count) => {
    setPluginTitle(pluginId, _isNil(count) ? labelText : `${labelText} (${count})`);
  };

  const menuManagement = {
    path: '/',
    visibleOnTopNavbar: true,
    visibleOnTabNavbar: true,
    component: Home,
    label: 'Menu Requests',
    isProtected: true,
    layout: LayoutOne,
    icon: DashboardIcon,
    exact: true,
    /** Enable filtering of auto QC'ed change requests by default, but disable it in tests */
    additionalProps: {
      shouldFilterAutoQCedChangeRequests: true,
      setRequestCount: setRequestCount(pluginId, label),
    },
  };
  const photoList = {
    path: '/photo/menu/:vendorId/:countryCode',
    visibleOnTabNavbar: false,
    label: 'Menu Photo',
    isProtected: true,
    layout: LayoutTwo,
    component: PhotoList,
    parent: Home,
    exact: true,
  };
  const productReview = {
    path: '/changes/:countryCode/:vendorCode/product/:item_id',
    visibleOnTabNavbar: false,
    label: 'Product Review',
    isProtected: true,
    layout: LayoutTwo,
    component: ProductReviewPage,
    exact: true,
  };
  const choiceGroupReview = {
    path: '/changes/:countryCode/:vendorCode/choice-group/:item_id',
    visibleOnTabNavbar: false,
    isProtected: true,
    layout: LayoutTwo,
    component: ChoiceGroupReview,
  };
  const categoryReview = {
    path: '/changes/:countryCode/:vendorCode/category/:item_id',
    visibleOnTabNavbar: false,
    isProtected: true,
    layout: LayoutTwo,
    component: CategoryReviewPage,
    exact: true,
  };

  const AccessDenied = {
    path: '/access-denied',
    visibleOnTabNavbar: false,
    label: 'Access Denied',
    isProtected: true,
    layout: LayoutTwo,
    component: AccessDeniedPage,
    exact: true,
  };

  const availablePages = isTalabat
    ? [productReview, choiceGroupReview, categoryReview, AccessDenied]
    : [menuManagement, photoList, productReview, choiceGroupReview, categoryReview, AccessDenied];
  const onInit = getPendingRequestCountOnInit(currentCountry, pluginId, setPluginTitle, label);
  return initPlugin(pluginId, availablePages, onInit);
};

export default MenuManagement;
