import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { styles } from './styles';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import countryToCodeAndSymbol, { codeToSymbol } from '../../utility/translation';
import isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles(styles);

const NormalTextField = styled(TextField)`
  width: 100%;
`;

export const TitleTextField = styled(NormalTextField)``;

const CategoryDetailForm = ({
  titles,
  handleTitleChange,
  isTitleEditable,
  countryCode,
  readonly,
}) => {
  const classes = useStyles();

  const languageKeys = countryToCodeAndSymbol[countryCode].map((country) => country.code);
  const titleGridXs = 12 / languageKeys.length;

  return (
    <div className={classes.container} style={{ fontFamily: 'Roboto' }}>
      <div className={classes.categoryDetailText}>Category Details</div>
      <Grid container spacing={2} justifyContent="center">
        {languageKeys.map((language) => {
          let titleObj = titles.find((titleItem) => !!titleItem?.[language]);
          const defaultValue = isEmpty(titleObj) ? '' : titleObj[language];
          return (
            <Grid item xs={titleGridXs} key={language}>
              <TitleTextField
                label={`Category Title [${codeToSymbol[language]}]`}
                value={defaultValue}
                name={`title-${language}`}
                margin="normal"
                onChange={(e) => handleTitleChange(e, language)}
                disabled={!isTitleEditable || readonly}
                inputProps={{
                  'data-hj-whitelist': true,
                  'data-enzyme': `title_${language}`,
                  'data-cp': `title_${language}`,
                }}
                spellCheck={true}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default CategoryDetailForm;
