import { useCallback, useRef, useState } from "react";

function useThunkReducer(reducer, initialArg, init = a => a, isDebug = false) {
  const [hookState, setHookState] = useState(init(initialArg));
  const state = useRef(hookState);

  const getState = useCallback(() => state.current, [state]);
  const setState = useCallback(
    newState => {
      state.current = newState;
      setHookState(newState);
    },
    [state, setHookState],
  );

  const reduce = useCallback(
    action => {
      const data = reducer(getState(), action);
      if (isDebug) {
        console.log({ action, data });
      }
      return data;
    },
    [reducer, getState, isDebug],
  );

  const dispatch = useCallback(
    action => {
      return typeof action === "function"
        ? action(dispatch, getState)
        : setState(reduce(action));
    },
    [getState, setState, reduce],
  );

  return [hookState, dispatch, getState];
}

export default useThunkReducer;
