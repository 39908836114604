import React from 'react';
import { Typography, Link } from '@material-ui/core';

const PageNotFound = () => {
  return (
    <div
      data-enzyme={'CompleteScreen'}
      style={{ minHeight: '100%', display: 'flex', justifyContent: 'center', padding: '30px 0' }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ textAlign: 'center' }}>
          <img alt="empty state" src="/assets/404NotFound.png" width="auto" height="157" />
          <Typography variant={'h4'} style={{ padding: '15px 0' }}>
            Page Not Found
          </Typography>
          <Typography variant={'subtitle2'}>
            <Link href="/">Click hero to return to the main page</Link>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
