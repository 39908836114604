import FWFMain from '@deliveryhero/fwf-sdk-javascript';
import config from '../config';
let fwf = null;

export const initFwf = (user) => {
  if (fwf === null) {
    fwf = new FWFMain({ ...config.fwfConfig, user });
  }
  return fwf;
};

export const updateCountry = (countryCode) => {
  fwf.updateUserAttributes({ country_code: countryCode });
};

export const getFlag = (flag) => {
  if (fwf === null) {
    throw new Error('please init FunWithFlags first');
  }
  return fwf.getVariation(flag, true);
};

export const getFlags = (flag) => {
  if (fwf === null) {
    throw new Error('please init FunWithFlags first');
  }
  return fwf.getVariations(flag);
};

export const initialFlag = (fwfFlagsMapper) => {
  return Object.keys(fwfFlagsMapper)
    .map((key) => {
      return { [key]: { loaded: false, variation: false } };
    })
    .reduce((acc, current) => Object.assign(acc, current));
};

export const updateFlag = (fwfFlagsMapper, data) => {
  return Object.keys(fwfFlagsMapper)
    .map((key) => {
      return { [key]: { loaded: true, ...data[fwfFlagsMapper[key]] } };
    })
    .reduce((acc, current) => ({ ...acc, ...current }));
};
