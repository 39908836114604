
const REJECT_REASON_IMAGE_BLURRY_PHOTO = {
  key: 'photo_rejection_reason_blurry_photo',
  text: 'Photo is too blurry.  Please try again and submit a new photo.',
};

const REJECT_REASON_IMAGE_LANDSCAPE_PHOTO = {
  key: 'photo_rejection_reason_landscape_photo',
  text: 'Photo is not in landscape format.  Please try again and submit a new photo.',
};

const REJECT_REASON_IMAGE_MARKUP = {
  key: 'photo_rejection_watermarks_logos_present',
  text: 'Watermarks and logos should not be present. Please submit a new photo without watermarks and logos.',
};

const REJECT_REASON_IMAGE_TOO_CLOSE = {
  key: 'photo_rejection_reason_too_close',
  text: 'Photo is too close up. Please take a photo further away and try again.',
};

const REJECT_REASON_IMAGE_MISLEADING_SIDE_PRODUCT = {
  key: 'photo_rejection_reason_misleading_side_product',
  text: 'Misleading side product in the photo.  Please try again and submit a new photo.',
};

const REJECT_REASON_IMAGE_WRONG_PHOTO = {
  key: 'photo_rejection_wrong_photo',
  text: 'Dish photo does not match with item name. Please try again and submit a new photo.',
};

const REJECT_REASON_IMAGE_ITEM_NOT_CENTERED = {
  key: 'photo_rejection_item_not_centered',
  text:
    'Item is not in the center of the photo. Please ensure item is in the middle of the photo for best browsing experience.',
};

const REJECT_REASON_IMAGE_NO_BORDERS = {
  key: 'photo_rejection_no_borders',
  text: 'Border covers too much of the photo. Please crop out the border and try again.',
};

const REJECT_REASON_IMAGE_NEW_VERTICAL = {
  key: 'photo_rejection_new_vertical',
  text:
    'Unfortunately changes for shops cannot be processed here, please submit your changes using the "Request Changes" option on the side.',
};

const REJECT_REASON_IMAGE_CONTAIN_PRICE = {
  key: 'photo_rejection_contain_price',
  text:
    'The photo contains price, contact details and promotion information. Please try again and submit a new photo.',
};

const REJECT_REASON_IMAGE_COLLAGE = {
  key: 'photo_rejection_collages',
  text: 'Collages are not allowed. Please submit a single photo instead of combining multiple photos.',
};

const REJECT_REASON_OTHER_PLATFORMS = {
  key: 'photo_rejection_other_platforms',
  text: 'Photos of the dish that are taken from a printed menu, poster or computer screen are not acceptable. Please submit the original dish photo file.',
};

const REJECT_REASON_NOT_DISH_PHOTO = {
  key: 'photo_rejection_not_dish_photo',
  text: 'The photo you submitted was not an image of your food. Please try again and submit a new photo. ',
};



//For Talabat
const REJECT_REASON_REJECTION_FOR_POS_CHAINS = {
  key:'photo_rejection_for_pos_chains',
  text:'Rejection for POS chains'
};

const REJECT_REASON_NOT_MATCHING_GUIDELINES = {
  key:'photo_rejection_not_matching_guidelines',
  text:'Not Matching Guidelines'
};

const REJECT_REASON_ITEM_IMAGE_ARE_MISMATCHING = {
  key:'photo_rejection_image_are_mismatching',
  text:'Item & Image are mismatching'
};

const REJECT_REASON_EXTRA_COMPONENTS_NOT_MENTIONED = {
  key:'photo_rejection_extra_components_not_mentioned',
  text:'Extra components not mentioned'
}



const DEFAULT_REJECT_REASONS = [
  REJECT_REASON_IMAGE_BLURRY_PHOTO,
  REJECT_REASON_IMAGE_LANDSCAPE_PHOTO,
  REJECT_REASON_IMAGE_MARKUP,
  REJECT_REASON_IMAGE_TOO_CLOSE,
  REJECT_REASON_IMAGE_MISLEADING_SIDE_PRODUCT,
  REJECT_REASON_IMAGE_WRONG_PHOTO,
  REJECT_REASON_NOT_DISH_PHOTO,
  REJECT_REASON_IMAGE_COLLAGE,
  REJECT_REASON_OTHER_PLATFORMS,
  REJECT_REASON_IMAGE_ITEM_NOT_CENTERED,
  REJECT_REASON_IMAGE_NO_BORDERS,
  REJECT_REASON_IMAGE_NEW_VERTICAL,
  REJECT_REASON_IMAGE_CONTAIN_PRICE,
  
];

const TALABAT_REJECT_REASONS = [
  REJECT_REASON_REJECTION_FOR_POS_CHAINS,
  REJECT_REASON_NOT_MATCHING_GUIDELINES,
  REJECT_REASON_ITEM_IMAGE_ARE_MISMATCHING,
  REJECT_REASON_EXTRA_COMPONENTS_NOT_MENTIONED

];

export const getPhotoRejectReasons = (GEID) => {
  const splitGEID = GEID.split("_");
  const isTalabat = splitGEID[0] === "TB" || splitGEID[0] === "HF"; // HF_EG

  if(isTalabat){
    return TALABAT_REJECT_REASONS;
  }

   return DEFAULT_REJECT_REASONS;
};
