import { initFwf, getFlags, updateFlag, updateCountry, getCurrentCountry } from '../App/utility';
import { useDispatch } from 'react-redux';
import { fwfFlagsMapper } from '../App/store/fwfReducer';
import Cookies from 'js-cookie';
export const useFWFV2 = () => {
  /*   
  FLAGS_SET_DATA;
  FLAGS_RESET;
  FLAGS_SET_LOADING; 
  */
  // const prevCountryCode = usePrevious(countryCode);
  const currentCountry = getCurrentCountry(Cookies.get('country'));
  const email = localStorage.getItem('email');
  const dispatch = useDispatch();
  let didCancel = false;

  const reloadFWF = async () => {
    if (email) {
      dispatch(
        initFlagsAction({
          email: email,
          currentCountry: currentCountry?.code,
          didCancel: didCancel,
        }) as any
      );
    }
  };

  const resetFlags = () => {
    dispatch({ type: 'FLAGS_RESET' });
  };

  const initFlagsAction = ({ email, currentCountry, didCancel }) => {
    return async (dispatch) => {
      dispatch({ type: 'FLAGS_SET_LOADING', loading: true });
      try {
        initFwf({
          email,
          userId: email,
          country_code: currentCountry,
        });
        updateCountry(currentCountry);

        const flagsXhrResponse = await getFlags(Object.values(fwfFlagsMapper));
        if (!didCancel) {
          dispatch({ type: 'FLAGS_SET_DATA', flags: updateFlag(fwfFlagsMapper, flagsXhrResponse) });
        }
      } catch (error) {
        console.error('Error loading fwf', error);
      }

      dispatch({ type: 'FLAGS_SET_LOADING', loading: false });
    };
  };
  return {
    reloadFWF,
    resetFlags,
  };
};
