import * as React from 'react';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import { Alert } from '@material-ui/lab';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import {
  TYPE_OF_CHANGE,
  TYPE_OF_CHANGE_CHOICE_GROUP,
  TYPE_OF_CHANGE_CATEGORY,
} from '../../../App/common/constant';
import { codeToSymbol } from '../../utility/translation';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(styles);

const {
  VARIANT_CREATE,
  VARIANT_DELETE,
  PRODUCT_CREATE,
  VARIANT_CHOICE_GROUP_ADD,
  VARIANT_CHOICE_GROUP_DELETE,
} = TYPE_OF_CHANGE;

const {
  CHOICES_CREATE,
  CHOICES_DELETE,
  CHOICES_ADD,
  CHOICE_GROUP_CREATE,
  EMBEDDED_CHOICES_DELETE,
  EMBEDDED_CHOICE_GROUP_DELETE,
} = TYPE_OF_CHANGE_CHOICE_GROUP;

const { CATEGORY_CREATE, CATEGORY_DELETE } = TYPE_OF_CHANGE_CATEGORY;

const CREATE_LIST = [
  VARIANT_CREATE,
  VARIANT_CHOICE_GROUP_ADD,
  CHOICE_GROUP_CREATE,
  CHOICES_CREATE,
  CHOICES_ADD,
  CATEGORY_CREATE,
];
const DELETE_LIST = [
  VARIANT_DELETE,
  VARIANT_CHOICE_GROUP_DELETE,
  CHOICES_DELETE,
  CATEGORY_DELETE,
  EMBEDDED_CHOICES_DELETE,
  EMBEDDED_CHOICE_GROUP_DELETE,
];

const ChangeDetail = ({
  oldValue,
  newValue,
  label,
  date,
  language,
  request,
  product,
  hasReleatedChoiceGroup,
}) => {
  const classes = useStyles();
  let d1;
  if (typeof date == 'string') {
    const d = parseInt(date, 10);
    d1 = moment(d, 'X').format('DD MMM, YYYY');
  } else {
    d1 = moment(date).format('DD MMM, YYYY');
  }

  const type = request?.item?.typeOfChange.toLowerCase();
  const isChoiceGroupChange = Object.values(TYPE_OF_CHANGE_CHOICE_GROUP).find(
    (x) => x.toLowerCase() === type
  );
  const hasProductData = type === TYPE_OF_CHANGE.PRODUCT_CREATE.toLowerCase() || product;

  const showNewOldValue =
    label === 'ChoiceTitleChange' || label === 'EmbeddedVariantTitleChange'
      ? true
      : label !== PRODUCT_CREATE && label !== CATEGORY_CREATE && hasReleatedChoiceGroup && product;

  return (
    <Box p={1} color="rgba(0, 0, 0, 0.87)">
      <Box p={1} fontSize={16} fontWeight={'bold'} bgcolor="white">
        {label} {language && `[ ${codeToSymbol[language]} ]`}
        <Box style={{ float: 'right' }}>
          <Box component="span" fontWeight="normal">
            Requested On{' '}
          </Box>
          {d1}
        </Box>
        {request?.item?.typeOfChange.toLowerCase().includes('price') &&
        request?.hasOwnProperty('advancedPriceQCMessage') &&
        request?.advancedPriceQCMessage !== null ? (
          <div className={classes.priceQCLabel}>
            It is not approved by Price QC!
            <Tooltip title={request?.advancedPriceQCMessage} placement="top" arrow>
              <InfoOutlinedIcon className={classes.priceQCInfoIcon}></InfoOutlinedIcon>
            </Tooltip>
          </div>
        ) : (
          <></>
        )}
      </Box>

      {showNewOldValue && (
        <>
          {CREATE_LIST.includes(label) ? (
            <></>
          ) : (
            <Box
              p={1}
              style={{ textDecoration: 'line-through' }}
              className={classes.changeValuesOld + ' oldValue'}
            >
              {oldValue ?? '-'}
            </Box>
          )}
          {DELETE_LIST.includes(label) ? (
            <></>
          ) : (
            <Box p={1} className={'newValue ' + classes.changeValuesNew}>
              {newValue ?? '-'}
            </Box>
          )}
        </>
      )}

      {!showNewOldValue && !isChoiceGroupChange && (
        <>
          {!hasProductData && (
            <Box>
              <Alert
                severity="info"
                style={{ width: '100%' }}
                icon={<InfoIcon fontSize="inherit" />}
              >
                Product not found
              </Alert>
            </Box>
          )}
          {!hasReleatedChoiceGroup && (
            <Box>
              <Alert
                severity="info"
                style={{ width: '100%' }}
                icon={<InfoIcon fontSize="inherit" />}
              >
                Variant or choice group deleted
              </Alert>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

ChangeDetail.propTypes = {
  oldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  language: PropTypes.string,
};

export default ChangeDetail;
