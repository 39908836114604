export const photostyles = (theme) => ({
  button: {
    margin: 0,
    marginRight: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  image: {
    textAlign: 'center',
    overflowX: 'auto',
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  loader: {
    textAlign: 'center',
    width: 'auto',
    height: '600px',
    marginTop: '150px',
  },
  countryFilterContainer: {
    textAlign: 'right',
    paddingTop: theme.spacing(5),
  },
  innerscroll: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop:0
  },
  defaultOptions: {
    display: 'inline-block',
  },
  moreOptions: {
    display: 'inline-block',
    paddingLeft: 10,
    borderLeft: '1px solid rgb(235, 235, 235)',
    marginLeft: 'auto',
  },
});

export const tablestyles = (theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: 'collapse',
    '& tbody': {
      paddingRight: '20px solid transparent',
    },
  },
  checkBoxColumn: {
    paddingLeft: 10,
    paddingRight: 10,
    width: 5,
  },
  photoCell: {
    padding: 0,
    width: 320,
    paddingTop: 14,
    paddingBottom: 14,
  },
  safeGridImageContent:{
    float:'left',
    width:'100%',
    minHeight:240,
    position:'relative'
  },
  dishPhotoImage: {
    width: 320,
    position:'initial'
  },
  tableRoot: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto',
  },
  button: {
    margin: 0,
    marginRight: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  label: {
    fontWeight: 'bold',
    width: 'auto',
  },
  labelContainer: {
    flexBasis: '20%',
  },
  vendorName: {
    color: 'rgb(232, 107, 106)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  vendorCode: {
    verticalAlign: 'top',
  },
  agentEmail: {
    color: theme.palette.custom.secondary,
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  productInformation: {
    verticalAlign: 'top',
    paddingLeft: 16,
  },
  productTitle: {
    color: 'rgb(0, 0, 0)',
    fontSize: '15px',
    fontWeight: 'normal',
    letterSpacing: 0.46,
    paddingBottom: 8,
  },
  productDescription: {
    color: 'rgb(0, 0, 0)',
    fontSize: '14px',
    fontWeight: 'normal',
    letterSpacing: 0.4,
  },
  updatedBy: {
    verticalAlign: 'top',
  },
  dateSubmitted: {
    verticalAlign: 'top',
    fontSize: '14px',
  },
  photoEnlargeContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 770,
  },
  photoEnlargeContainerHeader: {
    position: 'absolute',
    top: 0,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: '#f1f1f1',
  },
});

export default photostyles;
