export const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

export const modalStyles = (theme) => ({
  root: {
    float: 'right',
    '@media (max-width: 700px)': {
      width: '100%',
      minWidth: '100%',
    },
  },
});

export const alertStyles = (theme) => ({
  alert: {
    margin: `${theme.spacing(3)}px ${theme.spacing(3)}px 0`,
  },
});

export const productSectionStyles = (theme) => ({
  sectionWrapper: {
    '&+&': {
      marginTop: '1rem',
    },
    borderRadius: 5,
    border: '1px solid #e5e5e5',
    '@media (max-width: 500px)': {
      border: 0,
    },
  },
  sectionHeader: {
    background: '#f5f5f5',
    padding: '26px 23px 22px',
    display: 'flex',
  },
  sectionHeaderSpan: {
    fontSize: '14px',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.87)',
    flex: 1,
  },
  sectionContent: {
    padding: '26px 23px 22px',
    '@media (max-width: 500px)': {
      padding: '16px 16px 24px',
    },
  },
});

export default styles;
