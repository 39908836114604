import { getChanges } from '../utility/information';
import _get from 'lodash/get';
import ChangeDetail from '../components/ChangeDetail';
import React from 'react';
import { getDefaultLanguage } from '../../../src/utility/information';

export const renderChangeDetails = (
  request,
  product,
  vats,
  categories,
  currency,
  newEmbeddedVariantChoiceGroup
) => {
  const changeType = _get(request, 'item.typeOfChange', 'UnrecognizedChange');
  let [oldValue, newValue] = getChanges(
    changeType,
    request.item.content.data,
    request.item.patchData,
    product,
    categories,
    vats,
    currency
  );
  let hasReleatedChoiceGroup = true;

  if (changeType === 'EmbeddedVariantChoiceGroupAdd') {
    let cgIds = request?.item?.patchData?.variants[request?.baseItemId]?.choice_groups;
    //For multiple variant
    if (!cgIds) {
      let itemId = request?.item.itemId;
      let variants = request?.item?.patchData?.variants;
      if (Object.keys(variants).toString().includes('_')) {
        itemId = Object.keys(variants).toString();
        cgIds = request?.item?.patchData?.variants[itemId]?.choice_groups;
      }
      cgIds = request?.item?.patchData?.variants[itemId]?.choice_groups;
    }
    if (cgIds && product?.variants) {
      cgIds = Object.keys(cgIds);
      cgIds.forEach((id) => {
        product.variants.forEach((variant) => {
          const newCgData = variant.choice_groups.find((cg) => cg.id === id);
          if (newCgData) {
            newValue = newCgData.title?.[getDefaultLanguage(newCgData.title)];
          }
        });
      });

      if (newValue === '-' && newEmbeddedVariantChoiceGroup?.length > 0) {
        //Talabat Flow
        cgIds.forEach((id) => {
          const newCgData = newEmbeddedVariantChoiceGroup.find((cg) => cg.cgId === id);
          if (newCgData) {
            if (newCgData.title) {
              newValue = newCgData.title?.[getDefaultLanguage(newCgData.title)];
            } else {
              newEmbeddedVariantChoiceGroup.forEach((cgRequest) => {
                for (const key in cgRequest.variants) {
                  if (id in cgRequest.variants[key].choice_groups) {
                    const title = cgRequest.variants[key].choice_groups[id].title;
                    newValue = title?.[getDefaultLanguage(title)];
                  }
                }
              });
            }
          }
        });
      }

      hasReleatedChoiceGroup = newValue !== '-';
    }
  }

  return (
    <ChangeDetail
      key={request.requestId}
      label={changeType}
      date={request.item.timestamp}
      oldValue={oldValue}
      newValue={newValue}
      product={product}
      hasReleatedChoiceGroup={hasReleatedChoiceGroup}
      request={request}
      data-enzyme={'ChangeDetail'}
    />
  );
};
