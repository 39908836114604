import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import styles from './styles';

import { useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
const useStyles = makeStyles(styles);

const TopNavbar = () => {
  const location = useLocation();
  const navigationState = useSelector((state: any) => state.navigationState);
  const navigationDispatch = useDispatch();
  useEffect(() => {
    //set selected Topnavbar item on first landing of the page
    navigationState.topNavbarPages.forEach((page, index) => {
      if (page.children.map((it) => it.path).includes(location.pathname)) {
        navigationDispatch({
          type: 'SET_SELECTED_NAVBAR_TOP_ITEM',
          data: { index: index, selected: true },
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  //set selected item on click
  const handleSelectedTopNavbar = (index) => {
    navigationDispatch({
      type: 'SET_SELECTED_NAVBAR_TOP_ITEM',
      data: { index: index, selected: true },
    });
  };
  const classes: any = useStyles();
  return (
    <List component="nav" disablePadding style={{ display: 'flex' }}>
      {navigationState.topNavbarPages.map((page, index) => (
        <ListItem
          onClick={() => handleSelectedTopNavbar(index)}
          key={index}
          component={NavLink}
          to={page.path}
          isActive={() => {
            return page.children.map((child) => child.path).includes(location.pathname);
          }}
          className={`${classes.ListItem}`}
          exact
        >
          <ListItemText
            primaryTypographyProps={{
              style: {
                color: 'white',
                fontWeight: 'bold',
              },
            }}
            classes={{ primary: classes.listItemText }}
          >
            {page.label}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};
export { TopNavbar };
