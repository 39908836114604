import * as React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(styles);

const HighLightBox = ({ children, type, className, ...props }) => {
  const classess = useStyles({ type });
  return (
    <Box type={type} p={2} bgcolor="#f5f5f5" className={`${classess.root} ${className}`} {...props}>
      {children}
    </Box>
  );
};

HighLightBox.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
};

export default HighLightBox;
