import React from 'react';
import Box from '@material-ui/core/Box';
import _startCase from 'lodash/startCase';

import StatusBox from '../StatusBox';

const HighlightBoxHeader = ({ status = 'pending' }) => {
  return (
    <Box display="flex" p={1}>
      <Box flex={1} justifyContent="center" fontSize={18} lineHeight="28px">
        {_startCase(status)} Requests
      </Box>
      <Box flex={0}>
        <StatusBox type={status} />
      </Box>
    </Box>
  );
};

export default HighlightBoxHeader;
