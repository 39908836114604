import { useEffect, useRef } from 'react';
import config from '../App/config';
import axios from '../App/utility/axios';

export interface IUserStore {
  isAllowedUser: boolean;
}

export const useDishPhotoService = () => {
  const promiseArray = useRef([]);

  useEffect(() => {
    if (promiseArray.current.length > 1) {
      console.log(promiseArray.current.pop(), promiseArray.current);
    }
  }, [promiseArray]);

  const fetchDishPhoto = async (currentCountry) => {
    const controller = new AbortController();
    promiseArray.current.forEach((promiseToCancel) => {
      promiseToCancel.controller.abort();
      promiseArray.current.splice(promiseToCancel, 1);
    });
    promiseArray.current.push({ controller: controller });

    const dishPhoto: any = await axios(
      `${config.dishPhotosApi}/api/v1/dishphoto/pending?countryCode=${currentCountry.code}`,
      { signal: controller.signal, headers: { 'Content-Type': 'application/json' } }
    );

    return dishPhoto.data.sort(function (a, b) {
      return a.modifiedOn - b.modifiedOn;
    });
  };

  return {
    fetchDishPhoto,
  };
};
