// @ts-check
import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import styles from './style';

const useStyles = makeStyles(styles);

/**
 * @typedef Props
 * @property {boolean | undefined} isSafeGridEnabled
 * @property {PhotoType | undefined} photoType
 * @property {number} imageWidth
 * @property {number} imageHeight
 * @param {Props & Pick<React.ImgHTMLAttributes<HTMLImageElement>, 'className' | 'alt' | 'src'>} props
 */
const SafeGridImage = ({ alt, className, isSafeGridEnabled, photoType = 'dish', src, imageWidth = 320, imageHeight = 234 }) => {
  const classes = useStyles({ photoType, imageWidth, imageHeight });

  return (
    <Box className={className} alignItems="center" display="flex" position="relative">

      <div className={classes.imgContent}>
        <img src="/assets/loading.gif" alt="Placeholder" className={classes.placeholderImg}/>
        <img alt={alt} className={classes.image} src={src} loading="lazy" />
      </div>

      {isSafeGridEnabled && (
        <>
          <span className={classes.horizontalGrid} style={{zIndex:10}} />
          <span className={classes.verticalGrid} style={{zIndex:10}}  />
        </>
      )}
    </Box>
  );
};

export default SafeGridImage;
