import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Box,
  Checkbox,
  Modal,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FileCopy from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';

import { tablestyles } from './styles';
import { getDefaultLanguage, capitalizeFirstLetter } from '../../../utility/information';
import { copyToClipboard } from '../../../utility/information';
import Notification from '../../../App/components/Notification';
import SafeGridImage from '../../components/SafeGridImage';

const useStyles = makeStyles(tablestyles);

const getUploadedBy = (classes, vendorName, agentEmail) => {
  let className;
  let typo;
  if (agentEmail) {
    className = classes.agentEmail;
    typo = agentEmail;
  } else {
    className = classes.vendorName;
    typo = vendorName;
  }
  return <Typography className={className}>{typo}</Typography>;
};

const getMasterCategoryTitle = (masterCategories, id) => {
  return masterCategories.find((category) => category.id === id)?.title || null;
};

/**
 * @typedef Props
 * @property {boolean | undefined} isSafeGridEnabled
 * @param {Props} props
 */
const TableContent = ({
  masterCategories = [],
  groupedPhoto,
  handlePhotoCheck,
  isPhotoChecked,
  handleAllPhotoCheck,
  isAllPhotoChecked,
  isSafeGridEnabled,
}) => {
  const classes = useStyles();
  const [openNotification, setOpenNotification] = useState(false);
  const [openPhotoEnlargeView, setOpenPhotoEnlargeView] = useState(false);
  const [photoEnlargeViewProps, setPhotoEnlargeViewProps] = useState({});

  return (
    <Paper className={classes.tableRoot}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.checkBoxColumn}>
              <Checkbox
                checked={isAllPhotoChecked()}
                onClick={handleAllPhotoCheck}
                value={true}
                data-cp="SelectAll"
              ></Checkbox>
            </TableCell>
            <TableCell align="left" className={classes.photoCell}>
              Select All
            </TableCell>
            <TableCell align="left" />
            <TableCell align="left">Category</TableCell>
            <TableCell align="left">Uploaded by</TableCell>
            <TableCell align="left">Vendor code</TableCell>
            <TableCell align="left">Date &amp; time submitted</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupedPhoto.map(
            (photo) =>
              photo.product &&
              photo.change && (
                <React.Fragment key={`${photo.vendorId}-${photo.productId}`}>
                  <TableRow
                    key={`${photo.vendorId}-${photo.productId}`}
                    //onClick={() => handleClickRow(index)}
                  >
                    <TableCell className={classes.checkBoxColumn}>
                      <Checkbox
                        onClick={(e) => handlePhotoCheck(e, photo.vendorId, photo.productId)}
                        checked={isPhotoChecked(photo.vendorId, photo.productId)}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.photoCell}
                      onClick={(e) => {
                        e.stopPropagation();
                        setPhotoEnlargeViewProps({
                          src: photo.change.location + '?time=' + photo.modifiedOn,
                          photoType: photo.photoType,
                        });
                        setOpenPhotoEnlargeView(true);
                      }}
                    >
                      <div className={classes.safeGridImageContent}>
                      <SafeGridImage
                        alt="Dish"
                        src={photo.change.location + '?time=' + photo.modifiedOn}
                        className={classes.dishPhotoImage}
                        isSafeGridEnabled={isSafeGridEnabled}
                        photoType={photo.photoType}
                      />
                      </div>
                     
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.productInformation}
                      data-enzyme="product-title"
                    >
                      {photo.photoType === 'hero' || photo.photoType === 'listing' ? (
                        <Typography className={classes.productTitle}>
                          {capitalizeFirstLetter(photo.photoType)} Photo
                        </Typography>
                      ) : (
                        <>
                          {!_isEmpty(photo.product) && (
                            <>
                              <Typography className={classes.productTitle}>
                                {photo.product?.title &&
                                  photo.product?.title[getDefaultLanguage(photo.product?.title)]}
                              </Typography>
                              <Typography className={classes.productDescription}>
                                {
                                  photo.product.description[
                                    getDefaultLanguage(photo.product.description)
                                  ]
                                }
                              </Typography>
                            </>
                          )}
                        </>
                      )}
                    </TableCell>
                    <TableCell className={classes.productInformation}>
                      {getMasterCategoryTitle(
                        masterCategories,
                        photo.product?.category?.masterCategoryId
                      )}
                    </TableCell>
                    <TableCell align="left" className={classes.updatedBy}>
                      {getUploadedBy(classes, photo.vendorName, photo.agentEmail)}
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={(e) => {
                        e.stopPropagation();
                        copyToClipboard(photo.vendorId);
                        setOpenNotification(true);
                      }}
                      className={`${classes.vendorCode} vendorCode`}
                    >
                      <Box display="flex" alignItems="flex-start">
                        <Box component="span" display="inline" mr={1} color="gray">
                          <FileCopy />
                        </Box>
                        {photo.vendorId}
                      </Box>
                    </TableCell>
                    <TableCell className={classes.dateSubmitted}>
                      {moment(photo.modifiedOn).format('DD MMM YYYY, H:mm')}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )
          )}
        </TableBody>
      </Table>
      <Notification
        variant="success"
        open={openNotification}
        handleClose={() => setOpenNotification(false)}
        message="Successfully copied the vendor code to clipboard."
      />
      <Modal open={openPhotoEnlargeView}>
        <>
          <div className={classes.photoEnlargeContainerHeader}>
            <IconButton
              style={{ float: 'right' }}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => setOpenPhotoEnlargeView(false)}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          </div>
          <SafeGridImage
            alt={photoEnlargeViewProps?.photoType}
            src={photoEnlargeViewProps?.src}
            className={classes.photoEnlargeContainer}
            isSafeGridEnabled={true}
            photoType={photoEnlargeViewProps?.photoType}
            imageWidth={770}
            imageHeight={563}
          />
        </>
      </Modal>
    </Paper>
  );
};

export default TableContent;
