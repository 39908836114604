// @ts-check
import { Box as DefaultBox } from '@material-ui/core';
import defaultAxios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import defaultConfig from '../../../App/config';
import { CONSTANTS } from '../../../utility';
import { isProduction } from '../../../../src/utility/information';
import { GET_DINE_IN_COLLECTIONS } from '../../containers/Home/helpers';
import { Link } from '@material-ui/core';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';

/** @type {React.CSSProperties} */
const STYLE_CONTAINER_MENU_PHOTO_NOT_PRESENT = {
  alignItems: 'center',
  border: '1px solid #dcdcdc',
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  width: '100%',
};

/** @type {React.CSSProperties} */
const STYLE_CONTAINER_MENU_PHOTO_PRESENT = {
  ...STYLE_CONTAINER_MENU_PHOTO_NOT_PRESENT,
  alignItems: 'flex-start',
  border: 'none',
  bottom: 16,
  color: CONSTANTS.COLOR_NORMAL_TEXT,
  position: 'relative',
  textDecoration: 'none',
};

/** @type {React.CSSProperties} */
const STYLE_HEADER_MENU_PHOTO_NOT_PRESENT = {
  color: CONSTANTS.COLOR_HEADER,
  fontSize: 20,
  fontWeight: 500,
};

/** @type {React.CSSProperties} */
const STYLE_LINK_WITH_MENU_PHOTO_URL = {
  color: CONSTANTS.COLOR_LINK,
  cursor: 'pointer',
  textDecoration: 'underline',
};

/** @type {React.CSSProperties} */
const STYLE_IMG_WITH_MENU_PHOTO_URL = {
  backgroundColor: '#a7a7a7',
  flex: '0 0 100%',
  height: '100%',
  marginTop: 4,
  objectFit: 'contain',
  width: '100%',
};
/** @type {React.CSSProperties} */
const STYLE_IMG_WITH_DINE_IN_MENU_PHOTO_URL = {
  flex: '0 0 100%',
  height: '100%',
  objectFit: 'contain',
  width: '100%',
  border: '1px solid #d0d0d0',
  borderRadius: '5px',
  backgroundColor: '#f4f4f4',
};
/** @type {React.CSSProperties} */
const STYLE_IMG_WITH_DINE_IN_MENU_PDF_URL = {
  flex: '0 0 100%',
  height: '100%',
  objectFit: 'contain',
  width: '100%',
  border: '1px solid #d0d0d0',
  borderRadius: '5px',
  backgroundColor: '#f4f4f4',
  textAlign: 'center',
  padding: '50px 0',
};

/** @type {React.CSSProperties} */
const STYLE_IMG_MENU_PHOTO_NOT_PRESENT_PLACEHOLDER = { height: 200, width: 200 };

/** @type {React.CSSProperties} */
const STYLE_TEXT_ALONGSIDE_LINK_WITH_MENU_PHOTO_URL = {
  color: CONSTANTS.COLOR_NORMAL_TEXT,
};

/**
 * @typedef Props
 * @property {typeof DefaultBox} [Box]
 * @property {Pick<typeof defaultAxios, 'request'>} [axios]
 * @property {Pick<typeof defaultConfig, 'menuAttachedFilesApi'>} [config]
 * @property {string | undefined} menuPhoto
 * @property {string | undefined} vendorCode
 * @property {any | undefined} currentCountry
 * @property {any | undefined} fwfFlags
 * @param {import('@material-ui/core').BoxProps & Props} arg0
 */
const ProductDetailMenuPhoto = ({
  Box /* istanbul ignore next */ = DefaultBox,
  axios /* istanbul ignore next */ = defaultAxios,
  config /* istanbul ignore next */ = defaultConfig,
  menuPhoto,
  vendorCode,
  currentCountry,
  fwfFlags,
  ...props
}) => {
  const [hasImageLoadFailed, setImageLoadHasFailed] = useState(false);
  const [isLoadingMenuPhotoURL, setIsLoadingMenuPhotoURL] = useState(false);
  const [hasDineInMenuPhoto, setHasDineInMenuPhoto] = useState(false);
  const [dineInMenus, setDineInMenus] = useState([]);
  const [dineInMenuType, setDineInMenuType] = useState('');
  const [menuPhotoURL, setMenuPhotoURL] = useState('');
  const geidWithVendorCode = `${currentCountry.geid};${vendorCode}`;
  const { fwfDineInMenuCollection } = fwfFlags;

  const {
    data: getDineInCollectionsData,
    loading,
    // error,
  } = useQuery(GET_DINE_IN_COLLECTIONS, {
    variables: {
      refID: geidWithVendorCode,
      getLatest: true,
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    skip: !fwfDineInMenuCollection.variation,
  });

  useEffect(() => {
    if (
      getDineInCollectionsData?.getDineInCollections.length > 0 &&
      fwfDineInMenuCollection.variation
    ) {
      setHasDineInMenuPhoto(true);
      setDineInMenuType(getDineInCollectionsData?.getDineInCollections[0].Type);
      setDineInMenus(getDineInCollectionsData?.getDineInCollections[0].DineInImages);

      if (
        getDineInCollectionsData?.getDineInCollections[0].DineInImages.length === 1 &&
        getDineInCollectionsData?.getDineInCollections[0].DineInImages?.[0].endsWith('.pdf')
      ) {
        setDineInMenuType('Pdf');
        return;
      }

      setDineInMenuType(getDineInCollectionsData?.getDineInCollections[0].Type);
    }
  }, [getDineInCollectionsData, fwfDineInMenuCollection.variation]);

  const fetchMenuPhotoURL = useCallback(async () => {
    if (!!menuPhoto) {
      setIsLoadingMenuPhotoURL(true);

      const reTryMenuUrl = isProduction
        ? 'https://apac-vss.deliveryhero.com/menuimages/api/v1/menuImages'
        : 'https://apac-api.dev.pandaqc.io/menuimages/api/v1/menuImages';

      try {
        /** @type {AxiosResponse<{ url: string }>} */
        const {
          data: { url },
        } = await axios.request({
          baseURL: config.menuAttachedFilesApi,
          method: 'GET',
          url: `/entry/${menuPhoto}`,
        });

        setMenuPhotoURL(url);
      } catch {
        try {
          const {
            data: { url },
          } = await axios.request({
            baseURL: reTryMenuUrl,
            method: 'GET',
            url: `/entry/${menuPhoto}`,
          });

          setMenuPhotoURL(url);
        } catch {}
      } finally {
        setIsLoadingMenuPhotoURL(false);
      }
    }
  }, [axios, config, menuPhoto]);

  useEffect(() => {
    if (!hasDineInMenuPhoto && !loading) {
      fetchMenuPhotoURL();
    }
  }, [fetchMenuPhotoURL, hasDineInMenuPhoto, loading]);

  /** @type {React.DOMAttributes<HTMLImageElement>['onError']} */
  const onImageLoadError = () => setImageLoadHasFailed(true);

  const redirectMenuLink = (link) => {
    if (!link.match(/^https?:\/\//i)) {
      link = `http://${link}`;
    }
    window.open(link, '_blank');
  };

  /** @type {React.ReactNode} */
  let children = null;

  if (loading || isLoadingMenuPhotoURL) {
    children = (
      <div style={STYLE_CONTAINER_MENU_PHOTO_NOT_PRESENT}>
        <img
          alt="Placeholder"
          src="/assets/NoSearchResults.svg"
          style={STYLE_IMG_MENU_PHOTO_NOT_PRESENT_PLACEHOLDER}
        />

        <span style={STYLE_HEADER_MENU_PHOTO_NOT_PRESENT}>Loading...</span>
      </div>
    );
  } else {
    if (hasDineInMenuPhoto) {
      if (dineInMenuType === 'Link' || dineInMenuType === 'Pdf') {
        children = (
          <div style={STYLE_CONTAINER_MENU_PHOTO_NOT_PRESENT}>
            <img
              alt="Placeholder"
              src="/assets/NoSearchResults.svg"
              style={STYLE_IMG_MENU_PHOTO_NOT_PRESENT_PLACEHOLDER}
            />

            <span style={STYLE_HEADER_MENU_PHOTO_NOT_PRESENT}>This vendor has a Dine in menu</span>

            <Box mt={1}>
              <Link
                onClick={() => redirectMenuLink(dineInMenus.length > 0 ? dineInMenus[0] : '')}
                style={STYLE_LINK_WITH_MENU_PHOTO_URL}
              >
                Click to open the link
              </Link>
              <span style={STYLE_TEXT_ALONGSIDE_LINK_WITH_MENU_PHOTO_URL}> in a new tab</span>
            </Box>
          </div>
        );
      } else if (dineInMenuType === 'File') {
        if (dineInMenus.length > 1) {
          children = (
            <>
              <span>This vendor has Dine in menu files</span>
              <Box display={'flex'} mt={2} sx={{ flexWrap: 'wrap' }}>
                {dineInMenus.map((image, index) => (
                  <Box p={1} key={index} sx={{ width: '25%' }}>
                    <a
                      href={image}
                      rel="noopener noreferrer"
                      target="_blank"
                      style={STYLE_CONTAINER_MENU_PHOTO_PRESENT}
                    >
                      {image.endsWith('.pdf') ? (
                        <div style={STYLE_IMG_WITH_DINE_IN_MENU_PDF_URL}>
                          <PictureAsPdf />
                        </div>
                      ) : (
                        <img
                          alt="Menu"
                          onError={onImageLoadError}
                          src={image}
                          style={STYLE_IMG_WITH_DINE_IN_MENU_PHOTO_URL}
                        />
                      )}
                    </a>
                  </Box>
                ))}
              </Box>
            </>
          );
        } else {
          children = (
            <a
              href={dineInMenus[0]}
              rel="noopener noreferrer"
              target="_blank"
              style={STYLE_CONTAINER_MENU_PHOTO_PRESENT}
            >
              <span>This vendor has a Dine in menu</span>

              <img
                alt="Menu"
                onError={onImageLoadError}
                src={dineInMenus[0]}
                style={STYLE_IMG_WITH_MENU_PHOTO_URL}
              />
            </a>
          );
        }
      }
    } else if (hasImageLoadFailed && !!menuPhotoURL) {
      children = (
        <div style={STYLE_CONTAINER_MENU_PHOTO_NOT_PRESENT}>
          <img
            alt="Placeholder"
            src="/assets/NoSearchResults.svg"
            style={STYLE_IMG_MENU_PHOTO_NOT_PRESENT_PLACEHOLDER}
          />

          <span style={STYLE_HEADER_MENU_PHOTO_NOT_PRESENT}>
            The attached menu is in PDF format.
          </span>

          <Box mt={1}>
            <a
              href={menuPhotoURL}
              rel="noopener noreferrer"
              target="_blank"
              style={STYLE_LINK_WITH_MENU_PHOTO_URL}
            >
              Click to open the file
            </a>{' '}
            <span style={STYLE_TEXT_ALONGSIDE_LINK_WITH_MENU_PHOTO_URL}>in a new tab</span>
          </Box>
        </div>
      );
    } else if (!!menuPhotoURL) {
      children = (
        <a
          href={menuPhotoURL}
          rel="noopener noreferrer"
          target="_blank"
          style={STYLE_CONTAINER_MENU_PHOTO_PRESENT}
        >
          <span>Menu photo uploaded by the Vendor:</span>

          <img
            alt="Menu"
            onError={onImageLoadError}
            src={menuPhotoURL}
            style={STYLE_IMG_WITH_MENU_PHOTO_URL}
          />
        </a>
      );
    } else if (!isLoadingMenuPhotoURL) {
      children = (
        <div style={STYLE_CONTAINER_MENU_PHOTO_NOT_PRESENT}>
          <img
            alt="Placeholder"
            src="/assets/NoSearchResults.svg"
            style={STYLE_IMG_MENU_PHOTO_NOT_PRESENT_PLACEHOLDER}
          />

          <span style={STYLE_HEADER_MENU_PHOTO_NOT_PRESENT}>No menu photo available</span>
        </div>
      );
    }
  }

  /**
   * Limit the height to take care of padding. Note that it's not 80px, but 64px, since we move the
   * children up a big.
   */
  return (
    <Box bgcolor="#f7f7f7" padding={5} {...props} display="block">
      {children}
    </Box>
  );
};

export default ProductDetailMenuPhoto;
