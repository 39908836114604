// @ts-check
import defaultOpsPortalClient from './opsPortal';
import { Storage, storageKeys } from './../storage/storage';
/**
 * @param {object} arg0
 * @param {Pick<typeof defaultOpsPortalClient, 'getAccessToken' | 'isRunningInOpsPortal' | 'getCurrentCountry' | 'getCountries' | 'setPluginLoaded'>} [arg0.opsPortalClient]
 */
export function createAuthClient({ opsPortalClient = defaultOpsPortalClient }) {
  return {
    getToken: async () => {
      if (await opsPortalClient.isRunningInOpsPortal()) {
        const accessToken = await opsPortalClient.getAccessToken();
        // @ts-ignore Pass down access token to child window in a secure context
        // @ts-ignore
        Storage.set(storageKeys.ACCESS_TOKEN, accessToken, {
          expires: 7,
          sameSite: 'None',
          secure: true,
        });
        return accessToken;
      } else {
        return Storage.get(storageKeys.ACCESS_TOKEN);
      }
    },
    setPluginLoaded: async () => await opsPortalClient.setPluginLoaded(),
    isRunningInOpsPortal: async () => await opsPortalClient.isRunningInOpsPortal(),
    getCountries: async () => await opsPortalClient.getCountries(),
    getCurrentCountry: async () => await opsPortalClient.getCurrentCountry(),
    /** @param {string} token */
    setToken: async (token) => {
      if (await opsPortalClient.isRunningInOpsPortal()) return;
      Storage.set(storageKeys.ACCESS_TOKEN, token, {
        expires: 7,
        sameSite: 'None',
        secure: true,
      });
    },
    getClient: () => opsPortalClient,
  };
}

/* istanbul ignore next */
const defaultAuthClient = createAuthClient({});
export default defaultAuthClient;
