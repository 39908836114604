import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { gridStyle } from './style';
import _isEmpty from 'lodash/isEmpty';

type Props = {
  itemsPerRow?: number;
  children: React.ReactNode;
};

const useStyles = makeStyles(gridStyle);

const GridDisplay = ({ itemsPerRow = 0, children }: Props) => {
  const classes = useStyles({ perRow: itemsPerRow });

  if (_isEmpty(children)) {
    return null;
  }

  return (
    <div className={classes.gridContainer}>
      {/*@ts-ignore*/}
      {children.map((element, index) => (
        <div key={index} className={classes.gridItem}>
          {element}
        </div>
      ))}
    </div>
  );
};

export default GridDisplay;
