import { combineReducers, createStore, applyMiddleware } from 'redux';
import { navigationReducer } from './navigationReducer';
import { filterModalReducer } from './filterModalReducer';
import { filterReducer } from './filterReducer';
import { fwfReducer } from './fwfReducer';
import thunk from 'redux-thunk';
/* import logger from 'redux-logger'; */

export const combinedReducers = combineReducers({
  navigationState: navigationReducer,
  filterModalState: filterModalReducer,
  filterState: filterReducer,
  fwfState: fwfReducer,
});

export const store = createStore(combinedReducers, applyMiddleware(thunk));
