import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import HistoryPhotoQueue from './../components/HistoryPhotoQueue/HistoryPhotoQueue';
import { PHOTO_QUEUE_HISTORY_REQUESTS } from '../../MenuManagement/containers/Home/helpers';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Container, Grid, Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { IfilterState } from './../../App/store/filterReducer';
export const PhotosContainer = ({
  setCustomContext,
  customContext,
  setTitle,
  currentCountry,
  fwfFlags,
  setRequestCount,
}) => {
  const filterState: IfilterState = useSelector((state: any) => state.filterState);
  const rowsPerPage = 20;
  const pageToken = '';
  const { enqueueSnackbar } = useSnackbar();
  const {
    loading,
    data: historyPhotoQueueData,
    refetch: refetchHistoryPhotoQueueData,
  } = useQuery(PHOTO_QUEUE_HISTORY_REQUESTS, {
    variables: {
      input: {
        globalEntityID: currentCountry.geid,
        timeFrom: filterState.payload.input.timeFrom
          ? `${filterState.payload.input.timeFrom}`
          : null,
        timeTo: filterState.payload.input.timeTo ? `${filterState.payload.input.timeTo}` : null,
        search: filterState.payload.input.search || null,
      },
      pageSize: rowsPerPage,
      pageToken: pageToken,
    },
    onError: (error: any): any => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    displayName: 'AgentQueuePhotoHistoryQuery',
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  } as any);

  useEffect(() => {
    refetchHistoryPhotoQueueData();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container maxWidth="xl">
        <Grid item>
          {historyPhotoQueueData?.getPhotoQCRequestHistory && (
            <HistoryPhotoQueue
              historyPhotoQueueData={historyPhotoQueueData.getPhotoQCRequestHistory}
              refetchHistoryPhotoQueueData={refetchHistoryPhotoQueueData}
              rowsPerPage={rowsPerPage}
              fwfFlags={fwfFlags}
              setRequestCount={setRequestCount}
              customContext={customContext}
            />
          )}
          {loading && (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
              <CircularProgress />
            </Box>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default PhotosContainer;
