import { SvgIconComponent } from '@material-ui/icons';
import { fetchPendingNumber } from './MenuManagement/store/pendingNumbers/api';

export interface IPage {
  path: string;
  isProtected: boolean;
  component: any;
  parent?: any;
  label?: string;
  exact?: boolean;
  icon?: SvgIconComponent;
  layout: any;
  additionalProps?: { [x: string]: any };
  visibleOnTabNavbar?: boolean;
  visibleOnTopNavbar?: boolean;
}

export const initPlugin = (pluginId: string, pages: IPage[], onInit: () => void) => {
  return {
    id: pluginId,
    pages: pages,
    ...(!!onInit ? { init: onInit } : {}),
  };
};

interface CurrentCountry {
  code: string;
}

export const getPendingRequestCountOnInit =
  (
    currentCountry: CurrentCountry,
    pluginId: string,
    setPluginTitle: (pluginId: string, label: string) => void,
    label: string
  ) =>
  async () => {
    try {
      // lifecycle starts here
      const STATUS = 'pending';
      const requestCount = await fetchPendingNumber({
        country: currentCountry,
        status: STATUS,
      });
      // set counter to 0 in case there is error res from api
      const totalRequestNumber =
        (Number.isInteger(requestCount.data.total) && requestCount.data.total) || 0;

      setPluginTitle(pluginId, `${label} (${totalRequestNumber})`);
    } catch (error) {
      console.log(error);
    }
  };
