// @ts-check
/** @type {Styles<'switchDescription'>} */
const styles = {
  switchDescription: {
    color: 'black',
    fontWeight: 'bold',
  },
};

export default styles;
