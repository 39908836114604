import styled from 'styled-components';

export const AvailabilityDropDownContainer = styled.div`
  .MuiInput-underline:before {
    border-bottom: 0;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0;
  }

  .MuiInput-underline:after {
    border-bottom: 0;
  }

  .webapp_global-MuiSelect-root {
    display: flex;
  }
`;
