import palette from './palette';

const typography = {
  h1: {
    color: palette.text.primary,
    fontWeight: '500',
    fontSize: '35px',
    letterSpacing: '-0.24px',
    lineHeight: '40px',
  },
  h2: {
    color: palette.text.primary,
    fontWeight: '500',
    fontSize: '29px',
    letterSpacing: '-0.24px',
    lineHeight: '32px',
  },
  h3: {
    color: palette.text.primary,
    fontWeight: '500',
    fontSize: '24px',
    letterSpacing: '-0.06px',
    lineHeight: '28px',
  },
  h4: {
    color: palette.text.primary,
    fontWeight: '500',
    fontSize: '20px',
    letterSpacing: '-0.06px',
    lineHeight: '24px',
  },
  h5: {
    color: palette.text.primary,
    fontWeight: '500',
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  h6: {
    color: palette.text.primary,
    fontWeight: '500',
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '25px',
  },
  subtitle2: {
    color: palette.text.primary,
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '16px',
  },

  button: {
    color: palette.text.primary,
    fontSize: '14px',
  },
  caption: {
    color: 'rgba(0,0,0,0.54)',
  },
  useNextVariants: true,
  body1: {
    fontSize: 14,
  },
  body2: {
    color: '#616161',
    fontWeight: 'normal',
    lineHeight: 1.5,
    fontSize: 14,
  },
};

export default typography;
