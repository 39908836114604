import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 10px;
  color: rgba(0, 0, 0, 0.87);
`;
export const Label = styled.div`
  text-transform: capitalize;
  margin-right: 10px;
`;
export const IDLabel = styled.span`
  font-size: 12px;
  font-weight: normal;
`;

export const ItemDetails = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

export const Item = styled.div`
  padding: 8px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  background: white;
`;
export const Date = styled.div`
  /* float: right; */
`;
export const DateLabel = styled.span`
  font-weight: normal;
`;
export const OldValue = styled.div`
  padding: 10px;
  background-color: rgba(255, 140, 144, 0.24);
  text-decoration: line-through;
`;
export const NewValue = styled.div`
  padding: 10px;
  background-color: rgba(151, 230, 48, 0.24);
`;
