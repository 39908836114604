import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

export const BannerWrapper = styled.div`
  position: relative;
  display: flex;
  max-width: 1312px; /* this number follow what rps team set */
  border-radius: 2px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.08), 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
  min-height: ${(props) => props.theme.spacing(6)}px;
`;

export const CloseButtonIcon = styled(CloseIcon)`
  color: #757575;
  height: ${(props) => props.theme.spacing(3)}px;
  width: ${(props) => props.theme.spacing(3)}px;
`;

export const CloseButton = styled(IconButton)`
  && {
    position: absolute;
    right: ${(props) => props.theme.spacing(0.5)}px;
    top: ${(props) => props.theme.spacing(0.5)}px;
    height: ${(props) => props.theme.spacing(6)}px;
    width: ${(props) => props.theme.spacing(6)}px;
  }
`;
