import config from '../../../App/config';
import axios from '../../../App/utility/axios';

export const ALLOWED_CATEGORIES = {
  'Big Mains': 'master_category_big_mains',
  Desserts: 'master_category_desserts',
  'Drinks, Only non-alcoholic items': 'master_category_nonalcholic_drinks',
  'Drinks, including alcoholic items': 'master_category_alcholic_drinks',
  "Kid's Menu": 'master_category_kidsmenu',
  'Non food/ non drinks related': 'master_category_nonfood',
  'Plates and Boxes': 'master_category_boxes',
  'Pre-packaged Food': 'master_category_prepacked_food',
  'Side Dishes and Add Ons': 'master_category_side_dishes',
  'Small Mains': 'master_category_small_mains',
  'Starters (warm and cold)': 'master_category_starters',
};

export async function fetchMasterCategories({ country, status }) {
  const res = await axios(`${config.api}/internal/categories`, {
    params: {
      status,
    },
    headers: { 'content-type': 'application/json', 'x-global-entity-id': country.geid },
  });
  return res.data.filter(
    (category) =>
      Object.keys(ALLOWED_CATEGORIES).includes(category.title) || category.id === 'total'
  );
}
