import { TYPE_OF_CHANGE, TYPE_OF_CHANGE_CHOICE_GROUP } from '../../../../App/common/constant';
import { getCurrentCountry } from '../../../../App/utility';
import Cookies from 'js-cookie';
import { getMutationRequestDefaultData } from '../../../../utility/information';
import countryToCodeAndSymbol from '../../../utility/translation';

const {
  CHOICE_GROUP_CREATE,
  CHOICE_GROUP_QUANTITY_CHANGE,
  CHOICE_GROUP_TITLE_CHANGE,
  CHOICES_ADD,
  CHOICES_DELETE,
  CHOICES_CREATE,
  CHOICES_PRICE_CHANGE,
  CHOICE_AVAILABILITY_CHANGE,
  EMBEDDED_CHOICE_GROUP_TITLE_CHANGE,
  EMBEDDED_CHOICE_GROUP_QUANTITY_CHANGE,
  EMBEDDED_CHOICES_CREATE,
  EMBEDDED_CHOICE_TITLE_CHANGE,
  CHOICE_TITLE_CHANGE,
  EMBEDDED_CHOICES_PRICE_CHANGE,
  EMBEDDED_CHOICES_DELETE,
} = TYPE_OF_CHANGE_CHOICE_GROUP;

const TYPES_OF_CHANGE_TO_SHOW_MENU_PHOTO = new Set([
  CHOICE_GROUP_CREATE,
  CHOICES_CREATE,
  CHOICES_ADD,
  TYPE_OF_CHANGE.PRODUCT_CREATE,
  TYPE_OF_CHANGE.VARIANT_CREATE,
  CHOICES_PRICE_CHANGE,
  TYPE_OF_CHANGE.VARIANT_UNITPRICE_CHANGE,
]);

export const getEditableList = (choiceRequests) => {
  let editableList = [];
  Object.values(choiceRequests).forEach((changeList) => {
    editableList = [...editableList, ...Object.keys(changeList)];
  });
  return editableList;
};

export const getMenuPhotoData = (requests, fwfFlags) => {
  const isFeatureOn = fwfFlags?.fwfProductDetailMenuPhoto?.variation;

  const relevantChange = requests.find((request) => {
    return TYPES_OF_CHANGE_TO_SHOW_MENU_PHOTO.has(request.item.typeOfChange);
  });

  return {
    shouldShowMenuPhoto: isFeatureOn && !!relevantChange,
    data: relevantChange,
  };
};

export const getLanguageArray = (titleData, country) => {
  const languageArray = Object.keys(titleData || {}).map((l) => ({
    symbol: l.split('_')[0].toUpperCase(),
    code: l,
  }));

  if (country && !languageArray.length > 0) {
    let languageKeys = countryToCodeAndSymbol[country.code];

    return languageKeys;
  }

  return languageArray.length > 0 ? languageArray : [{ symbol: 'EN', code: 'en_GB' }];
};

export const getApprovalQueryInputFromPatchData = ({ patchData, currentUser }) => {
  const currentCountry = getCurrentCountry(Cookies.get('country'));
  //Compile all the changes into an array for submission
  const input = [];

  Object.keys(patchData).forEach((requestId) => {
    const request = patchData[requestId];
    const { updated, version, ...requestPatchData } = request;

    const defaultData = {
      type: 'APPROVE',
      ...getMutationRequestDefaultData({ ...request, requestId }, currentUser, currentCountry),
    };
    if (updated) {
      input.push({
        ...defaultData,
        patchData: requestPatchData,
      });
    } else {
      input.push(defaultData);
    }
  });

  return input;
};

export const getValuesForChangeDetail = (changePayload, item) => {
  const [change, original] = getPatchData(changePayload);

  //Following will be undefined unless choice related change
  const choiceId = Object.keys(change?.choices || {})[0];
  const originalChoice = original?.choices?.[choiceId];
  const newChoice = change?.choices?.[choiceId];

  const getChangedChoice = (data) => {
    return data.choices[Object.keys(change.choices)[0]];
  };

  switch (changePayload?.item?.typeOfChange) {
    case EMBEDDED_CHOICES_DELETE: {
      const choiceData = item?.choices?.find((choice) => choice.id === choiceId);

      return [
        [...Object.values(choiceData?.title || {}), `Price: ${choiceData?.price}`].join(', '),
        '-',
      ];
    }
    case CHOICES_DELETE: {
      // Get the info of the choice
      const changedChoice = change.choices[Object.keys(change.choices)[0]];

      /* istanbul ignore if */
      if (changedChoice == null) {
        return [];
      } else {
        return [
          [...Object.values(changedChoice.title || {}), `Price: ${changedChoice.price}`].join(', '),
          '-',
        ];
      }
    }
    case EMBEDDED_CHOICES_CREATE:
    case CHOICES_ADD:
    case CHOICES_CREATE: {
      const { price, title } = getChangedChoice(change);
      return ['-', [...Object.values(title || {}), price].join(', ')];
    }
    case EMBEDDED_CHOICE_TITLE_CHANGE:
    case CHOICE_TITLE_CHANGE:
      const { title: oldTitle } = getChangedChoice(original);
      const { title: newTitle } = getChangedChoice(change);

      const newTitles = [];
      const oldTitles = [];

      for (let titleKey in newTitle) {
        if (newTitle.hasOwnProperty(titleKey)) {
          newTitles.push(newTitle[titleKey]);
        }
        if (oldTitle?.hasOwnProperty(titleKey)) {
          oldTitles.push(oldTitle[titleKey]);
        }
      }

      return [oldTitles.join(', '), newTitles.join(', ')];
    case EMBEDDED_CHOICE_GROUP_QUANTITY_CHANGE:
    case CHOICE_GROUP_QUANTITY_CHANGE: {
      const quantityKeys = Object.keys(change?.quantity || {});
      const oldQuantity = [];
      const newQuantity = [];

      quantityKeys.forEach((qKey) => {
        oldQuantity.push(`${qKey.toUpperCase()}: ${original.quantity[qKey]}`);
        newQuantity.push(`${qKey.toUpperCase()}: ${change.quantity[qKey]}`);
      });

      return [oldQuantity.join(', '), newQuantity.join(', ')];
    }
    case EMBEDDED_CHOICE_GROUP_TITLE_CHANGE:
    case CHOICE_GROUP_TITLE_CHANGE: {
      const titleKeys = Object.keys(change.title);
      let oldTitleValue = [];
      let newTitleValue = [];
      let originalTitle, changeTitle;

      titleKeys.forEach((tKey) => {
        originalTitle =
          original?.title && original.title.hasOwnProperty(tKey) ? original.title[tKey] : '-';

        changeTitle = change?.title && change.title.hasOwnProperty(tKey) ? change.title[tKey] : '-';

        oldTitleValue.push(originalTitle);
        newTitleValue.push(changeTitle);
      });

      return [oldTitleValue.join(', '), newTitleValue.join(', ')];
    }
    case CHOICE_GROUP_CREATE: {
      const { title, quantity } = change;
      return ['-', [...Object.values(title || {}), ...Object.values(quantity || {})].join(', ')];
    }
    case EMBEDDED_CHOICES_PRICE_CHANGE:
    case CHOICES_PRICE_CHANGE: {
      return [originalChoice.price, newChoice.price];
    }
    case CHOICE_AVAILABILITY_CHANGE: {
      return [
        originalChoice.availability ? 'True' : 'False',
        newChoice.availability ? 'True' : 'False',
      ];
    }
    default:
      return [change?.old_value, change?.new_value];
  }
};

export const getTypeOfChange = (typeOfChange, minMax = '') => {
  if (minMax === 'minimum') {
    return 'Minimum Quantity Change';
  }

  if (minMax === 'maximum') {
    return 'Maximum Quantity Change';
  }

  return typeOfChange;
};

export const getPatchData = (QCRequest) => {
  const typeOfChange = QCRequest.item.typeOfChange;
  let patchData;
  let oldData;

  if (typeOfChange.startsWith('Embedded')) {
    const requestItem = QCRequest.item;
    const requestPatchData = requestItem.patchData;
    const requestData = requestItem.content.data;

    patchData = requestPatchData?.variants
      ? Object.values(Object.values(requestPatchData.variants)[0].choice_groups)[0]
      : [];
    oldData = !!requestData
      ? Object.values(Object.values(requestData.variants)[0].choice_groups)[0]
      : null;
  } else {
    patchData = QCRequest.item.patchData;
    oldData = QCRequest.item.content.data;
  }

  return [patchData, oldData];
};

export const getEmbeddedPatchDataIds = (patchData) => {
  const variantId = Object.keys(patchData?.variants ?? {})?.[0];
  const variantData = patchData?.variants?.[variantId];
  const choiceGroupId = Object.keys(variantData?.choice_groups ?? {})?.[0];
  const choiceGroupData = variantData?.choice_groups?.[choiceGroupId];
  const choiceId = Object.keys(choiceGroupData?.choices ?? {})?.[0];
  const choiceData = choiceGroupData?.choices?.[choiceId];

  return {
    variantId,
    choiceGroupId,
    choiceId,
    variantData,
    choiceGroupData,
    choiceData,
  };
};
