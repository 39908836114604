import styled from 'styled-components';
import { StatusType } from '../../types';

export const HighLightBoxContainer = styled.div``;

export const HightLightBoxHeader = styled.div`
  display: flex;
  padding: 10px;
`;

export const HightLightBoxLabel = styled.div`
  flex: 1;
  justify-content: center;
  line-height: 28px;
  font-size: 18px;
`;

export const ErrorMessageWrapper = styled.div`
  padding: 10px;
`;

export const StatusBoxWrapper = styled.div`
  flex: 0 1 0%;
  white-space: nowrap;
`;

export const DishPhotoChangeContianer = styled.div`
  max-width: 166px;
`;

export const HightLightBox = styled.div`
  padding: 10px;
  background: #f5f5f5;
  border-left: 5px;
  border-left-style: solid;
  border-left-color: ${(props) => {
    switch (props.type) {
      case StatusType.Pending:
        return '#d9a732';
      case StatusType.Rejected:
        return '#d61f26';
      default:
        return 'green';
    }
  }};
`;
