import React from 'react';
import NutritionInformationSection from './NutritionInformationSection';
import NutritionDetailsSection from './NutritionDetailsSection';
import OtherInformationSection from './OtherInformationSection';
import ProducerInformationSection from './ProducerInformationSection';
import ProductDetailsSection from './ProductDetailsSection';
import { PrepackagedProps } from '../types';

const Prepackaged: React.FC<PrepackagedProps> = ({
  t,
  producerName,
  producerAddress,
  producerCountry,
  handleOnChange,
  energyOne,
  fat,
  carbohydrates,
  salt,
  energyTwo,
  saturates,
  sugars,
  protein,
  alcohol,
  netWeight,
  legalDescription,
  storageInstructions,
  instructionsForUse,
  nutritionalClaims,
  warnings,
  initialWarnings,
  handleWarningsChange,
  errors,
  isLiquid,
  handleOnChangeIsLiquid,
  sizeInLitres,
  bottleDeposit,
  gtin,
  handleSearchBarcode,
  isLoadingBarcode,
  ingredients,
  isGtinFetched,
  isGtinFound,
  isGermanVendor,
  isFinnishVendor,
  isEANRequired = false,
}) => {
  return (
    <div data-testid="nutritions-form-prepacked">
      <ProductDetailsSection
        t={t}
        gtin={gtin}
        handleSearchBarcode={handleSearchBarcode}
        isLoadingBarcode={isLoadingBarcode}
        errors={errors}
        isGtinFound={isGtinFound}
        isGtinFetched={isGtinFetched}
        data-testid="nutrition_information_product_detail_section"
        isEANRequired={isEANRequired}
      />
      <NutritionInformationSection
        t={t}
        warnings={warnings}
        initialWarnings={initialWarnings}
        handleWarningsChange={handleWarningsChange}
        errors={errors}
        isLiquid={isLiquid}
        handleOnChangeIsLiquid={handleOnChangeIsLiquid}
        ingredients={ingredients}
        handleOnChange={handleOnChange}
        isGermanVendor={isGermanVendor}
      />
      <NutritionDetailsSection
        t={t}
        handleOnChange={handleOnChange}
        energyOne={energyOne}
        fat={fat}
        carbohydrates={carbohydrates}
        salt={salt}
        energyTwo={energyTwo}
        saturates={saturates}
        sugars={sugars}
        protein={protein}
        isLiquid={isLiquid}
        errors={errors}
      />
      <OtherInformationSection
        t={t}
        handleOnChange={handleOnChange}
        alcohol={alcohol}
        netWeight={netWeight}
        legalDescription={legalDescription}
        storageInstructions={storageInstructions}
        instructionsForUse={instructionsForUse}
        nutritionalClaims={nutritionalClaims}
        sizeInLitres={sizeInLitres}
        isLiquid={isLiquid}
        bottleDeposit={bottleDeposit}
        errors={errors}
        isFinnishVendor={isFinnishVendor}
      />
      <ProducerInformationSection
        t={t}
        producerName={producerName}
        producerAddress={producerAddress}
        producerCountry={producerCountry}
        handleOnChange={handleOnChange}
        errors={errors}
      />
    </div>
  );
};

export default Prepackaged;
