import React, {useMemo} from 'react';
import { Box, TextField } from '@material-ui/core';
import { StyledAutocomplete } from '../styles';
import CheckBoxOption from './CheckBoxOption';
import handleNotApplicableLogic from './handleNotApplicableLogic';
import { hasFieldError } from '../utils';
import { AdditivesProps } from '../types';

const Additives: React.FC<AdditivesProps> = ({
  t,
  options = [],
  handleOnChange,
  value,
  errors,
  shouldShowAdditives,
}) => {
  if (!shouldShowAdditives) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasError = useMemo(() => {
    return hasFieldError('additives', errors)
  }, [errors]);

  const additivesText = t('menu_management_pandora.nutrition_information_additives');

  const onChange = (_e, value) => {
    const transformedValue = handleNotApplicableLogic(options, value);
    handleOnChange(transformedValue);
  };

  return (
    <Box mb={3}>
      <StyledAutocomplete
        data-testid="nutrition_information_additives_input"
        multiple
        options={options}
        getOptionLabel={(option) => option}
        disableCloseOnSelect
        onChange={onChange}
        renderOption={(option, { selected }) => (
          <CheckBoxOption option={option} selected={selected} />
        )}
        renderInput={(params) => (
          <TextField {...params} error={hasError} variant="standard" label={additivesText} />
        )}
        value={value}
      />
    </Box>
  );
};

export default Additives;
