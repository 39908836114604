import styled from 'styled-components';
import { Grid, Checkbox, Box, Button } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

const FIRST_COL_WIDTH = 33;

export const ChoiceWrapperGrid = styled(Grid)`
  flex-wrap: nowrap;
  position: relative;
`;

export const ChoiceGridHeader = styled(Grid)`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  @media (max-width: 500px) {
    padding: 16px 24px;
  }
`;

export const ChoiceGridText = styled(Grid)`
  font-size: 16px;
  line-height: 24px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
    line-height: 16px;
    color: #777777;
    overflow: hidden;
    width: 100%;
  }
`;

export const Desc = styled.div`
  position: static;
  width: 200px;
  height: 16px;
  left: calc(50% - 200px / 2);
  top: calc(50% - 16px / 2 + 14px);
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #777777;
  margin-top: 4px;
`;

export const ChoiceGroupNameText = styled(Grid)`
  padding-right: 5px;
  font-size: 16px;
  width: 100%;
  line-height: 24px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 14px;
  }
  .MuiGrid-grid-xs-7 {
    flex-grow: 0;
    max-width: 43.333333%;
    flex-basis: 0%;
  }
`;

export const ChoiceContainer = styled(Box)`
  display: flex;
  width: 100%;
  border: ${(props) =>
    props.hasError ? `1px solid ${props.theme.palette.error.dark}` : `1px solid #dddddd`};

  & + & {
    border-top: 0px;
  }
`;

export const HeaderItemContainer = styled(Box)`
  display: flex;
  width: calc(100% - ${FIRST_COL_WIDTH}px);
  margin-left: ${FIRST_COL_WIDTH}px;
  margin-bottom: 10px;
  border-bottom: 0.5px solid rgb(205, 205, 203);
  padding-bottom: 10px;
`;

export const RowItemContainer = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export const ActiveChoiceCheckBox = styled(Checkbox)`
  && {
    padding: 16px;
    margin-left: ${(props) => props.theme.spacing(1)}px;
    width: ${(props) => props.theme.spacing(3)}px;
    height: ${(props) => props.theme.spacing(3)}px;
    align-self: center;
  }
`;

export const GridContainer = styled(Grid)`
  padding: 26px;

  ${ActiveChoiceCheckBox} + ${RowItemContainer} & {
    padding-left: 0;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    max-height: 120px;
    padding: ${(props) => (props.hideCheckbox ? `16px` : `16px 0px`)};
  }
  .MuiGrid-grid-xs-7 {
    flex-basis: 0%;
  }
`;

export const Status = styled.div`
  padding: 12px 0px;
`;

export const CreateChoiceGroupButton = styled(Button)`
  && {
    margin-top: ${(props) => props.theme.spacing(2)}px;
    margin-bottom: ${(props) => props.theme.spacing(4)}px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
`;

export const AddIcon = styled(Add)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
`;
