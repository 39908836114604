import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import _filter from 'lodash/filter';

import { makeStyles } from '@material-ui/core/styles';
import { Close, FileCopy } from '@material-ui/icons';
import {
  Container,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Grid,
  Box,
  Button,
} from '@material-ui/core';
import styles, { tablestyles } from './styles';
import { TYPE_OF_CHANGE_PHOTOS } from '../../../App/common/constant';
import { Count } from '../../../PhotoManagement/components/CategoryFilter';
import SafeGridToggle from '../../../PhotoManagement/components/SafeGridToggle';
import SafeGridImage from '../../../PhotoManagement/components/SafeGridImage';
import {
  capitalizeFirstLetter,
  copyToClipboard,
  getDefaultLanguage,
} from '../../../utility/information';
import AppRightBar from '../../../App/layouts/AppRightBar';
import { useSnackbar } from 'notistack';
import EmptyState from '../../../components/EmptyState2/EmptyState';
import { AppContext } from '../../../App';
import { historyStyles } from './../../styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useInitialMount } from '../../../App/hooks/useInitialMount';
import { useSelector } from 'react-redux';
const useStyles = makeStyles(styles);
const useTableStyles = makeStyles(tablestyles);
const useHistoryManagementStyles = makeStyles(historyStyles);

const photoTypes = {
  [TYPE_OF_CHANGE_PHOTOS.DISH_PHOTO_CHANGE]: 'Dish Photos',
  [TYPE_OF_CHANGE_PHOTOS.HERO_PHOTO_CHANGE]: 'Hero Photos',
  [TYPE_OF_CHANGE_PHOTOS.LISTING_PHOTO_CHANGE]: 'Listing Photos',
};

const HistoryPhotoQueue = ({
  customContext,
  fwfFlags,
  setRequestCount,
  historyPhotoQueueData,
  refetchHistoryPhotoQueueData,
  rowsPerPage,
}) => {
  const { fwfDishPhotoMasterCategoryFilter, fwfDishPhotoTypeFilter, fwfPhotoSafeGrid } = fwfFlags;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const historyManagementClasses = useHistoryManagementStyles();
  const { currentCountry } = React.useContext(AppContext);

  const [photoRequests, setPhotoRequests] = useState([]);
  const [photoTypeToShow, setPhotoTypeToShow] = useState('');
  const photoRequestsByType = {};
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [pageToken, setPageToken] = useState('');
  const [displayedRequestListByPhotoType, setDisplayedRequestListByPhotoType] = useState([]);
  const [filteredDisplayRequestList, setFilteredDisplayRequestList] = useState([]);
  const [isSafeGridEnabled, setIsSafeGridEnabled] = useState(false);
  const [photoToView, setPhotoToView] = useState(null);
  const isInitialMount = useInitialMount();

  const [isDataPayloadChanged, setIsDataPayloadChanged] = useState(false);

  const filterState = useSelector((state) => state.filterState);

  useEffect(() => {
    setPhotoRequests([]);
  }, [currentCountry.geid]);

  useEffect(() => {
    if (!!historyPhotoQueueData) {
      const filteredPhotoRequests = getPhotoRequests(historyPhotoQueueData.qcRequests);

      if (isDataPayloadChanged) {
        setPhotoRequests(filteredPhotoRequests);
      } else {
        setPhotoRequests((state) => [...state, ...filteredPhotoRequests]);
      }
      //setFilteredDisplayRequestList(filteredPhotoRequests);
    } else {
      setPhotoRequests([]);
      setFilteredDisplayRequestList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyPhotoQueueData]);

  const getInputParams = useCallback(() => {
    setPageToken('');
    return {
      globalEntityID: currentCountry.geid,
      timeFrom: filterState.payload.input.timeFrom
        ? moment(filterState.payload.input.timeFrom.unix).format('X')
        : null,
      timeTo: filterState.payload.input.timeTo
        ? moment(filterState.payload.input.timeTo.unix).format('X')
        : null,
      search: filterState.payload.input.search || null,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry.geid]);

  /*   useEffect(() => {
    if (pageToken !== '') {
      refetchHistoryPhotoQueueData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageToken]); */

  useEffect(() => {
    //Change displayed requests based on type of photo filter and category filter
    let filteredDisplayRequestList = photoRequests;

    if (!!photoTypeToShow) {
      filteredDisplayRequestList = photoRequestsByType[photoTypeToShow];
      setDisplayedRequestListByPhotoType(filteredDisplayRequestList || []);
    } else {
      setDisplayedRequestListByPhotoType(photoRequests);
    }

    if (!!selectedCategory) {
      filteredDisplayRequestList = filteredDisplayRequestList.filter((request) => {
        const category = request?.metadata?.product?.Category;
        return category?.ID === selectedCategory;
      });
    }

    setFilteredDisplayRequestList(filteredDisplayRequestList || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoTypeToShow, photoRequests, selectedCategory]);

  useEffect(() => {
    let inputParams = getInputParams();
    //let tokenFlag = false;
    if (!isInitialMount) {
      /*       if (!_.isEqual(prevAmount, customContext)) {
        tokenFlag = true;
        setIsDataPayloadChanged(tokenFlag);
      } else {
        tokenFlag = false;
        setIsDataPayloadChanged(tokenFlag);
      }
      if (tokenFlag) {
        payload.pageToken = '';
      } */
      let payload = {
        input: inputParams,
      };
      refetchHistoryPhotoQueueData(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry]);

  const handleOnLoadMore = () => {
    if (historyPhotoQueueData.pageToken !== '') {
      setPageToken(historyPhotoQueueData.pageToken);
      if (isDataPayloadChanged) {
        setIsDataPayloadChanged(false);
      }
      refetchHistoryPhotoQueueData({
        pageToken: historyPhotoQueueData.pageToken,
      });
    }
  };

  const getPhotoRequests = (requestList) => {
    //Only show photo change related requests
    return _filter(requestList, (r) =>
      Object.values(TYPE_OF_CHANGE_PHOTOS).includes(r.item.typeOfChange)
    );
  };

  /*   useEffect(() => {
    //Set count in Tab each time photoRequests changes

    const requestCount = pendingPhotoQCRequestsData?.getPendingPhotoQCRequestsCount || 0;
    setRequestCount(requestCount);
    setTotalItemCount(requestCount);

    //Group all photo requests by typeOfChange whenever it changes
    const groupedPhotoRequests = _groupBy(photoRequests, (pr) => pr.item.typeOfChange);
    setPhotoRequestsByType(groupedPhotoRequests);
  }, [photoRequests, setRequestCount, pendingPhotoQCRequestsData]); */

  //Init category filter and update everytime photo type is changed
  useEffect(() => {
    let categories = [];
    displayedRequestListByPhotoType.forEach((request) => {
      const relevantProduct = request?.metadata?.product;
      const category = relevantProduct?.Category;

      if (!!category) {
        if (categories.length > 0) {
          const index = categories.findIndex((categoryAcc) => categoryAcc.id === category.ID);

          if (index === -1) {
            categories.push({
              id: category.ID,
              productCount: 1,
              title: category.Title,
            });
          } else {
            categories[index].productCount++;
          }
        } else {
          categories.push({
            id: category.ID,
            productCount: 1,
            title: category.Title,
          });
        }
      }
    });

    setCategories(categories);
  }, [displayedRequestListByPhotoType]);

  //Filter Menu Actions
  const handleFilterChange = (e) => {
    const selectedPhotoType = e.target.value;

    setPhotoTypeToShow(selectedPhotoType);
    setSelectedCategory(''); // reset category to all whenever type is changed
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;

    setSelectedCategory(selectedCategory);
  };
  //Filter Menu Actions END

  //Table Actions
  const onCopyVendorCode = (vendorCode) => (e) => {
    e.stopPropagation();
    copyToClipboard(vendorCode);
    enqueueSnackbar('Successfully copied the vendor code to clipboard', { variant: 'success' });
  };

  //Bottom Action Bar related
  const modalRooter = document.getElementById('appbar');

  if (photoRequests.length === 0) {
    return <EmptyState />;
  }
  return (
    <div style={{ display: 'flex', width: '100%' }} data-enzyme={'HistoryPhotoQueue'}>
      <AppRightBar modalRoot={modalRooter}></AppRightBar>
      <Container maxWidth="xl" className={classes.innerscroll}>
        <Box alignItems="center" display="flex" my={1}>
          <Box mr={1}>
            {fwfDishPhotoTypeFilter.variation && (
              <FormControl>
                <Select
                  value={photoTypeToShow}
                  autoWidth
                  disableUnderline
                  displayEmpty
                  onChange={handleFilterChange}
                >
                  <MenuItem value={''}>
                    All Photos <Count count={photoRequests.length} />
                  </MenuItem>
                  {Object.keys(photoTypes).map((photoTypeOfChange, index) => (
                    <MenuItem key={index} value={photoTypeOfChange}>
                      {photoTypes[photoTypeOfChange]}{' '}
                      <Count count={photoRequestsByType[photoTypeOfChange]?.length || 0} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
          <Box>
            {fwfDishPhotoMasterCategoryFilter.variation && (
              <FormControl>
                <Select
                  autoWidth
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  disableUnderline
                  displayEmpty
                >
                  <MenuItem value={''}>
                    All Categories <Count count={filteredDisplayRequestList?.length} />
                  </MenuItem>
                  {categories.map((category) => (
                    <MenuItem key={category?.id} value={category?.id}>
                      {category?.title?.[getDefaultLanguage(category?.title)] ||
                        category?.defaultTitle ||
                        ''}
                      &nbsp;
                      <Count count={category?.productCount} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
          {fwfPhotoSafeGrid != null && fwfPhotoSafeGrid.variation && (
            <SafeGridToggle onChange={setIsSafeGridEnabled} value={isSafeGridEnabled} />
          )}
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Box>
              <Paper className={tableClasses.tableRoot}>
                <Table className={tableClasses.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className={tableClasses.photoCell}>
                        Photos
                      </TableCell>
                      <TableCell align="left" />
                      <TableCell align="left">Category</TableCell>
                      <TableCell align="left">Uploaded by</TableCell>
                      <TableCell align="left">Vendor code</TableCell>
                      <TableCell align="left">Date &amp; time submitted</TableCell>
                      <TableCell>All Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredDisplayRequestList.map((currentRequest, index) => {
                      const currentRequestItem = currentRequest?.item;
                      const currentMetaData = currentRequest?.metadata;
                      const currentProductData = currentMetaData?.product;
                      const currentCategoryData = currentProductData?.Category;
                      const currentVendorCode = currentRequestItem?.vendorCode;
                      const currentPatchData = currentRequestItem?.patchData || {};
                      const currentPhotoUrl =
                        currentPatchData?.location || currentPatchData?.photo_url;

                      return (
                        <TableRow key={index}>
                          <TableCell
                            align="left"
                            className={tableClasses.photoCell}
                            onClick={(e) => {
                              e.stopPropagation();
                              setPhotoToView({
                                src: currentPhotoUrl + `?time=${currentRequestItem?.timestamp}`,
                                photoType: currentPatchData?.type,
                              });
                            }}
                          >
                            <div className={tableClasses.safeGridImageContent}>
                              <SafeGridImage
                                alt="Dish"
                                src={currentPhotoUrl}
                                className={tableClasses.dishPhotoImage}
                                isSafeGridEnabled={isSafeGridEnabled}
                                photoType={currentPatchData?.type}
                              />
                            </div>
                          </TableCell>
                          <TableCell
                            align="left"
                            className={tableClasses.productInformation}
                            data-enzyme="product-title"
                          >
                            {['hero', 'listing'].includes(currentPatchData?.type) ? (
                              <Typography className={tableClasses.productTitle}>
                                {capitalizeFirstLetter(currentPatchData?.type)} Photo
                              </Typography>
                            ) : (
                              <>
                                {!!currentProductData ? (
                                  <>
                                    <Typography className={tableClasses.productTitle}>
                                      {
                                        currentProductData?.Title[
                                          getDefaultLanguage(currentProductData?.Title)
                                        ]
                                      }
                                    </Typography>

                                    <Typography className={tableClasses.productDescription}>
                                      {
                                        currentProductData?.Description?.[
                                          getDefaultLanguage(currentProductData?.Description)
                                        ]
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  <Typography className={tableClasses.productTitle}>
                                    Product data is empty...
                                  </Typography>
                                )}
                              </>
                            )}
                          </TableCell>
                          <TableCell className={tableClasses.productInformation}>
                            {
                              currentCategoryData?.Title?.[
                                getDefaultLanguage(currentCategoryData?.Title)
                              ]
                            }
                          </TableCell>
                          <TableCell align="left" className={tableClasses.updatedBy}>
                            {!!currentRequestItem?.assignedAgent ? (
                              <Typography className={classes.agentEmail}>
                                {currentRequestItem.assignedAgent}
                              </Typography>
                            ) : (
                              <Typography className={classes.vendorName}>
                                {currentVendorCode}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell
                            align="left"
                            onClick={onCopyVendorCode(currentVendorCode)}
                            className={`${tableClasses.vendorCode} vendorCode`}
                          >
                            <Box display="flex" alignItems="flex-start">
                              <Box component="span" display="inline" mr={1} color="gray">
                                <FileCopy />
                              </Box>
                              {currentRequestItem?.vendorCode}
                            </Box>
                          </TableCell>
                          <TableCell className={tableClasses.dateSubmitted}>
                            {moment(currentRequestItem?.timestamp, 'X').format('DD MMM YYYY, H:mm')}
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              className={
                                historyManagementClasses.statusButton +
                                ' ' +
                                (currentRequestItem.status === 'APPROVE'
                                  ? historyManagementClasses.statusApproved
                                  : historyManagementClasses.statusRejected)
                              }
                            >
                              {currentRequestItem.status}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {historyPhotoQueueData.pageToken !== '' && (
                  <div className={historyManagementClasses.loadMore}>
                    <Button
                      onClick={() => handleOnLoadMore()}
                      className={historyManagementClasses.loadMoreButton}
                      size="large"
                      variant="contained"
                      color="primary"
                    >
                      <ExpandMoreIcon />
                      Load more data
                      <ExpandMoreIcon />
                    </Button>
                  </div>
                )}
                {!!photoToView && (
                  <Modal open={!!photoToView}>
                    <>
                      <div className={tableClasses.photoEnlargeContainerHeader}>
                        <IconButton
                          style={{ float: 'right' }}
                          key="close"
                          aria-label="Close"
                          color="inherit"
                          onClick={() => setPhotoToView(null)}
                        >
                          <Close className={classes.icon} />
                        </IconButton>
                      </div>
                      <SafeGridImage
                        alt={photoToView.photoType}
                        src={photoToView.src}
                        className={tableClasses.photoEnlargeContainer}
                        isSafeGridEnabled={isSafeGridEnabled}
                        photoType={photoToView.photoType}
                        imageWidth={770}
                        imageHeight={563}
                      />
                    </>
                  </Modal>
                )}
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default HistoryPhotoQueue;
