import cookieAbstract from './cookieAbstract';
import localStorageAbstract from './localStorageAbstract';

export enum storageKeys {
  ACCESS_TOKEN = 'token',
  REFRESH_TOKEN = 'refresh_token',
}

class _CookieStorage extends cookieAbstract<storageKeys> {}

class _LocalStorage extends localStorageAbstract<storageKeys> {}

const config = {
  driver: 'cookieStorage',
};
let driverInstance;
if (config.driver === 'localStorage') {
  driverInstance = new _LocalStorage();
} else if (config.driver === 'cookieStorage') {
  driverInstance = new _CookieStorage();
}

export const Storage = driverInstance;
