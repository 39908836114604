import React, { useRef, useState, useReducer, useMemo, useCallback } from 'react';
import {
  Box,
  Grid,
  Typography,
  Card,
  Button,
  CardContent,
  TextareaAutosize,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _debounce from 'lodash/debounce';

import usePhotoCount from './usePhotoCount';
import styles from './styles';
import axios from '../App/utility/axios';
import config from '../App/config';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(styles);

const initialReducer = {
  isOpen: false,
  isApproval: true,
  isSuccess: false,
};

const modalReducer = (state, action) => {
  switch (action.type) {
    case 'APPROVAL_SUCCESS':
      return {
        ...state,
        isOpen: true,
        isApproval: true,
        isSuccess: true,
      };
    case 'APPROVAL_FAILED':
      return {
        ...state,
        isOpen: true,
        isApproval: true,
        isSuccess: false,
      };
    case 'REJECTION_SUCCESS':
      return {
        ...state,
        isOpen: true,
        isApproval: false,
        isSuccess: true,
      };
    case 'REJECTION_FAILED':
      return {
        ...state,
        isOpen: true,
        isApproval: false,
        isSuccess: false,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};

const RiderPhotos = ({ currentCountry }) => {
  const classes = useStyles();
  const geID = currentCountry.geid;

  const count = usePhotoCount(geID);
  const { enqueueSnackbar } = useSnackbar();

  const [downloadRewardsReportLoading, setDownloadRewardsReportLoading] = useState(false);
  const [downloadApprovedReportLoading, setDownloadApprovedReportLoading] = useState(false);
  const [approveRiderPhotosLoading, setApproveRiderPhotosLoading] = useState(false);
  const [rejectRiderPhotosLoading, setRejectRiderPhotosLoading] = useState(false);
  const riderPhotosIdsRef = useRef(null);
  //Used for input validation feedback
  const [errorMessage, setErrorMessage] = useState('');
  const [idCount, setIdCount] = useState(0);

  const [modalParams, dispatch] = useReducer(modalReducer, initialReducer);

  const downloadRewardsReport = async () => {
    setDownloadRewardsReportLoading(true);
    try {
      const response = await downloadReport('approved');
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/vnd.ms-excel' })
      );
      window.open(url);
    } catch (err) {
      enqueueSnackbar(err?.message || `File downloading operation failed.`, { variant: 'error' });
    } finally {
      setDownloadRewardsReportLoading(false);
    }
  };

  const downloadAgentApprovalReport = async () => {
    setDownloadApprovedReportLoading(true);
    try {
      const response = await downloadReport('new');
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/vnd.ms-excel' })
      );
      window.open(url);
    } catch (err) {
      enqueueSnackbar(err?.message || `File downloading operation failed.`, { variant: 'error' });
    } finally {
      setDownloadApprovedReportLoading(false);
    }
  };

  const downloadReport = (queryParamStatus) => {
    return axios({
      url: `${config.fuzeRiderPhotosApi}/${geID}/file?status=${queryParamStatus}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
        'x-api-key': config.fuzeRiderPhotosApiKey,
      },
    });
  };

  const validatePhotoIds = useCallback((photoIds) => {
    setIdCount(photoIds.length);

    if (photoIds.length === 0) {
      return false;
    }
    if (photoIds.length > 50) {
      setErrorMessage('Maximum 50 codes only');
      return false;
    }
    // Check format of ids provided
    // Format is lowercase hexadecimal in the format, 8-4-4-4-12
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    let allCorrectFormat = true;
    const wronglyFormatted = [];
    for (let i = 0; i < photoIds.length; i++) {
      let isCorrectFormat = regex.test(photoIds[i]);

      if (!isCorrectFormat) {
        wronglyFormatted.push(photoIds[i]);
        allCorrectFormat = false;
      }
    }

    if (!allCorrectFormat) {
      const wrongCount = wronglyFormatted.length;
      setErrorMessage(
        `There ${
          wrongCount > 1 ? `are ${wrongCount} IDs` : `is ${wrongCount} ID`
        } with incorrect format. Please check the list again.`
      );
    } else {
      setErrorMessage('');
    }

    return allCorrectFormat;
  }, []);

  const getPhotoIdArray = useCallback(() => {
    const riderPhotoIdsStr = riderPhotosIdsRef.current.value;

    return riderPhotoIdsStr
      ? riderPhotoIdsStr
          .split(/[\n,]+/)
          .map((photoId) => photoId.replaceAll('\n', '').trim())
          .filter((element) => !!element)
      : [];
  }, [riderPhotosIdsRef]);

  const idsOnchange = useCallback(() => {
    const photoIds = getPhotoIdArray();
    validatePhotoIds(photoIds);
  }, [getPhotoIdArray, validatePhotoIds]);

  const debouncedValidation = useMemo(() => _debounce(idsOnchange, 300), [idsOnchange]);

  const rejectRiderPhotoIds = async () => {
    const photoIds = getPhotoIdArray();
    //Not necessary at this point since already validated onChange but just leaving it just in case button disabled state is bypassed
    const isValidPhotoIds = validatePhotoIds(photoIds);
    if (!isValidPhotoIds) return;

    setRejectRiderPhotosLoading(true);
    try {
      await processRiderPhotos(photoIds, 'rejected');
      dispatch({ type: 'REJECTION_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'REJECTION_FAILED' });
    } finally {
      setRejectRiderPhotosLoading(false);
    }
  };

  const approveRiderPhotoIds = async () => {
    const photoIds = getPhotoIdArray();
    const isValidPhotoIds = validatePhotoIds(photoIds);
    if (!isValidPhotoIds) return;

    setApproveRiderPhotosLoading(true);
    try {
      await processRiderPhotos(photoIds, 'approved');
      dispatch({ type: 'APPROVAL_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'APPROVAL_FAILED' });
    } finally {
      setApproveRiderPhotosLoading(false);
    }
  };

  const processRiderPhotos = (photoIds, status) => {
    const body = {
      ids: photoIds,
      status,
    };
    return axios({
      url: `${config.fuzeRiderPhotosApi}/${geID}/photo/process`,
      method: 'POST',
      data: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': config.fuzeRiderPhotosApiKey,
      },
    });
  };

  const handleClose = () => {
    dispatch({ type: 'CLOSE_MODAL' });
  };

  return (
    <Box p={3}>
      <Box mb={2}>
        <Typography variant="h4" gutterBottom>
          Rider Photos QC
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item sm={12} md={4}>
          <Box mb={2}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Number of Photos: {count}
                </Typography>
                <Typography variant="body2">
                  Download the file required to review all the menu photos submitted by riders.
                </Typography>
                <CardActions style={{ paddingLeft: 0 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={downloadApprovedReportLoading}
                    onClick={downloadAgentApprovalReport}
                    classes={classes}
                  >
                    {downloadApprovedReportLoading ? 'Downloading' : 'Download Agent Approval File'}
                  </Button>
                </CardActions>
              </CardContent>
            </Card>
          </Box>

          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Rewards Report
              </Typography>
              <Typography variant="body2">
                Download the file required for regional commercial to review the rewards. The report
                will include last 3 months of data. For more historical data, please contact
                XXX@XXX.com
              </Typography>
              <CardActions style={{ paddingLeft: 0 }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={downloadRewardsReportLoading}
                  onClick={downloadRewardsReport}
                  classes={classes}
                >
                  {downloadRewardsReportLoading ? 'Downloading' : 'Download Rewards Report'}
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Process Rider Photos
              </Typography>
              <Typography gutterBottom>
                Bulk enter the ID code to approve or reject rider photos. Maximum 50 ID codes at
                once.
              </Typography>
              <TextareaAutosize
                ref={riderPhotosIdsRef}
                aria-label="id code"
                rows={5}
                style={{
                  width: '100%',
                  overflow: 'auto',
                }}
                onChange={debouncedValidation}
              />
              {!!errorMessage && <Typography style={{ color: 'red' }}>{errorMessage}</Typography>}
              <CardActions
                style={{ paddingLeft: 0, paddingRight: 0, justifyContent: 'space-between' }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={!!errorMessage || idCount === 0 || rejectRiderPhotosLoading}
                  onClick={rejectRiderPhotoIds}
                  style={
                    !!errorMessage || idCount === 0 || rejectRiderPhotosLoading
                      ? {}
                      : {
                          backgroundColor: '#c20018',
                          color: '#ffffff',
                        }
                  }
                >
                  {rejectRiderPhotosLoading ? 'Rejecting' : 'Reject'}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!!errorMessage || idCount === 0 || approveRiderPhotosLoading}
                  onClick={approveRiderPhotoIds}
                  classes={classes}
                >
                  {approveRiderPhotosLoading ? 'Approving' : 'Approve'}
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog open={modalParams.isOpen} onClose={handleClose}>
        <DialogTitle>
          {modalParams.isApproval ? 'Approval' : 'Rejection'}{' '}
          {modalParams.isSuccess ? 'Success' : 'Failed'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {modalParams.isSuccess ? (
              <span>
                {idCount > 1 ? `${idCount} codes have` : `${idCount} code has`} been successfully{' '}
                {modalParams.isApproval ? 'approved' : 'rejected'}.
              </span>
            ) : (
              <span>There has been a technical issue, please try again later.</span>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={'contained'} color={'primary'} onClick={handleClose}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RiderPhotos;
