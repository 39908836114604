export const getOldPatchData = (QCRequest, inputData, patchData) => {
  Object.keys(patchData).forEach((data) => {
    const request = patchData[data];

    if (request.updated) {
      const queryDataIndex = inputData
        .map(function (e) {
          return e.qcRequestID;
        })
        .indexOf(data);
      const changesIndex = QCRequest.map(function (e) {
        return e.requestId;
      }).indexOf(data);

      if (QCRequest[changesIndex]?.item?.patchData?.variants) {
        const variantIds = Object.keys(QCRequest[changesIndex]?.item?.patchData?.variants);
        let correctVariantId;
        variantIds.forEach((id) => {
          if (id.includes('_')) {
            correctVariantId = id.split('_')[0];
            QCRequest[changesIndex].item.patchData.variants[correctVariantId] =
              QCRequest[changesIndex].item.patchData.variants[id];

            delete QCRequest[changesIndex].item.patchData.variants[id];
          }
        });
      }

      inputData[queryDataIndex].oldPatchData = QCRequest[changesIndex].item.patchData;
    }
  });

  return inputData;
};
