import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { filterStyles } from './styles';
import { Count } from '../CategoryFilter';
import { fetchPhotoTypes } from '../../containers/ManagePhoto/helpers';

const useStyles = makeStyles(filterStyles);

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const STATUS = 'pending';

const PhotoFilter = ({ open, value, country, onChange }) => {
  const classes = useStyles();
  const [photoTypes, setPhotoTypes] = useState([]);
  useEffect(() => {
    async function loadData() {
      try {
        const photoTypes = await fetchPhotoTypes({ country, status: STATUS });
        if (photoTypes.length) setPhotoTypes(photoTypes);
      } catch (error) {
        console.error(error);
      }
    }
    loadData();
  }, [country]);

  const total = photoTypes.find((photoType) => photoType.id === 'total') || null;

  return (
    <FormControl>
      <Select
        open={open}
        autoWidth
        className={classes.select}
        disableUnderline
        displayEmpty
        classes={{ root: classes.root }}
        value={value}
        onChange={onChange}
        data-cp="photo-filter"
      >
        <MenuItem value="">All Photos {total && <Count count={total.count} />}</MenuItem>
        {photoTypes
          .filter((photoType) => photoType.id !== 'total')
          .map((photoType, idx) => (
            <MenuItem key={idx} value={photoType.id}>
              {capitalize(photoType.id)} Photos <Count count={photoType.count} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default PhotoFilter;
