import _ from 'lodash';
import { COUNTRY_LIST } from '../App/common/constant';
import Cookies from 'js-cookie';

export function capitalizeFirstLetter(string) {
  if(!string){
    return null;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getCurrentCountry(countryCode) {
  let country = COUNTRY_LIST[countryCode];
  if (!countryCode || !country) {
    countryCode = 'SG';
    Cookies.set('country', 'SG', { sameSite: 'None', secure: true });
  }

  return COUNTRY_LIST[countryCode];
}

export function copyToClipboard(text) {
  if (window.clipboardData && window.clipboardData.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text);
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    var textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('Copy to clipboard failed !', ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
}

// {en_TW: '', 'zh_TW: 'yyy'}

/** @param {Record<string, string|undefined>} [languages]
 *  If en_XX's value is present, return en_XX.
 *  Else fallback to the alternative languagekey if value is present.
 *  If no values are present or empty languages obj, return undefined.
 */

export function getDefaultLanguage(languages) {
  if (languages == null) return undefined;

  const enLanKey = Object.keys(languages).filter((x) => _.startsWith(x, 'en_'))[0];
  if (enLanKey && languages[enLanKey]) {
    return enLanKey;
  }

  /** @type {string | undefined} */
  let firstLanguageKey;
  for (const languageKey in languages) {
    if (!languages[languageKey]) continue;
    if (firstLanguageKey == null) firstLanguageKey = languageKey;
    if (_.startsWith(languageKey, 'en_')) return languageKey;
  }

  return firstLanguageKey;
}

export const isProduction = process.env.REACT_APP_ENV === 'production';

export const getMutationRequestDefaultData = (
  request,
  currentUser,
  currentCountry,
  additionalData = {}
) => {
  return {
    qcRequestID: request.requestId,
    version: request.version,
    assignedAgent: currentUser?.sub,
    metaData: {
      agentRoles: currentUser?.permissions?.['ops-portal-fuze']?.[currentCountry.geid],
      geid: currentCountry.geid,
    },
    geid: currentCountry.geid,
    ...additionalData,
  };
};
