import * as React from 'react';
import { useTheme, InputAdornment, Grid, makeStyles, createStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DateTimePicker, EndDateTimePicker } from './style';
import { DateTimeRangePickerProps } from './type';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    container: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    customDatePicker: {
      '& > div': {
        backgroundColor: '#F4F6F6',
        borderRadius: '4px',
        border: 'none',

        '&:before': {
          content: 'initial',
        },
        '&:after': {
          borderColor: '#333',
        },
      },

      '& label.Mui-focused': {
        color: '#677374',
      },
    },
  })
);
const DateRangePicker: React.FC<DateTimeRangePickerProps> = ({
  startLabel,
  endLabel,
  toLabel,
  fromLabel,
  onChange,
  dateRange,
  focusedDateRange = [],
  variant,
  className,
  separatorLabel = `to`,
  ...props
}) => {
  const theme = useTheme();
  const [startDate, endDate] = dateRange;
  const [focusedStartDate, focusedEndDate] = focusedDateRange;
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DateTimePicker
              className={classes.customDatePicker}
              label={startLabel}
              helperText={fromLabel}
              value={startDate}
              initialFocusedDate={focusedStartDate}
              onChange={(dateTimeMomentObject) =>
                onChange({ type: 'SET_START_DATE', date: dateTimeMomentObject })
              }
              inputVariant={`filled`}
              animateYearScrolling
              disableFuture
              required
              variant={variant}
              theme={theme}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodayIcon />
                  </InputAdornment>
                ),
              }}
              {...props}
            />
          </Grid>
          <Grid item xs={12}>
            <EndDateTimePicker
              className={classes.customDatePicker}
              label={endLabel}
              helperText={toLabel}
              value={endDate}
              initialFocusedDate={focusedEndDate}
              disableFuture
              onChange={(dateTimeMomentObject) =>
                onChange({ type: 'SET_END_DATE', date: dateTimeMomentObject })
              }
              inputVariant={`filled`}
              animateYearScrolling
              required
              variant={variant}
              theme={theme}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodayIcon />
                  </InputAdornment>
                ),
              }}
              {...props}
            />
          </Grid>
        </Grid>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default DateRangePicker;
