const countryToCodeAndSymbol = {
  SG: [
    {
      code: 'en_SG',
      symbol: 'EN',
    },
    {
      code: 'zh_SG',
      symbol: '中文',
    },
  ],
  CZ: [
    {
      code: 'en_CZ',
      symbol: 'EN',
    },
    {
      code: 'cs_CZ',
      symbol: 'CS',
    },
  ],
  MY: [
    {
      code: 'en_MY',
      symbol: 'EN',
    },
    {
      code: 'zh_MY',
      symbol: '中文',
    },
  ],
  PH: [
    {
      code: 'en_PH',
      symbol: 'EN',
    },
  ],
  BD: [
    {
      code: 'en_BD',
      symbol: 'EN',
    },
  ],
  PK: [
    {
      code: 'en_PK',
      symbol: 'EN',
    },
  ],
  HK: [
    {
      code: 'en_HK',
      symbol: 'EN',
    },
    {
      code: 'zh_HK',
      symbol: '中文',
    },
  ],
  TW: [
    {
      code: 'zh_TW',
      symbol: '中文',
    },
    {
      code: 'en_TW',
      symbol: 'EN',
    },
  ],
  TH: [
    {
      code: 'en_TH',
      symbol: 'EN',
    },
    {
      code: 'th_TH',
      symbol: 'TH',
    },
  ],
  RO: [
    {
      code: 'ro_RO',
      symbol: 'RO',
    },
    {
      code: 'en_GB',
      symbol: 'EN',
    },
  ],
  BG: [
    {
      code: 'bg_BG',
      symbol: 'BG',
    },
    {
      code: 'en_BG',
      symbol: 'EN',
    },
  ],
  CA: [
    {
      code: 'en_CA',
      symbol: 'EN',
    },
    {
      code: 'fr_CA',
      symbol: 'FR',
    },
  ],
  NO: [
    {
      code: 'nb_NO',
      symbol: 'NB',
    },
    {
      code: 'en',
      symbol: 'EN',
    },
  ],
  FI: [
    {
      code: 'fi_FI',
      symbol: 'FI',
    },
    {
      code: 'en',
      symbol: 'EN',
    },
  ],
  SE: [
    {
      code: 'sv_SE',
      symbol: 'SV',
    },
    {
      code: 'en',
      symbol: 'EN',
    },
  ],
  LA: [
    {
      code: 'lo_LA',
      symbol: 'LA',
    },
    {
      code: 'en_LA',
      symbol: 'EN',
    },
    {
      code: 'zh_LA',
      symbol: 'ZH',
    },
  ],
  KH: [
    {
      code: 'en_KH',
      symbol: 'EN',
    },
    {
      code: 'km_KH',
      symbol: 'KH',
    },
    {
      code: 'zh_KH',
      symbol: 'ZH',
    },
  ],
  MM: [
    {
      code: 'en_MM',
      symbol: 'EN',
    },
    {
      code: 'my_MM',
      symbol: 'MY',
    },
  ],
  HU: [
    {
      code: 'hu_HU',
      symbol: 'HU',
    },
    {
      code: 'en_HU',
      symbol: 'EN',
    },
  ],
  JP: [
    {
      code: 'ja_JP',
      symbol: '日本語',
    },
    {
      code: 'en_JP',
      symbol: 'EN',
    },
  ],
  AT: [
    { code: 'de_AT', symbol: 'DE' },
    { code: 'en_AT', symbol: 'EN' },
  ],
  DE: [
    { code: 'de_DE', symbol: 'DE' },
    { code: 'en_DE', symbol: 'EN' },
  ],
  SK: [
    { code: 'sk_SK', symbol: 'SK' },
    { code: 'en_SK', symbol: 'EN' },
  ],
  KW: [
    { code: 'En', symbol: 'EN' },
    { code: 'Ar', symbol: 'AR' },
  ],
  DK: [
    { code: 'Dk', symbol: 'DK' },
    { code: 'en_DK', symbol: 'EN' },
  ],
  SA: [
    { code: 'ar', symbol: 'AR' },
    { code: 'en', symbol: 'EN' },
  ],
  BH: [
    { code: 'Ar', symbol: 'AR' },
    { code: 'En', symbol: 'EN' },
  ],
  QA: [
    { code: 'Ar', symbol: 'AR' },
    { code: 'En', symbol: 'EN' },
  ],
  OM: [
    { code: 'Ar', symbol: 'AR' },
    { code: 'En', symbol: 'EN' }
  ],
  JO: [
    { code: 'Ar', symbol: 'AR' },
    { code: 'En', symbol: 'EN' },
  ],
  EG: [
    { code: 'Ar', symbol: 'AR' },
    { code: 'En', symbol: 'EN' },
  ],
  IQ: [
    { code: 'Ar', symbol: 'AR' },
    { code: 'En', symbol: 'EN' },
  ],
  AE: [
    { code: 'Ar', symbol: 'AR' },
    { code: 'En', symbol: 'EN' },
  ],
};

export const codeToSymbol = {
  en: 'EN', // FI and SE need this
  en_SG: 'EN',
  en_CZ: 'EN',
  cs_CZ: 'CZ',
  zh_SG: '中文',
  en_MY: 'EN',
  zh_MY: '中文',
  ms_MY: 'BM',
  en_PH: 'EN',
  en_BD: 'EN',
  en_PK: 'EN',
  en_HK: 'EN',
  zh_HK: '中文',
  zh_TW: '中文',
  en_TW: 'EN',
  en_TH: 'EN',
  th_TH: 'TH',
  ja_TH: 'JA',
  ro_RO: 'RO',
  bg_BG: 'BG',
  en_BG: 'EN',
  en_CA: 'EN',
  fr_CA: 'FR',
  nb_NO: 'NB',
  en_GB: 'EN',
  fi_FI: 'FI',
  sv_SE: 'SV',
  lo_LA: 'LA',
  en_LA: 'EN',
  zh_LA: '中文',
  en_KH: 'EN',
  km_KH: 'KH',
  zh_KH: '中文',
  en_MM: 'EN',
  my_MM: 'MY',
  en_HU: 'EN',
  hu_HU: 'HU',
  ja_JP: 'JP',
  en_JP: 'EN',
  de_AT: 'DE',
  en_AT: 'EN',
  de_DE: 'DE',
  en_DE: 'EN',
  sk_SK: 'SK',
  en_SK: 'EN',
  Ar: 'العربية',
  ar: 'العربية',
  en_AR: 'EN',
  tr_TR: 'Türkçe',
  En: 'EN', // KW needs this
};

export default countryToCodeAndSymbol;
