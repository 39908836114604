import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const EmptyState = () => {
  return (
    <Grid container style={{ flex: 1 }} direction="column" justifyContent="center" alignItems="center">
      <img alt="empty state" src="/assets/MenuEmpty.png" width="auto" height="157" />
      <Typography variant="h4" gutterBottom>
        Search with Restaurant Name or Salesforce ID (GRID)
      </Typography>
      <Typography>
        From your menu creation case on Salesforce. This will let you check if we have the menu
        data.
      </Typography>
    </Grid>
  );
};

export default EmptyState;
