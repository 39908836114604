import React, { useState, useEffect, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import _keyBy from 'lodash/keyBy';
import moment from 'moment';
import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { TYPE_OF_CHANGE_PHOTOS } from 'App/common/constant';
import Form from '../../../MenuManagement/containers/Home/NewProductDetails/ProductDetailForm';
import { getCurrentCountry } from '../../../App/utility';
import { AppContext, UserContext } from '../../../App';
import { getChangeRequestGraphQLInput } from './helpers';
import { getFromBrandProxy } from '../../../MenuManagement/containers/utils';
import useProductDetails from '../../../MenuManagement/containers/useProductDetails';
import {
  ITEM_CHANGES_QUERY,
  TALABAT_PROCESS_CHANGE_REQUESTS_QUERY,
  PROCESS_CHANGE_REQUESTS_QUERY,
} from '../../../MenuManagement/containers/Home/helpers';
import AppRightBar from '../../../App/layouts/AppRightBar';
import { defaultErrorMessage } from '../../../MenuManagement/utility/information';
import { Alert, AlertTitle } from '@material-ui/lab';
import ActionBar from '../ManagePhoto/ActionBar';
import UploadPhotoModal from '../../components/UploadPhotoModal';
import RejectModal from '../../../App/components/RejectModal';
import { getPhotoRejectReasons } from '../../common/constants/rejectReason';
import { batchImageDownload, uploadPhotos } from '../ManagePhoto/helpers';
import Notification from 'App/components/Notification';
import { formatErrorForDisplay } from 'utility';
import { TicketResolvedScreen } from '../../../components/TicketResolvedScreen';
import LoadingState from '../../../components/LoadingState';
import ErrorScreen from '../../../components/ErrorScreen';
import StatusInfoBox from '../../../components/StatusInfoBox';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { TYPE_OF_CHANGE } from '../../../App/common/constant';
import Cookies from 'js-cookie';
import RelatedItems from 'components/RelatedItems';
import { useSelector } from 'react-redux';

const DishPhotoReview = ({ match }) => {
  const location = useLocation();
  const { getHistoricalData } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const showHistoricalData = getHistoricalData === 'true';
  const { enqueueSnackbar } = useSnackbar();
  const { vendorCode, countryCode, item_id: productId } = match.params;
  const isProductCreationRequest = productId.startsWith('NEW');
  const { currentCountry, setCurrentCountry } = React.useContext(AppContext);
  const currentUser = React.useContext(UserContext);
  const [countryData, setCountryData] = useState(getCurrentCountry(countryCode));
  const [isPermission, setIsPermission] = useState(
    currentUser?.permissions?.['ops-portal-fuze'][countryData.geid]?.length > 0
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const [notification, setNotification] = useState({
    type: 'success',
    message: '',
    open: false,
  });
  const [downloading, setDownloadInProgress] = useState(false);
  const [uploadPhotoLoading, setUploadPhotoLoading] = useState(false);
  const [isUploadPhotoModalOpen, setIsUploadPhotoModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

  const [categories, setCategories] = useState([]);
  const [product, setProduct] = useState(null);
  const [[productStatus, alertSeverity], setProductStatus] = useState([]);
  // using changeRequests as array is to fit the utility function we have
  const [changeRequests, setChangeRequests] = useState([]);
  const [hasRequestResponse, setHasRequestResponse] = useState(false);
  const [hasImageUrls, setHasImageUrls] = useState(false);
  const [changeRequestMeta, setChangeRequestMeta] = useState({
    dishPhotoUrl: '',
    vendorName: '',
    dateSubmitted: '',
  });
  const [isEntityDataLoaded, setIsEntityDataLoaded] = useState(true);
  const changeRequestById = _keyBy(changeRequests, 'requestId');

  const { category, variants, compiledDescription, compiledTitle } = useProductDetails(
    product,
    changeRequests
  );
  const fwfFlags = useSelector((state) => state.fwfState.flags);

  //Fetch product data from previous QCRequests
  const { data: productData } = useQuery(ITEM_CHANGES_QUERY, {
    variables: {
      itemID: productId,
      latestCaseIDOnly: true,
      statusList: ['APPROVE', 'REJECT', 'PENDING'],
    },
    displayName: 'ProductItemQuery',
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    skip: !productId.startsWith('NEW_') || !isPermission,
  });

  useEffect(() => {
    const existingRequests = productData?.getItemByItemID?.qcRequests ?? [];

    const productCreateRequest = existingRequests.find(
      (request) => request.item.typeOfChange === TYPE_OF_CHANGE.PRODUCT_CREATE
    );

    if (!!productCreateRequest) {
      const productCreatePatchData = productCreateRequest.item.patchData;
      const reformattedProductData = {
        ...productCreatePatchData,
        variants: Object.values(productCreatePatchData.variants),
      };

      setProduct(reformattedProductData);

      const requestStatus = productCreateRequest.item.status;
      let statusSeverity;
      switch (requestStatus) {
        case 'APPROVE':
          statusSeverity = 'success';
          break;
        case 'REJECT':
          statusSeverity = 'error';
          break;
        case 'PENDING':
          statusSeverity = 'warning';
          break;
        default:
          statusSeverity = 'info';
      }

      setProductStatus([requestStatus, statusSeverity]);
    } else {
      setProductStatus([]);
    }
  }, [productData]);

  //Set current country based on URL
  useEffect(() => {
    setCountryData(getCurrentCountry(countryCode));
    setIsPermission(currentUser?.permissions?.['ops-portal-fuze'][countryData.geid]?.length > 0);

    if (!isPermission) {
      window.location.href = '/access-denied';
      return;
    }

    Cookies.set('country', countryData.code, { sameSite: 'None', secure: true });
    setCurrentCountry(countryData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCurrentCountry, countryCode]);

  const onPageLoad = useCallback(async () => {
    // init category
    getFromBrandProxy('categories', vendorCode, currentCountry.geid).then(({ data }) => {
      setCategories(data);
    });

    // init product
    if (!isProductCreationRequest) {
      getFromBrandProxy('product', vendorCode, currentCountry.geid, productId).then(({ data }) => {
        if (data) {
          setHasImageUrls(data?.image_urls?.length > 0);
          setProduct(data);
        }
      });
    }
  }, [isProductCreationRequest, productId, vendorCode, currentCountry.geid]);

  // on page load
  useEffect(() => {
    const loadPage = async () => {
      setIsEntityDataLoaded(false);
      await onPageLoad();
      setIsEntityDataLoaded(true);
    };

    if (isPermission) loadPage();
  }, [onPageLoad, countryCode, currentCountry, isPermission]);

  const {
    data: getItemByIdData,
    loading,
    error,
  } = useQuery(ITEM_CHANGES_QUERY, {
    variables: {
      itemID: productId,
      isPhotoChange: true,
      statusList: ['APPROVE', 'REJECT', 'PENDING', 'INVALID'],
      ...(showHistoricalData
        ? {
            latestCaseIDOnly: true,
            statusList: ['APPROVE', 'REJECT'],
          }
        : {}),
    },
    displayName: 'PhotoRequestQuery',
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    skip: !isPermission,
  });
  const isQueryLoaded = !error && !loading;

  useEffect(() => {
    if (isQueryLoaded) {
      const qcRequestChanges = getItemByIdData?.getItemByItemID?.qcRequests || [];

      const dishPhotoChanges = qcRequestChanges.filter(
        (data) =>
          (data.item.typeOfChange.includes(TYPE_OF_CHANGE_PHOTOS.DISH_PHOTO_CHANGE) ||
            data.item.typeOfChange.includes(TYPE_OF_CHANGE_PHOTOS.EMBEDDED_PHOTO_UPDATE)) &&
          data.item.patchData !== null
      );

      setHasRequestResponse(dishPhotoChanges.length > 0);
      let pendingRequests = dishPhotoChanges;
      let newRequest = [];
      let aStatus, bStatus, aTime, bTime;
      if (!showHistoricalData) {
        newRequest = dishPhotoChanges.sort((a, b) => {
          aStatus = a?.item?.status.toLowerCase();
          bStatus = b?.item?.status.toLowerCase();
          aTime = a?.item?.timestamp;
          bTime = b?.item?.timestamp;

          if (aTime === bTime) {
            if (aStatus === 'pending') {
              return -1;
            } else if (bStatus === 'pending') {
              return 1;
            }
          }
          return bTime - aTime;
        });

        pendingRequests = newRequest.filter((req, i) => {
          return req?.item?.status?.toLowerCase() === 'pending';
        });
      }

      setChangeRequests(pendingRequests);
      if (!pendingRequests[0]) return;

      const vendorName = pendingRequests[0].meta.vendorName || '';
      const dishPhotoUrl =
        pendingRequests[0].item.patchData.location ||
        pendingRequests[0].item.patchData.photo_url ||
        '';
      const dateSubmitted = pendingRequests[0].item.timestamp || '';

      setChangeRequestMeta({
        vendorName,
        dishPhotoUrl,
        dateSubmittedString: moment(parseInt(dateSubmitted) * 1000).format('DD MMM YYYY, H:mm'),
      });
    }
  }, [getItemByIdData, isQueryLoaded, showHistoricalData]);

  let talabatChangeRequestsQuery = fwfFlags.fwfTBCheckIfThereIsMore.variation
    ? TALABAT_PROCESS_CHANGE_REQUESTS_QUERY
    : PROCESS_CHANGE_REQUESTS_QUERY;

  //Submit handlers
  const [processChangeRequests] = useMutation(talabatChangeRequestsQuery, {
    onError: () => {},
  });

  const processChangeRequestsThen = (data, message) => {
    if (data?.data?.completeQCRequests) {
      enqueueSnackbar(message, {
        variant: 'success',
      });
      setIsComplete(true);
    } else if (data?.errors?.message) {
      enqueueSnackbar(data?.errors?.message, { variant: 'error' });
    } else {
      enqueueSnackbar(defaultErrorMessage, { variant: 'error' });
    }
  };

  const handleApprove = () => {
    setIsProcessing(true);

    let input = getChangeRequestGraphQLInput({
      patchData: {
        ...changeRequestById,
      },
      approveOrReject: 'APPROVE',
      currentUser,
    });
    input.reverse(); //from small to large [timestamp]

    let changeRequestPayload;
    if (fwfFlags.fwfTBCheckIfThereIsMore.variation) {
      changeRequestPayload = {
        input: input,
        itemID: productId,
        itemType: 'photo',
      };
    } else {
      changeRequestPayload = {
        input: input,
      };
    }

    processChangeRequests({
      variables: changeRequestPayload,
    })
      .then((data) => {
        processChangeRequestsThen(data, 'Successfully saved request');
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleReject = (rejectReasons) => {
    setIsProcessing(true);

    const input = getChangeRequestGraphQLInput({
      patchData: {
        ...changeRequestById,
      },
      approveOrReject: 'REJECT',
      currentUser,
      additionalFields: { rejectionReasons: rejectReasons },
    });

    let changeRequestPayload;
    if (fwfFlags.fwfTBCheckIfThereIsMore.variation) {
      changeRequestPayload = {
        input: input,
        itemID: productId,
        itemType: 'photo',
      };
    } else {
      changeRequestPayload = {
        input: input,
      };
    }

    processChangeRequests({
      variables: changeRequestPayload,
    })
      .then((data) => {
        processChangeRequestsThen(data, 'Requests successfully rejected');
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleDownload = async () => {
    const dishPhotoUrl = changeRequestMeta.dishPhotoUrl;

    await batchImageDownload({
      getImageDownloads: (downloadImage) => {
        // adding array to match the implementation of batchImageDownload
        const imageDownloads = [[dishPhotoUrl, downloadImage(dishPhotoUrl)]];
        return imageDownloads;
      },
      preDownloadCallbackFn: () => {
        setDownloadInProgress((_) => true);
      },
      postDownloadCallbackFn: () => {
        setDownloadInProgress(false);
      },
    });
  };

  const batchUpload = async (files) => {
    try {
      setUploadPhotoLoading(true);
      await uploadPhotos(files);
      await handleApprove();

      // setGroupedPhoto(newGroupedPhoto);
      setUploadPhotoLoading(false);
      setIsUploadPhotoModalOpen(false);
      setNotification({
        open: true,
        type: 'success',
        message: `Successfully uploaded photo${files.length > 1 ? 's' : ''}`,
      });
    } catch (error) {
      console.error(error, 'Dish photo upload error');
      setNotification({
        open: true,
        type: 'error',
        message: formatErrorForDisplay({ error }),
      });
    }
  };

  const isDataLoaded = isEntityDataLoaded && isQueryLoaded;
  const isPageReady = isDataLoaded && !!product && !error;

  if (!isDataLoaded) {
    return <LoadingState />;
  }

  if (
    isDataLoaded &&
    !showHistoricalData &&
    (isComplete || (hasRequestResponse && changeRequests.length === 0))
  ) {
    return <TicketResolvedScreen />;
  }

  if (isPageReady && !hasRequestResponse) {
    const errorMessages = [];

    if (!product) {
      errorMessages.push('Product could not be found');
    }
    if (changeRequests.length === 0) {
      errorMessages.push('No changes found');
    }

    return <ErrorScreen errors={errorMessages} testId={'DishPhotoReviewError'} customText={''} />;
  }

  return (
    <>
      <Box display="flex" data-testid={'DishPhotoReviewPage'}>
        <Box width="50%" flex={'0 0 50%'} display="flex" alignItems="baseline" marginTop={10}>
          <img
            alt="Uploaded by vendor"
            data-testid="DishPhotoReviewPage-img"
            style={{ width: '100%' }}
            src={changeRequestMeta.dishPhotoUrl}
          />
        </Box>
        <Box flex={'0 0 50%'} overflow="scroll" bgcolor="white" width="100%" ml="auto">
          <Alert severity="info">
            <Box data-testid="DishPhotoReviewPage-vendorCode">Vendor Code: {vendorCode}</Box>
            <Box>Vendor Name: {changeRequestMeta.vendorName}</Box>
            <Box>Date & time submitted: {changeRequestMeta.dateSubmittedString}</Box>
          </Alert>

          {!showHistoricalData && (
            <Alert severity="info">
              This request contains
              <AlertTitle>
                {isProductCreationRequest || !hasImageUrls ? 'New Photo' : 'Update Photo'}
              </AlertTitle>
            </Alert>
          )}

          {showHistoricalData && <StatusInfoBox changeRequests={changeRequests}></StatusInfoBox>}

          {productStatus && (
            <Alert severity={alertSeverity}>
              <div>The related product is currently in '{productStatus}' status</div>
            </Alert>
          )}

          <RelatedItems
            currentCountry={currentCountry}
            itemID={productId}
            type={'Photo'}
            vendorCode={vendorCode}
          ></RelatedItems>

          <Box m={3}>
            <Form
              applicableChangeTypes={changeRequests.map((c) => c.item.typeOfChange)}
              vats={null}
              vatId={null}
              title={compiledTitle}
              description={compiledDescription}
              category={category.value}
              variants={Object.values(variants)}
              isPrepacked={null}
              product={product}
              categories={categories}
              vendorChoiceGroups={[]}
              currentCountry={currentCountry}
              handleTitleDescUpdate={() => {
                /* no op */
              }}
              handleCategoryUpdate={() => {
                /* no op */
              }}
              handleVatUpdate={() => {
                /* no op */
              }}
              handlePriceUpdate={() => {
                /* no op */
              }}
              handleVariationNameUpdate={() => {
                /* no op */
              }}
              handlePrepackedUpdate={() => {
                /* no op */
              }}
              updateChoiceGroups={null}
              isAgentAssignedToVendor={true}
              readonly={showHistoricalData}
            />
          </Box>
        </Box>
      </Box>
      {!showHistoricalData && (
        <>
          <AppRightBar modalRoot={document.getElementById('appbar')}>
            <ActionBar
              checks={[`${vendorCode}|${productId}`]} // the only change
              approveLoading={isProcessing}
              setIsRejectModalOpen={setIsRejectModalOpen}
              batchApprove={handleApprove}
              setisUploadPhotoModalOpen={setIsUploadPhotoModalOpen}
              batchDownload={handleDownload}
              downloading={downloading}
            />
          </AppRightBar>
          <UploadPhotoModal
            loading={uploadPhotoLoading}
            batchUpload={batchUpload} // upload to s3 and approve
            open={isUploadPhotoModalOpen}
            handleClose={() => setIsUploadPhotoModalOpen(false)}
          />

          <RejectModal
            loading={isProcessing}
            setIsRejectModalOpen={setIsRejectModalOpen}
            isRejectModalOpen={isRejectModalOpen}
            onSubmit={handleReject}
            header="Choose Reject Reason"
            subheader="Are you sure there is nothing you can do to approve this item? This
                action is permanent. The vendor will see the rejection reason you
                choose."
            rejectReasons={getPhotoRejectReasons(currentCountry.geid)}
          />
        </>
      )}

      <Notification
        variant={notification.type}
        open={notification.open}
        handleClose={() => {
          setNotification({
            open: false,
            type: 'success',
            message: '',
          });
        }}
        message={notification.message}
      />
    </>
  );
};

export default DishPhotoReview;
