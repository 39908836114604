import React, { useState, useEffect, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import _get from 'lodash/get';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import ProductDetailMenuPhoto from '../../components/ProductDetailMenuPhoto';
import HighLightBox from '../../components/HighlightBox';
import Form from '../Home/NewProductDetails/ProductDetailForm';
import { getCurrentCountry } from '../../../App/utility';
import { AppContext, UserContext } from '../../../App';
import { getMenuPhotoData, getChangeRequestGraphQLInput } from './helpers';
import { renderChangeDetails } from '../ProductHelpers';
import { getOldPatchData } from '../reviewHelpers';
import { getFromBrandProxy, getVats } from '../utils';
import useProductDetails from '../useProductDetails';
import {
  ITEM_CHANGES_QUERY,
  TALABAT_PROCESS_CHANGE_REQUESTS_QUERY,
  ITEMS_CHANGES_QUERY,
  PROCESS_CHANGE_REQUESTS_QUERY,
} from '../Home/helpers';
import HighlightBoxHeader from '../../components/HighlightBoxHeader';
import AppRightBar from '../../../App/layouts/AppRightBar';
import { defaultErrorMessage } from '../../utility/information';
import RooterApproveAndRejectButton from '../../components/RooterApproveAndRejectButton';
import { TicketResolvedScreen } from '../../../components/TicketResolvedScreen';
import { REJECTION_REASON_TYPE } from '../../common/constants/rejectReason';
import LoadingState from '../../../components/LoadingState';
import ErrorScreen from '../../../components/ErrorScreen';
import StatusInfoBox from '../../../components/StatusInfoBox';
import {
  TYPE_OF_CHANGE,
  TYPE_OF_CHANGE_CHOICE_GROUP,
  STATUS_LIST,
} from '../../../App/common/constant';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import RelatedItems from 'components/RelatedItems';

const ProductReview = ({ match }) => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { vendorCode, countryCode, item_id: productId } = match.params;
  const { getHistoricalData } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const showHistoricalData = getHistoricalData === 'true';
  const isProductCreationRequest = productId.startsWith('NEW');
  const { currentCountry, setCurrentCountry } = React.useContext(AppContext);
  const currentUser = React.useContext(UserContext);
  const [countryData, setCountryData] = useState(getCurrentCountry(countryCode));
  const [isPermission, setIsPermission] = useState(
    currentUser?.permissions?.['ops-portal-fuze'][countryData.geid]?.length > 0
  );
  const fwfFlags = useSelector((state) => state.fwfState.flags);
  const [shouldShowMenuPhoto, setShouldShowMenuPhoto] = useState(false);
  const [changeWithImage, setChangeWithImage] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [queryError, setQueryError] = useState(null);

  const [vats, setVats] = useState([]);
  const [categories, setCategories] = useState([]);
  const [choiceGroups, setChoiceGroups] = useState([]);
  const [product, setProduct] = useState({});
  const [variantsData, setVariantsData] = useState({});
  const [changeRequests, setChangeRequests] = useState([]);
  const [hasRequestResponse, setHasRequestResponse] = useState(false);
  const [isEntityDataLoaded, setIsEntityDataLoaded] = useState(true);
  const [newChoiceGroup, setNewChoiceGroup] = useState([]);
  const [newEmbeddedVariantChoiceGroup, setNewEmbeddedVariantChoiceGroup] = useState([]);
  const [skipEmbeddedVariantChoiceQuery, setSkipEmbeddedVariantChoiceQuery] = useState(true);
  const [newlyAddedChoiceGroupIds, setNewlyAddedChoiceGroupIds] = useState([]);

  //Set current country based on URL
  useEffect(() => {
    setCountryData(getCurrentCountry(countryCode));
    setIsPermission(currentUser?.permissions?.['ops-portal-fuze'][countryData.geid]?.length > 0);

    if (!isPermission) {
      window.location.href = '/access-denied';
      return;
    }

    Cookies.set('country', countryData.code, { sameSite: 'None', secure: true });
    setCurrentCountry(countryData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCurrentCountry, countryCode]);

  const {
    isPrepacked,
    category,
    vatId,
    variants,
    handleTitleDescUpdate,
    handleCategoryUpdate,
    handlePrepackedUpdate,
    handlePriceUpdate,
    handleVariationNameUpdate,
    handleVatUpdate,
    updateChoiceGroups,
    getPatchData,
    compiledDescription,
    compiledTitle,
  } = useProductDetails(product, changeRequests);

  const onPageLoad = useCallback(async () => {
    if (currentCountry.code !== countryCode) {
      return;
    }

    // init category
    getFromBrandProxy('categories', vendorCode, currentCountry.geid).then((categoryData) => {
      setCategories(categoryData.data);
    });

    // init choice groups
    getFromBrandProxy('choice-groups', vendorCode, currentCountry.geid).then((choiceGroupData) => {
      setChoiceGroups(choiceGroupData.data);
    });

    // init product
    if (!isProductCreationRequest) {
      getFromBrandProxy('product', vendorCode, currentCountry.geid, productId).then(
        (productData) => {
          setProduct(productData.data);
        }
      );
    }

    // init vats
    if (fwfFlags?.fwfVATTaxVisibility?.variation) {
      getVats(countryCode).then((vatData) => {
        setVats(vatData.data);
      });
    }
  }, [fwfFlags, isProductCreationRequest, productId, vendorCode, countryCode, currentCountry]);

  // on page load
  useEffect(() => {
    const loadPage = async () => {
      setIsEntityDataLoaded(false);
      await onPageLoad();
      setIsEntityDataLoaded(true);
    };

    if (isPermission) loadPage();
  }, [onPageLoad, countryCode, currentCountry, isPermission]);

  const {
    data: getItemByIdData,
    loading,
    error,
  } = useQuery(ITEM_CHANGES_QUERY, {
    variables: {
      itemID: productId,
      statusList: ['APPROVE', 'REJECT', 'PENDING', 'INVALID'],
      ...(showHistoricalData
        ? {
            latestCaseIDOnly: true,
            statusList: ['APPROVE', 'REJECT'],
          }
        : {}),
    },
    displayName: 'ProductItemQuery',
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    skip: !isPermission,
  });

  const { data: getItemByIdsData } = useQuery(ITEMS_CHANGES_QUERY, {
    variables: {
      itemIDs: newlyAddedChoiceGroupIds,
      statusList: ['APPROVE', 'REJECT', 'PENDING', 'INVALID'],
    },
    displayName: 'ProductItemsQuery',
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    skip: skipEmbeddedVariantChoiceQuery || !isPermission,
  });

  const isQueryLoaded = !error && !loading;

  useEffect(() => {
    let variantData = {};
    Object.keys(variants).forEach((variant) => {
      if (
        variants[variant].hasOwnProperty('choiceGroups') ||
        variants[variant].hasOwnProperty('choice_groups')
      ) {
        variantData[variant] = variants[variant];
      }
    });

    setVariantsData(variantData);
  }, [variants]);

  useEffect(() => {
    if (!error) {
      let qcRequestChanges = getItemByIdData?.getItemByItemID?.qcRequests || [];

      let applicableChanges = qcRequestChanges.filter(
        (r) =>
          Object.values(TYPE_OF_CHANGE).includes(r.item.typeOfChange) && r.item.patchData !== null
      );

      setHasRequestResponse(applicableChanges.length > 0);

      //EmbeddedVariantChoiceGroupAdd
      qcRequestChanges.forEach((req) => {
        if (
          req.item.typeOfChange === TYPE_OF_CHANGE_CHOICE_GROUP.CHOICE_GROUP_CREATE ||
          req.item.typeOfChange === TYPE_OF_CHANGE_CHOICE_GROUP.EMBEDDED_CHOICE_GROUP_CREATE
        ) {
          setNewEmbeddedVariantChoiceGroup((newEmbeddedVariantChoiceGroup) => [
            {
              cgId: req.item.itemId,
              ...req.item.patchData,
            },
            ...newEmbeddedVariantChoiceGroup,
          ]);
        }
      });

      let pendingRequests = [];
      if (!showHistoricalData) {
        let newRequest = [
          {
            item: {
              timestamp: 0,
            },
          },
        ];

        applicableChanges.forEach((req) => {
          if (req.item.timestamp > newRequest[0].item.timestamp) {
            newRequest = [req];
          }
        });

        const validRequest = newRequest[0].item.status?.toLowerCase() === 'pending';

        if (validRequest) {
          applicableChanges.forEach((request) => {
            if (request.item.status.toLowerCase() === 'pending') {
              pendingRequests.push(request);
            }
          });
        }
      } else {
        pendingRequests = applicableChanges;
      }

      let newpendingRequests = [];

      let variantIds = [];
      let count = 0;
      pendingRequests.forEach((request) => {
        let variants = request.item.patchData.variants;

        if (variants && Object.keys(variants).length > 0) {
          let variantId = Object.keys(variants).toString();
          let equal = false;
          variantIds.forEach((variant) => {
            if (variant === variantId) {
              variantId = variant + '_' + count;
              count++;

              let tempRequest = JSON.parse(JSON.stringify(request));

              tempRequest.item.patchData.variants[variantId] =
                tempRequest.item.patchData.variants[variant];
              delete tempRequest.item.patchData.variants[variant];

              newpendingRequests.push(tempRequest);
              equal = true;
            }
          });

          if (variantIds.length <= 0 || !equal) {
            newpendingRequests.push(request);
          }

          variantIds.push(variantId);
        } else {
          newpendingRequests.push(request);
        }
      });

      let productCreateRequests = newpendingRequests.filter(
        (r) => TYPE_OF_CHANGE.PRODUCT_CREATE === r.item.typeOfChange && r.item.patchData !== null
      );

      let embeddedVariantChoiceGroupAddRequests = newpendingRequests.filter(
        (r) =>
          TYPE_OF_CHANGE.EMBEDDED_VARIANT_CHOICE_GROUP_ADD === r.item.typeOfChange &&
          r.item.patchData !== null
      );

      if (productCreateRequests.length > 0) {
        let choiceGroupRequests = qcRequestChanges.filter(
          (r) =>
            TYPE_OF_CHANGE_CHOICE_GROUP.EMBEDDED_CHOICE_GROUP_CREATE === r.item.typeOfChange &&
            (r.item.status === STATUS_LIST.APPROVE || r.item.status === STATUS_LIST.PENDING)
        );

        let choiceGroups = [];
        choiceGroupRequests.forEach((choiceData) => {
          let variants = choiceData.item.patchData.variants;
          Object.keys(variants).forEach((variant) => {
            Object.keys(variants[variant].choice_groups).forEach((cg) => {
              let cgData = variants[variant].choice_groups[cg];
              let copyCgData = { ...cgData };
              copyCgData.choices = Object.values(copyCgData.choices);
              copyCgData.variantId = variant;
              choiceGroups.push(copyCgData);
              setNewChoiceGroup(choiceGroups);
            });
          });
        });
      } else if (embeddedVariantChoiceGroupAddRequests?.length > 0 && choiceGroups?.length > 0) {
        //existing cg
        let cgDetailData = [];

        let cgDetail;
        embeddedVariantChoiceGroupAddRequests.forEach((request) => {
          let itemId = request.item.itemId;
          const variants = request.item.patchData.variants;

          const arrVariants = Object.keys(variants);
          const findVariant = arrVariants.find((v) => v.includes('_'));

          if (findVariant) {
            itemId = findVariant;
          }

          const requestCgIds = Object.keys(request.item.patchData.variants[itemId].choice_groups);

          requestCgIds.forEach((requestCgId) => {
            cgDetail = {
              id: requestCgId,
              cgId: requestCgId,
              variantId: request.item.itemId,
            };
          });

          cgDetailData.push(cgDetail);
        });

        if (cgDetailData.length > 0) {
          let checkCgData = choiceGroups.filter((o1) => {
            return cgDetailData.some((o2) => {
              return o1.id === o2.id;
            });
          });

          if (checkCgData.length > 0) {
            let mergedCgData = [];

            for (let i = 0; i < cgDetailData.length; i++) {
              mergedCgData.push({
                ...cgDetailData[i],
                ...checkCgData.find((itmInner) => itmInner.id === cgDetailData[i].id),
              });
            }

            setNewChoiceGroup(mergedCgData);
          } else {
            //not existing cg, newly added cg get query data example id: NEWCG_123456

            const cgIdsArr = cgDetailData.map((cg) => cg.id);
            setNewlyAddedChoiceGroupIds(cgIdsArr);
            setSkipEmbeddedVariantChoiceQuery(false);
            cgDetailData.forEach((cgData) => {
              getItemByIdsData?.getItemsByItemIDs?.qcRequests.forEach((request) => {
                if (
                  request.item.typeOfChange === 'ChoiceGroupCreate' &&
                  request.item.itemId === cgData.cgId
                ) {
                  cgData.quantity = request.item.patchData.quantity;
                  cgData.title = request.item.patchData.title;
                  cgData.choices = Object.values(request.item.patchData.choices);
                }
              });
            });
            setNewChoiceGroup(cgDetailData);
          }
        }
      }

      setChangeRequests(newpendingRequests);
      setQueryError(null);
    } else {
      setQueryError(error);
    }
  }, [getItemByIdData, loading, error, showHistoricalData, choiceGroups, getItemByIdsData]);

  useEffect(() => {
    const { isFlagOn, photoRequest } = getMenuPhotoData(changeRequests, fwfFlags);

    setShouldShowMenuPhoto(isFlagOn && !!photoRequest);
    setChangeWithImage(photoRequest);
  }, [changeRequests, fwfFlags]);

  let talabatChangeRequestsQuery = fwfFlags.fwfTBCheckIfThereIsMore.variation
    ? TALABAT_PROCESS_CHANGE_REQUESTS_QUERY
    : PROCESS_CHANGE_REQUESTS_QUERY;

  //Submit handlers
  const [processChangeRequests] = useMutation(talabatChangeRequestsQuery, {
    onError: () => {},
  });

  const processChangeRequestsThen = (data, message) => {
    if (data?.data?.completeQCRequests) {
      enqueueSnackbar(message, {
        variant: 'success',
      });
      setIsComplete(true);
    } else if (data?.errors?.message) {
      enqueueSnackbar(data?.errors?.message, { variant: 'error' });
    } else {
      enqueueSnackbar(defaultErrorMessage, { variant: 'error' });
    }
  };

  const onApprove = () => {
    setIsProcessing(true);
    const patchData = getPatchData();

    const arrPatchData = Object.keys(patchData);

    let releatedRequest;
    arrPatchData.forEach((requestId) => {
      // requestId => variantId
      releatedRequest = changeRequests.find((request) => request?.requestId === requestId);
      if (
        releatedRequest &&
        patchData[requestId].hasOwnProperty('variants') &&
        !!patchData[requestId].variants[requestId]
      ) {
        //change variant key
        patchData[requestId].variants[releatedRequest.item.itemId] =
          patchData[requestId].variants[requestId];

        //delete has requestId key variants
        delete patchData[requestId].variants[requestId];
      }
    });

    let queryInputData = getChangeRequestGraphQLInput({
      patchData: patchData,
      approveOrReject: 'APPROVE',
      currentUser,
    });

    queryInputData = getOldPatchData(changeRequests, queryInputData, patchData);

    let changeRequestPayload;
    if (fwfFlags.fwfTBCheckIfThereIsMore.variation) {
      changeRequestPayload = {
        input: queryInputData,
        itemID: productId,
        itemType: 'product',
      };
    } else {
      changeRequestPayload = {
        input: queryInputData,
      };
    }

    processChangeRequests({
      variables: changeRequestPayload,
    })
      .then((data) => {
        processChangeRequestsThen(data, 'Successfully saved request');
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const onReject = (rejectReasons) => {
    setIsProcessing(true);

    const input = getChangeRequestGraphQLInput({
      patchData: getPatchData(),
      approveOrReject: 'REJECT',
      currentUser,
      additionalFields: { rejectionReasons: rejectReasons },
    });

    let changeRequestPayload;
    if (fwfFlags.fwfTBCheckIfThereIsMore.variation) {
      changeRequestPayload = {
        input: input,
        itemID: productId,
        itemType: 'product',
      };
    } else {
      changeRequestPayload = {
        input: input,
      };
    }

    processChangeRequests({
      variables: changeRequestPayload,
    })
      .then((data) => {
        processChangeRequestsThen(data, 'Requests successfully rejected');
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const isDataLoaded = isEntityDataLoaded && isQueryLoaded;
  const isPageReady = isDataLoaded && !!getItemByIdData && !loading && changeRequests.length > 0;

  if (loading || !isEntityDataLoaded) {
    return <LoadingState />;
  }

  if (
    isDataLoaded &&
    !showHistoricalData &&
    (isComplete || (changeRequests.length === 0 && hasRequestResponse))
  ) {
    return <TicketResolvedScreen />;
  }

  if (!isPageReady && !hasRequestResponse) {
    const errorMessages = [];

    if (queryError) {
      errorMessages.push('Changes could not be retrieved.');
    }
    if (!queryError && changeRequests.length === 0) {
      errorMessages.push('No changes found');
    }

    return <ErrorScreen errors={errorMessages} testId={'ProductReviewError'} />;
  }

  const status = _get(changeRequests[0], 'item.status')?.toLowerCase();

  return (
    <>
      <Box display="flex" data-enzyme={'ProductReviewPage'}>
        {shouldShowMenuPhoto && (
          <ProductDetailMenuPhoto
            flex="0 0 50%"
            menuPhoto={changeWithImage?.meta?.menuPhoto}
            width="50%"
            currentCountry={currentCountry}
            fwfFlags={fwfFlags}
            vendorCode={vendorCode}
          />
        )}
        <Box
          flex={shouldShowMenuPhoto ? '0 0 50%' : ''}
          overflow="scroll"
          bgcolor="white"
          width="100%"
          ml="auto"
        >
          <Box marginY={3}>
            {showHistoricalData && <StatusInfoBox changeRequests={changeRequests}></StatusInfoBox>}

            <HighLightBox type={status}>
              <HighlightBoxHeader status={status} />
              {changeRequests.map((request) =>
                renderChangeDetails(
                  request,
                  product,
                  vats,
                  categories,
                  currentCountry?.currency,
                  newEmbeddedVariantChoiceGroup.length > 0
                    ? newEmbeddedVariantChoiceGroup
                    : newChoiceGroup
                )
              )}
            </HighLightBox>
          </Box>

          <RelatedItems
            currentCountry={currentCountry}
            itemID={productId}
            type={'Product'}
            vendorCode={vendorCode}
          ></RelatedItems>

          <Box m={3}>
            <Form
              applicableChangeTypes={changeRequests.map((c) => c.item.typeOfChange)}
              vats={vats}
              vatId={vatId.value}
              title={compiledTitle}
              description={compiledDescription}
              category={category.value}
              variants={Object.values(variantsData)}
              isPrepacked={isPrepacked.value}
              product={product}
              categories={categories}
              vendorChoiceGroups={choiceGroups}
              fwfFlags={fwfFlags}
              currentCountry={currentCountry}
              handleTitleDescUpdate={handleTitleDescUpdate}
              handleCategoryUpdate={handleCategoryUpdate}
              handleVatUpdate={handleVatUpdate}
              handlePriceUpdate={handlePriceUpdate}
              handleVariationNameUpdate={handleVariationNameUpdate}
              handlePrepackedUpdate={handlePrepackedUpdate}
              updateChoiceGroups={updateChoiceGroups}
              isAgentAssignedToVendor={true}
              newChoiceGroup={newChoiceGroup}
              readonly={showHistoricalData}
            />
          </Box>
        </Box>
      </Box>
      {!showHistoricalData && (
        <AppRightBar modalRoot={document.getElementById('appbar')}>
          <RooterApproveAndRejectButton
            handleApprove={onApprove}
            handleReject={onReject}
            buttonsDisabled={isProcessing}
            rejectionReasonType={REJECTION_REASON_TYPE.PRODUCT}
          />
        </AppRightBar>
      )}
    </>
  );
};

export default ProductReview;
