import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { CircularProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles(styles);

const LoadingState = () => {
  const classes = useStyles();

  return (
    <div className={classes.centeredContainer}>
      <div style={{ textAlign: 'center' }}>
        <CircularProgress />
        <Typography variant={'h4'}>Please wait while we load the data</Typography>
      </div>
    </div>
  );
};

export default LoadingState;
