export const styles = (theme) => ({
  commonRoot: {
    /*     padding: theme.spacing(0.4),
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.25),
    borderRadius: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', */
    width: '100%',
  },
  input: {
    width: '100%',
    '& > div': {
      backgroundColor: "#F4F6F6",
      borderRadius: "4px",
      border:"none",

      '&:before': {
        content:"initial"
      },
      '&:after': {
        borderColor:"#333"
      },
    },

    '& label.Mui-focused':{
      color:"#677374"
    }
  },

});

export default styles;
