import styled from 'styled-components';
import { DatePicker as DatePickerBase } from '@material-ui/pickers';
import { Box } from '@material-ui/core';

export const DatePicker = styled(DatePickerBase)`
  flex: 1 1 311px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex: 0 0 100%;
  }

  & .MuiInput-underline:before {
    border-color: rgb(189, 189, 189);
  }

  & .MuiOutlinedInput-input {
    padding: 18.5px 14px;
  }
`;

export const EndDatePicker = styled(DatePicker)`
  && {
    margin-left: ${(props) => props.theme.spacing(3)}px;

    ${(props) => props.theme.breakpoints.down('sm')} {
      margin: ${(props) => props.theme.spacing(3)}px 0 0;
    }
  }
`;

export const DateRangeWrapperBox = styled(Box)`
  display: flex;
  flex-flow: row nowrap;

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-flow: column;
  }
`;
