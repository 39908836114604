import axios from '../../App/utility/axios';
import config from '../../App/config';

export const getFromBrandProxy = async (itemType, vendorCode, GEID, itemId = '') => {
  try {
    const response = await axios(`${config.brandProxyApi}/${itemType}/${itemId}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-global-entity-id': GEID,
        'x-vendor-id': vendorCode,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const getVats = async (countryCode) => {
  try {
    const response = await axios(`${config.api}/internal/vats?country=${countryCode}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
