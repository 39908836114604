import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { styles } from "./styles";

const useStyles = makeStyles(styles);

export default function LoaderWrapper({ children, isLoading }) {
  const classes = useStyles();
  if (isLoading) {
    return (
      <Box p={5} className={classes.loader}>
        <CircularProgress />
      </Box>
    );
  }
  return <>{children}</>;
}
