import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Box, Checkbox, Typography, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { TYPE_OF_CHANGE_CHOICE_GROUP } from '../../../../App/common/constant';
import { tablestyles, changesStyles } from '../styles';
import { getDefaultLanguage } from '../../../../utility/information';
import ChoiceGroupDetailModal from './ChoiceGroupDetailModal';
import axios from '../../../../App/utility/axios';
import config from '../../../../App/config';
import { brandProxyApiHeader } from '../../../utility/information';
import { getEmbeddedPatchDataIds, getPatchData } from './helpers';
import { AppContext } from '../../../../App';

const useStyles = makeStyles(tablestyles);
const useChangesStyles = makeStyles(changesStyles);

const ChoiceGroup = ({
  checked,
  onCheckChange,
  showCheckbox,
  fwfFlags,
  choiceGroupId,
  changeArray,
}) => {
  const classes = useStyles();
  const changesClasses = useChangesStyles();
  const { currentCountry } = React.useContext(AppContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [choiceGrpApierror, setChoiceGrpApierror] = useState(false);
  const [apiIsLoading, setApiIsLoading] = useState(false);

  //Fetch choice group object on load
  useEffect(() => {
    if (!choiceGroupId.startsWith('NEWCG')) {
      const isEmbedded = changeArray[0].item.typeOfChange.startsWith('EmbeddedChoice');
      const productId = changeArray[0].meta.productID;

      setApiIsLoading(true);
      axios(
        `${config.brandProxyApi}/${isEmbedded ? 'product' : 'choice-group'}/${
          isEmbedded ? productId : choiceGroupId
        }`,
        {
          headers: brandProxyApiHeader(currentCountry, changeArray),
        }
      )
        .then((response) => {
          const data = response.data.data;
          if (isEmbedded) {
            //For Talabat 1 product -> 1 variant so it is always in index[0]
            //1 Product -> 1 Variant -> MANY Choice groups
            const choiceGroups = data.variants[0].choice_groups;
            const { choiceGroupId: realChoiceGroupId } = getEmbeddedPatchDataIds(
              changeArray[0].item.patchData
            );

            let choiceGroupData = choiceGroups.find((cg) => cg.id === realChoiceGroupId);
            setCurrentItem(choiceGroupData);
          } else {
            setCurrentItem(response.data.data);
          }
          setChoiceGrpApierror(false);
          setApiIsLoading(false);
        })
        .catch((error) => {
          setChoiceGrpApierror(true);
          setApiIsLoading(false);
        });
    }
  }, [changeArray, choiceGroupId, currentCountry]);

  useEffect(() => {
    const changeTypeList = changeArray.map((c) => c.item.typeOfChange);

    const isCreate =
      changeTypeList.includes(TYPE_OF_CHANGE_CHOICE_GROUP.CHOICE_GROUP_CREATE) ||
      changeTypeList.includes(TYPE_OF_CHANGE_CHOICE_GROUP.EMBEDDED_CHOICE_GROUP_CREATE);
    if (isCreate) {
      let changeRequest = {};
      let newChoiceGroupData = {};
      if (changeTypeList.includes(TYPE_OF_CHANGE_CHOICE_GROUP.CHOICE_GROUP_CREATE)) {
        //if choice group create, should only have 1 change
        changeRequest = changeArray[0];
        newChoiceGroupData = changeRequest.item.patchData;
      }
      if (changeTypeList.includes(TYPE_OF_CHANGE_CHOICE_GROUP.EMBEDDED_CHOICE_GROUP_CREATE)) {
        changeRequest = changeArray[0];
        const newChoiceGroups = Object.values(changeRequest.item.patchData.variants)[0]
          ?.choice_groups;
        newChoiceGroupData = Object.values(newChoiceGroups ?? {})[0];
      }

      const createdItem = {
        choices: Object.values(newChoiceGroupData?.choices || {}) ?? [],
        defaultTitle: Object.values(newChoiceGroupData?.title || {})[0],
        deleted: false,
        id: changeRequest.item.itemId,
        quantity: newChoiceGroupData?.quantity ?? { min: 0, max: 0 },
        title: newChoiceGroupData?.title || {},
        type: 'choice-group',
      };
      setCurrentItem(createdItem);
    }
  }, [changeArray]);

  const handleChoiceGroupModalClose = () => {
    setModalOpen(false);
  };

  const onChoiceRowClick = () => {
    setModalOpen(true);
  };

  const onChoiceChangesCheck = useCallback(
    (event) => {
      if (event.target.checked) {
        onCheckChange((prev) => {
          return _.union(prev, changeArray);
        });
      } else {
        onCheckChange((prev) => _.difference(prev, changeArray));
      }
    },
    [changeArray, onCheckChange]
  );

  const renderQuantity = () => {
    const quantityChanges = changeArray.filter((changeRequest) => {
      const typeOfChange = changeRequest.item.typeOfChange;
      return (
        typeOfChange === TYPE_OF_CHANGE_CHOICE_GROUP.EMBEDDED_CHOICE_GROUP_QUANTITY_CHANGE ||
        typeOfChange === TYPE_OF_CHANGE_CHOICE_GROUP.CHOICE_GROUP_QUANTITY_CHANGE ||
        typeOfChange === TYPE_OF_CHANGE_CHOICE_GROUP.CHOICE_GROUP_CREATE
      );
    });

    let newValues = {};
    let oldValues = {};

    quantityChanges.forEach((change) => {
      const [newData, oldData] = getPatchData(change);
      newValues = {
        ...newValues,
        ...newData?.quantity,
      };
      oldValues = {
        ...oldValues,
        ...oldData?.quantity,
      };
    });

    const hasOldMinimum = !_.isNil(oldValues.minimum);
    const hasOldMaximum = !_.isNil(oldValues.maximum);

    if (!newValues.hasOwnProperty('minimum')) {
      newValues.minimum = currentItem?.quantity?.minimum;
    }
    if (!newValues.hasOwnProperty('maximum')) {
      newValues.maximum = currentItem?.quantity?.maximum;
    }

    return (
      <>
        MIN:
        {hasOldMinimum && (
          <Box
            component="span"
            p={1}
            className={classes.choicePriceChange}
            data-enzyme={`quantity-min-old-${choiceGroupId}`}
          >
            <Typography
              variant="body2"
              className={`${classes.lineThrough} ${changesClasses.changeValuesOld}`}
            >
              {oldValues.minimum || '-'}
            </Typography>
          </Box>
        )}
        <Box
          component="span"
          p={1}
          className={classes.choicePriceChange}
          data-enzyme={`quantity-min-new-${choiceGroupId}`}
        >
          <Typography
            variant="body2"
            className={hasOldMinimum ? changesClasses.changeValuesNew : ''}
          >
            {newValues.minimum || '-'}
          </Typography>
        </Box>
        MAX:
        {hasOldMaximum && (
          <Box
            component="span"
            p={1}
            className={classes.choicePriceChange}
            data-enzyme={`quantity-max-old-${choiceGroupId}`}
          >
            <Typography
              variant="body2"
              className={`${classes.lineThrough} ${changesClasses.changeValuesOld}`}
            >
              {oldValues.maximum || '-'}
            </Typography>
          </Box>
        )}
        <Box
          component="span"
          p={1}
          className={classes.choicePriceChange}
          data-enzyme={`quantity-max-new-${choiceGroupId}`}
        >
          <Typography
            variant="body2"
            className={hasOldMaximum ? changesClasses.changeValuesNew : ''}
          >
            {newValues.maximum || '-'}
          </Typography>
        </Box>
      </>
    );
  };

  const messageBox = useCallback(
    (params) => {
      const [message1, className1, message2, className2] = params;
      return (
        <Box component="span" p={1} className={classes.choiceBlock}>
          <Typography variant={'subtitle2'} className={className1}>
            {message1}
          </Typography>
          {!!message2 && (
            <Typography variant={'subtitle2'} className={className2}>
              {message2}
            </Typography>
          )}
        </Box>
      );
    },
    [classes.choiceBlock]
  );

  const renderTitle = useCallback(() => {
    let newTitle;
    let oldTitle;
    const getChange = (change) => changeArray.find(({ item }) => item.typeOfChange === change);
    const titleChange =
      getChange(TYPE_OF_CHANGE_CHOICE_GROUP.CHOICE_GROUP_TITLE_CHANGE) ||
      getChange(TYPE_OF_CHANGE_CHOICE_GROUP.EMBEDDED_CHOICE_GROUP_TITLE_CHANGE);
    const titleCreation =
      getChange(TYPE_OF_CHANGE_CHOICE_GROUP.CHOICE_GROUP_CREATE) ||
      getChange(TYPE_OF_CHANGE_CHOICE_GROUP.EMBEDDED_CHOICE_GROUP_CREATE);

    // if the api call fails
    if (!apiIsLoading && choiceGrpApierror) {
      return messageBox(['Failed to get item data.']);
    }

    // if current item is not returned by response
    const currentItemExists = !(currentItem === null || _.isEmpty(currentItem));
    if (!currentItemExists && !apiIsLoading && !choiceGrpApierror) {
      return messageBox(['Item is no longer available. Please reject these changes.']);
    }

    // if title has changed return [old title] [new title] view
    if (!!titleChange && currentItemExists) {
      const [newData, oldData] = getPatchData(titleChange);
      newTitle = newData?.title;
      oldTitle = oldData?.title;

      if (!!newTitle?.[getDefaultLanguage(newTitle)]) {
        // has title change conditional
        return messageBox([
          oldTitle?.[getDefaultLanguage(oldTitle)] || '-',
          `${classes.lineThrough} ${changesClasses.changeValuesOld}`,
          newTitle?.[getDefaultLanguage(newTitle)] || '-',
          changesClasses.changeValuesNew,
        ]);
      }
    }

    // if title is created return [new title] view
    if (!!titleCreation) {
      const [newData] = getPatchData(titleCreation);
      newTitle = newData?.title;

      return messageBox([
        newTitle?.[getDefaultLanguage(newTitle)] || '-',
        changesClasses.changeValuesNew,
      ]);
    }

    oldTitle = {
      ...currentItem?.title,
      ...oldTitle,
    };

    // default title view if all of the above doesnt meet
    return messageBox([oldTitle?.[getDefaultLanguage(oldTitle)] || 'Loading...']);
  }, [
    changeArray,
    currentItem,
    changesClasses,
    classes,
    choiceGrpApierror,
    apiIsLoading,
    messageBox,
  ]);

  const renderChange = (request) => {
    const typeOfChange = request.item.typeOfChange;

    if (!Object.values(TYPE_OF_CHANGE_CHOICE_GROUP).includes(typeOfChange)) {
      return null;
    }

    const [patchData, oldData] = getPatchData(request);

    const patchDataChoices = patchData?.choices || {};
    const oldDataChoices = oldData?.choices || {};

    return (
      <div key={request.requestId}>
        {!_.isEmpty(patchDataChoices) && (
          <Box data={currentItem} p={1}>
            <Typography variant={'subtitle2'} gutterBottom>
              Choices
            </Typography>
            <Box display={'flex'}>
              {_.map(patchDataChoices, (choice, key) => {
                const relevantChoice = currentItem?.choices?.find((c) => c.id === key);
                const isCreate =
                  typeOfChange === TYPE_OF_CHANGE_CHOICE_GROUP.EMBEDDED_CHOICE_GROUP_CREATE ||
                  typeOfChange === TYPE_OF_CHANGE_CHOICE_GROUP.CHOICES_CREATE ||
                  typeOfChange === TYPE_OF_CHANGE_CHOICE_GROUP.CHOICE_GROUP_CREATE ||
                  typeOfChange === TYPE_OF_CHANGE_CHOICE_GROUP.CHOICES_ADD;
                const isDelete =
                  typeOfChange === TYPE_OF_CHANGE_CHOICE_GROUP.CHOICES_DELETE ||
                  typeOfChange === TYPE_OF_CHANGE_CHOICE_GROUP.EMBEDDED_CHOICES_DELETE;

                return (
                  <Box key={key} marginRight={1}>
                    <Box align={'left'}>
                      <Typography gutterBottom>{typeOfChange}</Typography>
                      <Typography
                        variant={'body2'}
                        className={
                          !(isCreate || isDelete)
                            ? ''
                            : isDelete
                            ? `${classes.lineThrough} ${changesClasses.changeValuesOld}`
                            : changesClasses.changeValuesNew
                        }
                      >
                        {choice?.title?.[getDefaultLanguage(choice?.title)] ||
                          relevantChoice?.title?.[getDefaultLanguage(relevantChoice?.title)] ||
                          '-'}
                      </Typography>
                    </Box>
                    <Box align="left">
                      {isCreate || isDelete ? (
                        <>
                          <Typography>Price</Typography>
                          {oldDataChoices[key] && (
                            <Typography
                              variant="body2"
                              className={`${classes.lineThrough} ${changesClasses.changeValuesOld}`}
                            >
                              {oldDataChoices[key].price || '-'}
                            </Typography>
                          )}
                          <Typography
                            variant="body2"
                            className={
                              typeOfChange === TYPE_OF_CHANGE_CHOICE_GROUP.CHOICES_DELETE
                                ? `${classes.lineThrough} ${changesClasses.changeValuesOld}`
                                : changesClasses.changeValuesNew
                            }
                          >
                            {choice?.price || '-'}
                          </Typography>
                        </>
                      ) : (
                        Object.keys(choice).map((dataKey) => (
                          <div key={dataKey}>
                            <Typography>{_.capitalize(dataKey)}</Typography>
                            {oldDataChoices[key] && (
                              <Typography
                                variant="body2"
                                className={`${classes.lineThrough} ${changesClasses.changeValuesOld}`}
                              >
                                {_.capitalize(oldDataChoices[key][dataKey]?.toString()) || '-'}
                              </Typography>
                            )}
                            <Typography
                              variant="body2"
                              className={
                                typeOfChange === TYPE_OF_CHANGE_CHOICE_GROUP.CHOICES_DELETE
                                  ? `${classes.lineThrough} ${changesClasses.changeValuesOld}`
                                  : changesClasses.changeValuesNew
                              }
                            >
                              {_.capitalize(choice?.[dataKey].toString()) || '-'}
                            </Typography>
                          </div>
                        ))
                      )}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </div>
    );
  };

  return (
    <>
      <Box
        mb={1}
        p={0}
        border={1}
        borderColor={'grey.300'}
        display={'flex'}
        onClick={onChoiceRowClick}
        data-enzyme={`choice-group-${choiceGroupId}`}
      >
        <Box p={1} align="left" style={{ flexBasis: '20%' }}>
          {showCheckbox ? (
            <InputLabel>
              <Checkbox
                checked={checked}
                onChange={onChoiceChangesCheck}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
              {renderTitle()}
            </InputLabel>
          ) : (
            <InputLabel>{renderTitle()}</InputLabel>
          )}
        </Box>
        <Box display={'flex'}>{changeArray.map(renderChange)}</Box>
        <Box p={1} style={{ marginLeft: 'auto' }}>
          {renderQuantity()}
        </Box>
      </Box>
      {currentItem && (
        <ChoiceGroupDetailModal
          choiceGroupModalOpen={modalOpen}
          handleChoiceGroupModalClose={handleChoiceGroupModalClose}
          changeItem={{ item: currentItem, changes: changeArray }}
          isAgentAssignedToVendor={showCheckbox}
          fwfFlags={fwfFlags}
          historyMode={false}
        />
      )}
    </>
  );
};

export default ChoiceGroup;
