export const styles = (theme) => ({
  label: {
    fontWeight: 'bold',
    width: 'auto',
  },
  labelTimestamp: {
    fontWeight: 'normal',
  },
  labelContainer: {
    flexBasis: '20%',
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
  changeValuesNew: {
    background: theme.palette.changeValues.new,
  },
  changeValuesOld: {
    background: theme.palette.changeValues.old,
  },
});

export default styles;
