// @ts-check
import defaultAuthClient from 'client/authClient';
import { CONSTANTS } from 'utility';

/**
 * @typedef Args
 * @property {Pick<typeof defaultAuthClient, 'getToken'>} [authClient]
 * @param {Args} arg0
 * @return {AxiosRequestInterceptor}
 */
export const authInterceptor =
  ({ authClient /* istanbul ignore next */ = defaultAuthClient }) =>
  async (requestConfig) => {
    let {
      headers: {
        [CONSTANTS.HEADER_IGNORE_AUTHORIZATION]: ignoreAuthorizationHeader,
        ...headers
      } = {},
    } = requestConfig;

    if (headers['Authorization'] == null && !ignoreAuthorizationHeader) {
      const authorization = await authClient.getToken();
      headers = {
        ...headers,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authorization}`,
      };

      requestConfig = { ...requestConfig, headers };
    }

    return requestConfig;
  };

/* istanbul ignore next */
export default authInterceptor({});
