import { Moment } from 'moment';
export type IfilterState = {
  draft: {
    startDateTime: {
      moment: null | Moment;
      unix: null | Number;
    };
    endDateTime: {
      moment: null | Moment;
      unix: null | Number;
    };
    vendorCode: null | string; //search: null,
    assignedAgent: boolean;
    requestId: string | null;
  };
  hasDataChanged: boolean;
  payload: {
    input: {
      contentType: null | string;
      timeFrom: null | string | number;
      timeTo: null | string | number;
      search: null | string;
    };
    assignedAgent: boolean;
    requestIdSuffix: null | string;
  };
};

export const filterInitialState: IfilterState = {
  draft: {
    startDateTime: {
      moment: null,
      unix: null,
    }, // timeFrom: null,
    endDateTime: {
      moment: null,
      unix: null,
    }, // timeTo: null,
    vendorCode: null, //search: null,
    assignedAgent: false,
    requestId: null,
  },
  hasDataChanged: false,
  payload: {
    input: {
      contentType: null,
      timeFrom: null,
      timeTo: null,
      search: null,
    },
    assignedAgent: false,
    requestIdSuffix: null,
  },
};

export const filterReducer = (state = filterInitialState, action) => {
  if (action.type === 'SET_FILTER_VENDOR_CODE') {
    return { ...state, draft: { ...state.draft, vendorCode: action.vendorCode } };
  } else if (action.type === 'SET_FILTER_REQUEST_ID') {
    return { ...state, draft: { ...state.draft, requestId: action.requestId } };
  } else if (action.type === 'SET_FILTER_START_DATE_TIME') {
    return {
      ...state,
      draft: { ...state.draft, startDateTime: { moment: action.startDateTime, unix: action.unix } },
    };
  } else if (action.type === 'SET_FILTER_END_DATE_TIME') {
    return {
      ...state,
      draft: { ...state.draft, endDateTime: { moment: action.endDateTime, unix: action.unix } },
    };
  } else if (action.type === 'SET_FILTER_ALL_RESET') {
    return {
      ...state,
      draft: {
        ...state.draft,
        vendorCode: null,
        assignedToMe: null,
        requestId: null,
        startDateTime: { ...state.draft.startDateTime, moment: null, unix: null },
        endDateTime: { ...state.draft.endDateTime, moment: null, unix: null },
      },
    };
  } else if (action.type === 'SET_FILTER_APPLY_TO_PAYLOAD') {
    const validRequestId =
      (state.draft.requestId && state.draft.requestId.length >= 6) || !state.draft.requestId;

    return {
      ...state,
      payload: {
        ...state.payload,
        input: {
          timeFrom: state.draft.startDateTime.unix,
          timeTo: state.draft.endDateTime.unix,
          search: state.draft.vendorCode,
        },
        requestIdSuffix: validRequestId ? state.draft.requestId : null,
        assignedToMe: state.draft.assignedAgent,
      },
    };
  } else if (action.type === 'SET_FILTER_ASSIGN_TO_ME') {
    return { ...state, draft: { ...state.draft, assignedAgent: action.assignedToMe } };
  }
  return state;
};
