import { initialFlag } from './../../App/utility';

export const fwfFlagsMapper = {
  fwfPluginACL: 'cae-permission-acl',
  fwfCountryACL: 'cae-country-acl',
  fwfMasterACL: 'cae-master-acl',
  fwfDateTimeFilter: 'cae-date-time-picker',
  fwfAgentStatus: 'cae-agent-status',
  fwfPrePackedVisible: 'cae-prepackaged-visibility',
  fwfDishPhotoMasterCategoryFilter: 'cae-dish-photo-master-category-filter',
  fwfDishPhotoTypeFilter: 'cae-dish-photo-type-filter',
  fwfPublishMenu: 'cae-publish-menu',
  fwfDownloadMenuExcel: 'cae-download-menu-excel',
  fwfProductDetailTitleField: 'cae-product-detail-title-field',
  fwfProductDetailDescField: 'cae-product-detail-desc-field',
  fwfProductDetailCategoryField: 'cae-product-detail-category-field',
  fwfProductDetailVATField: 'cae-product-detail-vat-field',
  fwfProductDetailPricesAndSizesField: 'cae-product-detail-prices-and-sizes-field',
  fwfProductDetailChoiceGroupsField: 'cae-product-detail-choice-groups-field',
  fwfProductDetailPrepackedField: 'cae-product-detail-prepacked-field',
  fwfPhotoSafeGrid: 'cae-photo-safe-grid',
  fwfProductDetailMenuPhoto: 'cae-product-detail-menu-photo',
  fwfAutoQCChangeRequests: 'cae-auto-qc-change-requests',
  fwfAgentQueueRequests: 'cae-agent-queue-requests',
  fwfVATTaxVisibility: 'cae-vat-tax-visibility',
  fwfOpsPortalCountries: 'cae-ops-portal-countries',
  fwfFeatureRiderPhotosMenu: 'cae-feature-rider-menu',
  fwfLazyLoadAgentQueue: 'cae-lazyload-agent-queue',
  fwfQueueOrder: 'cae-queue-order',
  fwfInfiniteScroll: 'agentqueue-infinite-scroll',
  fwfAgentQueuePhoto: 'cae-agent-photo-queue',
  fwfIsHistoryPanelVisible: 'is-history-module-visible',
  fwfTBCheckIfThereIsMore: 'tb-check-if-there-is-more',
  fwfDineInMenuCollection: 'dine-in-menu-collection',
};

const initialFlags = { ...initialFlag(fwfFlagsMapper) };

const initialState = {
  loading: null,
  flags: { ...initialFlags },
};

export const fwfReducer = (state = initialState, action) => {
  if (action.type === 'FLAGS_SET_DATA') {
    return { ...state, flags: action.flags };
  } else if (action.type === 'FLAGS_RESET') {
    return { ...state, loading: null, flags: { ...initialFlags } };
  } else if (action.type === 'FLAGS_SET_LOADING') {
    return { ...state, loading: action.loading };
  }
  return state;
};
