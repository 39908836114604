import { fetchPendingNumber as fetchPendingNumberApi } from './api';

export function fetching() {
  return {
    type: 'REQUEST_START',
  };
}

export function fetchended() {
  return {
    type: 'REQUEST_END',
  };
}

export function fetchPendingNumber({ data }) {
  return {
    type: 'FETCH_PENDING_NUMBERS',
    data,
  };
}

export function setPendingNumbers({ data }) {
  return {
    type: 'SET_PENDING_NUMBERS',
    data,
  };
}

export function fetchPendingNumberAsync({ country, status }) {
  return async (dispatch) => {
    dispatch(fetching());

    const photoRequestResponse = await fetchPendingNumberApi({
      country,
      status,
    });

    dispatch(
      fetchPendingNumber({
        data: photoRequestResponse.data.total,
      })
    );

    dispatch(fetchended());
  };
}
