import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

// The next version of material-ui/core has LoadingButton component. When we upgrade to that
// we can drop this.

const LoadingButton = ({ pending, onClick, children, ...props }) => {
  return (
    <Button variant="contained" disabled={pending} onClick={onClick} {...props}>
      {pending && <CircularProgress size={14} />}
      {children}
    </Button>
  );
};

export default LoadingButton;
