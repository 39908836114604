import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import styles from './styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(styles);

const TabNavbar = () => {
  const classes: any = useStyles();
  const navigationState = useSelector((state: any) => state.navigationState);
  const activeIndex = useMemo(() => {
    const index = navigationState.topNavbarPages.findIndex((page) => {
      return page.selected;
    });
    if (index === -1) {
      // default will be changed
      return 0;
    }
    return index;
  }, [navigationState.topNavbarPages]);

  return (
    <List component="nav" disablePadding style={{ display: 'flex' }}>
      {navigationState.topNavbarPages[activeIndex].children.map((page, index) => (
        <ListItem
          key={page.path}
          component={NavLink}
          to={page.path}
          className={classes.bottomTabButton}
          activeClassName={classes.bottomTabButtonActive}
          exact
        >
          <ListItemText primaryTypographyProps={{ className: classes.bottomTabButtonText }}>
            {page.label}{' '}
            {page.id === 'menu-management' && navigationState.menuRequestCount !== null
              ? ` (${navigationState.menuRequestCount})`
              : page.id === 'photo-management' &&
                navigationState.menuPhotosCount !== null &&
                ` (${navigationState.menuPhotosCount})`}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};
export { TabNavbar };
