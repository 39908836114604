
export const styles = () => ({
    progressbar: {
        backgroundColor: "#D70F64",
        height:"6px",
        //margin: "39.5px 0",
        //float: "left" as 'left',
        //width: "100%",
        "& > *": {
            backgroundColor: "#e991b6",
        },
  },
  linkButton: {
    margin:"0 10px 0 0",
    backgroundColor: "#D70F64",
    '&:hover': {
        backgroundColor: '#d4216c',
      },
  }
});

export default styles;
