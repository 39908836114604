import _ from 'lodash';
import { TYPE_OF_CHANGE } from '../../App/common/constant';
import { CONSTANTS } from '../../utility';
import React from 'react';
import { getDefaultLanguage } from '../../utility/information';

const getCategoryObject = (categories, value) => {
  const category = _.filter(categories, (category) => {
    return category.id === value;
  });
  return category[0];
};

const formatChoice = (choiceId, choiceToFormat, parentChoiceGroup) => {
  /*
  Have to account for missing data depending on typeOfChange
  ChoiceGroupCreate
  ChoicesDelete
  ChoicesAdd
  ChoicesCreate
  ChoicesPriceChange
   */
  const choiceData = parentChoiceGroup?.choices?.find((choice) => choice.id === choiceId);

  const formatted = {
    tempId: choiceId, //Required by ChoicesSection
    choice: {
      id: choiceId,
      title: choiceToFormat?.title || choiceData?.title || {},
    },
    price: choiceToFormat?.price || choiceData?.price || 0,
  };

  //Preserve source data from request
  if (choiceToFormat?.hasOwnProperty('originalData') && !!choiceToFormat?.originalData) {
    //pass forward original data
    formatted.originalData = choiceToFormat.originalData;
  } else {
    //set original data
    formatted.originalData = choiceToFormat || choiceData;
  }

  return formatted;
};

const brandProxyApiHeader = (currentCountry, changes) => {
  return {
    'Content-Type': 'application/json',
    'x-global-entity-id': currentCountry?.geid,
    'x-vendor-id': changes?.[0]?.item?.vendorCode,
  };
};

const defaultErrorMessage = (
  <span>
    Oops, something went wrong. Please unassign the request and fill up the incident{' '}
    <a
      href={CONSTANTS.URL_INCIDENT_FORM}
      target={'__blank'}
      rel={'noopener noreferrer'}
      style={{ color: 'white' }}
    >
      form
    </a>
    , we will get back to you very soon.
  </span>
);

const getChanges = (typeOfChange, oldData, patchData, product, categories, vatList, currency) => {
  const getEmbeddedVariantData = () => {
    let newVariantKeys = Object.keys(patchData?.variants ?? {})[0];

    const newVariantData = patchData?.variants?.[newVariantKeys] ?? {};

    if(!patchData || !newVariantKeys){
      return false
    }
    
    if (newVariantKeys.includes('_')) {
      newVariantKeys = newVariantKeys.split('_')[0];
    }

    const oldVariantData = oldData?.variants?.[newVariantKeys] ?? {};

    return { newVariantData, oldVariantData };
  };

  switch (typeOfChange) {
    case TYPE_OF_CHANGE.PRODUCT_CREATE: {
      const {
        title = {},
        category_id: category,
        description,
        is_prepacked = false,
        vat_id = '',
        variants = {},
      } = patchData;
      const categoryObj = getCategoryObject(categories, category);
      const categoryString =
        categoryObj && categoryObj.title[getDefaultLanguage(categoryObj.title)];
      const descString = Object.values(description || {}).join(', ');
      const titleString = Object.values(title || {}).join(', ');
      const vatsString = vatList.find((vat) => vat.id === vat_id)?.name;
      // for now don't show container price
      const variantString = Object.values(variants)
        .map((v) => {
          const variantTitle = Object.values(v?.title || []).join(', ');
          if (variantTitle.length) {
            return `${currency?.symbol || currency} ${v?.unit_price}, ${variantTitle}`;
          }
          return `${currency?.symbol || currency} ${v?.unit_price}`;
        })
        .join(', ');

      const newValue = [
        titleString,
        descString,
        categoryString,
        vatsString && `${vatsString} - VAT`,
        variantString,
        (is_prepacked || is_prepacked === false) && `${is_prepacked} - Pre-packed`,
      ]
        .filter(Boolean)
        .join(', ');
      return ['-', newValue];
    }
    //CATEGORY_CREATE not currently supported
    case TYPE_OF_CHANGE.CATEGORY_CREATE: {
      const { title, description } = patchData;
      const descString = Object.values(description || {}).join(', ');
      const titleString = Object.values(title || {}).join(', ');
      const newValue = [titleString, descString].filter(Boolean).join(', ');
      return ['-', newValue];
    }
    case TYPE_OF_CHANGE.CATEGORY_DELETE: {
      let findCategory, defaultLang, categoryTitle, oldValue, newValue;

      if (categories.length > 0) {
        findCategory = categories.find((category) => category.id === oldData.category_id);

        //If category already deleted
        if (!findCategory) {
          return ['-', '-'];
        }
        defaultLang = getDefaultLanguage(findCategory?.title);
        categoryTitle = findCategory?.title;

        oldValue = categoryTitle[defaultLang] ? categoryTitle[defaultLang] : '-';
        newValue = _.get('-');
      }

      return [oldValue, newValue];
    }
    case TYPE_OF_CHANGE.CATEGORY_CHANGE: {
      const oldVal = getCategoryObject(categories, oldData.category_id);
      const newVal = getCategoryObject(categories, patchData.category_id);
      let defaultLanguage = oldVal
        ? getDefaultLanguage(oldVal.title)
        : newVal
        ? getDefaultLanguage(newVal.title)
        : '';
      return [
        oldVal ? oldVal.title[defaultLanguage] : '-',
        newVal ? newVal.title[defaultLanguage] : '-',
      ];
    }
    case TYPE_OF_CHANGE.EMBEDDED_VARIANT_DELETE:
      const deletedVariantId = Object.keys(patchData?.variants ?? {})[0];

      const productVariants = product?.variants ?? [];
      const deletedVariant = productVariants.find((variant) => variant.id === deletedVariantId);

      const deletedVariantDetails = [
        Object.values(deletedVariant?.title ?? {}).join(', '),
        `${currency.symbol} ${deletedVariant?.unit_price}`,
      ];
      return [deletedVariantDetails.join(', '), '-'];
    case TYPE_OF_CHANGE.EMBEDDED_VARIANT_CREATE:
    case TYPE_OF_CHANGE.VARIANT_CREATE: {
      //EmbeddedVariantCreate has the same structure but nested
      const newVariantKeys = Object.keys(patchData?.variants ?? {});

      const newVariantData =
        newVariantKeys.length > 0 ? patchData?.variants?.[newVariantKeys[0]] : patchData;
      const { title = {}, unit_price: unitPrice } = newVariantData;
      // TODO: add containerPrice to array back once supported
      const prices =
        typeof unitPrice === 'undefined' ? [] : [unitPrice].map((p) => `${p ? p.toFixed(2) : 0}`);
      return ['-', [...prices, ...Object.values(title || {})].join(', ')];
    }
    case TYPE_OF_CHANGE.VARIANT_DELETE: {
      const deletedVariant = product?.variants?.find((v) => v.id === oldData.variant_id);

      const defaultLanguage = getDefaultLanguage(deletedVariant?.title);
      const deletedVariantTitle =
        deletedVariant?.title?.[defaultLanguage] || deletedVariant?.defaultTitle || '-';
      const deletedVariantPrice = deletedVariant?.unitPrice;

      const deletedVariantDisplay = deletedVariantPrice
        ? `${deletedVariantTitle}, ${deletedVariantPrice}`
        : deletedVariantTitle;
      return [deletedVariantDisplay, '-'];
    }
    case TYPE_OF_CHANGE.VARIANT_CONTAINERPRICE_CHANGE: {
      return [
        product?.item?.content.data?.container_price.toFixed(2),
        patchData?.container_price.toFixed(2),
      ];
    }
    case TYPE_OF_CHANGE.VARIANT_UNITPRICE_CHANGE: {
      return [oldData?.unit_price?.toFixed(2), patchData?.unit_price?.toFixed(2)];
    }
    case TYPE_OF_CHANGE.VARIANT_TITLE_CHANGE:
    case TYPE_OF_CHANGE.TITLE_CHANGE:
    case TYPE_OF_CHANGE.CATEGORY_TITLE_CHANGE: {
      const defaultLang = getDefaultLanguage(patchData?.title);
      const oldValue = _.get(oldData, `title[${defaultLang}]`, '-');
      const newValue = _.get(patchData, `title[${defaultLang}]`, '-');
      return [oldValue, newValue];
    }
    case TYPE_OF_CHANGE.EMBEDDED_VARIANT_TITLE_CHANGE: {
      const { newVariantData, oldVariantData } = getEmbeddedVariantData();

      const defaultLang = getDefaultLanguage(newVariantData?.title);
      const oldValue = _.get(oldVariantData, `title[${defaultLang}]`, '-');
      const newValue = _.get(newVariantData, `title[${defaultLang}]`, '-');
      return [oldValue, newValue];
    }
    case TYPE_OF_CHANGE.EMBEDDED_VARIANT_UNIT_PRICE_CHANGE: {
      const { newVariantData, oldVariantData } = getEmbeddedVariantData();

      return [oldVariantData?.unit_price.toFixed(2), newVariantData?.unit_price.toFixed(2)];
    }
    case TYPE_OF_CHANGE.DESCRIPTION_CHANGE: {
      const defaultLang = getDefaultLanguage(patchData?.description);
      const oldValue = _.get(oldData, `description[${defaultLang}]`, '-');
      const newValue = _.get(patchData, `description[${defaultLang}]`, '-');
      return [oldValue, newValue];
    }
    case TYPE_OF_CHANGE.VAT_CHANGE: {
      const oldVatObj = vatList.find((vat) => vat?.id === parseInt(oldData?.vat_id, 10));
      const NewVatObj = vatList.find((vat) => vat?.id === parseInt(patchData?.vat_id, 10));

      return [oldVatObj?.name || '-', NewVatObj?.name || '- VAT'];
    }
    case TYPE_OF_CHANGE.PREPACKED_CHANGE: {
      const oldValue =
        oldData.is_prepacked || oldData.is_prepacked === false ? `${oldData.is_prepacked}` : `-`;
      const newValue =
        patchData.is_prepacked || patchData.is_prepacked === false
          ? `${patchData.is_prepacked}`
          : `-`;
      return [oldValue, newValue];
    }
    case TYPE_OF_CHANGE.PRODUCT_AVAILABILITY_CHANGE: {
      const oldValue = _.capitalize(oldData?.available?.toString());
      const newValue = _.capitalize(patchData?.available?.toString());

      return [oldValue, newValue];
    }
    default:
      return ['-', '-'];
  }
};

export { getCategoryObject, formatChoice, brandProxyApiHeader, defaultErrorMessage, getChanges };
