import { useEffect, useRef, useState } from 'react';
import { getCurrentCountry } from '../App/utility';
import Cookies from 'js-cookie';
import config from '../App/config';

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const useCatalogServiceEndpoint = () => {
  const country = Cookies.get('country');
  const [currentCountry, setCurrentCountry] = useState(getCurrentCountry(country));

  useEffect(() => {
    setCurrentCountry(getCurrentCountry(Cookies.get('country')));
  }, [country]);

  return config.catalogServiceApi.replace('my', currentCountry.code.toLowerCase());
};
