import { useEffect } from 'react';
import { Datacenter, datadogRum } from '@datadog/browser-rum';

import config from '../App/config';

const useDataDog = () => {
  useEffect(() => {
    const initDatadog = () => {
      const dhEnv = {
        development: 'dev',
        production: 'prd',
      }[process.env.REACT_APP_ENV];

      if (!!dhEnv) {
        datadogRum.init({
          applicationId: config.ddg.applicationId,
          clientToken: config.ddg.clientToken,
          env: dhEnv,
          datacenter: Datacenter.EU,
          site: 'datadoghq.eu',
          sampleRate: 100,
        });
      }
    };
    initDatadog();
  }, []);
};

export default useDataDog;
