export interface Language {
  code: string;
  symbol: string;
  rtl?: boolean;
  required?: boolean;
}

export enum StatusType {
  Success,
  Rejected,
  Pending,
  RequestPending,
  Draft,
  Error,
}

export type TranslatorFn = (key: string, params?: Record<string, any>) => string;

export interface Translatable {
  [key: string]: string;
}
