export const SET_VATS = 'SET_VATS';
export const SET_REJECT_MODAL_OPEN = 'SET_REJECT_MODAL_OPEN';
export const SET_CATEGORIES_BY_VENDOR = 'SET_CATEGORIES_BY_VENDOR';
export const SET_CHOICE_GROUPS_BY_VENDOR = 'SET_CHOICE_GROUPS_BY_VENDOR';
export const SET_GROUPED_PRODUCT_REQUESTS = 'SET_GROUPED_PRODUCT_REQUESTS';
export const SET_GROUPED_CHOICE_GROUP_REQUESTS = 'SET_GROUPED_CHOICE_GROUP_REQUESTS';
export const SET_COLLAPSE = 'SET_COLLAPSE';
export const SET_CHECKED_REQUESTS = 'SET_CHECKED_REQUESTS';
export const RESOLVE_CHECKED_REQUESTS = 'RESOLVE_CHECKED_REQUESTS';
export const SET_AGENT_QUEUE_DATA = 'SET_AGENT_QUEUE_DATA';
export const SET_AGENT_HISTORY_QUEUE_DATA = 'SET_AGENT_HISTORY_QUEUE_DATA';
export const APPEND_AGENT_QUEUE_DATA = 'APPEND_AGENT_QUEUE_DATA';
export const SET_VENDOR_ITEMS = 'SET_VENDOR_ITEMS';
export const SET_LAZY_VENDOR_ITEMS = 'SET_LAZY_VENDOR_ITEMS';
export const SET_IS_PROCESSING_LAZY_LOAD = 'SET_IS_PROCESSING_LAZY_LOAD';
export const SET_VENDOR_NAMES = 'SET_VENDOR_NAMES';
export const SET_ASSIGNED_AGENT = 'SET_ASSIGNED_AGENT';
export const SET_IS_PROCESSING_DATA = 'SET_IS_PROCESSING_DATA';

export const setVats = (vats) => {
  return {
    type: SET_VATS,
    payload: vats,
  };
};

export const setRejectModalOpen = (rejectModalOpen) => {
  return {
    type: SET_REJECT_MODAL_OPEN,
    payload: rejectModalOpen,
  };
};

export const setCategoriesByVendor = (categoriesByVendor) => {
  return {
    type: SET_CATEGORIES_BY_VENDOR, 
    payload: categoriesByVendor,
  };
};

export const setChoiceGroupsByVendor = (choiceGroupsByVendor) => {
  return {
    type: SET_CHOICE_GROUPS_BY_VENDOR,
    payload: choiceGroupsByVendor,
  };
};

export const setGroupedProductRequests = (groupedProductRequests) => {
  return {
    type: SET_GROUPED_PRODUCT_REQUESTS,
    payload: groupedProductRequests,
  };
};

export const setGroupedChoiceGroupRequests = (groupedChoiceGroupRequests) => {
  return {
    type: SET_GROUPED_CHOICE_GROUP_REQUESTS,
    payload: groupedChoiceGroupRequests,
  };
};

export const setCollapse = (collapse) => {
  return {
    type: SET_COLLAPSE,
    payload: collapse,
  };
};

export const setCheckedRequests = (checkedRequests) => {
  return {
    type: SET_CHECKED_REQUESTS,
    payload: checkedRequests,
  };
};

export const resolveCheckedRequests = () => {
  return {
    type: RESOLVE_CHECKED_REQUESTS,
  };
};

export const setAgentQueueData = (agentQueueData, pageToken) => {
  return {
    type: SET_AGENT_QUEUE_DATA,
    payload: agentQueueData,
    pageToken,
  };
};
export const setAgentHistoryQueueData = (agentHistoryQueueData, pageToken) => {
  return {
    type: SET_AGENT_HISTORY_QUEUE_DATA,
    payload: agentHistoryQueueData,
    pageToken,
  };
};

export const appendAgentQueueData = (agentQueueData, pageToken) => {
  return {
    type: APPEND_AGENT_QUEUE_DATA,
    payload: agentQueueData,
    pageToken,
  };
};

export const setVendorItems = (vendorItems) => {
  return {
    type: SET_VENDOR_ITEMS,
    payload: vendorItems,
  };
};

export const setLazyVendorItems = (lazyVendorItems) => {
  return {
    type: SET_LAZY_VENDOR_ITEMS,
    payload: lazyVendorItems,
  };
};

export const setIsProcessingLazyLoad = (isProcessing) => {
  return {
    type: SET_IS_PROCESSING_LAZY_LOAD,
    payload: isProcessing,
  };
};

export const setVendorNames = (vendorNames) => {
  return {
    type: SET_VENDOR_NAMES,
    payload: vendorNames,
  };
};

export const setAssignedAgent = (vendorCode, assignedAgent) => {
  return {
    type: SET_ASSIGNED_AGENT,
    payload: { vendorCode, assignedAgent },
  };
};

export const setIsProcessingData = (isProcessing) => {
  return {
    type: SET_IS_PROCESSING_DATA,
    payload: isProcessing,
  };
};
