import React from 'react';
import { useTheme, useMediaQuery, Button, Dialog } from '@material-ui/core';
import {
  ConfirmButton,
  DialogTitleWrapper,
  DialogContentWrapper,
  DialogActionsWrapper,
  DialogContentTextWrapper,
} from './style';
import { AlertDialogProps } from './types';
import { Box } from '@material-ui/core';

const AlertDialog: React.FC<AlertDialogProps> = ({
  open,
  disabled,
  title,
  description,
  rejectLabel,
  confirmLabel,
  onClose,
  onAccept,
  onReject,
}) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitleWrapper id="alert-dialog-title">
          <Box fontSize={{ xs: 20, md: 22 }} component="span">
            {' '}
            {title}
          </Box>
        </DialogTitleWrapper>
        <DialogContentWrapper>
          <DialogContentTextWrapper theme={theme} id="alert-dialog-description">
            {description}
          </DialogContentTextWrapper>
        </DialogContentWrapper>
        <DialogActionsWrapper theme={theme}>
          <Button onClick={onReject} color="primary" disabled={disabled} fullWidth={isSmallDevice}>
            {rejectLabel}
          </Button>
          <ConfirmButton
            onClick={onAccept}
            color="primary"
            variant="contained"
            disabled={disabled}
            fullWidth={isSmallDevice}
          >
            {confirmLabel}
          </ConfirmButton>
        </DialogActionsWrapper>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
