import React from 'react';
import { StyledComponentProps, AppBar, Toolbar, Typography } from '@material-ui/core';
import { BackButton, SubmitButton, InfoButton, DraftButton } from './style';

import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import Info from '@material-ui/icons/Info';

export interface ModalAppBarProps extends StyledComponentProps {
  buttonDisabled: boolean;
  buttonText: string;
  title: string;
  formType?: string;
  onClose: (e: any) => void;
  handleDraftButtonClick?: () => void;
  submitButtonShouldRender?: boolean;
  draftButtonShouldRender?: boolean;
  draftButtonDisabled?: boolean;
  draftButtonText?: string;
  handleButtonClick: () => void;
  t: (key: string) => string;
  position: 'fixed' | 'absolute' | 'relative' | 'static' | 'sticky';
  infoLink?: string;
}

const ModalAppBar: React.FC<ModalAppBarProps> = ({
  buttonText,
  onClose,
  handleDraftButtonClick,
  draftButtonShouldRender,
  submitButtonShouldRender,
  draftButtonDisabled,
  draftButtonText,
  handleButtonClick,
  buttonDisabled,
  position,
  title,
  infoLink,
}) => {
  return (
    <AppBar
      position={position}
      style={{
        color: 'rgb(255,255,255)',
        backgroundColor: 'rgb(45, 54, 69)',
      }}
    >
      <Toolbar>
        <BackButton onClick={onClose} color="inherit" aria-label="Menu">
          <KeyboardBackspace />
        </BackButton>

        <Typography
          variant="h1"
          color="inherit"
          style={{ fontSize: 20, textTransform: 'capitalize' }}
        >
          {title}
        </Typography>

        {infoLink && (
          <InfoButton href={infoLink} target="_blank" aria-label="Menu">
            <Info />
          </InfoButton>
        )}

        {draftButtonShouldRender && (
          <DraftButton
            color="primary"
            onClick={handleDraftButtonClick}
            disabled={buttonDisabled || draftButtonDisabled}
            variant="contained"
            data-cp="product-save-btn"
          >
            {draftButtonText}
          </DraftButton>
        )}
        {submitButtonShouldRender && (
          <SubmitButton
            color="primary"
            onClick={handleButtonClick}
            disabled={buttonDisabled}
            variant="contained"
            data-cp="product-save-btn"
          >
            {buttonText}
          </SubmitButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default ModalAppBar;
