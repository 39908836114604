const masterCategoriesReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_MASTER_CATEGORIES_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_MASTER_CATEGORIES_SUCCESS':
      return { ...state, data: action.data };
    case 'FETCH_MASTER_CATEGORIES_FAILURE':
      return { ...state, error: action.data };
    default:
      return state;
  }
};

export default masterCategoriesReducer;
