import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import config from '../../../App/config';
import styles from './styles';
import axios from '../../../App/utility/axios';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(styles);

const PhotoList = ({ match, setCustomContext }) => {
  const [numPages, setNumPages] = useState(1);
  const classes = useStyles();
  const [files, setFiles] = useState([{}]);
  const { vendorId, countryCode } = match.params;
  const onDocumentLoadSuccess = (payload) => {
    setNumPages(payload.numPages);
  };

  useEffect(() => {
    setCustomContext({ name: `Menu Photos For ${vendorId}` });
  }, [setCustomContext, vendorId]);

  useEffect(() => {
    const getFilesUrl = async () => {
      const URL = `${config.menuAttachedFilesApi}/${vendorId}/${countryCode}`;

      try {
        const res = await axios(URL, {
          headers: { 'Content-Type': 'application/json' },
        });
        const { menuImages } = res.data;
        setFiles((prevFiles) => [...prevFiles, ...menuImages]);
      } catch (error) {
        console.log(error);
      }
    };

    getFilesUrl();
  }, [countryCode, vendorId]);

  return (
    <div className={classes.root}>
      {files.map(({ location = '' }, i) =>
        location.endsWith('.pdf') ? (
          <Document
            className={classes.pdfWrapper}
            file={location}
            onLoadSuccess={onDocumentLoadSuccess}
            key={'doc' + i}
          >
            {[...Array(numPages)].map((x, i) => (
              <Page pageNumber={i + 1} key={i + 1} className={'page'} />
            ))}
          </Document>
        ) : (
          <img src={location} key={i} className={classes.image} alt="" />
        )
      )}
    </div>
  );
};

export default PhotoList;
