export const style = theme => ({
  container: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    height: "100vh",
    background: "white",
    width: "100%",
  },
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  left: {
    flex: 1,
    display: "flex",
    paddingLeft: theme.spacing(10),
    flexDirection: "column",
  },
  right: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginBottom: 50,
  },
  logoCircleBigWrapper: {
    width: "40px",
    height: "40px",
    borderRadius: "20px",
    overflow: "hidden",
  },
  appBarLeft: {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
  loginButton: {
    width: 350,
    textTransform: "none",
    fontSize: 18,
    height: 43,
    boxShadow: theme.shadows[1],
    borderRadius: 3,
  },
});

export default style;
