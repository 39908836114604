import React from 'react';
import { BannerWrapper, CloseButton, CloseButtonIcon } from './style';
import { BannerProps } from './types';

const Banner: React.FunctionComponent<BannerProps> = ({ children, className, onClose }) => {
  return (
    <BannerWrapper className={className}>
      {children}
      {onClose && (
        <CloseButton onClick={onClose}>
          <CloseButtonIcon />
        </CloseButton>
      )}
    </BannerWrapper>
  );
};

export default Banner;
