import styled from 'styled-components';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';

export const ConfirmButton = styled(Button)`
  background: #d61f26 !important;
  &.MuiButton-contained.Mui-disabled {
    color: white;
    opacity: 0.5;
  }
`;

export const DialogTitleWrapper = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    padding: 24px;
    padding-bottom: 0;
  }
`;

export const DialogContentWrapper = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding-top: 16px;
    padding-bottom: 28px;
  }
  &.MuiDialogContentText-root {
    font-size: 18px;
  }
`;

export const DialogContentTextWrapper = styled(DialogContentText)`
  &.MuiDialogContentText-root {
    font-size: ${(props) => props.theme.typography.htmlFontSize}px;
    ${(props) => props.theme.breakpoints.down('sm')} {
      font-size: ${(props) => props.theme.typography.fontSize}px;
    }
  }
`;

export const DialogActionsWrapper = styled(DialogActions)`
  &.MuiDialogActions-root {
    padding-top: 0px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;

    ${(props) => props.theme.breakpoints.down('sm')} {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &.MuiDialogActions-spacing > :not(:first-child) {
    ${(props) => props.theme.breakpoints.down('sm')} {
      margin-left: 0px;
    }
  }
`;
