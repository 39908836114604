import React from 'react';
import { InfoBox } from './styles';
import { NutritionDisclaimerProps } from './types';

const NutritionDisclaimer: React.FC<NutritionDisclaimerProps> = ({
  t,
  platformCompanyName,
  shouldRender = true,
}) =>
  shouldRender ? (
    <InfoBox data-testid="nutrition_disclaimer_infobox" severity="info">
      {t('menu_management_pandora.nutrition_information_card_disclaimer', {
        platformCompanyName,
      })}
    </InfoBox>
  ) : null;

export default NutritionDisclaimer;
