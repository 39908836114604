import { COUNTRY_LIST } from './constant/countryList';

export const supportedCountryList = (countryList) => {
  if (countryList === '*') return COUNTRY_LIST;

  const supportedCountries = {};
  Object.keys(COUNTRY_LIST)
    .filter(
      (code) =>
        countryList
          .replace(/ /g, '')
          .split(',')
          .indexOf(code) > -1
    )
    .forEach((code) => (supportedCountries[code] = COUNTRY_LIST[code]));

  return supportedCountries;
};
