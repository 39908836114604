import _isNil from 'lodash/isNil';
import { getPendingRequestCountOnInit, initPlugin } from '../initPlugin';
import { LayoutOne } from '../App/layouts/TabsLayout';
import { HistoryContainer } from './containers/HistoryContainer';
import { PhotosContainer } from './containers/PhotosContainer';
import { IPage } from '../initPlugin';
const HistoryManagement = ({ setPluginTitle, currentCountry, optionalPath = '' }) => {
  const pluginId = 'history-management';
  const label = 'History';
  // const isTalabat = currentCountry.geid === 'TB_KW';

  const setRequestCount = (pluginId, labelText) => (count) => {
    setPluginTitle(pluginId, _isNil(count) ? labelText : `${labelText} (${count})`);
  };

  const _historyManagement: IPage = {
    path: optionalPath || '/history',
    visibleOnTopNavbar: true,
    visibleOnTabNavbar: true,
    component: HistoryContainer,
    label: 'Menu History',
    isProtected: true,
    layout: LayoutOne,
    additionalProps: {
      setRequestCount: setRequestCount(pluginId, label),
    },
  };

  const historyPhotoManagement: IPage = {
    path: '/history/photos',
    visibleOnTabNavbar: true,
    parent: HistoryContainer,
    component: PhotosContainer,
    label: 'Photos',
    isProtected: true,
    layout: LayoutOne,
    additionalProps: {
      setRequestCount: setRequestCount(pluginId, label),
    },
  };

  const availablePages = [_historyManagement, historyPhotoManagement];

  const onInit = getPendingRequestCountOnInit(currentCountry, pluginId, setPluginTitle, label);

  return initPlugin(pluginId, availablePages, onInit);
};

export default HistoryManagement;
