import React, { useContext, useEffect, useCallback, useState, Fragment } from 'react';
import { removePhotoRequests } from '../../../MenuManagement/containers/Home/helpers';
import { AppContext } from '../../../App/index';
import FileCopy from '@material-ui/icons/FileCopy';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttachFile from '@material-ui/icons/AttachFile';
import { copyToClipboard, getDefaultLanguage } from '../../../../src/utility/information';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import axios from '../../../App/utility/axios';
import config from '../../../App/config';
import { ProductDetailModal } from '../../../MenuManagement/containers/Home/NewProductDetails/index';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../styles';
import ChoiceGroupDetailModal from '../../../MenuManagement/containers/Home/NewChoiceGroup/ChoiceGroupDetailModal';
import { TYPE_OF_CHANGE } from '../../../App/common/constant';

import {
  Box,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Loading from 'MenuCreation/containers/MenuCreation/components/Loading';
import EmptyState from 'components/EmptyState2';
import { useInitialMount } from '../../../App/hooks/useInitialMount';
import { useSelector } from 'react-redux';

const HistoryMenuQueue = ({
  historyMenuData,
  refetchHistoryMenuData,
  error,
  customContext,
  setCustomContext,
  pageToken,
  setPageToken,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [historyData, setHistoryData] = useState([]);
  const [vendorCategories, setVendorCategories] = useState([]);
  const [vendorChoiceGroups, setVendorChoiceGroups] = useState([]);
  const [vendorProducts, setVendorProducts] = useState([]);
  const [nextPageDataLoaded, setNextPageDataLoaded] = useState(true);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [isProductDetailModalOpen, setIsProductDetailModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({});
  const [isCGDetailModalOpen, setIsCGDetailModalOpen] = useState(false);

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { currentCountry } = useContext(AppContext);
  const isInitialMount = useInitialMount();
  const filterState = useSelector((state) => state.filterState);

  const getEligibleChanges = useCallback((qcRequests) => {
    if (qcRequests) {
      return removePhotoRequests(qcRequests);
    }
  }, []);

  useEffect(() => {
    setHistoryData([]);
  }, [currentCountry.geid]);

  useEffect(() => {
    if (!!historyMenuData) {
      const eligibleChanges = getEligibleChanges(historyMenuData.qcRequests);
      setHistoryData((state) => [...state, ...eligibleChanges]);
      setHasNextPage(!!historyMenuData.pageToken);
      setNextPageDataLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyMenuData]);

  const getFromBrandProxy = useCallback(
    async (itemType, vendorCode, itemId = '') => {
      try {
        const response = await axios(`${config.brandProxyApi}/${itemType}/${itemId}`, {
          headers: {
            'Content-Type': 'application/json',
            'x-global-entity-id': currentCountry.geid,
            'x-vendor-id': vendorCode,
          },
        });
        return response.data.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    [currentCountry.geid]
  );

  const onCopyVendorCode = (vendorCode) => (evt) => {
    evt.stopPropagation();
    copyToClipboard(vendorCode);
    enqueueSnackbar('Successfully copied the vendor code to clipboard', { variant: 'success' });
  };

  const openDetailModal = (request) => () => {
    setSelectedRequest(request);
    const vendorCode = request.item.vendorCode;

    getFromBrandProxy('categories', vendorCode).then((data) => {
      setVendorCategories(data);
    });
    getFromBrandProxy('choice-groups', vendorCode).then((data) => {
      setVendorChoiceGroups(data);
    });

    let baseItemId = request?.meta?.productID ?? request?.item?.itemId;

    try {
      axios(`${config.brandProxyApi}/product/${baseItemId}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-global-entity-id': currentCountry.geid,
          'x-vendor-id': vendorCode,
        },
      }).then((response) => {
        setVendorProducts(response?.data?.data);
      });
    } catch (error) {
      console.log(error);
    }

    const type = request.item.typeOfChange.toLowerCase();

    const isProductChange = Object.values(TYPE_OF_CHANGE).find((x) => x.toLowerCase() === type);

    setIsProductDetailModalOpen(!!isProductChange);
    setIsCGDetailModalOpen(!isProductChange);
  };

  const loadMoreData = () => {
    setNextPageDataLoaded(false);
    let pageTokenn = historyMenuData?.pageToken;
    setPageToken(pageToken);
    refetchHistoryMenuData({
      pageToken: pageTokenn,
    });
  };

  const getInputParams = useCallback(() => {
    setPageToken('');
    return {
      globalEntityID: currentCountry.geid,
      timeFrom: filterState.payload.input.timeFrom
        ? moment(filterState.payload.input.timeFrom.unix).format('X')
        : null,
      timeTo: filterState.payload.input.timeTo
        ? moment(filterState.payload.input.timeTo.unix).format('X')
        : null,
      search: filterState.payload.input.search || null,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry.geid, filterState.payload]);

  useEffect(() => {
    let inputParams = getInputParams();
    //let tokenFlag = false;
    if (!isInitialMount) {
      let payload = {
        input: inputParams,
      };
      refetchHistoryMenuData(payload);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry]);

  const getProductName = (request) => {
    if (request?.metadata?.product?.Title) {
      return request.metadata.product.Title[getDefaultLanguage(request.metadata.product.Title)];
    } else {
      return request.meta.productID ?? request.item.id;
    }
  };

  const handleChoiceGroupModalClose = () => {
    setIsCGDetailModalOpen(false);
  };

  if (historyData.length === 0) {
    return <EmptyState />;
  }

  return (
    <>
      <Paper data-enzyme={'AgentQueueHistoryTable'}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell data-cp="vendor-name" align={'left'}>
                Product Name
              </TableCell>
              <TableCell align={'left'}>Vendor Code</TableCell>
              <TableCell align={'left'}>Submit Date</TableCell>
              <TableCell align={'left'}>Change Type with Preview</TableCell>
              <TableCell>Agent Info</TableCell>
              <TableCell>All Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historyData.map((request, index) => {
              const assignedAgent = request.item.assignedAgent || '-';
              return (
                <Fragment key={index}>
                  <TableRow onClick={openDetailModal(request)}>
                    <TableCell>{getProductName(request)}</TableCell>
                    <TableCell onClick={onCopyVendorCode(request.item.vendorCode)}>
                      <Box>
                        <FileCopy />
                        {request.item.vendorCode}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {moment(request.item.timestamp, 'X').format('DD MMM YYYY, H:mm')}
                    </TableCell>
                    <TableCell>
                      <Link
                        onClick={(e) => e.stopPropagation()}
                        href={`/photo/menu/${request.item.vendorCode}/${currentCountry.code}`}
                        target={'_blank'}
                        rel={'noopener'}
                      >
                        <AttachFile />
                      </Link>
                    </TableCell>
                    <TableCell>{assignedAgent}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        className={
                          classes.statusButton +
                          ' ' +
                          (request.item.status === 'APPROVE'
                            ? classes.statusApproved
                            : classes.statusRejected)
                        }
                      >
                        {request.item.status}
                      </Button>
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })}
          </TableBody>
        </Table>

        {hasNextPage && (
          <div className={classes.loadMore}>
            {!nextPageDataLoaded ? (
              <Loading />
            ) : (
              <Button
                onClick={() => loadMoreData()}
                className={classes.loadMoreButton}
                size="large"
                variant="contained"
                color="primary"
              >
                <ExpandMoreIcon />
                Load more data
                <ExpandMoreIcon />
              </Button>
            )}
          </div>
        )}
      </Paper>

      {isProductDetailModalOpen && (
        <ProductDetailModal
          vats={[]}
          open={isProductDetailModalOpen}
          isSaving={false}
          handleClose={() => {
            setIsProductDetailModalOpen(false);
          }}
          handleSave={false}
          changeItem={{ item: vendorProducts, changes: [selectedRequest] }}
          categories={vendorCategories}
          choiceGroups={vendorChoiceGroups}
          cgData={[]}
          choiceGroupAPIStatus={true}
          isAgentAssignedToVendor={false}
          fwfFlags={{}}
          currentCountry={currentCountry}
          historyMode={true}
        />
      )}

      {isCGDetailModalOpen && (
        <ChoiceGroupDetailModal
          choiceGroupModalOpen={isCGDetailModalOpen}
          handleChoiceGroupModalClose={handleChoiceGroupModalClose}
          changeItem={{ item: vendorChoiceGroups, changes: [selectedRequest] }}
          isAgentAssignedToVendor={false}
          fwfFlags={{}}
          historyMode={true}
        />
      )}
    </>
  );
};

export default HistoryMenuQueue;
