// @ts-check
import axiosStatic from 'axios';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';

import config from './App/config';
import App from './App/index';
import axiosInstance from './App/utility/axios';
import defaultAuthClient from './client/authClient';
import errorLink from './utility/apollo/errorLink';
import { applyInterceptors as applyAxiosInterceptors } from './utility/axios/interceptors';

import { store } from './App/store';
import { Provider } from 'react-redux';
import Cookies from 'js-cookie';
import { getCurrentCountry } from './App/utility';
import { useFWFV2 } from './hooks/useFWFV2';

/* import { IVendorDataWithState } from './components/MultipleVendors/type';
 */
export * from './components';
export * from './types';
export * from './enums';
export * from './context';
export * from './validation-rules';
/* 
export { IVendorDataWithState }; */
const customFetch = (uri, options) => {
  return fetch(uri, {
    ...options,
    headers: {
      ...options.headers,
      'x-global-entity-id': getCurrentCountry(Cookies.get('country')).geid,
    },
  });
};

const httpLink = createHttpLink({
  uri: `${config.fuzeGQLApi}/query`,
  fetch: customFetch,
});

const retryLink = new RetryLink({
  attempts: {
    retryIf: (error, operation) => {
      // same logic as axios setup
      return error.statusCode === 401 || error.statusCode === 403;
    },
  },
});

const authLink = setContext(async (_, { headers }) => {
  const token = await defaultAuthClient.getToken();
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([retryLink, errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

applyAxiosInterceptors(axiosStatic);
applyAxiosInterceptors(axiosInstance);
const rootElement = document.getElementById('root');

const AppMiddleware = () => {
  const { reloadFWF } = useFWFV2();
  // @ts-ignore
  /*   const fwfState: { loading: boolean; flags: any } = useSelector((state) => state.fwfState);
  const currentCountryCode = getCurrentCountry(Cookies.get('country'))?.code;
  const email = localStorage.getItem('email'); */

  useEffect(() => {
    reloadFWF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*   useEffect(() => {
    console.log('fwfState MIDDLEWARE', fwfState);
  }, [fwfState.flags]); */

  //return fwfState.loading === null || fwfState.loading === false ? <App /> : <>Loading</>;
  return <App />;
};
ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <AppMiddleware />
    </Provider>
  </ApolloProvider>,
  rootElement
);
