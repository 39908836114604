import * as React from 'react';
import {
  HighLightBoxContainer,
  HightLightBoxHeader,
  HightLightBoxLabel,
  StatusBoxWrapper,
  ErrorMessageWrapper,
  HightLightBox,
} from './style';
import StatusBox from '../StatusBox';
import { StatusType } from '../../types';
import ChangeDetail from '../ChangeDetails';
import ErrorMessage from '../../components/ErrorMessage';
import { HighlightChangedetailProps } from './type';
import { TypeOfChange } from '../../enums';

const { CHOICE_AVAILABILITY_CHANGE } = TypeOfChange;

const HighlightChangedetail: React.FC<HighlightChangedetailProps> = ({
  className,
  status,
  changes,
  rejectReasons,
  showLanguage,
  t,
}) => {
  /**
   * If the status is not pending nor rejected
   * It must be success for customer, Even though we might
   * store as invalid, nochange, amended or approved
   *
   * @param status string
   */
  const getStatusForDisplay = (status: StatusType) => {
    if (status === StatusType.Pending) {
      return 'pending';
    }

    if (status === StatusType.Rejected) {
      return 'rejected';
    }

    return 'approved';
  };

  const filteredRejectReasons = rejectReasons.filter(
    (rejectedReason) => typeof rejectedReason === 'string'
  );

  const getValuesForChangeDetail = (change: any) => {
    switch (change.typeOfChange) {
      case t(CHOICE_AVAILABILITY_CHANGE):
        return [
          change.oldValue.available ? 'true' : 'false',
          change.newValue.available ? 'true' : 'false',
        ];
      default:
        return [change.oldValue || '-', change.newValue || '-'];
    }
  };

  return (
    <HighLightBoxContainer className={className} data-cp="highlightbox_container">
      <HightLightBox type={status}>
        <HightLightBoxHeader>
          <HightLightBoxLabel>
            {t(`menu_management_pandora.${getStatusForDisplay(status)}`)}{' '}
            {t('menu_management_pandora.requests')}
          </HightLightBoxLabel>
          <StatusBoxWrapper>
            <StatusBox type={status} t={t} />
          </StatusBoxWrapper>
        </HightLightBoxHeader>

        {changes.length > 0 &&
          status === StatusType.Rejected &&
          filteredRejectReasons.map((rejectedReason: string) => (
            <ErrorMessageWrapper key={rejectedReason}>
              <ErrorMessage message={t(rejectedReason)} />
            </ErrorMessageWrapper>
          ))}

        {changes.map((change) => {
          const { typeOfChange, updatedOn, language } = change;
          const [oldValue, newValue] = getValuesForChangeDetail(change);

          return (
            <React.Fragment key={`change_${change.id}`}>
              <ChangeDetail
                id={change.id}
                key={`change_${change.id}`}
                label={typeOfChange}
                dateString={updatedOn}
                oldValue={oldValue}
                newValue={newValue}
                language={language}
                showLanguage={showLanguage}
                t={t}
              />
            </React.Fragment>
          );
        })}
      </HightLightBox>
    </HighLightBoxContainer>
  );
};

export default HighlightChangedetail;
