import React from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, LinearProgress } from '@material-ui/core';
import { GET_RELATED_ITEMS } from '../../../src/MenuManagement/containers/Home/helpers';
import styles from './styles';
const useStyles = makeStyles(styles);

const RelatedItems = ({ currentCountry, itemID, vendorCode, type }) => {
  const classes = useStyles();

  const { data: relatedItems, loading: loadingRelatedItems } = useQuery(GET_RELATED_ITEMS, {
    variables: {
      GEID: currentCountry?.geid,
      itemID: itemID,
      currentType: type,
      vendorCode: vendorCode,
    },
  });

  if (loadingRelatedItems) {
    return (
      <Box m={3}>
        <LinearProgress className={classes.progressbar} />
      </Box>
    );
  }
  return (
    <div>
      {relatedItems?.getRelatedItemLinksByTypeAndItemID.length > 0 && (
        <Box m={3} style={{ height: '85px' }}>
          <div>
            <h2>Related Items Link</h2>
            {relatedItems.getRelatedItemLinksByTypeAndItemID.map((item, i) => {
              return (
                <Button
                  href={item.Link}
                  key={i}
                  variant="contained"
                  color="primary"
                  target="_blank"
                  className={classes.linkButton}
                >
                  {item.ItemType}
                </Button>
              );
            })}
          </div>
        </Box>
      )}
    </div>
  );
};

export default RelatedItems;
