import config from '../../../App/config';
import axios from '../../../App/utility/axios';
import { CONSTANTS, searchUtil } from '../../../utility';
import jszip from 'jszip';
import fileSaver from 'file-saver';
import moment from 'moment';

export async function fetchDishPhoto(currentCountry) {
  const dishPhoto = await axios(
    `${config.dishPhotosApi}/api/v1/dishphoto/pending?countryCode=${currentCountry.code}`,
    { headers: { 'Content-Type': 'application/json' } }
  );

  return dishPhoto.data.sort(function (a, b) {
    return a.modifiedOn - b.modifiedOn;
  });
}

export async function fetchPhotoTypes({ country, status }) {
  const res = await axios(`${config.dishPhotosApi}/api/v1/dishphoto/photo-type-count`, {
    params: {
      status,
      country: country.code,
    },
    headers: { 'Content-Type': 'application/json', 'X-Global-Entity-Id': country.geid },
  });
  return res.data;
}

export async function search(currentCountry, q, fromDate, toDate, masterCategory, photoType) {
  const endpoint = `${config.dishPhotosApi}/api/v1/dishphoto/search-changes`;

  const dishPhoto = await searchUtil({
    country: currentCountry,
    endpoint,
    q,
    fromDate,
    toDate,
    masterCategory,
    photoType,
  });

  return dishPhoto.data.sort(function (a, b) {
    return a.modifiedOn - b.modifiedOn;
  });
}

export async function putDishPhoto(data, type) {
  //check if the dish photo request is for a new item and change the status to pending-approved
  if (data.productId.startsWith('NEW') && type === 'approved') {
    type = 'pending-approved';
  }

  const body = {
    ...data,
    progressStatus: type,
  };

  return axios(config.fwcApi + `/dishphoto/${data.vendorId}/updateChangeRequest`, {
    method: 'put',
    data: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json', 'X-Global-Entity-Id': body.globalEntityId },
  });
}

export async function uploadPhotos(files) {
  const response = await axios(
    `${config.dishPhotosApi}/api/v1/dishphoto/getUploadDetailsInternal`,
    { method: 'get', headers: { 'Content-Type': 'application/json' } }
  );

  const { awsAccessKeyId, bucketUrl, policyB64, signature } = await response.data;

  const URL = bucketUrl;
  const uploadPromise = files.map((file) => {
    let bodyFormData = new FormData();
    let filename = file.name.replace('uploads_', '');
    bodyFormData.append('Content-Type', 'image/jpg');
    bodyFormData.append('AWSAccessKeyId', awsAccessKeyId);
    bodyFormData.append('key', `uploads/${filename}`);
    bodyFormData.append('acl', 'public-read');
    bodyFormData.append('success_action_status', '201');
    bodyFormData.append('policy', policyB64);
    bodyFormData.append('signature', signature);
    // file must be the last
    bodyFormData.append('file', file);
    return axios.post(URL, bodyFormData, {
      headers: {
        [CONSTANTS.HEADER_IGNORE_AUTHORIZATION]: true,
        'Content-Type': 'multipart/form-data',
      },
    });
  });

  return await Promise.all(uploadPromise);
}

export const batchImageDownload = async ({
  getImageDownloads,
  preDownloadCallbackFn,
  postDownloadCallbackFn,
}) => {
  preDownloadCallbackFn();

  const zip = new jszip();
  const downloadImage = async (uri) => {
    const response = await fetch(uri, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Origin: window.location.origin,
      },
    });
    const buffer = await response.arrayBuffer();
    return buffer;
  };

  const imageDownloads = getImageDownloads(downloadImage);

  const downloads = await Promise.all(imageDownloads);
  downloads.forEach(([fileLocation, binaryContent]) => {
    let filename = fileLocation.substring(fileLocation.lastIndexOf('/') + 1);
    let decodedfilename = decodeURIComponent(filename).replace('uploads/', 'uploads_');
    zip.file(decodedfilename, binaryContent, { binary: true });
  });

  const content = await zip.generateAsync({ type: 'blob' });
  await fileSaver.saveAs(content, `download_dish_photos_${moment().format('DDMMMYYY_HHmm')}.zip`);

  postDownloadCallbackFn();
};
