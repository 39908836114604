// @ts-check
import config from '../config';
import axios from './axios';

export async function fetchCurrentUser() {
  return axios.get(`${config.authorizerApi}/auth/me`, {});
}

export function unixConverter(timestamp) {
  const a = new Date(timestamp * 1000);
  const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate().toString().length === 1 ? "0" + a.getDate() : a.getDate();
  const hour = a.getHours().toString().length === 1 ? "0" + a.getHours() : a.getHours();
  const min = a.getMinutes().toString().length === 1 ? "0" + a.getMinutes() : a.getMinutes();
  const sec = a.getSeconds().toString().length === 1 ? "0" + a.getSeconds() : a.getSeconds()
  const time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
  return time;
}

