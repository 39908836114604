import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

const Message = styled(Typography)`
  padding-top: 34px;
  padding-bottom: 34px;
  text-align: center;
  color: rgb(30, 145, 214);
`;

export const EmptyStateMessage = ({ children }) => <Message>{children}</Message>;
