import React from 'react';
import ChangeDetail from '../../../components/ChangeDetail';
import { getValuesForChangeDetail, getTypeOfChange } from './helpers';

const ChoiceGroupDetailSummary = ({ changeRequests, item }) => {
  return (
    <>
      {changeRequests.map((request) => {
        const [oldValue, newValue] = getValuesForChangeDetail(request, item);
        return (
          <ChangeDetail
            key={request.requestId}
            label={getTypeOfChange(request?.item?.typeOfChange)}
            date={request?.item?.timestamp}
            oldValue={oldValue}
            newValue={newValue}
            request={request}
          />
        );
      })}
    </>
  );
};

export default ChoiceGroupDetailSummary;
