import React from "react";
import theme from "../theme";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { homestyles } from "./styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(homestyles);

function EmptyLayout({ children }) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>{children}</div>
    </ThemeProvider>
  );
}

export default EmptyLayout;
