import { TALABAT_PRICE_REJECT_REASONS } from './priceRejectReason';

const REJECT_REASON_MISMATCHING_TRANSLATION_CHOICE_NAME = {
  key: 'menu_rejection_mismatching_translation_choice_name',
  text: 'Mismatching translation - Choice Name',
};

const REJECT_REASON_CHOICE_NAME_IN_THE_HEADER = {
  key: 'menu_rejection_choice_name_in_the_header',
  text: 'Choice Name in the Header',
};

const REJECT_REASON_2_MERGED_HEADERS = {
  key: 'menu_rejection_2_merged_headers',
  text: '2 Merged Headers',
};

const REJECT_REASON_REJECTION_FOR_POS_CHAINS = {
  key: 'menu_rejection_rejection_for_pos_chains',
  text: 'Rejection for POS chains',
};

const REJECT_REASON_VAGUE_CHOICE = {
  key: 'menu_rejection_vague_choice',
  text: 'Vague Choice',
};

export const TALABAT_CG_REJECT_REASONS = [
  REJECT_REASON_MISMATCHING_TRANSLATION_CHOICE_NAME,
  REJECT_REASON_CHOICE_NAME_IN_THE_HEADER,
  REJECT_REASON_2_MERGED_HEADERS,
  REJECT_REASON_REJECTION_FOR_POS_CHAINS,
  REJECT_REASON_VAGUE_CHOICE,
  ...TALABAT_PRICE_REJECT_REASONS
];
