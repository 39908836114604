// @ts-check
import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import defaultAuthClient from '../../../client/authClient';
import ErrorBoundary from '../ErrorBoundary';

/** @type {string | null} */
let TOKEN_DEFAULT;
TOKEN_DEFAULT = null;

/**
 * @typedef ProtectedRouteProps
 * @property {Pick<typeof defaultAuthClient, 'getToken'>} [authClient]
 * @property {React.ComponentType<any>} component
 * @property {React.ComponentType<any>} layout
 * @property {string} path
 */
/**
 * @template Props
 * @param {ProtectedRouteProps & Props} props
 */
export const ProtectedRoute = ({
  authClient = defaultAuthClient,
  component: Component,
  layout: Layout,
  path,
  ...rest
}) => {
  const [token, setToken] = useState(TOKEN_DEFAULT);

  useEffect(() => {
    async function _() {
      const token = await authClient.getToken();
      setToken(token || null);
    }

    _();
  }, [authClient, setToken]);

  /** Treat undefined as unset */
  if (token) {
    return (
      <Route
        exact
        path={path}
        render={(props) => {
          return !!token ? (
            <ErrorBoundary>
              <Layout {...rest} {...props}>
                <Component {...rest} {...props} />
              </Layout>
            </ErrorBoundary>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
              }}
            />
          );
        }}
      />
    );
  } else {
    return null;
  }
};

/**
 * @param {object} props
 * @param {React.ReactNode} [props.children]
 * @param {React.ComponentType<any>} props.component
 * @param {string} props.path
 */
export const PublicRoute = ({ children, ...rest }) => <Route {...rest}>{children}</Route>;
