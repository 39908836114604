import { Typography } from '@material-ui/core';
import React from 'react';

interface iDetailDisplayProps {
  label: string;
  value: string;
  className?: string;
}

const DetailDisplay = ({ label, value, className = '' }: iDetailDisplayProps) => {
  return (
    <div className={className} data-enzyme={'DetailDisplay'}>
      <Typography variant={'caption'}>{label}</Typography>
      <Typography>{value || 'N/A'}</Typography>
    </div>
  );
};

export default DetailDisplay;
