// @ts-check
import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import Cookies from 'js-cookie';
import { Storage, storageKeys } from './../../storage/storage';
import config from '../../App/config';
import axios, { logOut } from '../../App/utility/axios';

/**
 * There is a race condition here, whereby multiple requests call this function
 * to refresh the token, possibly resulting in one or more of them using a stale
 * refresh token from cookies. As a result, we need to protect access to the
 * cookies until the refresh token API call has fully finished and new tokens
 * are stored.
 * The root cause of this problem may lie in the asynchronous nature of the
 * refresh-token API call, which does not block subsequent callers from
 * executing this function and getting whatever refresh token currently in the
 * cookies, stale or not.
 */
const defaultGetNewToken = async () => {
  try {
    const res = await axios.post(`${config.authorizerApi}/auth/refresh-token`, null, {
      headers: {
        Authorization: `Bearer ${Cookies.get('refresh_token')}`,
      },
    });
    Storage.set(storageKeys.ACCESS_TOKEN, res.data.access_token, {
      expires: 7,
      sameSite: 'None',
      secure: true,
    });
    Cookies.set('refresh_token', res.data.refresh_token, {
      expires: 7,
      sameSite: 'None',
      secure: true,
    });
  } catch (error) {
    console.error(error);
    logOut();
  }
};

/**
 * @typedef ErrorLinkFactoryArgs
 * @property {typeof defaultGetNewToken} [getNewToken]
 * @param {ErrorLinkFactoryArgs} arg0
 * @return {Parameters<typeof onError>[0]}
 */
export const errorLinkFactory = ({ getNewToken = defaultGetNewToken }) => {
  const errorStatusCodes = new Set([401, 403]);
  /** @type {(() => void)[]} */
  const pendingRequests = [];
  let isRefreshingToken = false;

  return ({ graphQLErrors, networkError, operation, forward }) => {
    // if it is a ServerError, `networkError` object contains statusCode
    if (
      networkError != null &&
      'statusCode' in networkError &&
      errorStatusCodes.has(networkError.statusCode)
    ) {
      /** @type {ReturnType<typeof fromPromise>} */
      let refreshObservable;

      if (isRefreshingToken) {
        refreshObservable = fromPromise(
          new Promise((resolve) => {
            /**
             * This promise must be resolved when the token has successfully
             * been refreshed, otherwise this request may never be completed.
             */
            pendingRequests.push(() => resolve(undefined));
          })
        );
      } else {
        refreshObservable = fromPromise(
          (async () => {
            isRefreshingToken = true;

            try {
              await getNewToken();
            } catch {
            } finally {
              isRefreshingToken = false;
              pendingRequests.forEach((pendingRequest) => pendingRequest());
            }
          })()
        );
      }

      return refreshObservable.flatMap(() => {
        return forward(operation);
      });
    }
  };
};

export default onError(errorLinkFactory({}));
