// @ts-check
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles(styles);

/**
 * @param {object} props
 * @param {string} [props.className]
 * @param {React.ReactNode} props.message
 * @param {() => void} props.onClose
 * @param {keyof typeof variantIcon} props.variant
 */
function MySnackbarContentWrapper({ className, message, onClose, variant, ...other }) {
  const classes = useStyles();
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

/**
 * @param {object} props
 * @param {boolean} props.open
 * @param {() => void} props.handleClose
 * @param {React.ReactNode} props.message
 * @param {keyof typeof variantIcon} props.variant
 */
export default function Notification({ open, handleClose, message, variant }) {
  return (
    <div data-testid="notification-wrapper">
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        onEnded={() => handleClose()}
        onCompositionEnd={() => handleClose()}
      >
        <MySnackbarContentWrapper onClose={handleClose} variant={variant} message={message} />
      </Snackbar>
    </div>
  );
}

Notification.propTypes = {
  message: PropTypes.string.isRequired,
};
