import React, { useCallback, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { useDropzone } from 'react-dropzone';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import styles from './styles';
import { makeStyles } from '@material-ui/core/styles';
import UploadButton from '../../../App/components/UploadButton';
import CancelButton from '../../../App/components/CancelButton';

const useStyles = makeStyles(styles);
const UploadPhotoModal = ({ batchUpload, handleClose, open, loading }) => {
  const [files, setFiles] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    setFiles((prev) => [...prev, ...acceptedFiles]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ['image/jpeg', 'image/jpg'],
  });
  const handleDeleteFile = (deletedFile) => () => {
    setFiles(files.filter((file) => deletedFile !== file));
  };
  const classes = useStyles();
  const handleBatchUpload = async () => {
    batchUpload(files);
    setFiles([]);
  };

  return (
    <Modal
      open={open}
      className={classes.root}
      onClose={handleClose}
      data-testid="dishphoto-upload-modal"
    >
      <Paper className={classes.paper}>
        <Box mb={2}>
          <Typography variant="h4">Upload Edited Photos</Typography>
        </Box>
        <Box mb={2} fontSize={16}>
          Please do not change the original file name when you upload photos.
        </Box>

        <Box
          mb={2}
          p={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          border="2px dashed"
          borderColor="primary.main"
          minHeight={300}
          style={{ cursor: 'pointer' }}
          data-testid="dishphoto-dropzone-wrapper"
          {...getRootProps()}
        >
          <input {...getInputProps()} data-testid="dishphoto-dropzone-input" />
          {isDragActive}
          <b>
            Drag images Here
            <br />
          </b>
          or <br />
          <Box color="#479fc7" style={{ textDecoration: 'underline' }}>
            Choose images to upload
          </Box>
        </Box>

        {files?.length > 0 && (
          <Box my={2} p={1} border={'1px solid #efefef'} overflow={'auto'} maxHeight={200}>
            {files.map((file) => (
              <Box key={file.name} display="flex" alignItems="center">
                <Typography flex={1} style={{ maxWidth: 200 }} component="span" noWrap>
                  {file.name}
                </Typography>
                <ClearIcon
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={handleDeleteFile(file)}
                />
              </Box>
            ))}
          </Box>
        )}

        <Box display="flex" justifyContent="flex-end">
          <CancelButton onClick={handleClose} variant="outlined">
            Cancel
          </CancelButton>
          <Box ml={2}>
            <UploadButton
              variant="contained"
              color="primary"
              onClick={() => handleBatchUpload(files)}
              disabled={files.length === 0}
              data-testid="dishphoto-upload-btn"
            >
              {loading ? 'Uploading...' : 'Upload'}
            </UploadButton>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default UploadPhotoModal;
