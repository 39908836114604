import MenuCreation from './containers/MenuCreation';
import { initPlugin } from '../initPlugin';
import { LayoutTwo } from '../App/layouts/TabsLayout';
function MenuCreationPlugin({ setPluginTitle, currentCountry, optionalPath = '' }) {
  const pluginId = 'menu-creation';
  const label = 'Menu Creation';

  const onInit = () => {
    setPluginTitle(pluginId, label);
  };

  return initPlugin(
    pluginId,
    [
      {
        path: optionalPath || '/menu-creation',
        visibleOnTopNavbar: false,
        component: MenuCreation,
        label,
        isProtected: true,
        layout: LayoutTwo,
      },
    ],
    onInit
  );
}

export default MenuCreationPlugin;
