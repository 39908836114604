import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import photoStyles from '../../PhotoManagement/containers/ManagePhoto/styles';

//@ts-ignore
const useStyles = makeStyles(photoStyles);

const EmptyState = () => {
  const classes = useStyles();

  return (
    <Box bgcolor="#ffff" m={5} className={classes.image} data-testid="empty-state">
      <img alt="logo" src="/assets/EmptyState.png" width="auto" height="600px" />
    </Box>
  );
};

export default EmptyState;