import * as React from "react";
import { productSectionStyles } from "./styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(productSectionStyles);

const ProductSection = ({ children, title, desc }) => {
  const classes = useStyles();
  return (
    <div className={classes.sectionWrapper}>
      <div className={classes.sectionHeader}>
        {title}{" "}
        <span className={classes.sectionHeaderSpan}>{desc && `- ${desc}`}</span>
      </div>
      <div className={classes.sectionContent}>{children}</div>
    </div>
  );
};

export default ProductSection;
