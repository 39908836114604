import { SectionV2 } from '../Section';
import React from 'react';
import {
  HelperTextWrapper,
  ChoiceAutoComplete,
  DeleteIcon,
  ChoicesSectionWrapper,
  ChoicePriceInput,
  DraggableIconContainer,
  AddChoiceButton,
  ChoiceTax,
  ChoiceRemoteCode,
  AvailabilityBox,
  ChoiceRowInnerWrapper,
  ChoiceRowOuterWrapper,
  ChoiceAutoCompleteWithSuggestion,
  FormAgeRestriction,
  AgeRestrictionCheckbox,
} from './style';
import { Box, MenuItem, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { ChoiceNameProps, ChoiceRowProps, ChoicesSectionProps } from './types';
import TextField from '../TextField';
import { EmptyStateMessage } from '../EmptyState/SubSectionEmptyState';
import AvailibilityDropDown from '../AvailabilityDropDown';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { capitalizeFirstLetter, getShouldShowRemoteCode } from './helper';
import NutritionSection from './NutritionSection';
import NutritionDisclaimer from '../NutritionInformation/NutritionDisclaimer';
import ChoiceBarcodeSearch from './ChoiceBarcodeSearch';

const ChoiceName: React.FC<ChoiceNameProps> = ({
  value,
  choices,
  onChange,
  onChoiceTextChange,
  disabled,
  error,
  textfieldProps,
  id,
  language,
  getSuggestionList,
  onSuggestionSelect,
  shouldDisbleChoiceReplace,
  ...props
}) => {
  return !getSuggestionList ? (
    <ChoiceAutoCompleteWithSuggestion
      getSuggestionList={getSuggestionList}
      onSuggestionSelect={onSuggestionSelect}
      onChange={onChoiceTextChange}
      label={textfieldProps.label}
      rules={textfieldProps.rules}
      onValidate={textfieldProps.onValidate}
      disabled={disabled || shouldDisbleChoiceReplace}
      error={!!error}
      helperText={error}
      language={language}
      value={value.title}
      fullWidth
      id={id}
      key={language}
      {...props}
      {...textfieldProps}
    />
  ) : (
    <ChoiceAutoComplete
      {...props}
      id={id}
      options={choices}
      getOptionLabel={(option) => {
        return option.title || '';
      }}
      value={value}
      onChange={onChange}
      onInputChange={onChoiceTextChange}
      disabled={disabled}
      disableClearable
      filterOptions={
        onChoiceTextChange
          ? (options) => {
              return options.filter((option) => {
                const isValueTitleEmpty = !(option.title && value.title);
                if (isValueTitleEmpty) {
                  return true;
                }
                return option.title.toLowerCase().includes(value.title.toLowerCase());
              });
            }
          : undefined
      }
      renderInput={(params) => {
        return (
          <TextField
            disabled={disabled}
            {...textfieldProps}
            {...params}
            inputProps={{
              ...textfieldProps.inputProps,
              ...params.inputProps,
            }}
            rules={textfieldProps.rules}
            onValidate={textfieldProps.onValidate}
            error={!!error}
            helperText={error}
            label={textfieldProps.label}
            fullWidth
            language={language}
          />
        );
      }}
    />
  );
};

const ChoiceRow: React.FC<ChoiceRowProps> = ({
  choice,
  index,
  t,
  defaultLanguage,
  languages,
  allChoices,
  handleChoiceChange,
  handleChoiceTextChange,
  handleChoicePriceChange,
  handleDelete,
  currency,
  pickingDisabled,
  hideDelete,
  isDisabledPriceInput,
  isSortingDisabled,
  errors,
  pickedChoicesLength,
  disabled,
  rules,
  onValidateWith,
  wrapperRef,
  taxOptions = [],
  handleChoiceRowChange,
  isAvailabilityEnabled,
  handleAvailability,
  availabilityOptions,
  onChangePrepacked,
  handleAllergensChange,
  handleWarningsChange,
  handleAdditivesChange,
  handleOnChange,
  handleOnChangeIsLiquid,
  handleSearchBarcode,
  handleChoiceBarcodeSearch,
  isNutritionsInformationEnabled,
  nutritionMeta,
  getSuggestionList,
  shouldDisbleChoiceReplace,
  shouldRenderBarcodeSearch,
  showAgeRestriction,
  handleAgeRestrictionChange,
  ...props
}) => {
  const remoteCode = choice.choice.remoteCode;
  const tax = choice.choice.tax;
  const hideDeleteButton = hideDelete || disabled || pickedChoicesLength === 1;
  const onValidateTitle = onValidateWith?.(`rows.${index}_${defaultLanguage.code}`);
  const isDraggable =
    choice?.choice?.id && !choice.choice.id.startsWith('NEW') && !isSortingDisabled;
  const choiceTitle = choice.choice ? choice.choice.title[defaultLanguage.code] : '';
  const shouldShowTax = tax?.value && !!taxOptions.length;
  const shouldShowRemoteCode = getShouldShowRemoteCode(remoteCode);
  const isExistingChoice = choice.choice.id ? true : false;
  let nutritionProps;

  // attach the choice index to the handle functions
  const withIndex = (cb) => (val) => cb(val, index);

  if (isNutritionsInformationEnabled && choice.nutritionInformation) {
    nutritionProps = {
      ...choice.nutritionInformation,
      ...nutritionMeta,
      handleAllergensChange: withIndex(handleAllergensChange),
      handleWarningsChange: withIndex(handleWarningsChange),
      handleAdditivesChange: withIndex(handleAdditivesChange),
      handleOnChange: withIndex(handleOnChange),
      handleOnChangeIsLiquid: withIndex(handleOnChangeIsLiquid),
      handleSearchBarcode: withIndex(handleSearchBarcode),
    };
  }

  const onSuggestionSelect = (selected) => {
    const titles = {};

    languages.forEach((language) => {
      const value = selected[language.code];
      titles[language.code] = value;
      const event = { target: { value, titles } } as any;
      handleChoiceTextChange(event, language.code, index, true);
    });
  };

  return (
    <ChoiceRowOuterWrapper>
      {shouldRenderBarcodeSearch && !isExistingChoice && (
        <ChoiceBarcodeSearch
          errors={errors}
          index={index}
          onChoiceSearch={handleChoiceBarcodeSearch}
          t={t}
        />
      )}
      <ChoiceRowInnerWrapper
        data-testid="choice-row-wrapper"
        ref={wrapperRef}
        {...props}
        isDraggable={isDraggable}
      >
        {isDraggable && (
          <DraggableIconContainer>
            <DragIndicatorIcon style={{ color: '#757575' }} />
          </DraggableIconContainer>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <ChoiceName
              getSuggestionList={getSuggestionList}
              onSuggestionSelect={onSuggestionSelect}
              language={defaultLanguage.code}
              id={`rows.${index}_${defaultLanguage.code}`}
              value={{
                title: choiceTitle,
                id: choice.choice ? choice.choice.id : '',
              }}
              choices={allChoices.map((choice) => ({
                title: choice.title[defaultLanguage.code],
                id: choice.id,
              }))}
              onChange={(e, { id, title }) => {
                handleChoiceChange(e, id, index);
              }}
              onChoiceTextChange={(e, value, reason) => {
                const target = e.target as HTMLTextAreaElement;
                const title = target.value;
                handleChoiceTextChange(e, defaultLanguage.code, index, false, title);
              }}
              disabled={disabled || pickingDisabled}
              error={errors[`rows.${index}`] || errors[`rows.${index}_${defaultLanguage.code}`]}
              textfieldProps={{
                label: t('menu_management_pandora.choice_name'),
                onValidate: onValidateTitle,
                rules,
                inputProps: {
                  'data-value': choiceTitle,
                  'data-testid': `choice_rows_title_${defaultLanguage.code}`,
                },
              }}
              shouldDisbleChoiceReplace={shouldDisbleChoiceReplace}
            />
          </Grid>

          {languages
            .filter((language) => language.code !== defaultLanguage.code)
            .map((language) => {
              const onValidateOtherTitle = onValidateWith?.(`rows.${index}_${language.code}`);
              const choiceTitle: string = choice.choice ? choice.choice.title[language.code] : '';

              return (
                <Grid item xs={12} sm={4} key={language.code}>
                  <ChoiceName
                    getSuggestionList={getSuggestionList}
                    onSuggestionSelect={onSuggestionSelect}
                    language={language.code}
                    id={`rows.${index}_${language.code}`}
                    key={language.code}
                    value={{
                      title: choiceTitle,
                      id: choice.choice ? choice.choice.id : '',
                    }}
                    choices={allChoices.map((choice) => ({
                      title: choice.title[language.code],
                      id: choice.id,
                    }))}
                    onChange={(e, { id }) => {
                      handleChoiceChange(e, id, index);
                    }}
                    onChoiceTextChange={(e, value, reason) => {
                      const target = e.target as HTMLTextAreaElement;
                      const title = target.value;
                      handleChoiceTextChange(e, language.code, index, false, title);
                    }}
                    disabled={disabled || pickingDisabled}
                    error={errors[`rows.${index}_${language.code}`]}
                    textfieldProps={{
                      label: `${t('menu_management_pandora.choice_name')} - ${
                        language.symbol
                      } (${capitalizeFirstLetter(t('menu_management_pandora.optional'))})`,
                      onValidate: onValidateOtherTitle,
                      rules,
                      inputProps: {
                        'data-value': choiceTitle,
                        'data-testid': `choice_rows_title_${language.code}`,
                      },
                    }}
                    shouldDisbleChoiceReplace={shouldDisbleChoiceReplace}
                  />
                </Grid>
              );
            })}
          <Grid item xs={12} sm={4}>
            <ChoicePriceInput
              currency={`+${currency}`}
              onPriceChange={(val: string) => {
                handleChoicePriceChange(val, index);
              }}
              value={choice.price}
              disabled={isDisabledPriceInput}
              label={t('menu_management_pandora.price')}
              id={`rows.${index}_price`}
              data-testid={`rows.${index}_price`}
            />
          </Grid>
          {shouldShowTax && (
            <Grid item xs={12} sm={4}>
              <ChoiceTax
                select
                value={tax.value}
                name={'tax'}
                onChange={(e) => handleChoiceRowChange(e, index)}
                loading={tax.loading}
                disabled={disabled || pickingDisabled}
                error={errors[`rows.${index}_tax`]}
                inputProps={{
                  'data-value': tax.value,
                  'data-testid': `choice_rows_tax`,
                  'data-rowid': index,
                }}
                label={t('menu_management_pandora.vat')}
                helperText={errors[`rows.${index}_tax`]}
                fullWidth
              >
                {taxOptions.map((option) => (
                  <MenuItem
                    data-testid={'choice_rows_tax_option'}
                    data-value={`choice_rows_tax_${option.id}`}
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </ChoiceTax>
            </Grid>
          )}

          {shouldShowRemoteCode && (
            <Grid item xs={12} sm={4}>
              <ChoiceRemoteCode
                value={remoteCode.value}
                name={'remoteCode'}
                onChange={handleChoiceRowChange}
                loading={remoteCode.loading}
                disabled={disabled || pickingDisabled}
                error={errors[`rows.${index}_remoteCode`]}
                inputProps={{
                  'data-value': remoteCode.value,
                  'data-testid': `choice_rows_remoteCode`,
                  'data-rowid': index,
                }}
                label={t('menu_management_pandora.remote_code_placeholder')}
                helperText={
                  errors[`rows.${index}_remoteCode`] ||
                  t('menu_management_pandora.remote_code_helper_text')
                }
                fullWidth
              />
            </Grid>
          )}

          {showAgeRestriction && (
            <FormAgeRestriction
              data-testid="age_restriction_field"
              data-value={choice.choice.isAgeRestricted}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={choice.choice.isAgeRestricted}
                    onChange={(e) => handleAgeRestrictionChange(e, index)}
                    name="isAgeRestricted"
                  />
                }
                label={
                  <AgeRestrictionCheckbox>
                    {t('menu_management_pandora.age_restriction_info')}
                  </AgeRestrictionCheckbox>
                }
                disabled={disabled}
              />
            </FormAgeRestriction>
          )}

          {isAvailabilityEnabled && isExistingChoice && (
            <Grid item xs={12} sm={12}>
              <AvailabilityBox>
                {/* regardless of when item is unavailable until, it will show unavailable by default */}
                <AvailibilityDropDown
                  value={choice.choice.active ? 'true' : 'false'}
                  onChange={(availablity) => {
                    handleAvailability(availablity, index);
                  }}
                  labels={{
                    available: t('menu_management_pandora.item_availability_text'),
                    unavailable: t('menu_management_pandora.item_unavailability_text'),
                  }}
                  unAvailableForOptions={availabilityOptions}
                ></AvailibilityDropDown>
              </AvailabilityBox>
            </Grid>
          )}
        </Grid>
        {!hideDeleteButton && (
          <DeleteIcon data-testid={`rows.${index}_delete-button`}>
            <DeleteOutlineIcon
              onClick={(e) => {
                handleDelete(e, index);
              }}
            />
          </DeleteIcon>
        )}
      </ChoiceRowInnerWrapper>
      {isNutritionsInformationEnabled && nutritionProps && (
        <NutritionSection
          t={t}
          nutritionProps={nutritionProps}
          onChangePrepacked={(e, checked) => onChangePrepacked(e, checked, index)}
        />
      )}
    </ChoiceRowOuterWrapper>
  );
};

const ChoicesSection: React.FC<ChoicesSectionProps> = ({
  t,
  languages,
  defaultLanguage,
  currency,
  pickedChoices,
  allChoices = [],
  editableChoices = [],
  editableChoicesPrice = [],

  handleAddChoice,
  handleChoiceChange,
  handleChoiceTextChange,
  handleChoicePriceChange,
  handleDelete,
  isAddDisabled,
  onDragEnd,
  isSortingDisabled = true,
  errors,
  disabled,
  className,
  rules,
  onValidateWith,
  taxOptions,
  handleChoiceRowChange,
  handleAvailability,
  availabilityOptions,
  isAvailabilityEnabled,
  onChangePrepacked,
  handleAllergensChange,
  handleWarningsChange,
  handleAdditivesChange,
  handleOnChange,
  handleOnChangeIsLiquid,
  handleSearchBarcode,
  handleChoiceBarcodeSearch,
  isNutritionsInformationEnabled,
  nutritionMeta,
  getSuggestionList,
  onSuggestionSelect,
  disableChoiceReplaceMap = {},
  shouldRenderBarcodeSearch,
  showAgeRestriction,
  handleAgeRestrictionChange,
}) => {
  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      background: isDragging ? '#d8e6f3' : '',
      ...draggableStyle,
    };
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '' : 'initial',
    width: '100%',
  });

  const setIsDraggableProp = (isDraggable, isSortingDisabled) => {
    if (isSortingDisabled) {
      return true;
    }
    return isDraggable;
  };

  return (
    <ChoicesSectionWrapper className={className}>
      <SectionV2 title={t('menu_management_pandora.toppings')}>
        <HelperTextWrapper
          isNutritionsInformationEnabled={isNutritionsInformationEnabled}
          data-testid="choice-section-helper-text"
        >
          {t('menu_management_pandora.choices_section_help_text_v2')}
        </HelperTextWrapper>
        {isNutritionsInformationEnabled && (
          <Box padding={3} paddingTop={0} borderBottom={'1px solid rgb(215, 219, 231)'}>
            <NutritionDisclaimer
              t={t}
              platformCompanyName={nutritionMeta.platformCompanyName}
              shouldRender={isNutritionsInformationEnabled}
            />
          </Box>
        )}
        {pickedChoices.length > 0 ? (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable isDropDisabled={disabled} droppableId={`droppable`}>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot)}
                  >
                    {pickedChoices.map((choice, index) => {
                      const isDraggable = choice?.choice?.id && !choice.choice.id.startsWith('NEW');
                      const shouldDisbleChoiceReplace = disableChoiceReplaceMap[choice.tempId];
                      return (
                        <Draggable
                          key={`${choice.tempId}_${index}`}
                          draggableId={choice.choice ? choice.choice.id : ''}
                          index={index}
                          isDragDisabled={!isDraggable || isSortingDisabled || disabled}
                        >
                          {(provided, snapshot) => (
                            <ChoiceRow
                              key={`${choice.tempId}_${index}`}
                              errors={errors}
                              pickedChoicesLength={pickedChoices.length}
                              {...{
                                choice,
                                index,
                                t,
                                defaultLanguage,
                                languages,
                                allChoices,
                                handleChoiceChange,
                                handleChoiceTextChange,
                                handleChoicePriceChange,
                                handleDelete,
                                currency,
                                pickingDisabled: !editableChoices.includes(choice.choice.id),
                                isDisabledPriceInput: !editableChoicesPrice.includes(
                                  choice.choice.id
                                ),
                                isSortingDisabled,
                                disabled,
                                rules,
                                onValidateWith,
                                taxOptions,
                                handleChoiceRowChange,
                                handleAvailability,
                                availabilityOptions,
                                isAvailabilityEnabled,
                                onChangePrepacked,
                                handleAllergensChange,
                                handleWarningsChange,
                                handleAdditivesChange,
                                handleOnChange,
                                handleOnChangeIsLiquid,
                                handleSearchBarcode,
                                handleChoiceBarcodeSearch,
                                isNutritionsInformationEnabled,
                                nutritionMeta,
                                getSuggestionList,
                                onSuggestionSelect,
                                shouldDisbleChoiceReplace,
                                shouldRenderBarcodeSearch,
                                showAgeRestriction,
                                handleAgeRestrictionChange,
                              }}
                              wrapperRef={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              isDraggable={setIsDraggableProp(isDraggable, isSortingDisabled)}
                            />
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        ) : (
          <EmptyStateMessage data-testid="empty-choices">
            {t('menu_management_pandora.choice_group_choices_empty_state')}
          </EmptyStateMessage>
        )}
        <AddChoiceButton
          data-testid="add-choices-button"
          onClick={handleAddChoice}
          disabled={isAddDisabled || disabled}
        >
          <AddIcon />
          {t('menu_management_pandora.add_a_topping')}
        </AddChoiceButton>
      </SectionV2>
    </ChoicesSectionWrapper>
  );
};

export default ChoicesSection;
export { ChoiceRow, ChoiceName };
