export default function reducer(state, action) {
  switch (action.type) {
    case "REQUEST_START":
      return { ...state, loading: true };
    case "REQUEST_END":
      return { ...state, loading: false };
    case "FETCH_PENDING_NUMBERS":
      return { ...state, data: action.data };
    case "SET_PENDING_NUMBERS":
      return { ...state, data: action.data };
    default:
      throw new Error();
  }
}
