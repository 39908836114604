// @ts-check
import { CONSTANTS, constructMissingFieldErrorKey } from '../..';

/** @type {MissingFieldReplacement} */
const fieldReplacement = {
  choice_group: {
    defaultIfMissing: { title: {} },
    errorToReport: CONSTANTS.ERROR_CHOICE_GROUP_MISSING,
  },
};

/**
 * @return {AxiosRequestInterceptor}
 */
export const choiceGroupRequestInterceptorFn = () => async (request) => {
  if (
    request.data == null ||
    request.url == null ||
    request.url.match(/\/internal\/choice-groups\/(.*)$/) == null ||
    typeof request.data !== 'string' ||
    !request.data.includes(CONSTANTS.KEY_MISSING_FIELD_ERROR_PREFIX)
  ) {
    return request;
  }

  const requestData = JSON.parse(request.data);
  let missingFieldErrors = [];

  for (const dataKey in requestData) {
    if (dataKey.startsWith(CONSTANTS.KEY_MISSING_FIELD_ERROR_PREFIX)) {
      missingFieldErrors.push(requestData[dataKey]);
    }
  }

  let message = 'Please contact the Content Agent Experience team:';
  message = `${message} ${missingFieldErrors.join(', ')}`;
  throw new Error(message);
};

/**
 * @return {AxiosResponseInterceptor}
 */
export const choiceGroupResponseInterceptorFn = () => async (response) => {
  if (
    response.config.url == null ||
    !response.config.url.split('?')[0].endsWith('/internal/choice-groups-requests')
  ) {
    return response;
  }

  let { data } = response;
  const modifiedData = [];

  for (let choiceGroupDatum of data) {
    const choiceGroupDatumClone = { ...choiceGroupDatum };

    for (const fieldToCheck in fieldReplacement) {
      if (choiceGroupDatumClone[fieldToCheck] == null) {
        const { defaultIfMissing, errorToReport } = fieldReplacement[fieldToCheck];
        const errorKeyName = constructMissingFieldErrorKey(fieldToCheck);
        choiceGroupDatumClone[fieldToCheck] = defaultIfMissing;
        choiceGroupDatumClone[errorKeyName] = errorToReport;
      }
    }

    modifiedData.push(choiceGroupDatumClone);
  }

  return { ...response, data: modifiedData };
};

/* istanbul ignore next */
const choiceGroupMissingFieldRequestInterceptor = choiceGroupRequestInterceptorFn();
/* istanbul ignore next */
const choiceGroupMissingFieldResponseInterceptor = choiceGroupResponseInterceptorFn();
export { choiceGroupMissingFieldRequestInterceptor, choiceGroupMissingFieldResponseInterceptor };
