import Cookies, { CookiesStatic } from 'js-cookie';
export const enum sameSiteEnum {
  Strict,
  lax,
  Lax,
  none,
  None,
  undefined,
}
interface CookieAttributes {
  /**
   * Define when the cookie will be removed. Value can be a Number
   * which will be interpreted as days from time of creation or a
   * Date instance. If omitted, the cookie becomes a session cookie.
   */
  expires?: number;

  /**
   * Define the path where the cookie is available. Defaults to '/'
   */
  path?: string | undefined;

  /**
   * Define the domain where the cookie is available. Defaults to
   * the domain of the page where the cookie was created.
   */
  domain?: string | undefined;

  /**
   * A Boolean indicating if the cookie transmission requires a
   * secure protocol (https). Defaults to false.
   */
  secure?: boolean | undefined;

  /**
   * Asserts that a cookie must not be sent with cross-origin requests,
   * providing some protection against cross-site request forgery
   * attacks (CSRF)
   */
  sameSite?: any;
}

export default abstract class Storage<T extends string> {
  private readonly storage;
  private defaultOptions = { expires: 7, secure: true, sameSite: 'None' };

  public constructor(getStorage = (): CookiesStatic => Cookies) {
    this.storage = getStorage();
  }

  public instance() {
    return this.storage;
  }

  public get(key: T): string | undefined {
    return this.storage.get(key);
  }

  public set(key: T, value: string, options?: CookieAttributes): void {
    let _options = this.defaultOptions;
    if (options) {
      _options = { ...this.defaultOptions, ...options };
    }
    this.storage.set(key, value, _options);
  }

  public clearItem(key: T, options?: CookieAttributes): void {
    let _options = this.defaultOptions;
    if (options) {
      _options = { ...this.defaultOptions, ...options };
    }
    this.storage.remove(key, _options);
  }

  public clearItems(keys: T[]): void {
    keys.forEach((key) => this.storage.remove(key));
  }
}
