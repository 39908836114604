import { InputAdornment } from '@material-ui/core';
import TextField from '../TextField';
import React, { useEffect, useState } from 'react';
import { PriceComponentProps } from './types';

const PriceComponent: React.FC<PriceComponentProps> = ({
  currency,
  value,
  onPriceChange,
  rules,
  onValidate,
  ...props
}) => {
  value = parseFloat(value);
  const [price, setPrice] = useState(value && value.toFixed(2));
  useEffect(() => {
    setPrice(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const number = parseFloat(val);

    if (number < 0) {
      return;
    }

    if (isNaN(number)) {
      setPrice('0');
      onPriceChange('0');
      return;
    }

    setPrice(`${number}`);
    onPriceChange(`${number}`);
  };

  return (
    <TextField
      name="unitPrice"
      type="number"
      InputProps={{
        startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
        inputProps: {
          min: 0,
          type: 'number',
        },
      }}
      {...props}
      onChange={handleChange}
      value={price}
      rules={rules}
      onValidate={onValidate}
    />
  );
};

export default PriceComponent;
