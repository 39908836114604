import React, { useState, useMemo, useEffect, useReducer } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import _debounce from 'lodash/debounce';
import TableContainer from '@material-ui//core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import { gql, useLazyQuery } from '@apollo/client';

import styles from './styles';
import EmptyState from './components/EmptyState';
import EmptyData from './components/EmptyData';
import Loading from './components/Loading';
import PublishMenuDialog from './components/PublishMenuDialog';

const useStyles = makeStyles(styles);

export const GET_MENUS_QUERY = gql`
  query getMenus($filter: String) {
    vendorOnboarding(filter: $filter) {
      menuSearch {
        menu {
          id
          restaurantName
          salesForceId
          dataSource
        }
      }
    }
  }
`;

export const EXCEL_DOWNLOAD_QUERY = gql`
  query getDownloadLink($id: String!) {
    vendorOnboardingMenu(id: $id) {
      generateDownloadUrl
    }
  }
`;

const publishMenuReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_DIALOG': {
      return { ...state, menu: action.menu, dialogOpen: true };
    }
    case 'CLOSE_DIALOG':
      return { ...state, menu: null, dialogOpen: false };
    default:
      return state;
  }
};

const MenuCreation = ({ fwfFlags }) => {
  const classes = useStyles();
  const [query, setQuery] = useState('');
  const [selectedMenu, dispatch] = useReducer(publishMenuReducer, {
    dialogOpen: false,
    menu: null,
  });

  const [getMenus, { loading, data }] = useLazyQuery(GET_MENUS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: query,
    },
  });

  const [getDownloadLink] = useLazyQuery(EXCEL_DOWNLOAD_QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (d) => {
      window.open(d.vendorOnboardingMenu.generateDownloadUrl);
    },
  });

  const debouncedFetch = useMemo(() => _debounce(getMenus, 500), [getMenus]);

  useEffect(() => {
    if (!!query) debouncedFetch();
    return debouncedFetch.cancel;
  }, [query, debouncedFetch]);

  const handleChange = (evt) => {
    setQuery(evt.target.value);
  };

  const handlePublishMenu = (menu) => () => {
    dispatch({ type: 'OPEN_DIALOG', menu });
  };

  const handleDownloadMenuExcel =
    ({ id }) =>
    () => {
      getDownloadLink({ variables: { id } });
    };

  return (
    <>
      <Paper component="div" className={classes.root} square elevation={0}>
        <Paper component="div" className={classes.searchContainer} square>
          <InputBase
            className={classes.searchInput}
            placeholder="Search Restaurant Name or Salesforce ID (GRID)"
            inputProps={{
              'aria-label': 'Search Restaurant Name or Salesforce ID (GRID)',
            }}
            onChange={handleChange}
            value={query}
          />
          {query ? (
            <IconButton onClick={() => setQuery('')} aria-label="clear search">
              <CancelIcon />
            </IconButton>
          ) : (
            <IconButton type="submit" aria-label="search">
              <SearchIcon />
            </IconButton>
          )}
        </Paper>
      </Paper>
      {!query ? (
        <EmptyState />
      ) : (
        <>
          {loading && <Loading />}
          {!data || (data.vendorOnboarding.menuSearch.menu.length === 0 && <EmptyData />)}
          {data && data.vendorOnboarding.menuSearch.menu.length > 0 && (
            <Container maxWidth="xl" className={classes.main}>
              <MenuTable
                data={data.vendorOnboarding.menuSearch.menu}
                fwfFlags={fwfFlags}
                handlePublishMenu={handlePublishMenu}
                handleDownloadMenuExcel={handleDownloadMenuExcel}
              />
              {selectedMenu.menu && (
                <PublishMenuDialog
                  open={selectedMenu.dialogOpen}
                  menuDispatch={dispatch}
                  menu={selectedMenu.menu}
                />
              )}
            </Container>
          )}
        </>
      )}
    </>
  );
};

export const MenuTable = ({ data, fwfFlags, handlePublishMenu, handleDownloadMenuExcel }) => {
  const classes = useStyles();
  const { fwfPublishMenu, fwfDownloadMenuExcel } = fwfFlags;
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Restaurant name</TableCell>
            <TableCell>Salesforce ID (GRID)</TableCell>
            <TableCell>Data Source</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((menu) => {
            if (!menu) return null;
            return (
              <TableRow key={menu.id}>
                <TableCell component="th" scope="row">
                  {menu.restaurantName}
                </TableCell>
                <TableCell>{menu.salesForceId}</TableCell>
                <TableCell>{menu.dataSource}</TableCell>
                <TableCell align="right">
                  <Grid container justifyContent="flex-end" spacing={1}>
                    <Grid item>
                      {fwfPublishMenu.variation && (
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          onClick={handlePublishMenu(menu)}
                        >
                          Publish menu
                        </Button>
                      )}
                    </Grid>
                    <Grid item>
                      {fwfDownloadMenuExcel.variation && (
                        <Button
                          className={classes.button}
                          startIcon={<GetAppIcon />}
                          variant="contained"
                          color="primary"
                          onClick={handleDownloadMenuExcel(menu)}
                        >
                          Download menu excel
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MenuCreation;
