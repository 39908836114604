import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { Alert } from '@material-ui/lab';
import { Modal, ChoiceGroupDetailSection, ChoicesSection } from '../../../../components';
import Translate from '../../../../utility/Translate';
import { Box } from '@material-ui/core';
import HighLightBox from '../../../components/HighlightBox';
import StatusBox from '../../../components/StatusBox';
import ChoiceGroupDetailSummary from './ChoiceGroupDetailSummary';
import { defaultErrorMessage } from '../../../utility/information';
import { choiceGroupHighlightBoxStyle } from '../../../components/HighlightBox/styles';
import ProductDetailMenuPhoto from '../../../components/ProductDetailMenuPhoto';
import { gql, useMutation } from '@apollo/client';
import { AGENT_QUEUE_REQUESTS } from '../helpers';
import { AppContext, UserContext } from '../../../../App';
import { getMenuPhotoData, getApprovalQueryInputFromPatchData } from './helpers';
import useChoiceGroupDetails from './useChoiceGroupDetails';
import { capitalizeFirstLetter } from '../../../../utility/information';

const useChoiceGroupHighlightBoxStyles = makeStyles(choiceGroupHighlightBoxStyle);

const CHANGES_QUERY = gql`
  mutation ProcessChangeRequest($input: [CompleteQCRequestOpInput!]!) {
    completeQCRequests(input: $input)
  }
`;

const ChoiceGroupDetailModal = ({
  choiceGroupModalOpen,
  handleChoiceGroupModalClose,
  changeItem,
  isAgentAssignedToVendor = true,
  fwfFlags,
  historyMode,
}) => {
  const [shouldShowMenuPhoto, setShouldShowMenuPhoto] = useState(false);
  const [changeWithImage, setChangeWithImage] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = React.useContext(UserContext);

  const choiceGroupHighlightBoxClasses = useChoiceGroupHighlightBoxStyles();
  const { currentCountry } = React.useContext(AppContext);
  const currency = currentCountry.currency;

  const choiceGroup = changeItem.item;
  const changeRequests = changeItem.changes;

  useEffect(() => {
    const { changes } = changeItem;
    const { data, shouldShowMenuPhoto: shouldShowPhoto } = getMenuPhotoData(changes, fwfFlags);
    setShouldShowMenuPhoto(shouldShowPhoto);
    setChangeWithImage(data);
  }, [changeItem, fwfFlags]);

  const {
    title,
    compiledTitle,
    quantityMax,
    quantityMin,
    choices,
    processedQuantity,
    languages,
    handleMinimumChange,
    handleMaximumChange,
    handleTitleChange,
    getPatchData,
  } = useChoiceGroupDetails({ choiceGroup, requests: changeRequests });

  const [saveChanges] = useMutation(CHANGES_QUERY, {
    refetchQueries: [AGENT_QUEUE_REQUESTS],
    onCompleted: () => {
      enqueueSnackbar(`Successfully saved  request`, {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(defaultErrorMessage, { variant: 'error' });
    },
  });

  /**
   * Modal save click handler
   */
  const handleSubmit = () => {
    const patchData = getPatchData();

    saveChanges({
      variables: {
        input: getApprovalQueryInputFromPatchData({ patchData, currentUser }),
      },
    });
  };

  let status = 'pending';
  if (historyMode && changeRequests[0]?.item?.status) {
    status = changeRequests[0]?.item?.status.toLowerCase();
  }

  const handleChoiceTextChange = (e, languageCode, index) => {};

  /** We need these hacks because Modal does not allow us to set width, or add a custom class */
  return (
    <Modal
      open={choiceGroupModalOpen}
      handleClose={handleChoiceGroupModalClose}
      title={Translate('menu_management_pandora.edit_topping_template')}
      buttonText={Translate('menu_management_pandora.save')}
      isFormDirty={isAgentAssignedToVendor}
      handleButtonClick={handleSubmit}
      t={Translate}
      {...(shouldShowMenuPhoto ? { appBarPosition: 'absolute' } : {})}
    >
      <Box
        display="flex"
        {...(shouldShowMenuPhoto
          ? { height: 'calc(100% - 64px)', position: 'absolute', left: 0, top: 64, width: '100vw' }
          : {})}
      >
        {shouldShowMenuPhoto && (
          <ProductDetailMenuPhoto
            flex="0 0 50%"
            menuPhoto={changeWithImage?.meta?.menuPhoto}
            width="50%"
            currentCountry={currentCountry}
            vendorCode={
              changeItem?.changes?.length > 0 ? changeItem.changes[0].item.vendorCode : ''
            }
            fwfFlags={fwfFlags}
          />
        )}

        <Box
          {...(shouldShowMenuPhoto
            ? {
                flex: '0 0 50%',
                ml: 'auto',
                overflow: 'scroll',
                style: { backgroundColor: '#edf0f7' },
                width: '50%',
              }
            : { width: '100%' })}
        >
          {!isAgentAssignedToVendor && (
            <Alert severity="info" icon={<InfoIcon fontSize="inherit" />}>
              View-only mode.{' '}
              {!historyMode && 'Assign yourself to this vendor so you can process this request.'}
            </Alert>
          )}

          <HighLightBox type={status} className={choiceGroupHighlightBoxClasses.wrapper}>
            <Box display="flex" p={1}>
              <Box flex={1} justifyContent="center" fontSize={18} lineHeight="28px">
                {capitalizeFirstLetter(status)} Requests
              </Box>
              <Box flex={0}>
                <StatusBox type={status} />
              </Box>
            </Box>
            <ChoiceGroupDetailSummary changeRequests={changeRequests} />
          </HighLightBox>
          <ChoiceGroupDetailSection
            t={Translate}
            title={compiledTitle}
            defaultLanguage={languages[0]}
            languages={languages || []} // TODO: Get languages from some API response
            handleTitleChange={handleTitleChange}
            quantity={processedQuantity}
            numberOfChoices={Object.keys(choices).length || 0}
            isMinmaxDisable={
              !isAgentAssignedToVendor ||
              !(
                isAgentAssignedToVendor &&
                (!!quantityMin.relatedRequest || !!quantityMax.relatedRequest)
              )
            }
            isTextFieldDisable={
              !isAgentAssignedToVendor || !(isAgentAssignedToVendor && Object.keys(title).length)
            }
            handleMaximumChange={handleMaximumChange}
            handleMinimumChange={handleMinimumChange}
            errors={{}}
          />
          <ChoicesSection
            t={Translate}
            defaultLanguage={languages[0]}
            languages={languages || []}
            currency={currency?.code}
            pickedChoices={Object.values(choices)}
            // editableChoices={!isAgentAssignedToVendor ? [] : editableChoices}
            editableChoices={[]} // Choices cannot be edited until handlers are implemented so no reason to allow this
            isAddDisabled={true}
            isPriceDisabled={true}
            handleAddChoice={() => {}}
            handleChoiceTextChange={handleChoiceTextChange}
            handleChoiceChange={() => {}}
            handleChoicePriceChange={() => {}}
            handleDelete={() => {}}
            availabilityOptions={[]}
            errors={{}}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default ChoiceGroupDetailModal;
