export const styles = theme => ({
  root: {
    padding: theme.spacing(0),
  },
  menuSelector: {
    height: 33,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    fontSize: 14,
    // color: "white",
    boxShadow: "none",
    "& > div": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    color: "#f1f1f1",
    fontWeight: "bold",
  },
  formControl: {
    minWidth: 120,
    borderBottom: 0,
    padding: 0,
    textAlign: "center",
    color: "white",
    boxShadow: "none",
    fontWeight: "bold",
  },
  paper: {
    background: "rgb(58, 58, 69)",
  },
  list: {
    fontWeight: "bold",
    color: "white",
    "& :hover": {
      background: "#4eaed9",
    },
  },
  input: {
    fontWeight: "bold",
  },
});

export const menuItemStyles = theme => ({
  root: {
    fontWeight: "bold",
    color: "white",
    background: "rgb(58, 58, 69)",
    "& :hover": {
      background: "#4eaed9",
    },
    width: 200,
    minHeight: 48,
  },
  selected: {
    background: "#0e191a",
    color: "#4eaed9",
  },
});

export default styles;
