import _cloneDeep from 'lodash/cloneDeep';

export const initializeFormValue = (value, defaultValue, QCRequest) => {
  return { value: value || defaultValue, relatedRequest: QCRequest || null, hasChanged: false };
};

export const getUpdatedTitleOrDescriptionState = (prevState, value, language) => {
  let newState = _cloneDeep(prevState);
  let langKeyNotFound = false;

  newState = newState.map((item) => {
    if (item.value?.hasOwnProperty(language)) {
      // only update if the request has the language key
      item.value = { ...item.value, [language]: value };
      item.hasChanged = true;
    } else {
      langKeyNotFound = true;
    }
    return item;
  });

  if (langKeyNotFound) {
    // attach the changes to the first item with a related request
    newState.map((item) => {
      item.value = { ...item.value, [language]: value };
      item.hasChanged = true;

      return item;
    });
  }

  return newState;
};

export const getPatchDataAccumulatorFromFormStates = (formData) => {
  const patchDataAccumulator = {};

  const setInAccumulator = (dataKey, formDataPoint) => {
    //No related QCRequest, ignore field and move to next
    if (!formDataPoint.relatedRequest) {
      return;
    }

    const { relatedRequest, hasChanged, value } = formDataPoint;
    const requestId = relatedRequest.requestId;
    patchDataAccumulator[requestId] = {
      ...relatedRequest.item.patchData,
      ...patchDataAccumulator[requestId],
      [dataKey]: value,
      version: relatedRequest.version,
      ...(hasChanged ? { updated: true } : {}),
    };
  };

  Object.keys(formData).forEach((dataKey) => {
    if (formData.hasOwnProperty(dataKey)) {
      if (Array.isArray(formData[dataKey])) {
        // handle title and desc array
        formData[dataKey].forEach((formDataPoint) => {
          setInAccumulator(dataKey, formDataPoint);
        });
      } else {
        const formDataPoint = formData[dataKey];

        setInAccumulator(dataKey, formDataPoint);
      }
    }
  });

  return patchDataAccumulator;
};
