import { TYPE_OF_CHANGE_CATEGORY } from '../../../App/common/constant';
import _get from 'lodash/get';
import ChangeDetail from '../../components/ChangeDetail';
import React from 'react';

const {
  CATEGORY_DESCRIPTION_CHANGE,
  CATEGORY_TITLE_CHANGE,
  MASTER_CATEGORY_CHANGE,
  CATEGORY_AVAILABILITY_CHANGE,
} = TYPE_OF_CHANGE_CATEGORY;

export const getCategoryChangeDetails = (request, masterCategories = []) => {
  const typeOfChange = request.item.typeOfChange;
  const oldData = request?.item?.content?.data || {};
  const patchData = request?.item?.patchData || {};

  const changeDetailsForEachType = {
    [CATEGORY_DESCRIPTION_CHANGE]: () => getChangeValuesWithLanguage('description'),
    [CATEGORY_TITLE_CHANGE]: () => getChangeValuesWithLanguage('title'),
    [CATEGORY_AVAILABILITY_CHANGE]: () => getAvailabilityChange(),
    [MASTER_CATEGORY_CHANGE]: () => getMasterCategoryChangeValues(),
  };

  const getChangeValuesWithLanguage = (key) => {
    const language = Object.keys(patchData[key])[0];

    const oldValue = oldData[key] ? oldData[key][language] : '-';
    const newValue = patchData[key] ? patchData[key][language] : '-';
    return [oldValue, newValue];
  };

  const getMasterCategoryChangeValues = () => {
    // TODO: get master categories title instead of id from function parameter masterCategories
    const oldValue = oldData?.master_category_id || '-';
    const newValue = patchData?.master_category_id || '-';
    return [oldValue, newValue];
  };

  const getAvailabilityChange = () => {
    const oldValue = oldData?.available?.toString() ?? '-';
    const newValue = patchData?.available?.toString() ?? '-';

    return [oldValue, newValue];
  };

  if (typeOfChange in changeDetailsForEachType) {
    return changeDetailsForEachType[typeOfChange]();
  }
  return ['-', '-'];
};

export const renderChangeDetails = (request, masterCategories) => {
  const changeType = _get(request, 'item.typeOfChange', 'UnrecognizedChange');
  const [oldValue, newValue] = getCategoryChangeDetails(request, masterCategories);

  return (
    <ChangeDetail
      key={request.requestId}
      label={changeType}
      date={request.item.timestamp}
      oldValue={oldValue}
      newValue={newValue}
    />
  );
};
