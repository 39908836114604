import { useEffect, useReducer } from 'react';
import _isEmpty from 'lodash/isEmpty';

import { fetchCurrentUser } from '../App/utility';

const initialState = {
  user: {},
  loaded: false,
};

function reducer(state, action) {
  if (action.type === 'USER_LOADED') return { user: action.user, loaded: true };
  return state;
}

const useUser = () => {
  const [{ user, loaded }, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let didCancel = false;

    const whoamI = async () => {
      try {
        const res = await fetchCurrentUser();
        const user = res.data;
        if (!didCancel) {
          dispatch({ type: 'USER_LOADED', user });
        }
      } catch (error) {
        console.error('Error fetching current user permissions from', error);
      }
    };
    if (!loaded && _isEmpty(user)) {
      whoamI();
    }

    return () => {
      didCancel = true;
    };
  }, [user, loaded]);

  return { user };
};

export default useUser;
