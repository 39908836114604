import styled from 'styled-components';
import { Box, Select as MuiSelect } from '@material-ui/core';

export const Select = styled(MuiSelect)`
  .MuiSelect-root {
    display: flex;
    align-items: center;
  }
`;

export const IconBox = styled(Box)`
  margin-right: 8px;
  vertical-align: middle;
`;
