import styled from 'styled-components';
import { Grid, Checkbox, Box } from '@material-ui/core';
import Section from '../Section';

const FIRST_COL_WIDTH = 33;

export const ChoiceGroupSectionWrapper = styled(Section)`
  margin-bottom: 15px;
`;

export const Wrapper = styled.div`
  @media (max-width: 600px) {
    padding: 0;
  }
`;

export const ChoiceWrapperGrid = styled(Grid)`
  flex-wrap: nowrap;
  position: relative;
`;

export const ChoiceGridHeader = styled(Grid)`
  font-family: Roboto;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  @media (max-width: 600px) {
    padding: 16px 24px;
  }
`;

export const ChoiceGridText = styled(Grid)`
  font-size: 16px;
  color: rgba(0, 0, 0);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: 600px) {
    padding: 16px 24px;
  }
`;

export const ChoiceGroupNameText = styled(ChoiceGridText)`
  padding-right: 5px;
`;

export const ChoiceContainer = styled(Box)`
  display: flex;
  width: 100%;
`;

export const HeaderItemContainer = styled(Box)`
  display: flex;
  width: calc(100% - ${FIRST_COL_WIDTH}px);
  margin-left: ${FIRST_COL_WIDTH}px;
  margin-bottom: 10px;
  border-bottom: 0.5px solid rgb(205, 205, 203);
  padding-bottom: 10px;
`;

export const RowItemContainer = styled(Box)`
  display: flex;
  width: calc(100% - ${FIRST_COL_WIDTH}px);
  align-items: center;
`;

export const ActiveChoiceCheckBox = styled(Checkbox)`
  && {
    color: 'rgb(30, 145, 214)';
    padding-left: 10px;
  }
`;
