import * as React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import Box from '@material-ui/core/Box';
import styles from './styles';

const useStyles = makeStyles(styles);
const getColor = (disabled, type) => {
  if (disabled) {
    return '#b5b5b5';
  }

  if (type === 'contained') {
    return 'white';
  }

  return '#ff9190';
};

const RejectButton = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      classes={{ root: classes.root, contained: classes.contained }}
      {...rest}
    >
      <CancelIcon style={{ color: getColor(rest.disabled, rest.variant) }} />
      <Box ml={1}>{children}</Box>
    </Button>
  );
};

export default RejectButton;
