import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const ChoiceGroupSectionWrapper = styled(Box)``;

export const Wrapper = styled(Box)`
  color: #1e1e1e;

  @media (max-width: 500px) {
    padding: 0;
  }
`;

export const VariantTitle = styled(Box)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
`;
