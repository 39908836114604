export const historyStyles = (theme) => ({
  statusButton: {
    borderRadius: "4px",
  },
  statusApproved: {
      backgroundColor: "#E7F5EB",
      color:"#008337"
  },
  statusRejected: {
      backgroundColor: "#FFEBF0",
      color:"#E20338"
  },
  loadMore: {
      width:"100%",
      padding:"5px",

  },
  loadMoreButton: {
      width:"100%",
      padding:"5px",
      fontWeight: "bold"
  }
});

export default historyStyles;
