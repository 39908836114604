import { TextFieldCustomProps } from './types';
import React, { useState, useEffect, useMemo } from 'react';
import { BasicTextfield, AutocompleteWrapper } from './style';
import { Rule } from '../../validation-rules/types';
import { Translatable } from '../../types';
import debounce from 'lodash.debounce';
import { Skeleton } from '@material-ui/lab';

const ruleMatcher = (value: any, rules: Rule[]) => {
  const invalidatedRulesArray = [];
  let isErrors = false;
  for (const rule of rules) {
    const invalidatedRuleData = rule.validate(value);
    if (!invalidatedRuleData.valid) {
      invalidatedRulesArray.push({
        name: rule.name,
        value: invalidatedRuleData.value,
      });
      isErrors = true;
      break;
    }
  }
  return { isErrors, rules: invalidatedRulesArray };
};

const TextField: React.FC<TextFieldCustomProps> = ({
  className,
  rules,
  onValidate,
  onChange,
  loading,
  direction = 'ltr',
  getSuggestionList,
  onSuggestionSelect,
  select,
  ...props
}) => {
  const [errorRule, setErrorRule] = useState({ isErrors: false, rules: [] });
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState(props.value);
  const [suggestionValue, setSuggestionValue] = useState('');

  const debouncedGetSuggestions = useMemo(
    () =>
      debounce(async (query, cbf) => {
        try {
          const suggestions = await getSuggestionList(query, props.language);
          cbf(suggestions);
        } catch (e) {
          console.error('failed to get suggestions', e);
        }
      }, 2000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (onValidate) {
      onValidate(errorRule);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorRule]);

  useEffect(() => {
    if (getSuggestionList) {
      debouncedGetSuggestions(value, (res) => {
        setSuggestions(res);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onValueChange = async (e: React.ChangeEvent<HTMLInputElement>, val: string) => {
    if (!e) {
      return;
    }
    // adding this check because the event object is different for normal text field and autocomplete
    // for autocomplete the e.target.value will be the index of the option selected
    if (val && !select) {
      e = { ...e, target: { ...e.target, value: val, name: props.name, id: props.id } };
    }

    const { value } = e.target;

    if (rules?.length) {
      setErrorRule(ruleMatcher(value, rules));
    }

    setValue(value);
    onChange?.(e);
  };

  const onSelectSuggestion = async (e: React.ChangeEvent<HTMLInputElement>, selected) => {
    if (suggestions[selected.index]) {
      setSuggestionValue(selected.index);
      onSuggestionSelect?.(suggestions[selected.index]);
    }
  };

  if (loading) {
    return <Skeleton className={className} />;
  }

  let textFieldProps;

  if (getSuggestionList) {
    textFieldProps = { ...props };
    // Passing inputProps from choicegoupmodal is causing issues with autocomplete
    delete textFieldProps.inputProps;
  }

  return (
    <>
      {getSuggestionList ? (
        <AutocompleteWrapper
          style={{ width: '100%' }}
          options={suggestions}
          getOptionLabel={(option: Translatable) => option.label || ''}
          onInputChange={onValueChange}
          onChange={onSelectSuggestion}
          renderInput={(params) => (
            <BasicTextfield
              disabled={props.disabled}
              className={className}
              direction={direction}
              {...params}
              {...textFieldProps}
            />
          )}
          defaultValue={props.defaultValue || ''}
          inputValue={value}
          value={suggestionValue}
          loading={loading}
          disableClearable
          freeSolo
          {...props}
        />
      ) : (
        <BasicTextfield
          onChange={onValueChange}
          className={className}
          direction={direction}
          select={select}
          {...props}
        />
      )}
    </>
  );
};

export default TextField;
