import React, {useEffect, useState} from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { AgentQueueContext } from '../../containers/Home/AgentQueue';
import { setAssignedAgent } from '../../store/agentQueue/actions';
import { AppContext } from '../../../App';

const styles = () => ({
  root: {
    textTransform: 'none',
    fontWeight: 'bold',
    minWidth: 120,
    padding:'6px 14px'
  },
});

const useStyles = makeStyles(styles);

export const ASSIGN_QUERY = gql`
  mutation AssignQCRequestToAgent($requestID: [String!]!, $isAssignment: Boolean, $metaData: MetaData) {
    assignQCRequestToAgent(requestID: $requestID, isAssignment: $isAssignment, metaData: $metaData)
  }
`;

const AgentAssignButton = ({
  isAssigned,
  products,
  vendorCode,
  currentUser,
  clearChecks,
  onClickVendorRow,
}) => {
  const classes = useStyles();
  const { currentCountry } = React.useContext(AppContext);
  const { dispatch, isFetchingData } = React.useContext(AgentQueueContext);
  const [assignButtonText, setAssignButtonText] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [toggleAssignment, { loading }] = useMutation(ASSIGN_QUERY, {
    onError: () => {
      enqueueSnackbar(`Error assigning agent for vendor ${vendorCode}`, { variant: 'error' });
    },
  });
  const assignmentVariables = {
    requestID: products.map((p) => p.requestId),
    metaData: {
      agentRoles: currentUser?.permissions?.['ops-portal-fuze']?.[currentCountry.geid],
      geid: currentCountry.geid,
    },
  };

  const handleAssignmentToggle = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    toggleAssignment({
      variables: {
        ...assignmentVariables,
        isAssignment: !isAssigned,
      },
    }).then(() => {
      dispatch(setAssignedAgent(vendorCode, !isAssigned ? currentUser.sub : ''));

      const notificationText = `Successfully ${!isAssigned ? `assigned` : `unassigned`} assigned to vendor ${vendorCode}`;
      enqueueSnackbar(`${notificationText}`, { variant: 'success' });
    });
    if(!isAssigned){
      onClickVendorRow(vendorCode)(evt);
    }
    else{
      clearChecks();
    }
  }

  useEffect(() => {
    if(isAssigned){
      setAssignButtonText(loading ? 'Unassigning' : 'Unassign');
    }
    else{
      setAssignButtonText(loading ? 'Assigning' : 'Assign To Me');
    }
  }, [isAssigned, loading]);

  return (
    <Button
      variant={isAssigned ? 'outlined': 'contained'}
      color={isAssigned ? 'default' : 'primary'}
      classes={classes}
      disabled={loading || isFetchingData}
      onClick={handleAssignmentToggle}
    >
      {assignButtonText}
    </Button>
    )
};

export default AgentAssignButton;