const styles = (theme) => ({
  loader: {
    textAlign: 'center',
    width: 'auto',
    height: 600,
    marginTop: 150,
  },
  main: {
    display: 'flex',
    padding: theme.spacing(3),
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 72,
    background: 'rgb(58, 58, 69)',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 640,
    borderRadius: theme.spacing(0.5),
  },
  searchInput: {
    flex: 1,
    marginLeft: theme.spacing(1.5),
  },
  button: {
    textTransform: 'none',
  },
});

export default styles;
