import axios from '../../../App/utility/axios';
import config from '../../../App/config';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import { searchUtil } from '../../../utility';
import { TYPE_OF_CHANGE, TYPE_OF_CHANGE_CHOICE_GROUP } from '../../../App/common/constant';
import { gql } from '@apollo/client';
import moment from 'moment';

const { VARIANT_CHOICE_GROUP_ADD, VARIANT_CHOICE_GROUP_DELETE } = TYPE_OF_CHANGE;

const variantChangeTypes = [VARIANT_CHOICE_GROUP_ADD, VARIANT_CHOICE_GROUP_DELETE];

const getHeaders = () => ({
  'Content-Type': 'application/json',
});

export async function fetchStatuses(serviceType, geid) {
  const endpoint = `${config.agentApi}/api/v1/statuses?geid=${geid}&service_type=${serviceType}`;

  const response = await axios(endpoint, {
    headers: getHeaders(),
  });

  return response.data;
}

const toogleAgentStatus = async ({ agentEmail, vendorId, data, method }) => {
  const endpoint = `${config.agentApi}/api/v1/agents/${agentEmail}/vendors/${vendorId}`;
  const response = await axios(endpoint, {
    method: method,
    headers: getHeaders(),
    data: JSON.stringify(data),
  });

  return response.data;
};

export const assignAgent = async (agentEmail, vendorId, data) =>
  toogleAgentStatus({ agentEmail, vendorId, data, method: 'PUT' });

export const unAssignAgent = async (agentEmail, vendorId, data) =>
  toogleAgentStatus({ agentEmail, vendorId, data, method: 'DELETE' });

export async function search(currentCountry, q, fromDate, toDate) {
  const endpoint = `${config.api}/internal/search-changes`;

  const response = await searchUtil({
    endpoint,
    country: currentCountry,
    q,
    fromDate,
    toDate,
  });

  const products = response.data.data.filter((d) => d.product_id);
  const choices = response.data.data.filter((d) => d.choice_group_id);

  return [products, choices];
}

export async function fetchChangeRequest(country, status) {
  const response = await axios(
    config.api +
      '/change_requests?platformKey=PDE_DE&country=' +
      country.code +
      '&status=' +
      status,
    {
      headers: getHeaders(),
    }
  );

  return response.data;
}

export async function fetchChoiceGroupChangeData(country, status) {
  let response = await axios(
    config.choiceGroupApi +
      '/internal/choice-groups-requests?country=' +
      country.code +
      '&status=' +
      status,
    {
      headers: getHeaders(),
    }
  );

  return response.data;
}

export async function fetchMenuImages(vendorId, currentCountry) {
  let response = await axios(
    `${config.menuAttachedFilesApi}/${vendorId}/${currentCountry.code}`,
    {}
  );
  return Array.isArray(response.data) ? false : !!response.data.menuImages.length;
}

export async function fetchVendorCategories(groupedVendor, vendorId) {
  const response = await axios(config.api + '/change_requests/vendors/categories/' + vendorId, {
    headers: {
      ...getHeaders(),
      'x-global-entity-id': groupedVendor[vendorId][0].code || 'FP_SG',
    },
  });

  return response.data;
}

export function putChangeRequest(isCreated, data, body) {
  return axios(config.fwcApi + `/change_requests/vendors/${data.vendor_id}`, {
    method: `${isCreated ? 'post' : 'patch'}`,
    data: JSON.stringify(body),
    headers: {
      ...getHeaders(),
      'X-Global-Entity-Id': body.code,
    },
  });
}

export function putChoice(isCreated, data, body) {
  let choiceUpdateUrl = config.fwcApi + `/internal/choice-groups/`;
  if (!isCreated) {
    choiceUpdateUrl = choiceUpdateUrl + data.choice_group_id;
  }
  // eslint-disable-next-line no-useless-concat
  return axios(choiceUpdateUrl, {
    method: `${isCreated ? 'post' : 'patch'}`,
    data: JSON.stringify(body),
    headers: {
      ...getHeaders(),
      'X-Global-Entity-Id': body.code,
    },
  });
}

export function putVariantChanges(body) {
  return axios(`${config.fwcVariantApi}/api/variant/v1/variant/internal`, {
    method: 'put',
    data: JSON.stringify(body),
    headers: {
      ...getHeaders(),
      'X-Global-Entity-Id': body.code,
    },
  });
}

export const transform = (data) => {
  const nonEmptyChanges = data.filter((d) => d.changes.length > 0);
  return groupBy(nonEmptyChanges, 'vendor_id');
};

export const transformVendorDetails = (groupedData) => {
  return Object.values(groupedData).reduce((result, data) => {
    return {
      ...result,
      [data[0].vendor_id]: {
        vendor_id: data[0].vendor_id,
        vendor_name: data[0].vendor_name,
        externalRestaurantId: data[0].externalRestaurantId,
        timestamp: data.sort(function (a, b) {
          return a.timestamp - b.timestamp;
        })[0].timestamp,
      },
    };
  }, {});
};

export const transformSetVendorDetails = (prev, vendorDetailsBuf) => {
  if (isEmpty(prev)) {
    return vendorDetailsBuf;
  } else {
    let newVendorDetails = {};
    Object.keys({ ...prev, ...vendorDetailsBuf }).forEach((key) => {
      newVendorDetails[key] = {
        ...prev[key],
        ...vendorDetailsBuf[key],
      };
    });

    return newVendorDetails;
  }
};

export const separateChanges = (changes, separatedTypes) => {
  const separatedChanges = [];
  const otherChanges = [];

  changes.forEach((change) => {
    if (separatedTypes.includes(change.type_of_change)) {
      separatedChanges.push(change);
    } else {
      otherChanges.push(change);
    }
  });

  return [separatedChanges, otherChanges];
};

export const splitProductAndVariantChanges = (changes) => {
  return separateChanges(changes, variantChangeTypes);
};

export const removePhotoRequests = (QCRequests) => {
  return QCRequests.filter(
    (data) =>
      Object.values(TYPE_OF_CHANGE).includes(data.item.typeOfChange) ||
      Object.values(TYPE_OF_CHANGE_CHOICE_GROUP).includes(data.item.typeOfChange)
  );
};

export const AGENT_QUEUE_REQUESTS = gql`
  query GetAgentQueueRequests(
    $input: GetAgentQueueInput!
    $pageToken: String
    $assignedAgent: String
    $requestIdSuffix: String
  ) {
    agentQueue(
      input: $input
      pageToken: $pageToken
      assignedAgent: $assignedAgent
      requestIdSuffix: $requestIdSuffix
    ) {
      pageToken
      itemCount
      qcRequests {
        advancedPriceQCMessage
        requestId: id
        version
        metadata
        meta {
          menuPhoto
          productID
          vendorName
        }
        item {
          itemId: id
          patchData
          assignedAgent
          vendorCode
          timestamp
          typeOfChange
          content {
            requestId: id
            data
            type
          }
        }
      }
    }
  }
`;

export const PENDING_MENU_REQUESTS = gql`
  query GetPendingRequestsCount($globalEntityID: String!) {
    getPendingRequestsCount(globalEntityID: $globalEntityID)
  }
`;

export const GET_RELATED_ITEMS = gql`
  query getRelatedItemLinksByTypeAndItemID(
    $itemID: String!
    $currentType: String!
    $vendorCode: String!
  ) {
    getRelatedItemLinksByTypeAndItemID(
      itemID: $itemID
      currentType: $currentType
      vendorCode: $vendorCode
    ) {
      Link
      ItemType
      ItemID
    }
  }
`;

export const PENDING_PHOTOQC_REQUESTS = gql`
  query GetPendingPhotoQCRequestsCount($globalEntityID: String!) {
    getPendingPhotoQCRequestsCount(globalEntityID: $globalEntityID)
  }
`;

export const PROCESS_CHANGE_REQUESTS_QUERY = gql`
  mutation ProcessChangeRequest($input: [CompleteQCRequestOpInput!]!) {
    completeQCRequests(input: $input)
  }
`;
export const TALABAT_PROCESS_CHANGE_REQUESTS_QUERY = gql`
  mutation ProcessChangeRequest(
    $input: [CompleteQCRequestOpInput!]!
    $itemID: String
    $itemType: String
  ) {
    completeQCRequests(input: $input, itemID: $itemID, itemType: $itemType)
  }
`;

export const ITEM_CHANGES_QUERY = gql`
  query GetItemByItemID(
    $itemID: String!
    $latestCaseIDOnly: Boolean
    $statusList: [String]
    $isPhotoChange: Boolean
  ) {
    getItemByItemID(
      itemID: $itemID
      latestCaseIDOnly: $latestCaseIDOnly
      statusList: $statusList
      isPhotoChange: $isPhotoChange
    ) {
      itemCount
      qcRequests {
        advancedPriceQCMessage
        requestId: id
        version
        metadata
        meta {
          menuPhoto
          productID
        }
        item {
          itemId: id
          patchData
          assignedAgent
          vendorCode
          timestamp
          typeOfChange
          content {
            requestId: id
            data
            type
          }
          status
          rejectionReasons
        }
      }
    }
  }
`;

export const ITEMS_CHANGES_QUERY = gql`
  query GetItemsByItemIDs($itemIDs: [String!]!, $statusList: [String], $isPhotoChange: Boolean) {
    getItemsByItemIDs(itemIDs: $itemIDs, statusList: $statusList, isPhotoChange: $isPhotoChange) {
      itemCount
      qcRequests {
        requestId: id
        version
        metadata
        meta {
          menuPhoto
          productID
        }
        item {
          itemId: id
          patchData
          assignedAgent
          vendorCode
          timestamp
          typeOfChange
          content {
            requestId: id
            data
            type
          }
          status
          rejectionReasons
        }
      }
    }
  }
`;

export const PHOTO_QUEUE_REQUESTS = gql`
  query getPhotoQCRequests($input: GetAgentQueueInput!, $pageToken: String, $pageSize: Int) {
    getPhotoQCRequests(input: $input, pageToken: $pageToken, pageSize: $pageSize) {
      pageToken
      itemCount
      qcRequests {
        requestId: id
        version
        metadata
        meta {
          menuPhoto
          productID
          vendorName
        }
        item {
          itemId: id
          patchData
          assignedAgent
          vendorCode
          timestamp
          typeOfChange
          content {
            requestId: id
            data
            type
          }
        }
      }
    }
  }
`;

export const AGENT_QUEUE_HISTORY = gql`
  query getQCRequestHistory($input: GetAgentQueueInput!, $pageToken: String, $pageSize: Int) {
    agentQueueHistory(input: $input, pageToken: $pageToken, pageSize: $pageSize) {
      qcRequests {
        id
        metadata
        meta {
          productID
          vendorName
        }
        version
        item {
          itemId: id
          id
          patchData
          typeOfChange
          status
          rejectionReasons
          vendorCode
          timestamp
          assignedAgent
          content {
            requestId: id
            data
            type
          }
        }
      }
      pageToken
      itemCount
    }
  }
`;
export const PHOTO_QUEUE_HISTORY_REQUESTS = gql`
  query getPhotoQCRequestHistory($input: GetAgentQueueInput!, $pageToken: String, $pageSize: Int) {
    getPhotoQCRequestHistory(input: $input, pageToken: $pageToken, pageSize: $pageSize) {
      qcRequests {
        id
        metadata
        meta {
          productID
          vendorName
        }
        version
        item {
          itemId: id
          id
          patchData
          typeOfChange
          status
          rejectionReasons
          vendorCode
          timestamp
          assignedAgent
          content {
            requestId: id
            data
            type
          }
        }
      }
      pageToken
    }
  }
`;

export const GET_DINE_IN_COLLECTIONS = gql`
  query getDineInCollections($refID: String!, $getLatest: Boolean!) {
    getDineInCollections(refID: $refID, getLatest: $getLatest) {
      DineInImages
      Type
      ForceToUpdate
      CreatedAt
    }
  }
`;

export const checkWrongCountryCode = (countryCode, currentCountry, isLocalHost) => {
  if (countryCode !== currentCountry.code && !isLocalHost) {
    console.warn(
      '[WARNING] The country code of the request and the current country code do not match.'
    );
    return true;
  }
  return false;
};

export const returnFilteredData = (currentCountry, fromDate, toDate, vendorCode) => {
  return {
    globalEntityID: currentCountry.geid,
    timeFrom: fromDate ? moment(fromDate).format('X') : null,
    timeTo: toDate ? moment(toDate).format('X') : null,
    search: vendorCode || null,
  };
};
