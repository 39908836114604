import React from 'react';
import { Alert } from '@material-ui/lab';
import InfoIcon from '@material-ui/icons/Info';
import _concat from 'lodash/concat';
import { TALABAT_PRODUCT_REJECT_REASONS } from '../../MenuManagement/common/constants/rejectReason/talabat/productRejectReason';
import { TALABAT_PRICE_REJECT_REASONS } from '../../MenuManagement/common/constants/rejectReason/talabat/priceRejectReason';
import { TALABAT_CATEGORY_REJECT_REASONS } from '../../MenuManagement/common/constants/rejectReason/talabat/categoryRejectReason';
import { TALABAT_CG_REJECT_REASONS } from '../../MenuManagement/common/constants/rejectReason/talabat/cgRejectReason';
import { REJECT_REASON_NO_MENU_PHOTO_ATTACHED, REJECT_REASON_NO_ATTESTED_MENU_ATTACHED,REJECT_REASON_NO_MATCHING_PRICES, REJECT_REASON_EGYPT_NO_MATCHING_PRICES } from '../../MenuManagement/common/constants/rejectReason';

interface iProps {
  changeRequests: any[]
}
type reasonTypes = any [];
type assignedAgents = any [];

type rejectionDataType = {
  reasons: any,
  assignedAgent: any
}

const StatusInfoBox = ({ changeRequests }: iProps) => {

  let allRejectReasons = _concat(
    [],
    TALABAT_PRODUCT_REJECT_REASONS,
    TALABAT_PRICE_REJECT_REASONS,
    TALABAT_CATEGORY_REJECT_REASONS,
    TALABAT_CG_REJECT_REASONS,
    REJECT_REASON_NO_MENU_PHOTO_ATTACHED,
    REJECT_REASON_NO_ATTESTED_MENU_ATTACHED,
    REJECT_REASON_NO_MATCHING_PRICES,
    REJECT_REASON_EGYPT_NO_MATCHING_PRICES
  );
  allRejectReasons =  Array.from(new Set(allRejectReasons.filter((item) => item.key)));

  let reasons: reasonTypes = [];
  let assignedAgent: assignedAgents = [];
  let rejectionData: rejectionDataType = {reasons: [], assignedAgent: []};
  let isApprovedRequest = false;
  let isRejectedRequest = false;

    changeRequests.forEach((element: any) => {
      
        isApprovedRequest = element.item.status === "APPROVE";
        isRejectedRequest = element.item.status === "REJECT";

        if(isRejectedRequest || isApprovedRequest){

          if(element.item.rejectionReasons) {
            element.item.rejectionReasons.forEach((rejection: any) => {

              const reason = allRejectReasons.find((item) =>
                {
                  return item.key === rejection
                });
  
                const reasonText = reason ? reason.text : rejection;
  
                if(reasons.filter(e => e === reasonText).length < 1 ){
                  reasons.push(reasonText);
                }
  
              });
          }

          if(assignedAgent.filter(e => e === element.item.assignedAgent).length < 1 ){
            assignedAgent.push(element.item.assignedAgent);
          }
        }

      });

      rejectionData = {
        assignedAgent: assignedAgent,
        reasons: reasons
      }

  return (

    <div>

        <Alert severity="info" icon={<InfoIcon fontSize="inherit" />} >
        
        <p style={{ marginTop: '0', }}>View-only mode.</p>
        <p>{isApprovedRequest ? "Approved" : "Rejected"} by {rejectionData.assignedAgent.toString()}</p>


        {
          !!rejectionData.reasons.length && (
            <div>
              <p>Rejection reasons</p>

              <ul>
                {rejectionData.reasons.map((reason: any) => {     
                  return (<li>{reason}</li>) 
                })}
              </ul>
          </div>
          )
        }

        </Alert>

    </div>
  );
};

export default StatusInfoBox;