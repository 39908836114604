import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const EmptyData = () => {
  return (
    <Grid container style={{ flex: 1 }} direction="column" justifyContent="center" alignItems="center">
      <img alt="empty state" src="/assets/EmptyData.png" width="auto" height="157" />
      <Typography variant="h4" gutterBottom>
        There is no menu data
      </Typography>
      <Typography>
        Please try again or use another Restaurant Name or Salesforce ID (GRID).
      </Typography>
    </Grid>
  );
};

export default EmptyData;
