import React, { useState, useCallback, useEffect } from 'react';
import theme from './theme';
import { ThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as ReactRouter, Route, Switch } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useFWFV2 } from './../hooks/useFWFV2';
import {
  getCurrentCountry,
  app,
  getCountriesFromNamespaces,
  filterRegionIAMCountries,
  findCountryRegion,
  findRegionApp,
  redirectTo,
} from './utility';
import isEmpty from 'lodash/isEmpty';
import config from './config';
import { SnackbarProvider } from 'notistack';

import Login from './containers/Login';
import { ProtectedRoute, PublicRoute } from './components/RouteFactory';
import AppDialog from '../App/components/AppDialog';
import DefaultLayout from './layouts/NextLayout';
import EmptyLayout from './layouts/EmptyLayout';
import defaultOpsPortalClient from '../client/opsPortal';
import useDataDog from '../hooks/useDataDog';
import useOpsPortal from '../hooks/useOpsPortal';
import useUser from '../hooks/useUser';
import usePlugins from '../hooks/usePlugins';
import { Close } from '@material-ui/icons';
import PageNotFound from '../App/components/PageNotFound';

import { useDispatch, useSelector } from 'react-redux';
import { Storage, storageKeys } from './../storage/storage';
import { useInitialMount } from './hooks/useInitialMount';

export const AppBarContext = React.createContext({});
export const UserContext = React.createContext({});
export const AppContext = React.createContext({});

/**
 * @typedef Props
 * @property {typeof defaultOpsPortalClient} [opsPortalClient]
 * @param {Props} props
 */
function App({ opsPortalClient = defaultOpsPortalClient }) {
  const {
    isInsideOpsPortal,
    isAPAC,
    countries: opsPortalCountries,
    currentCountry: opsPortalCurrentCountry,
  } = useOpsPortal();

  const IsinitialMount = useInitialMount();
  const { reloadFWF } = useFWFV2();
  const fwfState = useSelector((state) => state.fwfState);

  const fwfFlags = fwfState.flags;
  const fwfLoading = fwfState.loading;

  const [routes, setRoutes] = useState([]);
  const [pages, setPages] = useState([]);
  const [topNavbarPages, setTopNavbarPages] = useState([]);
  const [unfilterdPages, setUnfilteredPages] = useState([]);
  const [currentCountry, setCurrentCountry] = useState(getCurrentCountry(Cookies.get('country')));
  const [supportedCountries, setSupportedCountries] = useState({});
  const [customContext, setCustomContext] = useState({ q: '', fromDate: '', toDate: '' });
  const [userContext, setUserContext] = useState({});
  const [errorDialog, setErrorDialog] = useState({
    open: false,
    message: '',
    title: 'Error',
    handler: () => {},
  });

  const navigationDispatch = useDispatch();
  const { user: currentUser } = useUser();

  const { parents, plugins: PLUGINS } = usePlugins(
    currentCountry,
    currentUser,
    fwfLoading, //check this
    fwfFlags
  );

  useEffect(() => {
    if (!IsinitialMount) {
      reloadFWF();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry]);

  const handleLogout = useCallback(() => {
    Cookies.remove('country');
    Storage.clearItem(storageKeys.ACCESS_TOKEN);
  }, []);

  useEffect(() => {
    if (isInsideOpsPortal && isEmpty(currentCountry)) {
      setCurrentCountry(opsPortalCurrentCountry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry, opsPortalCurrentCountry, isInsideOpsPortal]);

  /*
   * Init DataDog
   */
  useDataDog();

  /*
   * load Countries for a user
   */
  useEffect(() => {
    const REGION_COUNTRIES = config.supportedCountries;
    let redirectApp = '';
    let iamCountries = [];
    let supportedCountries = '';

    function getSupportedCountries() {
      const { fwfCountryACL, fwfMasterACL } = fwfFlags;
      const fwfCheck =
        fwfCountryACL.loaded &&
        fwfCountryACL.variation &&
        fwfMasterACL.loaded &&
        fwfMasterACL.variation;

      iamCountries = getCountriesFromNamespaces(currentUser.namespaces);

      /*
       * If App is inside Ops Portal, get countries/currently selected country from Ops Portal SDK.
       * Else, get it from IAM namespaces
       *
       */

      if (isInsideOpsPortal && isAPAC && fwfFlags.fwfOpsPortalCountries.variation) {
        supportedCountries = opsPortalCountries.map((c) => c.code).join(',');

        // By any chance if the init country value is not available, take the first country from the SDK result
        if (!supportedCountries.includes(currentCountry.code) && !isEmpty(supportedCountries)) {
          const fallbackCountry = supportedCountries.split(',')[0];
          setCurrentCountry(fallbackCountry);
          Cookies.set('country', fallbackCountry.code, { sameSite: 'None', secure: true });
        }
        setSupportedCountries(supportedCountries);
      } else {
        supportedCountries = filterRegionIAMCountries({
          fwfCheck,
          REGION_COUNTRIES,
          iamCountries,
        });

        if (!supportedCountries.includes(currentCountry.code)) {
          const selectCountry = getCurrentCountry(supportedCountries.split(',')[0]);
          setCurrentCountry(selectCountry);
          Cookies.set('country', selectCountry.code, { sameSite: 'None', secure: true });
        }
        setSupportedCountries(supportedCountries);
      }
    }

    function checkRegion() {
      if (iamCountries.length > 0 && isEmpty(supportedCountries)) {
        const region = findCountryRegion(iamCountries[0]);
        redirectApp = findRegionApp(region);
        if (redirectApp) {
          setErrorDialog({
            open: true,
            message: 'App Region and User Permissions mismatch, redirecting...',
            title: 'App Error',
            handler: () => redirectTo({ redirectTo: redirectApp }),
          });
          return;
        }
        handleLogout();
      }
    }

    if (currentUser.namespaces && fwfLoading === false) {
      getSupportedCountries();
      checkRegion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentCountry,
    isInsideOpsPortal,
    currentUser,
    handleLogout,
    fwfLoading,
    fwfFlags,
    isAPAC,
    opsPortalCountries,
  ]);

  useEffect(() => {
    const willActivate = isInsideOpsPortal && isAPAC && fwfFlags.fwfOpsPortalCountries.variation;
    opsPortalClient
      .getClient()
      .then((client) => {
        client.addEventListener('countryChange', (evt) => {
          if (willActivate) {
            Cookies.set('country', evt.data.code, { sameSite: 'None', secure: true });
            setCustomContext((prev) => ({ ...prev, ...{ q: '', fromDate: '', toDate: '' } })); // reset query params
            setCurrentCountry({ ...evt.data, geid: `${evt.data.entity.code}_${evt.data.code}` });
          }
        });
      })
      .catch((error) => {
        console.log('not inside ops portal', error);
      });
  }, [isInsideOpsPortal, fwfFlags, isAPAC, opsPortalClient]);

  useEffect(() => {
    /*     if (currentUser?.permissions) {
      currentUser.permissions['ops-portal-fuze']['TB_KW'] = [
        'admin',
        'dev',
        'inflow-dashboard',
        'menu-agent',
        'menu-creation',
        'photo-agent',
        'schedule-agent',
      ];
    } */
    setUserContext(currentUser || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  // update page's label
  const setPluginTitle = useCallback(
    (name, value) => {
      setPages((prev) => {
        return prev.map((page) => {
          if (page.id === name)
            return {
              ...page,
              label: value,
            };
          return page;
        });
      });
    },
    [setPages]
  );

  const renderRoute = useCallback(
    (page, setCustomContext, customContext, setPluginTitle) => {
      return page.isProtected ? (
        <ProtectedRoute
          component={page.component}
          path={page.path}
          key={page.path}
          exact={page.exact}
          setCustomContext={setCustomContext}
          customContext={customContext}
          currentCountry={currentCountry}
          setTitle={setPluginTitle}
          fwfFlags={fwfFlags}
          currentUser={currentUser}
          pages={pages}
          layout={page.layout}
          {...page.additionalProps}
        />
      ) : (
        <PublicRoute
          component={page.component}
          path={page.path}
          key={page.path}
          exact={page.exact}
          setCustomContext={setCustomContext}
          customContext={customContext}
          currentCountry={currentCountry}
          setTitle={setPluginTitle}
          fwfFlags={fwfFlags}
          currentUser={currentUser}
          pages={pages}
          layout={page.layout}
        />
      );
    },
    [currentCountry, currentUser, fwfFlags, pages]
  );

  const workitout = useCallback(
    (wholePlugins) => {
      // below plugins should be checked for Its existance In order to determine what root plugin will become.
      let priorityList = ['menu-management', 'photo-management', 'history-management'];
      let pluginList = wholePlugins.map((it) => it.id);
      let newPluginList = [];
      pluginList.forEach((it) => {
        if (priorityList.includes(it)) {
          newPluginList.push({ id: it, optionalPath: null });
        }
      });
      priorityList.every((prioritizedPlugin, index) => {
        let searchIndex = newPluginList.findIndex((it) => it.id === prioritizedPlugin);
        if (searchIndex !== -1) {
          newPluginList[searchIndex].optionalPath = '/';
          return false;
        } else return true;
      });
      //merge them
      wholePlugins.forEach((okPlugins) => {
        let indexToModify = newPluginList.findIndex((it) => it.id === okPlugins.id);
        if (indexToModify !== -1) {
          okPlugins.optionalPath = newPluginList[indexToModify].optionalPath;
        }
      });

      wholePlugins.map((it) => {
        if (it.optionalPath === '/') {
          //a = it.pages.findIndex(page=>{page.path==='/'})
          let photosPathIndex = it.pages.findIndex((page) => page.path === '/photos');
          if (photosPathIndex !== -1) {
            it.pages[photosPathIndex].path = it.optionalPath;
          }
        }
        return it;
      });
      return wholePlugins;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCountry]
  );
  //plugin [PENDING_MENU_REQUESTS.children tek elemena sahipse path'i '/' yap]
  useEffect(() => {
    let rt = [];
    let pg = [];
    let apg = [];
    let wholePlugins = [];
    const _topNavbarPages = [];
    let shouldSetPathToRoot = PLUGINS.length <= 1;
    let optionalPath = shouldSetPathToRoot ? '/' : '';
    PLUGINS.forEach((plugin) => {
      const ok = plugin({ app, setPluginTitle, currentCountry, optionalPath, fwfFlags });
      wholePlugins.push(ok);
    });
    const pluginList = workitout(wholePlugins);
    pluginList.forEach((plugin) => {
      const currentPages = plugin.pages
        .filter((page) => page.visibleOnTabNavbar)
        .map((data) => ({ ...data, id: plugin.id }));

      pg.push(...currentPages);
      rt.push(...plugin.pages.map((pg) => pg.path));
      apg.push(...plugin.pages);
    });
    // Below is added for menu to be created even if only children exist
    parents.forEach((parentPlugin) => {
      let instance = parentPlugin({
        app,
        setPluginTitle,
        currentCountry,
        optionalPath,
        fwfFlags,
      });

      _topNavbarPages.push(
        ...instance.pages
          .filter((page) => page.visibleOnTopNavbar)
          .map((data) => ({ ...data, id: instance.id }))
      );
    });

    let children;
    _topNavbarPages.forEach((topNav) => {
      topNav.selected = false;
      children = pg.filter((tabBar) => {
        if (topNav.component.name === (tabBar.parent && tabBar.parent.name)) {
          return tabBar;
        } else if (
          !tabBar.parent &&
          tabBar.visibleOnTabNavbar &&
          tabBar.visibleOnTopNavbar &&
          tabBar.component.name === topNav.component.name
        ) {
          return tabBar;
        }
        return false;
      });

      topNav.children = children;
    });

    setRoutes(rt);
    setPages(pg);
    setUnfilteredPages(apg);
    setTopNavbarPages(_topNavbarPages);
    navigationDispatch({ type: 'SET_NAVBAR_PAGES_TOP', topNavbarPages: _topNavbarPages });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PLUGINS, currentCountry, setPluginTitle, fwfFlags]);

  /*   useEffect(() => {
    function pluginLifecycle() {
      if (PLUGINS.length < 1) {
        return;
      }
      let shouldSetPathToRoot = false;
      if (PLUGINS.length < 1) {
        return;
      }
      PLUGINS.forEach((plugin) => {
        const optionalPath = shouldSetPathToRoot ? '' : '/';
        const ok = plugin({ app, setPluginTitle, currentCountry, optionalPath });
        shouldSetPathToRoot = true;
        // Init lifecycle hooks of plugins
        if (ok.init) ok.init();
      });
    }

    if (!currentCountry) {
      pluginLifecycle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry, setPluginTitle, PLUGINS]); */

  const notistackRef = React.useRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <StyledThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ReactRouter>
          <SnackbarProvider
            ref={notistackRef}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            maxSnack={1}
            autoHideDuration={3000}
            disableWindowBlurListener
            action={(key) => <Close onClick={onClickDismiss(key)} />}
          >
            {/*  {fwfLoading === false && ( */}
            <AppBarContext.Provider value={customContext}>
              <AppContext.Provider value={{ currentCountry, setCurrentCountry }}>
                <UserContext.Provider value={userContext}>
                  <Switch>
                    <Route exact path={routes}>
                      <DefaultLayout // #todo-1
                        sideBar={pages}
                        topNavbarPages={topNavbarPages}
                        setCustomContext={setCustomContext}
                        currentUser={currentUser}
                        currentCountry={currentCountry}
                        supportedCountries={supportedCountries}
                        fwfDateTimePicker={fwfFlags.fwfDateTimeFilter}
                        fwfFlags={fwfFlags}
                      >
                        {unfilterdPages.map((page) =>
                          renderRoute(page, setCustomContext, customContext, setPluginTitle)
                        )}
                      </DefaultLayout>
                    </Route>
                    <Route path={'/login'}>
                      <EmptyLayout>
                        <Route component={Login} path={'/login'} />
                      </EmptyLayout>
                    </Route>
                    {unfilterdPages.length !== 0 && (
                      <Route path="*">
                        <PageNotFound />
                      </Route>
                    )}
                  </Switch>
                </UserContext.Provider>
              </AppContext.Provider>
            </AppBarContext.Provider>
            {/* )} */}
          </SnackbarProvider>
        </ReactRouter>
        <AppDialog
          open={errorDialog.open}
          handleOkay={errorDialog.handler}
          title={errorDialog.title}
          message={errorDialog.message}
        />
      </ThemeProvider>
    </StyledThemeProvider>
  );
}

export default App;
