import styled from 'styled-components';

import { IconButton, Button } from '@material-ui/core';

export const BackButton = styled(IconButton)`
  margin-left: -12px;
  margin-right: 20px;
`;

export const DraftButton = styled(Button)`
  margin-left: auto;
`;

export const SubmitButton = styled(Button)`
  margin-left: auto;

  ${DraftButton} + & {
    margin-left: 20px;
  }
`;

export const InfoButton = styled(IconButton)`
  color: rgb(30, 145, 214);
`;
