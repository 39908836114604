import React, { useEffect, useReducer, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Box, Container, Grid, makeStyles, CircularProgress, Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { useSnackbar } from 'notistack';
import AppRightBar from '../../../App/layouts/AppRightBar';
import styles from './styles';
import { copyToClipboard, getMutationRequestDefaultData } from '../../../utility/information';
import RejectModal from '../../../App/components/RejectModal';
import { getMenuRejectReasons } from '../../common/constants/rejectReason';
import axios from '../../../App/utility/axios';
import config from '../../../App/config';
import { defaultErrorMessage } from '../../utility/information';
import {
  AGENT_QUEUE_REQUESTS,
  PENDING_MENU_REQUESTS,
  PROCESS_CHANGE_REQUESTS_QUERY,
  removePhotoRequests,
} from './helpers';
import { AppContext, UserContext } from '../../../App';
import AgentQueueTable from './AgentQueueTable';
import agentQueueReducer, { initialAgentQueueState } from '../../store/agentQueue/reducer';
import {
  resolveCheckedRequests,
  setAgentQueueData,
  setCheckedRequests,
  setRejectModalOpen,
  setVats,
} from '../../store/agentQueue/actions';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles(styles);
export const AgentQueueContext = React.createContext({});

const AgentQueue = ({
  fwfFlags,
  customContext,
  agentQueueResponseData,
  refetchAgentQueueData,
  loading,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = React.useContext(UserContext);
  const { currentCountry } = React.useContext(AppContext);
  const classes = useStyles();
  const [state, dispatch] = useReducer(agentQueueReducer, initialAgentQueueState);
  const globalDispatch = useDispatch();
  const filterState = useSelector((state) => state.filterState);

  const [processChangeRequests, { loading: isProcessing }] = useMutation(
    PROCESS_CHANGE_REQUESTS_QUERY,
    {
      refetchQueries: [AGENT_QUEUE_REQUESTS],
      onSuccess: () => {},
      onCompleted: async () => {
        dispatch(resolveCheckedRequests());
      },
      onError: () => {
        enqueueSnackbar(defaultErrorMessage, { variant: 'error' });
        dispatch(setCheckedRequests([]));
      },
    }
  );

  const { data: pendingMenuRequestsData } = useQuery(PENDING_MENU_REQUESTS, {
    variables: {
      globalEntityID: currentCountry.geid,
    },
  });
  useEffect(() => {
    return () => {
      globalDispatch({
        type: 'SET_SELECTED_NAVBAR_COUNT',
        data: {
          pluginComponentName: 'Home',
          count: null,
        },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (pendingMenuRequestsData) {
      globalDispatch({
        type: 'SET_SELECTED_NAVBAR_COUNT',
        data: {
          pluginComponentName: 'Home',
          count: pendingMenuRequestsData?.getPendingRequestsCount || 0,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingMenuRequestsData]);

  const getEligibleChanges = useCallback(
    (qcRequests) => {
      const queueOrder = fwfFlags?.fwfQueueOrder?.variation;
      // sort the requests
      const eligibleChanges = removePhotoRequests(qcRequests).sort((a, b) =>
        queueOrder === 'ASC'
          ? parseInt(a.item.timestamp) - parseInt(b.item.timestamp)
          : parseInt(b.item.timestamp) - parseInt(a.item.timestamp)
      );
      return eligibleChanges;
    },
    [fwfFlags]
  );

  const getInputParams = useCallback(() => {
    return {
      globalEntityID: currentCountry.geid,
      contentType: null,
      timeFrom: filterState.payload.input.timeFrom ? `${filterState.payload.input.timeFrom}` : null,
      timeTo: filterState.payload.input.timeTo ? `${filterState.payload.input.timeTo}` : null,
      search: filterState.payload.input.search || null,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry.geid, filterState.payload]);
  const getVariableParams = useCallback(() => {
    return {
      assignedAgent: filterState.payload.assignedToMe ? currentUser.sub : null,
      requestIdSuffix:
        filterState.payload?.requestIdSuffix?.length >= 6
          ? filterState.payload?.requestIdSuffix
          : null,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry.geid, filterState.payload]);

  useEffect(() => {
    if (agentQueueResponseData?.agentQueue?.qcRequests) {
      const eligibleChanges = getEligibleChanges(agentQueueResponseData.agentQueue.qcRequests);
      // Store all the changes
      dispatch(setAgentQueueData(eligibleChanges, agentQueueResponseData.agentQueue.pageToken));
    }
  }, [agentQueueResponseData, getEligibleChanges]);

  // Set the count in the tab on the top left
  /*   useEffect(() => {
    setRequestCount(pendingMenuRequestsData?.getPendingRequestsCount || 0);
  }, [setRequestCount, pendingMenuRequestsData]); */

  //Initialize a data refresh
  useEffect(() => {
    const interval = setInterval(() => {
      refetchAgentQueueData({
        input: getInputParams(),
        ...getVariableParams(),
      });
    }, 6e4);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry.geid, filterState.payload]);

  useEffect(() => {
    let inputParams = getInputParams();
    refetchAgentQueueData({
      input: inputParams,
      ...getVariableParams(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry.geid]);

  useEffect(() => {
    /**
     * Only fetch VAT tax if in a country with VAT tax visibility. Otherwise, we will keep getting
     * 404 and polluting our RUM dashboard.
     */
    if (fwfFlags?.fwfVATTaxVisibility?.variation) {
      const getVats = async () => {
        try {
          const response = await axios(
            `${config.api}/internal/vats?country=${currentCountry.code}`,
            {
              headers: { 'Content-Type': 'application/json' },
            }
          );
          dispatch(setVats(response.data));
        } catch (error) {
          console.log(error);
        }
      };
      getVats();
    }
  }, [currentCountry.code, fwfFlags]);

  const onCopyVendorCode = (vendorCode) => (evt) => {
    evt.stopPropagation();
    copyToClipboard(vendorCode);
    enqueueSnackbar('Successfully copied the vendor code to clipboard', { variant: 'success' });
  };

  const onBatchApprove = () => {
    processChangeRequests({
      variables: {
        input: state.checkedRequests.map((request) => ({
          ...getMutationRequestDefaultData(request, currentUser, currentCountry),
          type: 'APPROVE',
        })),
      },
    }).then(() => {
      enqueueSnackbar(
        `Successfully approved request${state.checkedRequests.length > 1 ? 's' : ''}`,
        {
          variant: 'success',
        }
      );
    });
  };

  const onBatchRejectionButtonClick = () => {
    dispatch(setRejectModalOpen(true));
  };

  const onBatchReject = (reasons) => {
    processChangeRequests({
      variables: {
        input: state.checkedRequests.map((request) => ({
          ...getMutationRequestDefaultData(request, currentUser, currentCountry),
          type: 'REJECT',
          rejectionReasons: reasons,
        })),
      },
    }).then(() => {
      enqueueSnackbar(
        `Successfully rejected request${state.checkedRequests.length > 1 ? 's' : ''}`,
        {
          variant: 'error',
        }
      );
      dispatch(setRejectModalOpen(false));
    });
  };

  const modalRooter = document.getElementById('appbar');

  const agentQueueContext = {
    state,
    dispatch,
    fetchData: refetchAgentQueueData,
    isFetchingData: loading,
  };

  return (
    <AgentQueueContext.Provider value={agentQueueContext}>
      <div style={{ display: 'flex', width: '100%' }}>
        {modalRooter && (
          <AppRightBar modalRoot={modalRooter}>
            <Box>
              <Button
                variant={'contained'}
                color={'primary'}
                style={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                  marginRight: 8,
                }}
                disabled={isProcessing || state.checkedRequests.length === 0}
                onClick={onBatchApprove}
              >
                <CheckCircleIcon />
                <Box ml={1}>{isProcessing ? 'Approving' : 'Approve'}</Box>
              </Button>
              <Button
                variant={'outlined'}
                disabled={isProcessing || state.checkedRequests.length === 0}
                onClick={onBatchRejectionButtonClick}
                style={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                  boxShadow: 'none',
                  marginRight: 8,
                }}
              >
                <CancelIcon />
                <Box ml={1}>{isProcessing ? 'Rejecting' : 'Reject'}</Box>
              </Button>
              {state.checkedRequests.length} selected
            </Box>
          </AppRightBar>
        )}
        <Container maxWidth="xl">
          <Grid item>
            <AgentQueueTable
              fwfFlags={fwfFlags}
              currentCountry={currentCountry}
              onCopyVendorCode={onCopyVendorCode}
              processChangeRequests={processChangeRequests}
              store={[state, dispatch]}
            />
            {state.isProcessingLazyLoad && (
              <div className={classes.loadingDiv}>
                <CircularProgress />
              </div>
            )}
          </Grid>
        </Container>
        <div>
          <RejectModal
            loading={isProcessing}
            header="Choose Reject Reason"
            subheader={
              'Are you sure there is nothing you can do to approve this item? This action is permanent. The vendor will see the rejection reason you choose.'
            }
            isRejectModalOpen={state.rejectModalOpen}
            setIsRejectModalOpen={(value) => dispatch(setRejectModalOpen(value))}
            onSubmit={onBatchReject}
            rejectReasons={getMenuRejectReasons(currentCountry.geid)}
          />
        </div>
      </div>
    </AgentQueueContext.Provider>
  );
};

export default AgentQueue;
