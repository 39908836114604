import React from 'react';
import { SelectProps } from './types';
import { MenuItem } from '@material-ui/core';
import { IconBox, Select as StyledSelect } from './style';

const Select: React.FC<SelectProps> = ({ value, options, onChange, defaultValue }) => {
  const isControlled = value === undefined;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  const valueProps = isControlled ? { defaultValue: defaultValue } : { value: value };

  return (
    <StyledSelect
      data-test-id="select-dropdown"
      onChange={handleChange}
      inputProps={{ 'data-test-id': 'select-dropdown' }}
      {...valueProps}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value} data-test-id={`select-menuitem-${option.value}`}>
          {option.icon && <IconBox component="span">{option.icon}</IconBox>}

          {option.label}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default Select;
