import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  border: 1px solid red;
  padding: 15px;
  color: red;
`;

export interface ErrorMessageProps {
  message: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return <Wrapper>{message}</Wrapper>;
};

export default ErrorMessage;
