import React, { useState, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { gql, useMutation } from '@apollo/client';

import LoadingButton from '../LoadingButton';
import Notification from '../../../../../App/components/Notification';

const PUBLISH_MENU_QUERY = gql`
  mutation publishMenu($menu: VendorOnboardingMenuPushMenu!) {
    VendorOnboardingMenuPushToPandora(input: $menu) {
      id
      restaurantName
    }
  }
`;

const PublishMenuDialog = ({ open, menuDispatch, menu }) => {
  const [vendorCode, setVendorCode] = useState('');
  const [openSuccessNotification, setOpenSuccessNotification] = useState(false);
  const [openErrorNotification, setOpenErrorNotification] = useState(false);
  const [publishMenu, { loading: mutationLoading }] = useMutation(PUBLISH_MENU_QUERY, {
    variables: {
      menu: {
        restaurantId: menu.restaurantId,
        vendorCode,
      },
    },
    onCompleted: (data) => {
      setVendorCode('');
      setOpenSuccessNotification(true);
      setTimeout(() => {
        menuDispatch({ type: 'CLOSE_DIALOG' });
      }, 2000);
    },
    onError: (err) => {
      console.error(err);
      setOpenErrorNotification(true);
    },
  });

  const handleClose = useCallback(() => {
    menuDispatch({ type: 'CLOSE_DIALOG' });
  }, [menuDispatch]);

  const handlePublishMenu = (menu) => () => {
    publishMenu({ variables: { menu: { restaurantId: menu.id, vendorCode } } });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'md'}
        aria-labelledby="publish-menu-dialog-title"
      >
        <DialogTitle id="publish-menu-dialog-title">
          Publish {menu.restaurantName}&#39;s menu to Pandora Backend
        </DialogTitle>
        <DialogContent>
          <List component="div" role="list">
            <ListItem role="listitem">
              <ListItemText
                primary="Restaurant Name"
                primaryTypographyProps={{}}
                secondary={menu.restaurantName}
              />
            </ListItem>
            <ListItem role="listitem">
              <ListItemText primary="Salesforce ID (GRID)" secondary={menu.salesForceId} />
            </ListItem>
            <ListItem role="listitem">
              <ListItemText primary="Data Source" secondary={menu.dataSource} />
            </ListItem>
          </List>
          <TextField
            required
            autoFocus
            variant="outlined"
            margin="dense"
            id="vendorCode"
            label="Vendor Code"
            type="text"
            fullWidth
            placeholder="Vendor Code"
            onChange={(evt) => {
              setVendorCode(evt.target.value);
            }}
            value={vendorCode}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" style={{ textTransform: 'none' }}>
            Cancel
          </Button>
          <LoadingButton
            disabled={mutationLoading || !vendorCode}
            pending={mutationLoading}
            onClick={handlePublishMenu(menu)}
            color="primary"
            variant="contained"
            style={{ textTransform: 'none' }}
          >
            Publish Menu
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Notification
        variant="success"
        open={openSuccessNotification}
        handleClose={() => setOpenSuccessNotification(false)}
        message="The menu has been published successfully."
      />
      <Notification
        variant="error"
        open={openErrorNotification}
        handleClose={() => setOpenErrorNotification(false)}
        message="Something went wrong. Please try again later."
      />
    </>
  );
};

export default PublishMenuDialog;
